import axios from "axios";
import Moment from "react-moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { apiUrl } from "../../environment/environment";
import Pagination from "../../Pagination/Pagination";

const PendingWorkout = () => {

  const navigate = useNavigate()
  const { page } = useParams()
  const [pageNum, setPageNum] = useState(+page);
  const [noOfRecords, setNoOfRecords] = useState(0);
  const limitValue = 10;

  const [srlist, setSessionRequestList] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    GetList(pageNum || 1);
  }, []);

  async function GetList(val) {
    const user = sessionStorage.getItem("user");
    const userData = JSON.parse(user);
    setLoading(true)
    // document.querySelector(".loading").classList.remove("d-none");
    // setTrainerId(id);
    const userParam = {
      user_id: userData._id,
      limitValue: limitValue,
      pageNumber: val || pageNum,
    };
    await axios
      .post(`${apiUrl}/client/session/getAllworkout`, userParam)
      .then(function (response) {
        setTimeout(() => {
          setLoading(false)
          setNoOfRecords(response.data.result[0].totalCount[0].count || 0)
          setSessionRequestList(response.data.result[0].paginatedResults);
        }, 500);
        // setSessionRequestList(...srlist, response.data.result);
        /* renderArr(response.data.result); */
      })
      .catch(function (error) {
        setLoading(false)
      });
  }

  const curPage = (pageNum) => {
    navigate(`/pendingworkout/${pageNum}`);
    setPageNum(pageNum);
    if (isSearching) {
      setLoading(true);
      setTimeout(async() => {
        await searchClick(searchname,pageNum)
        setLoading(false);
      }, 500);
    }else{
      GetList(pageNum);
    }
  };

  const [isSearching, setSearching] = useState(false);
  const [searchname, setSearchName] = useState("");

  const handlsearchInputs = (e) => {
    setSearchName(e.target.value);
    if (e.target.value !== "") {
      setSearching(true);
      searchClick(e.target.value);
    } else {
      setSearching(false);
      GetList(pageNum);
      setPageNum(pageNum)
    }
  };

  const searchClick = async (searchData, pageNumber) => {
    const obj = {
      limitValue: limitValue,
      pageNumber: pageNumber || pageNum,
    };

    const response = await axios.post(`${apiUrl}/client/session/searchallworkout`, { searchname: searchData, obj }, {})

    if (response.data.status === 1) {
      setSessionRequestList(response?.data?.result?.paginatedResults)
      setNoOfRecords(response?.data?.result?.totalCount[0]?.count || 0)
      setSearching(true);
    }
  }

  const clearClick = async (e) => {
    setSearchName("");
    setSearching(false);
    GetList(pageNum);
  };

  return (
    <>
      <div className="sub-head-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-12">
              <h1 className="main_title">Pending Workout Forms</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-md-12 col-12 p-0">
          {loading &&
            <div id="loader" className="loading">
              <div className="mainloader"></div>
            </div>}
          <div className={loading === false && ("trainer-main-box")}>
            <div className="row">
              <div className="col-md-12 col-12">
              {/* <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4">
                <div className="input-group position-relative">
                  <input
                    className="input-box search-input"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => handlsearchInputs(e)}
                    name="searchinput"
                    value={searchname}
                    autoComplete="off"
                  />
                  {isSearching ? (
                    <>
                      <button className="search_btn mt-0" onClick={clearClick}>
                        <i className="fa fa-times"></i>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="search_btn mt-0"
                        onClick={() => searchClick("")}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </>
                  )}
                </div>
                </div>
              </div> */}
                {srlist.length > 0 ? (
                  <>
                    <div className="history-table table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="wd70">Sr.no</th>
                            <th>Profile</th>
                            <th className="text-left">Clients</th>
                            <th className="text-left">Workout Date</th>
                            <th>Status</th>
                            {/* <th><strong>Action</strong></th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {srlist.length > 0 ? (
                            srlist.map((elem, index) => {
                              return (
                                <>
                                  <tr>
                                    <td>{index + 1 + (pageNum - 1) * limitValue}</td>
                                    <td>
                                      <div className="text-center">
                                        <img
                                          className="history-img"
                                          src={
                                            apiUrl + elem?.client_data?.profile
                                          }
                                          onError={(e) => {
                                            e.target.src =
                                              "/img/Small-no-img.png";
                                          }}
                                          alt="img"
                                          style={{ objectFit: "cover" }}
                                        />

                                      </div>
                                    </td>
                                    <td>
                                      <span className="history-name">
                                        {`${elem?.client_data?.firstname} ${elem?.client_data?.lastname}`}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        <Moment
                                          format="DD MMMM YYYY, hh:mm A"
                                          date={elem.date}
                                        />
                                      </span>
                                    </td>

                                    <td className="text-center">
                                      {elem.sessionworkout ? (
                                        <span className="text-success font-weight-bold p-status paid-s">
                                          Completed
                                        </span>
                                      ) : (
                                        <Link
                                          title="Go Workout Form" className="pending-s"
                                          to={`/sessiondetails?id=${elem._id}`}>
                                          <span className="text-warning font-weight-bold p-status pro-s">
                                            Pending
                                          </span>
                                        </Link>
                                      )}
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          ) : (
                            <>
                              <tr>
                                <td colspan="10">
                                  <div className="trainer-main-box">
                                    <div className="no-record-box">
                                      <img
                                        src="/img/no-record.png"
                                        alt="no-img"
                                      />
                                      <h4 className="font-weight-bold">
                                        No Pending Workout Forms Empty !
                                      </h4>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : loading === false && (
                  <div className="col-12">
                    <div className="trainer-main-box">
                      <div className="no-record-box">
                        <img src="/img/no-record.png" alt="no-img" />
                        <h4 className="font-weight-bold">
                          No Pending workout forms found !
                        </h4>
                        <p>You can view the workout forms here after completing a session with a member</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                <Pagination
                  className="pagination-bar"
                  currentPage={pageNum}
                  totalCount={noOfRecords}
                  pageSize={limitValue}
                  onPageChange={(page) => curPage(page)}
                />
              </div>
              {/* <div className="col-md-12 col-12 text-center mt-5">
                            <button className="training_btn w-25 mx-auto">Export Report</button>
                        </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingWorkout;
