const local = {
  apiUrl: "http://localhost:3155",
  // apiUrl: "https://api.chealth.in",
  apiKey: "AIzaSyBwHLFyn0iwn-ncdu4Um6hI2FBt7egMVng",
  authDomain: "commonhealth-66965.firebaseapp.com",
  projectId: "commonhealth-66965",
  storageBucket: "commonhealth-66965.appspot.com",
  messagingSenderId: "1019885696964",
  appId: "1:1019885696964:web:75df76d4ff319b426b9691",
  measurementId: "G-SHHT2D8G05",
};

//For staging server
const staging = {
  // apiUrl: "http://localhost:3155",
  apiUrl: "https://api.chealth.in",
  apiKey: "AIzaSyBwHLFyn0iwn-ncdu4Um6hI2FBt7egMVng",
  authDomain: "commonhealth-66965.firebaseapp.com",
  projectId: "commonhealth-66965",
  storageBucket: "commonhealth-66965.appspot.com",
  messagingSenderId: "1019885696964",
  appId: "1:1019885696964:web:75df76d4ff319b426b9691",
  measurementId: "G-SHHT2D8G05",
};

//For production server
const production = {
  // apiUrl: "http://localhost:3155",
  apiUrl: "https://api.chealth.in",
  apiKey: "AIzaSyBwHLFyn0iwn-ncdu4Um6hI2FBt7egMVng",
  authDomain: "commonhealth-66965.firebaseapp.com",
  projectId: "commonhealth-66965",
  storageBucket: "commonhealth-66965.appspot.com",
  messagingSenderId: "1019885696964",
  appId: "1:1019885696964:web:75df76d4ff319b426b9691",
  measurementId: "G-SHHT2D8G05",
};

if (process.env.REACT_APP_ENV === "local") module.exports = local;
else if (process.env.REACT_APP_ENV === "staging") module.exports = staging;
else if (process.env.REACT_APP_ENV === "production")
  module.exports = production;
else module.exports = staging;
