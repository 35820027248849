import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
// import { Collapse } from "react-bootstrap";
import swal from "sweetalert";
import { apiUrl } from "../../environment/environment";
import { useNavigate } from "react-router-dom";

const initialValue = {
  oldpassword: "",
  password: "",
  confirmpassword: "",
};

function ManageNotifications() {
  // const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [textNotifications, setTextNotification] = useState(false);
  const [maillinglist, setMaillinglist] = useState(false);
  const [emailNotifications, setEmailNotification] = useState(false);
  const [notifications, setNotifications] = useState(false);
  // const setMins = [10, 15, 20, 30, 40, 50];

  const [user, setUser] = useState(initialValue);
  const [errors, setErrors] = useState({});
  const [isOwdHidden, setIsOwdHidden] = useState(true);
  const [isNewHidden, setIsNewHidden] = useState(true);
  const [isConfHidden, setIsConfHidden] = useState(true);

  // const handleInputs = (e) => {
  // setUser({ ...user, [e.target.name]: e.target.value });
  // };

  useEffect(() => {
    GetList();
  }, []);

  const handleInputs = (e) => {
    let name = e.target.name;
    let allNotifications = {
      app: notifications,
      text: textNotifications,
      email: emailNotifications,
      mailing: maillinglist,
    };

    if (name === "emailnotifications") {
      setEmailNotification(!emailNotifications);
      allNotifications.email = !emailNotifications;
    }
    if (name === "maillinglist") {
      setMaillinglist(!maillinglist);
      allNotifications.mailing = !maillinglist;
    }
    if (name === "textnotifications") {
      setTextNotification(!textNotifications);
      allNotifications.text = !textNotifications;
    }
    if (name === "notifications") {
      setNotifications(!notifications);
      allNotifications.app = !notifications;
    }
    updateNotification(allNotifications);
  };

  const [pwdPop, setPwdPop] = useState(false);
  const oldpasswordRef = useRef()
  const passwordRef = useRef()
  const confirmPasswordRef = useRef()

  const isRequired = (fieldName) => {
    return ['oldpassword', 'password', 'confirmpassword'].includes(fieldName)
  }

  const handleInputsEdit = (e) => {
    const { name, value } = e.target
    if (value.trim() === "" && isRequired(name)) {
      let errorMessage = '';
      if (name === 'oldpassword') {
        errorMessage = "Please enter old password."
      } else if (name === 'password') {
        errorMessage = "Please enter new password."
      } else if (name === 'confirmpassword') {
        errorMessage = "Please enter confirm password."
      }
      setErrors((prevError) => ({ ...prevError, [name]: errorMessage }));
    } else if (name === 'oldpassword' && value.length < 6) {
      setErrors((prevError) => ({ ...prevError, [name]: "Old password length must be at least 6 characters" }));
    } else if (name === 'password' && value.length < 6) {
      setErrors((prevError) => ({ ...prevError, [name]: "New password length must be at least 6 characters" }));
    } else if (name === 'confirmpassword' && value.length < 6) {
      setErrors((prevError) => ({ ...prevError, [name]: "Confirm password length must be at least 6 characters" }));
    } else {
      setErrors((prevError) => ({ ...prevError, [name]: '' }));
    }

    setUser({ ...user, [name]: value });
  };


  const PostEditProfile = async (e) => {
    e.preventDefault();

    let isValid = true;
    var errormsg = {};

    if (user.oldpassword === "") {
      errormsg.oldpassword = "Please enter old password";
      isValid = false;
    } else if (user.oldpassword.length < 6) {
      errormsg.oldpassword = "Old password length must be at least 6 characters";
      isValid = false;
    }

    if (user.password === "") {
      errormsg.password = "Please enter new password";
      isValid = false;
    } else if (user.password.length < 6) {
      errormsg.password = "New password length must be at least 6 characters";
      isValid = false;
    }

    if (user.confirmpassword === "") {
      errormsg.confirmpassword = "Please enter confirm password";
      isValid = false;
    } else if (user.confirmpassword.length < 6) {
      errormsg.confirmpassword = "Confirm password length must be at least 6 characters";
      isValid = false;
    } else if (user.confirmpassword !== user.password) {
      errormsg.confirmpassword = "Password & confirm password not match";
      isValid = false;
    }

    if (Object.keys(errormsg).length > 0) {
      if (errormsg.oldpassword) {
        oldpasswordRef.current.focus()
      } else if (errormsg.password) {
        passwordRef.current.focus()
      } else if (errormsg.confirmpassword) {
        confirmPasswordRef.current.focus()
      }
    }

    setErrors(errormsg);
    if (isValid === true) {
      let obj = {
        oldpassword: user.oldpassword,
        password: user.password,
        confirmpassword: user.confirmpassword,
      };
      // document.querySelector(".loading").classList.remove("d-none");
      setPwdPop(true);
      await axios
        .post(`${apiUrl}/trainer/account/changePassword`, obj)
        .then((response) => {
          if (response.data.status === 1) {
            setTimeout(() => {
              setUser(initialValue)
              swal({
                title: "success !",
                text: response.data.message,
                icon: "success",
                button: true,
              });
              setPwdPop(false);
              // navigate("/manageNotifications")
            }, 500);
          } else {
            setPwdPop(false);
            setIsLoader(false);
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
            //window.alert(response.data.message);
          }
        })
        .catch(function (error) {
          document.querySelector(".loading").classList.add("d-none");
          setIsLoader(false);
          setPwdPop(false);

        });
    }
  };

  async function GetList() {
    setIsLoader(true);
    await axios
      .get(`${apiUrl}/trainer/account/getprofile`, {}, {})
      .then(function (response) {
        if (response.data.status === 1) {
          setTimeout(() => {
            setTextNotification(
              response?.data?.result?.textnotifications || textNotifications
            );
            setMaillinglist(
              response?.data?.result?.maillinglist || maillinglist
            );
            setEmailNotification(
              response?.data?.result?.emailnotifications || emailNotifications
            );
            setNotifications(
              response?.data?.result?.notification || notifications
            );
            setIsLoader(false);
          }, 200);
        } else {
          setIsLoader(false);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        setIsLoader(false);
        swal({
          title: "Error!",
          text: error,
          icon: "error",
          button: true,
        });
      });
  }

  async function updateNotification(allNotifications) {
    setIsLoader(true);
    await axios
      .post(`${apiUrl}/trainer/account/updateNotification`, {
        notification: allNotifications,
      })
      .then(function (response) {        
        if (response.data.status === 1) {
          
          setTextNotification(response?.data?.result?.textnotifications);
          setMaillinglist(response?.data?.result?.maillinglist);
          setEmailNotification(response?.data?.result?.emailnotifications);
          setNotifications(response?.data?.result?.notification);
          setTimeout(() => {         
            setIsLoader(false);
            swal({
              title: "Success!",
              text: response.data.message,
              icon: "success",
              button: true,
            });
          }, 500)
          
        } else {
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        setTimeout(() => {         
          setIsLoader(false);
        }, 500);
        swal({
          title: "Error!",
          text: error,
          icon: "error",
          button: true,
        });
      });
  }
  return (
    <>
      <div className="sub-head-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-12">
              <h1 className="main_title">Notifications</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-md-12 col-12 p-0">
          {isLoader && (
            <div className="loading">
              <div className="mainloader"></div>
            </div>
          )}
          <div className="noti_fications">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="row">
                  <div className="col-md-12 col-12 mb-3">
                    <div className="trainer-main-box">
                      <div className="d-flex justify-content-between">
                        <h4 className="title_notifi">Text Notifications</h4>
                        <span className="float-right">
                          <label className="switch">
                            <input
                              onChange={(e) => handleInputs(e)}
                              name="textnotifications"
                              defaultChecked={textNotifications}
                              type="checkbox"
                              className="default"
                              checked={
                                textNotifications !== false ? "checked" : ""
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-12 mb-3">
                    <div className="trainer-main-box">
                      <div className="d-flex justify-content-between">
                        <h4 className="title_notifi">Mailling List</h4>
                        <span className="float-right">
                          <label className="switch">
                            <input
                              onChange={(e) => handleInputs(e)}
                              name="maillinglist"
                              type="checkbox"
                              className="default"
                              checked={maillinglist !== false ? "checked" : ""}
                            />
                            <span className="slider round"></span>
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="row">
                  <div className="col-md-12 col-12 mb-3">
                    <div className="trainer-main-box">
                      <div className="d-flex justify-content-between">
                        <h4 className="title_notifi">Web Notifications</h4>
                        <span className="float-right">
                          <label className="switch">
                            <input
                              onChange={(e) => handleInputs(e)}
                              name="notifications"
                              type="checkbox"
                              className="default"
                              checked={notifications !== false ? "checked" : ""}
                            />
                            <span className="slider round"></span>
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-12 mb-3">
                    <div className="trainer-main-box">
                      <div className="d-flex justify-content-between">
                        <h4 className="title_notifi">Email Notification</h4>
                        <span className="float-right">
                          <label className="switch">
                            <input
                              onChange={(e) => handleInputs(e)}
                              name="emailnotifications"
                              type="checkbox"
                              className="default"
                              checked={
                                emailNotifications !== false ? "checked" : ""
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-12 mb-3">
                <div className="trainer-main-box">
                  {pwdPop && (
                    <div className="loading">
                      <div className="mainloader"></div>
                    </div>
                  )}
                  <div className="justify-content-between">
                    <h4 className="title_notifi"> New Password</h4>
                    <hr />
                    <div className="row">
                      <div className="col-md-12 mt-2 mb-4">
                        <input
                          onChange={(e) => handleInputsEdit(e)}
                          value={user.oldpassword}
                          type={isOwdHidden === true ? "password" : "text"}
                          name="oldpassword"
                          className="input-box"
                          ref={oldpasswordRef}
                          placeholder="Old password"
                        // autocomplete="off"
                        />
                        <i
                          className={`fa fa-eye${isOwdHidden === false ? "" : "-slash"
                            } icon`}
                          onClick={() => setIsOwdHidden(!isOwdHidden)}
                        ></i>
                        <div className="text-danger">{errors.oldpassword}</div>
                      </div>
                      <div className="col-md-12 mb-4">
                        <input
                          onChange={(e) => handleInputsEdit(e)}
                          value={user.password}
                          type={isNewHidden === true ? "password" : "text"}
                          name="password"
                          ref={passwordRef}
                          className="input-box"
                          placeholder="New Password"
                        // autocomplete="off"
                        />
                        <i
                          className={`fa fa-eye${isNewHidden === false ? "" : "-slash"
                            } icon`}
                          onClick={() => setIsNewHidden(!isNewHidden)}
                        ></i>
                        <div className="text-danger">{errors.password}</div>
                      </div>
                      <div className="col-md-12 mb-4">
                        <input
                          onChange={(e) => handleInputsEdit(e)}
                          value={user.confirmpassword}
                          type={isConfHidden === true ? "password" : "text"}
                          name="confirmpassword"
                          className="input-box"
                          ref={confirmPasswordRef}
                          placeholder="Confirm password"
                        // autocomplete="off"
                        />
                        <i
                          className={`fa fa-eye${isConfHidden === false ? "" : "-slash"
                            } icon`}
                          onClick={() => setIsConfHidden(!isConfHidden)}
                        ></i>
                        <div className="text-danger">
                          {errors.confirmpassword}
                        </div>
                      </div>
                      <div className="col-xl-8 col-md-12 mx-auto col-12">
                        <div className="row">
                          <div className="col-md-6 col-sm-6 col-12 mb-4">
                            <button
                              onClick={() => navigate("/myprofile")}
                              className="training_btn"
                            >
                              Back
                            </button>
                          </div>
                          <div className="col-md-6 col-sm-6 col-12 mb-4">
                            <a
                              href={() => false}
                              onClick={(e) => {
                                PostEditProfile(e);
                              }}
                              className="training_btn d-block"
                            >
                              Save
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageNotifications;
