/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiUrl } from "../../environment/environment";
import swal from "sweetalert";
import Moment from "react-moment";
function ViewPhoto() {
  const naviagte = useNavigate();
  // const ProfileImage_URL = "/img/Small-no-img.png";
  // const [profileimagepreview, setProfileImagePreview] =
  //   useState(ProfileImage_URL);
  // const [profileimage, setProfileImage] = useState(null);
  const initState = { date: new Date(), list: [], base64Img: [] };
  const [getAllPhotos, setGetAllPhotos] = useState([]);
  const [isMountRender, setMountRender] = useState(true);
  const [trainerData, setTrainerData] = useState();

  useEffect(() => {
    if (isMountRender) return;
  }, [isMountRender]);
  useEffect(() => {
    setTrainerData(JSON.parse(sessionStorage.getItem("user")));
    setMountRender(false);
    // callToken();
    fetchProgressPhotos();
  }, []);

  const fetchProgressPhotos = () => {
    document.querySelector(".loading").classList.remove("d-none");
    axios
      .get(`${apiUrl}/client/account/getprogressphotos`, {}, {})
      .then(function (response) {
        if (response.data.status === 1) {
          setTimeout(() => {
            if (response.data.result && response.data.result.length > 0) {
              if (response.data.result.length > 0) {
                if (
                  response.data.result.filter(
                    (x) =>
                      new Date(x.date).toLocaleDateString() ===
                      new Date().toLocaleDateString()
                  ).length === 0
                ) {
                  response.data.result.push(initState);
                } else {
                  response.data.result.forEach((ele) => {
                    ele["base64Img"] = ele.list;
                  });
                }
              }
              setGetAllPhotos(response.data.result.reverse());
            } else {
              response.data.result.push(initState);
              setGetAllPhotos(response.data.result.reverse());
            }
            document.querySelector(".loading").classList.add("d-none");
          }, 500);
        } else {
          document.querySelector(".loading").classList.add("d-none");
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        document.querySelector(".loading").classList.add("d-none");
        window.alert(error);
      });
  };


  return (
    <>
      <div className="sub-head-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-12">
              <h1 className="main_title">Progress Photos</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-md-12 col-12 p-0">
          <div className="loading d-none">
            <div className="mainloader"></div>
          </div>
          <div className="trainer-main-box">
            <div className="row">
            <div className="col-12">
              <button class="download-table-xls-button btn btn-primary mb-4" onClick={() => naviagte("/editprofile")}>Back</button>
            </div>
              {getAllPhotos.length > 0 &&
                getAllPhotos.map((res, index) => {
                  if (res !== "") {
                    return (
                      <>
                        <div key={index} className="col-12 col-md-6 mb-3">
                          <div className="progress-box">
                            <div className="row" dataId={index}>
                              <div className="col-md-12 col-12 mb-3 px-md-2 pb-3 viewimg-bor">
                                <div className="d-flex">
                                  <div className="p-photo">
                                    <img
                                      src={apiUrl + trainerData.profile}
                                      onError={(e) => {
                                        e.target.src = "/img/Small-no-img.png";
                                      }}
                                      alt="Profile"
                                    />
                                  </div>
                                  <div className="progress-content">
                                    <p>Photos</p>
                                    {/* <span>{new Date(res.date).toDateString()}</span> */}
                                    <span>
                                      <Moment
                                        format="DD MMMM YYYY"
                                        date={res.date}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>

                              {/* show horizontal after upload */}
                              {res?.base64Img &&
                                res?.base64Img?.length > 0 &&
                                res?.base64Img
                                ? res?.base64Img.map((ele, index) => {
                                  if (ele?.type?.indexOf("image/") > -1) {
                                    return <></>;
                                  } else {
                                    return (
                                      <div
                                        key={index}
                                        className="col-lg-3 col-md-6 col-12 mb-2 px-md-2"
                                      >
                                        <div className="prog-img">
                                          <img
                                            src={`${ele?.indexOf("data:image/") > -1
                                              ? ele
                                              : apiUrl + ele
                                              }`}
                                            onError={(e) => {
                                              e.target.src =
                                                "/img/Small-no-img.png";
                                            }}
                                            alt="img"
                                          />
                                        </div>
                                      </div>
                                    );
                                  }
                                })
                                : res?.list &&
                                  res?.list?.length > 0 ?
                                  res?.list.map((ele, index) => {
                                    let isFile = ele.type
                                      ? ele.type.indexOf("image") > -1
                                      : false;
                                    if (!isFile) {
                                      return (
                                        <div
                                          key={index}
                                          className="col-lg-3 col-md-6 col-12 mb-2 px-md-2"
                                        >
                                          <div className="prog-img">
                                            <img
                                              src={apiUrl + ele}
                                              onError={(e) => {
                                                e.target.src =
                                                  "/img/Small-no-img.png";
                                              }}
                                              alt="img"
                                            />
                                          </div>
                                        </div>
                                      );
                                    }
                                    return <></>
                                  }) : <>
                                    <div className="no-record-box pb-2">
                                      <img src="/img/no-record.png" alt="no-img" />
                                      <p className="font-weight-bold">
                                        Sorry, Today You Have Not Upload Progress Photos Yet
                                      </p>
                                    </div>
                                  </>}
                            </div>
                            <Link className="uploadpro-btn" title="Upload Progress Photos" to={"/UploadProgressPhotos"}>Upload Progress Photo</Link>
                          </div>
                        </div>
                      </>
                    );
                  }
                  return <></>
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewPhoto;
