import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSocket } from "./context/SocketProvider";

function RequireAuth() {
  const socket = useSocket();
  const token = sessionStorage.getItem("token");
  const user = JSON.parse(sessionStorage.getItem("user"));
 
  // const [CookiesData, setCookiesData] = useState()
  useEffect(() => {
    if (user?._id) { 
      socket.emit("online", { userid: user._id });
    }
   
  }, [user, socket]);

  axios.defaults.headers.common["Authorization"] = token;

  if (!token) {
    return <Navigate path="/client/login" />;
  }
  return <Outlet />;
}

export default RequireAuth;
