import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import "firebase/messaging";
import { apiKey, authDomain, projectId, storageBucket, messagingSenderId, appId, measurementId } from './environment/environment';
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: apiKey,
  authDomain: authDomain,
  projectId: projectId,
  storageBucket: storageBucket,
  messagingSenderId: messagingSenderId,
  appId: appId,
  measurementId: measurementId

};
const app = firebase.initializeApp(firebaseConfig);

/* change messaging */
const messaging = firebase.messaging(app);
// const messaging = firebase.messaging();

/* old messaging */
// const messaging = (async () => {
//   try {
//       const isSupportedBrowser = await firebase.messaging.isSupported();
//       if (isSupportedBrowser) {
//           return firebase.messaging();
//           //getMessaging(config);
//       }
//       console.log('Firebase not supported this browser');
//       return null;
//   } catch (err) {
//       console.log(err);
//       return null;
//   }
//   })();

const publicKey = "BNCZfVxqsD2-hoyM93omkWWDrmDVqtJBKfr6vqjeuS29ToUbOoQM4vufrtnnedVjBoCMtgaOHNo0M2bV43GWg2Q";

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    // console.log(currentToken)
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
    // window.alert(error)
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
