import axios from 'axios';
import React, { useRef, useState } from 'react';
import { apiUrl } from '../../environment/environment';
import swal from 'sweetalert';
import { Link, useNavigate } from 'react-router-dom';

const initialValues = {
    email: "",
};

function ForgotPassword() {
    const naviagte = useNavigate();
    const token = sessionStorage.getItem('token');
    if (token !== null) {
        naviagte('/');
        window.location.reload();
    }

    const emailRef = useRef()

    const [user, setUser] = useState(initialValues);
    const [error, setError] = useState({})
    const [dataLoader, setDataLoader] = useState(false)

    const isRequiredField = (fieldName) => {
        return ['email'].includes(fieldName);
    };
    const handleInputs = (e) => {
        const { name, value } = e.target
        setUser({ ...user, [name]: value });
        let reg_email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (value.trim() === '' && isRequiredField(name)) {
            setError((prevError) => ({ ...prevError, [name]: `Please enter ${name}!` }));
        } else if (name === 'email' && value.trim() !== '' && !reg_email.test(value)) {
            setError((prevError) => ({ ...prevError, email: 'Please enter a valid email!' }));
        } else {
            setError((prevError) => ({ ...prevError, [name]: '' }));
        }
    };

    const PostForgotPassword = async (e) => {
        e.preventDefault();
        let errormsg = {}
        let isValid = true
        let reg_email = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

        if (user.email === "") {
            errormsg.email = "Please enter email"
            isValid = false
        } else if (!reg_email.test(user.email)) {
            errormsg.email = "Please enter a valid email!";
            isValid = false;
        }

        if (Object.keys(errormsg).length > 0) {
            if (errormsg.email) {
                emailRef.current.focus()
            }
        }
        setError(errormsg)
        if (isValid) {
            setDataLoader(true)
            await axios.post(`${apiUrl}/trainer/account/passwordlink`, { "email": user.email }, {
            }).then(function (response) {
                // document.querySelector('.loading').classList.add('d-none');
                if (response.data.status === 1) {
                    swal({
                        title: "Success!",
                        text: response.data.message,
                        icon: "success",
                        button: true
                    })
                    setDataLoader(false)
                    naviagte("/client/login");

                }
                else {
                    setDataLoader(false)
                    swal({
                        title: "Error!",
                        text: response.data.message,
                        icon: "error",
                        button: true
                    })
                    //window.alert(response.data.message);
                }
            }).catch(function (error) {
                window.alert(error)
                setDataLoader(false)
            });
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="commonbox">
                    <div className="row">
                        <div className="col-md-6 p-0">
                            <div className="loginimg">
                                <div className="col-md-12 logoblock">
                                    <h3>Forgot Password</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 p-0">

                            <div className="loginbox Forgotbox">
                                <div className="login-form">
                                    {dataLoader &&
                                        <div className="loading">
                                            <div className="mainloader"></div>
                                        </div>
                                    }
                                    <div className="col-12 text-center">
                                        <a className="login-logo" href="/">
                                            <img src="/img/common-health.png" alt="logo" />
                                        </a>
                                        <h4 className="log-text">Link to reset password will be sent to your email account</h4>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <label>Email</label>
                                        <input onChange={(e) => handleInputs(e)} name="email" ref={emailRef} type="text" className="w-100 input-box" placeholder="Email" />
                                        {error.email &&
                                            <span className='text-danger'>{error.email}</span>
                                        }
                                    </div>
                                    <div className="col-md-12">
                                        <button onClick={(e) => PostForgotPassword(e)} className="loginbtn mt-3 mb-3">Submit</button>
                                        {/* <a href="">Back to Login</a> */}
                                    </div>
                                    <div className="col-12 text-center"><Link to="/trainer/login" className="linktext text-login"><i className="fa fa-arrow-circle-left pr-2"></i>Back To Login</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;
