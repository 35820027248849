import React from "react";
import Header from "./Header";
import Footer from "./Footer";

import EliteTrainer from "./EliteTrainer";

const HowItWorks = () => {



  return (
    <>
      <div className="">
        <Header />
        <div className="contact-img">
          <div className="container-fluid">
            <h4 className="contact-text">
              How  <span className="bottom-bor">It Works</span>
            </h4>
          </div>
        </div>
        <section className="goals-block">
          <div className="container-fluid">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className="goals-content text-center">
                    <h1>Achieve your aim.</h1>
                    <h3>
                      Regular physical activity can improve your muscle strength
                      and boost your endurance
                    </h3>
                  </div>
                </div>
                <div className="col-12">
                  <img className="goal-img" src="/img/workout.jpg" alt="img1" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="howtocommonhealth-block">
          <div className="container-fluid">
            <div className="col-12">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="howtocommonhealth-content">
                    <h2>How to Common Health</h2>
                    <p>
                      The trainer creates one-on-one fitness programs for their
                      clients, motivating and guiding them to achieve their
                      goals. Clients may wish to lose weight or gain muscle, and
                      as a personal trainer, you'll teach and help them to
                      exercise properly using workouts and specific plans.
                    </p>
                  </div>
                  <div className="d-sm-flex mb-4">
                    <span className="h-icon">1</span>
                    <div className="hit-content">
                      <h3>Coaching Anytime</h3>
                      <p>
                        As a trainer submit your profile with your experience
                        history and start your live session with your client.
                      </p>
                    </div>
                  </div>
                  <div className="d-sm-flex mb-4">
                    <span className="h-icon">2</span>
                    <div className="hit-content">
                      <h3>Choose Your Trainer</h3>
                      <p>
                        From the trainer list, you can choose a trainer from our
                        expert trainers to see reviews and speciality and submit
                        the request for your training
                      </p>
                    </div>
                  </div>
                  <div className="d-sm-flex mb-4">
                    <span className="h-icon">3</span>
                    <div className="hit-content">
                      <h3>Workout</h3>
                      <p>
                        Select your session, and start your training with our
                        expert Trainer.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="ch-howitwork">
                    <img src="/img/howitwork.png" alt="htw" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="limits-block">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-12">
                <div className="dapp-img">
                  <img
                    className="mob-img"
                    src="/img/mobile-app.png"
                    alt="app"
                  />
                </div>
              </div>
              <div className="col-lg-7 col-12">
                {/* <h3>Limits do not exist</h3> */}
                <h2 className="p-training-text">
                  Common Health an Android/iOS app that provides online fitness
                  coaching with a real, certified personal trainer. Common
                  Health is ideal for anyone who wants to lose weight or build
                  muscle but doesn’t have the budget or the schedule to meet
                  with an in-person trainer.
                </h2>
                <div className="appstore d-sm-flex">
                  <a className="custom_btn mr-sm-3" href="#">
                    <i className="fab fa-google-play"></i>
                    <p>
                      <span>Available on</span>
                      <br />
                      Google Play
                    </p>
                  </a>
                  <a className="custom_btn" href="#">
                    <i className="fab fa-apple"></i>
                    <p>
                      <span>Download on</span>
                      <br />
                      App Store
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="trusted-block">
          <div className="container-fluid">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className="trusted-content text-center">
                    <h2>Our Professional Trainer</h2>
                  </div>
                </div>

              <EliteTrainer />
                {/* <div className="col-lg-3 col-md-6 col-12">
                  <div className="team-box">
                    <div className="team-img-box">
                      <div className="team-img">
                        <img src="/img/trainer3.jpg" alt="img" />
                      </div>
                    </div>
                    <div className="team-content">
                      <a href="team-detail.html" tabindex="-1">
                        <h3 className="h3-title">John Hard</h3>
                      </a>
                      <span>Fitness Trainer</span>
                      <div className="team-social">
                        <ul className="list-inline">
                          <li>
                            <a href="javascript:void(0);" tabindex="-1">
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" tabindex="-1">
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" tabindex="-1">
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="team-box">
                    <div className="team-img-box">
                      <div className="team-img">
                        <img src="/img/trainer5.jpg" alt="img" />
                      </div>
                    </div>
                    <div className="team-content">
                      <a href="team-detail.html" tabindex="-1">
                        <h3 className="h3-title">Ruth Edwards</h3>
                      </a>
                      <span>Fitness Trainer</span>
                      <div className="team-social">
                        <ul className="list-inline">
                          <li>
                            <a href="javascript:void(0);" tabindex="-1">
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" tabindex="-1">
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" tabindex="-1">
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="team-box">
                    <div className="team-img-box">
                      <div className="team-img">
                        <img src="/img/trainer1.jpg" alt="img" />
                      </div>
                    </div>
                    <div className="team-content">
                      <a href="team-detail.html" tabindex="-1">
                        <h3 className="h3-title">Desert Antony</h3>
                      </a>
                      <span>Fitness Trainer</span>
                      <div className="team-social">
                        <ul className="list-inline">
                          <li>
                            <a href="javascript:void(0);" tabindex="-1">
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" tabindex="-1">
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" tabindex="-1">
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default HowItWorks;
