import React from 'react'

function WaitingLoader() {
    return (
        <div className="trainer-loader">
            <div className="col-12 text-center">
                <div className="no-record-box circle-box">
                    <div className="circle-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <h4 className="wait-title">
                        Please wait...
                    </h4>

                </div>
            </div>
        </div>
    )
}

export default WaitingLoader