import axios from "axios";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { apiUrl } from "../environment/environment";
import swal from "sweetalert";

const initValue = { email: "" }
const Footer = () => {
  const token = sessionStorage.getItem("token");
  const isLogin = token != null ? true : false;
  const [newsLetter, setNewsLetter] = useState(initValue);
  const [errors, setErrors] = useState({});

  const isRequiredField = (fieldName) => {
    return ['email'].includes(fieldName);
  }

  const inputChange = (e) => {
    const { name, value } = e.target
    setNewsLetter({ ...newsLetter, [name]: value });
    let reg_email = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
    if (value.trim() === '' && isRequiredField(name)) {
      let errorMessage = '';
      if (name === "email") {
        errorMessage = "Please enter email";
      }
      setErrors((prevError) => ({ ...prevError, [name]: errorMessage }));

    } else if (name === 'email' && value.trim() !== '' && !reg_email.test(value)) {
      setErrors((prevError) => ({ ...prevError, email: 'Please enter valid email' }));
    } else {
      setErrors((prevError) => ({ ...prevError, [name]: '' }));
    }
  };

  const emailRef = useRef()

  const submitHandle = async (e) => {
    e.preventDefault();
    let isValid = true;
    var errormsg = {};
    let reg_email = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

    if (newsLetter.email === "") {
      errormsg.email = "Please enter Email.";
      isValid = false;
    } else if (reg_email.test(newsLetter.email) === false) {
      errormsg.email = "Please enter valid Email.";
      isValid = false;
    }
    if (Object.keys(errormsg)) {
      if (errormsg.email) {
        emailRef.current.focus()
      }
    }

    setErrors(errormsg);
    if (isValid === true) {
      await axios
        .post(`${apiUrl}/admin/newsletter`, newsLetter)
        .then(function (response) {
          if (response.data.status === 2) {
            setNewsLetter(initValue)
            swal({
              title: "Success!",
              text: response.data.message,
              icon: "success",
              button: true,
            });
          } else {
            swal({
              title: "info!",
              text: response.data.message,
              icon: "info",
              button: true,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  return (
    <>
      <footer className="footer-bg">
        <div className="container-fluid">
          <div className="col-12">
            <div className="row pb-3">
              <div className="col-lg-4 col-md-12 col-12">
                <div className="footer-logo">
                  <a href="/">
                    <img src="/img/ch-white-logo.png" alt="footer-logo" />
                  </a>
                </div>
                <div className="footer-content">
                  <p>
                    We have been designing websites and marketing strategies for
                    trainers and clients. We have a reputation for being fast,
                    friendly, reliable, and meticulous with details.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="footer-menu">
                  <h4>Our Links</h4>
                  <div className="line"></div>
                  <ul className=" list-inline">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/HowItWorks">How It Works</Link>
                    </li>
                    <li>
                      <Link to="/contactus">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="our-link">
                  <h4>Our Newsletter</h4>
                  <div className="line"></div>
                  {isLogin !== true && (
                    <>
                      <form
                        // onSubmit={submitHandle}
                        className="position-relative"
                      >
                        <input
                          className="input_form"
                          onChange={inputChange}
                          type="email"
                          ref={emailRef}
                          value={newsLetter.email}
                          name="email"
                          placeholder="Email Address"
                        />
                        <button className="signup_btn" onClick={(e) => submitHandle(e)}>Submit</button>
                      </form>
                      <div className="text-danger">{errors.email}</div>

                    </>
                  )}
                  <nav className="social-media mb-4 ml-0">
                    <Link
                      target="_blank"
                      to="https://www.instagram.com/"
                      alt="social-media"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </Link>
                    <Link
                      target="_blank"
                      to="https://www.facebook.com/"
                      alt="social-media"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </Link>
                    <Link
                      target="_blank"
                      to="https://about.twitter.com/en"
                      alt="social-media"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter"></i>
                    </Link>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="footer-btm">
              <p className="mb-0">Copyright © 2024. All rights reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
