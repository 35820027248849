import axios from "axios";
import React from "react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { apiUrl } from "../environment/environment";

const Header = () => {
  const token = sessionStorage.getItem("token");
  const isLogin = token != null ? true : false;
  const navigate = useNavigate();
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const usertype = sessionStorage.getItem("usertype");

  const LogOut = async (e) => {
    e.preventDefault();

    if (usertype === "trainer") {
      const token = sessionStorage.getItem("token");
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
        axios
          .get(`${apiUrl}/trainer/account/logout`, {}, {})
          .then(function (response) {
            if (response.data.status === 1) {
              sessionStorage.clear();
              localStorage.clear();
              window.location.href = "/";
              // navigate("/",{replace:true});
            }
          })
          .catch(function (error) {});
      }
    } else {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = "/";
      // navigate("/",{replace:true});
    }
  };

  return (
    <>
     
      <header className="navbar-header">
        <div className="container-fluid">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-xl-2 col-lg-3 col-12">
                <a className="logo-web" href="/">
                  <img src="/img/common-health.png" alt="logo" />
                </a>
                <button
                  className="custom-toggler navbar-toggler d-lg-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                  aria-controls="collapseExample"
                  aria-expanded={!isNavCollapsed ? true : false}
                  aria-label="Toggle navigation"
                  onClick={handleNavCollapse}
                >
                  <i className="fas fa-bars"></i>
                </button>
              </div>
              <div className="col-xl-10 col-lg-9 col-12 pl-md-0">
                <div
                  className={`${
                    isNavCollapsed ? "collapse" : ""
                  } navbar-collapse`}
                  id="collapseExample"
                >
                  <button
                    className="custom-toggler navbar-toggler close_b d-lg-none d-block"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample"
                    aria-controls="collapseExample"
                    aria-expanded={!isNavCollapsed ? true : false}
                    aria-label="Toggle navigation"
                    onClick={handleNavCollapse}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                  <div className="header-nav">
                    <div className="row">
                      <div className="col-lg-12 col-12">
                        <div className="d-lg-flex justify-content-end">
                          <nav className="head-menu">
                            <NavLink exact activeClassName="active" to="/">
                              Home
                            </NavLink>
                            <NavLink  to="/howitworks">
                              How It Works
                            </NavLink>
                            <NavLink  to="/contactus">
                              Contact Us
                            </NavLink>
                          </nav>
                          
                          <div className="">
                            {isLogin === true ? (
                              <>
                                <div className="d-flex inline">
                                  <button
                                    className="main-login border-0 mx-2"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (usertype === "client") {
                                        navigate("/trainer?status=1");
                                      } else if (usertype === "trainer") {
                                        navigate("/schedulerequest");
                                      }
                                      window.location.reload();
                                    }}
                                  >
                                    Dashboard
                                  </button>
                                  <button
                                    className="main-login border-0"
                                    onClick={(e) => LogOut(e)}
                                  >
                                    Log Out
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                <NavLink className="main-login" to="/client/login">
                                  Login
                                </NavLink>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
