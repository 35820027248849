import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { apiUrl } from "../../environment/environment";
import axios from "axios";
// import { verifytokenCall } from '../Others/Utils.js';
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import ShowAvailableSessions from "./ShowAvailableSessions";
function PurchaseSession() {
  const navigate = useNavigate();


  const [plan, setPlan] = useState("");
  const [planName, setPlanName] = useState("Standard");

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([]);



  const [planDetail, setPlanDetail] = useState({
    date: new Date(),
    planType: plan,
    sessions: '',
    amount: '',
  });
  const [currentSessionModal, setCurrentSessionModal] = useState(false);



  useEffect(() => {
    GetList();
  }, []);

  const OnchnagePlan = (e, ele) => {

    setPlanName(ele.plantype)
    $("radio").val("");
    if (e.currentTarget.value) {
      setPlan(e.currentTarget.value);
    }

    var plnArray = e.currentTarget.value.split("-");
    setPlanDetail({
      date: new Date(),
      planType: plnArray[0] === "S" ? "Standard" : "Elite",
      sessions: ele.noofsession,
      amount: ele.amount,
    });
  };

  const callPurchasePlan = (e) => {
    e.preventDefault();
    if (plan) {
      sessionStorage.setItem("PurchasePlan", JSON.stringify(planDetail));
      navigate("/clientpayment");
    } else {
      swal({
        title: "Please choose plan!",
        text: "Please select a valid plan from the available options.",
        icon: "info",
        button: true,
      });
    }
  };

  async function GetList() {
    setLoading(true)
    // document.querySelector(".loading").classList.remove("d-none");
    await axios
      .get(`${apiUrl}/payment/getplan`, {}, {})
      .then(function (response) {
        if (response.data.status === 1) {
          setTimeout(() => {
            setList(response.data.result);
            setLoading(false)
          }, 500);
          // document.querySelector(".loading").classList.add("d-none");
        }
      })
      .catch(function (error) {
        setLoading(false)
        // document.querySelector(".loading").classList.add("d-none");
      });
  }

  // console.log("planDetail",planDetail);
  // console.log("plan",plan);
  const { state } = useLocation()

  useEffect(() => {
    if (state) {
      const name = state.charAt(0).toUpperCase() + state.slice(1).toLowerCase()
      setPlanName(name)
    }
  }, [state])

  return (
    <>
      {/* {loading &&
        <div className="loading">
          <div className="mainloader"></div>
        </div>
      } */}
      <div className="sub-head-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-12">
              <h1 className="main_title">Choose Your Plan</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-12 p-0">
          {loading &&
            <div className="loading height-full">
              <div className="mainloader"></div>
            </div>
          }
          <div className="trainer-main-box choose-plan">

            <div className="col-lg-10 offset-lg-1 col-12">
              <div className="chooseplan">
                <div className="row">
                  <p>
                    All of our trainers are certified & trustworthy! Elite
                    trainers are simply more experienced
                  </p>
                  <div className="col-xl-5 col-md-6 mb-3">
                    <div className={`planblock bg-white ${planName === "Standard" ? "active" : ""}`}>
                      <div className="headerblock">
                        <h4>Standard</h4>
                      </div>
                      <div className="custom-radio-wrap">
                        <form className="purchase-box">
                          {list.length > 0 ? (
                            list.map((ele, index) => {
                              if (ele.plantype === "Standard") {
                                return (
                                  <div
                                    key={"index" + index}
                                    className="form-group"
                                  >
                                    {/* <input checked={plan === `S-1-${ele.noofsession}`} onChange={(e) => { OnchnagePlan(e, ele); }} value={`S-1-${ele.noofsession}`} id={`Standard${index}`} type="radio" name="custom-radio-btn" /> */}
                                    <input
                                      checked={plan === `S-1-${ele.noofsession}`}
                                      onChange={(e) => {
                                        OnchnagePlan(e, ele);
                                      }}
                                      value={`S-1-${ele.noofsession}`}
                                      id={`Standard${index}`}
                                      type="radio"
                                      name="custom-radio-btn"
                                    />
                                    <label
                                      className="custom-radio"
                                      htmlFor={`Standard${index}`}
                                    >
                                      <span className="label-text">
                                        {ele.noofsession === "1"
                                          ? "Single"
                                          : `${ele.noofsession} Sessions`}{" "}
                                        <span className="float-right">
                                          $ {ele.amount}
                                          <span className="text-gray font-13"></span>
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                );
                              } else {
                                return <></>;
                              }
                            })
                          ) : (
                            <>
                              {/* <div className="no-record-box purchase-no">
                              <img src="/img/no-record.png" alt="no-img" />
                              <h4 className="font-weight-bold text-dark">
                                No session found!
                              </h4>
                            </div> */}
                            </>
                          )}
                          {/* <div className="form-group">
                                                <input checked={plan === 'S-12-660'} onChange={(e) => { OnchnagePlan(e); }} value={'S-12-660'} id="Standard2" type="radio" name="custom-radio-btn" />
                                                <label className="custom-radio" htmlFor="Standard2"></label>
                                                <span className="label-text">12 Sessions<span className="float-right">$660</span></span>
                                                <p className="pl-4 ml-2">Per Session $55 <br />$55*12</p>
                                            </div>

                                            <div className="form-group">
                                                <input checked={plan === 'S-36-1800'} onChange={(e) => { OnchnagePlan(e); }} value={'S-36-1800'} id="Standard3" type="radio" name="custom-radio-btn" />
                                                <label className="custom-radio" htmlFor="Standard3"></label>
                                                <span className="label-text">36 Sessions<span className="float-right">$1800</span></span>
                                                <p className="pl-4 ml-2">Per Session $50 <br />$50*36</p>
                                            </div> */}

                        </form>
                        <button
                          onClick={(e) => {
                            callPurchasePlan(e);
                          }}
                          className="loginbtn"
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-1 d-none d-xl-block"></div>
                  <div className="col-xl-5 col-md-6 mb-lg-3">
                    <div className={`planblock bg-white ${planName === "Elite" ? "active" : ""}`}>
                      <div className="headerblock">
                        <h4>Elite</h4>
                      </div>
                      <div className="custom-radio-wrap">
                        <form className="purchase-box">
                          {list.length > 0 ? (
                            list.map((ele, index) => {
                              if (ele.plantype === "Elite") {
                                return (
                                  <div
                                    key={"index" + index}
                                    className="form-group"
                                  >
                                    <input
                                      checked={plan === `E-1-${ele.noofsession}`}
                                      onChange={(e) => {
                                        OnchnagePlan(e, ele);
                                      }}
                                      value={`E-1-${ele.noofsession}`}
                                      id={`Elite${index}`}
                                      type="radio"
                                      name="custom-radio-btn"
                                    />
                                    <label
                                      className="custom-radio"
                                      htmlFor={`Elite${index}`}
                                    >
                                      <span className="label-text">
                                        {ele.noofsession === "1"
                                          ? "Single"
                                          : `${ele.noofsession} Sessions`}
                                        <span className="float-right">
                                          $ {ele.amount}
                                          <span className="text-gray font-13"></span>
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                );
                              } else {
                                return <></>;
                              }
                            })
                          ) : (
                            <>

                            </>
                          )}


                        </form>
                        <button
                          onClick={(e) => {
                            callPurchasePlan(e);
                          }}
                          className="loginbtn"
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="trainer-main-box">
            <div className="col-xl-12 col-lg-12">
              <div className="pur-session">
                <div className="d-sm-flex justify-content-between">
                  <h4 className="font-weight-bold align-self-center">
                    Selected Session
                  </h4>
                  <div>
                    <button onClick={() => { setCurrentSessionModal(true) }} className="text-decoration-none btn btn-primary mt-2 mb-4 choose-pay mx-1">
                      Available Sessions
                    </button>
                    <Link
                      to="/payment/clientpaymenthistory/1"
                      className="text-decoration-none btn btn-primary mt-2 mb-4 choose-pay mx-1">
                      Payment History
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 mb-md-5">
              <div className="graysession">
                <div className="table-responsive">
                  <table className="table w-100 mb-0">
                    <tbody>
                      <tr>
                        <td className="wd-date">
                          <span>Date :</span>
                          <span className="text-primary font-weight-bold">

                            {planDetail.date
                              .toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              })
                              .replace(/ /g, "-")}
                          </span>
                        </td>
                        <td className="wd-plane">
                          <span>Plan :</span>
                          <span className="text-primary font-weight-bold bg-transparent planborder">

                            {planDetail.sessions ? (planDetail?.planType) : 'N/A'}
                          </span>
                        </td>
                        <td className="wd-session">
                          <span>Sessions :</span>
                          <span className="text-primary font-weight-bold">

                            {planDetail.sessions ? (planDetail?.sessions === "1" ? "Single" : planDetail?.sessions) : 'N/A'}
                          </span>
                        </td>
                        <td className="wd-total">
                          <span>Total :</span>
                          <span className="text-primary font-weight-bold">
                            {planDetail.sessions ? (`$${planDetail.amount}`) : 'N/A'}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={currentSessionModal} onHide={(e) => { setCurrentSessionModal(false); }} className="mbody" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <ShowAvailableSessions />
      </Modal>

    </>
  );
}

export default PurchaseSession;
