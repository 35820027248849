/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiUrl } from "../../environment/environment";
import swal from "sweetalert";
// import { verifytokenCall } from "../Others/Utils.js";
import Plaid from "../Others/Plaid";
// import InputMask from "react-input-mask";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { capitalize } from "../Others/Utils";
import { phoneNumberLength } from "../utility/Utility";

const initVal = {
  platform: "",
  icon: "",
  url: "",
}

function MyProfile() {

  const userFirstNameRef = useRef()
  const userLastNameRef = useRef()
  const userPhonenoRef = useRef()
  const userAboutRef = useRef()
  const userTrainingstyleRef = useRef()
  const userquoteeRef = useRef()
  const userQulificationRef = useRef()
  const userCertificationRef = useRef()
  const iconRef = useRef()
  const urlRef = useRef()
  const experienceInputRefSe = useRef()

  const navigate = useNavigate();
  const ProfileImage_URL = "/img/Small-no-img.png";

  const TrainerProfileImage_URL = "/img/Small-no-img.png";
  const usertype = sessionStorage.getItem("usertype");

  const [client, setClient] = useState({});
  const [trainerData, setTrainerData] = useState();
  const [dataLoader, setDataLoader] = useState(false)

  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    oldpassword: "",
    password: "",
    confirmpassword: "",
    phoneno: "",
    countryDialCode: "",
    countryCode: "",
    country: "",
    gender: "Male",
    aboutus: "",
    trainingstyle: "",
    quote: "",
    experience: 0,
    qualifications: "",
    specialitys: "",
    introduction: "",
    certifications: "",
    emailnotifications: false,
    maillinglist: false,
    textnotifications: false,
  });
  const [mobileNumber, setMobilenumber] = useState("");

  const [profileimagepreview, setProfileImagePreview] = useState(ProfileImage_URL);

  const [trainerProfileImagePreview, setTrainerProfileImagePreview] = useState(TrainerProfileImage_URL);
  const [trainerimagepreview, setTrainerImage] = useState();
  const [expVal, setExp] = useState(1);
  const [qualificationshtmllist, setHtmlQualifications] = useState([]);

  const [workoutList, setWorkOutList] = useState([]);
  const [filterWorkout, setFilterWorkout] = useState([]);
  const [tags, settags] = useState([]);
  const ref = useRef(null);
  const trainingstyle = useRef("");

  const [socialmedia, setSocialmedia] = useState(initVal);

  const [socialmediaModal, setSocialmediaModal] = useState(false);
  const [popuploader, setPopuploader] = useState(false)
  const [qualificationslist, setQualifications] = useState([]);
  const [qualification, setQualification] = useState("");
  const [imagesQuaPathList, setImagesQuaPathList] = useState([]);

  const [certificationslist, setCertifications] = useState([]);
  const [certificationshtmllist, setHtmlCertifications] = useState([]);
  const [certification, setCertification] = useState("");
  const [cerimagesPathList, setCerImagesPathList] = useState([]);

  const [errors, setErrors] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [mobileNumberValidation, setMobileNumberValidation] = useState(true);
  useEffect(() => {
    setTrainerData(JSON.parse(sessionStorage.getItem("user")));
    document.body.classList.remove("scrollHide");
    // callToken();
    fetchProfile();
    getTypeOfWorkout();
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      // debugger
      trainingstyle.current = "";
      setFilterWorkout([]);
    }
  };

  const fetchProfile = async () => {
    // document.querySelector(".loading").classList.remove("d-none");
    setDataLoader(true)
    let _url = usertype === "client" ? "/client/account" : "/trainer/account";
    await axios
      .get(`${apiUrl}${_url}/getprofile`, {}, {})
      .then(function (response) {
        if (response.data.status === 1) {
          if (usertype === "client") {
            setTimeout(() => {
              response.data.result.oldpassword = "";
              response.data.result.password = "";
              response.data.result.confirmPassword = "";
              if (response.data.result) {
                setClient(response.data.result);

                setProfileImagePreview(
                  response.data.result.profile !== "null" &&
                    response.data.result.profile !== "undefined" &&
                    response.data.result.profile !== ""
                    ? apiUrl + response.data.result.profile
                    : ProfileImage_URL
                );
                // setProfileImage((response.data.result.profile !== "null" && response.data.result.profile !== "") ? apiUrl + response.data.result.profile : ProfileImage_URL);

                setTrainerProfileImagePreview(
                  response.data.result.profile !== "null" &&
                    response.data.result.profile !== "undefined" &&
                    response.data.result.profile !== ""
                    ? apiUrl + response.data.result.profile
                    : TrainerProfileImage_URL
                );
                setTrainerImage(
                  response.data.result.profile !== "null" &&
                    response.data.result.profile !== "undefined" &&
                    response.data.result.profile !== ""
                    ? apiUrl + response.data.result.profile
                    : TrainerProfileImage_URL
                );
              }
              setDataLoader(false)
            }, 500);
          } else {
            // debugger
            setTimeout(() => {
              response.data.result.oldpassword = "";
              response.data.result.password = "";
              response.data.result.confirmPassword = "";
              if (response.data.result) {
                setExp(response.data.result.experience);
                setUser(response.data.result);

                setProfileImagePreview(response.data.result.profile !== "null" &&
                  response.data.result.profile !== "undefined" &&
                  response.data.result.profile !== ""
                  ? apiUrl + response.data.result.profile
                  : TrainerProfileImage_URL);

                setTrainerProfileImagePreview(response.data.result.profile !== "null" &&
                  response.data.result.profile !== "undefined" &&
                  response.data.result.profile !== ""
                  ? apiUrl + response.data.result.profile
                  : TrainerProfileImage_URL);

                setTrainerImage(response.data.result.profile !== "null" &&
                  response.data.result.profile !== "undefined" &&
                  response.data.result.profile !== ""
                  ? apiUrl + response.data.result.profile
                  : TrainerProfileImage_URL);


                if (response.data?.result?.certifications?.name) {
                  setCertifications(response.data?.result?.certifications?.name.split(","))
                }

                if (response.data?.result?.certifications?.path) {
                  setCerImagesPathList(response.data?.result?.certifications?.path)
                }


                if (response.data?.result?.qualifications?.name) {
                  setQualifications(response.data?.result?.qualifications?.name.split(",") || "");
                }

                if (response.data?.result?.qualifications?.path) {
                  setImagesQuaPathList(response.data?.result?.qualifications?.path);
                }
                const newTags = response.data?.result?.trainingstyle.split(",");
                settags(newTags);
                trainingstyle.current = "";

              }

              let contectNUmber =
                response.data.result.country +
                response.data.result.countryDialCode +
                response.data.result.phoneno;
              setMobilenumber(contectNUmber);
              setDataLoader(false)
            }, 500);
          }
        }
        return response;
      })

      .catch(function (error) {
        setDataLoader(false)
        // document.querySelector(".loading").classList.add("d-none");
      });
  };

  const plusminus = (e) => {
    var count;
    if (e === "-") {
      count = expVal;
      count = parseInt(count) - 1;
      count = count < 1 ? 1 : count;
    } else {
      count = expVal;
      if (count > 49) {
        count = 50;
      } else {
        count++;
      }
    }
    if (count === "") {
      setErrors((prevErrors) => ({ ...prevErrors, experience: 'Please enter experience' }));
    } else if (count < 1) {
      setErrors((prevErrors) => ({ ...prevErrors, experience: 'Experience must be at least 1 year' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, experience: '' }));
    }
    setExp(count);
    return false;
  };

  const handleExp = (e) => {
    const newValue = e.target.value.replace(/\D/g, "");

    if (newValue === "") {
      setErrors((prevErrors) => ({ ...prevErrors, experience: 'Please enter experience' }));
    } else if (newValue < 1) {
      setErrors((prevErrors) => ({ ...prevErrors, experience: 'Experience must be at least 1 year' }));
    }else if (newValue > 50) {
      setErrors((prevErrors) => ({ ...prevErrors, experience: 'Experience must be at 50 year' }));
    }  else {
      setErrors((prevErrors) => ({ ...prevErrors, experience: '' }));
    }

    if (newValue?.length <= 51) {
      setExp(newValue);
    }
  };

  const OnFileChangeTrainerProfile = (event) => {
    const file_size = event.target.files[0].size;
    if (file_size > 1048000) {
      setTrainerProfileImagePreview(TrainerProfileImage_URL);
      alert("File size more than 1 MB. File size must under 1MB !");
      event.preventDefault();
    } else {
      const fileReader = new window.FileReader();
      const file = event.target.files[0];
      setTrainerImage(event.target.files[0]);

      fileReader.onload = (fileLoad) => {
        const { result } = fileLoad.target;
        setIsEdit(true);
        setTrainerProfileImagePreview(result);
      };

      fileReader.readAsDataURL(file);
    }
  };

  const OnFileDiacard = () => {
    setIsEdit(false);
    setTrainerProfileImagePreview(profileimagepreview && ProfileImage_URL);
    fetchProfile(1);
  };

  async function getTypeOfWorkout() {
    setDataLoader(true)
    // document.querySelector(".loading").classList.remove("d-none");
    await axios
      .get(`${apiUrl}/trainer/trainer/getworkoutcategory`, {}, {})
      .then(function (response) {
        // document.querySelector(".loading").classList.add("d-none");
        if (response?.data?.status === 1) {
          setTimeout(() => {
            setWorkOutList(response?.data?.result);
            setDataLoader(false)
          }, 500);
        } else {
          setDataLoader(false)
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        window.alert(error)
        // document.querySelector(".loading").classList.add("d-none");
      });
  }

  const tagChange = (e) => {
    setUser({ ...user, trainingstyle: e.target.value });
    handleSuggestion();
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
    }
    handleSuggestion();
  };

  const handleSuggestion = () => {
    const suggestFilterInput = workoutList.filter((suggest) =>
      suggest.name.toLowerCase().includes(user.trainingstyle?.toLowerCase())
    );

    const suggestFilterTags = suggestFilterInput.filter(
      (suggest) => !tags.includes(suggest.name)
    );
    setFilterWorkout(suggestFilterTags);

    if (suggestFilterInput.length === 0) {
      setErrors((prevErrors) => ({ ...prevErrors, trainingstyle: 'Please select training style' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, trainingstyle: '' }));
    }
  };

  const handleDelete = (i) => {
    const newTags = tags.filter((tag, j) => i !== j);
    settags(newTags);
    setFilterWorkout([]);
    if (newTags.length === 0) {
      setErrors((prevErrors) => ({ ...prevErrors, trainingstyle: 'Please select training style' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, trainingstyle: '' }));
    }
  };

  const AddTags = (text) => {
    settags([...tags, text]);
    user.trainingstyle = "";
    trainingstyle.current = "";
    setUser(user);
    setFilterWorkout([]);
  };

  // const validatePhoneNumber = (phoneNumber, countryCode) => {
  //   const formattedPhoneNumber = phoneNumber.replace(/[^0-9]/g, ""); // Remove non-numeric characters

  //   if (countryCode === "no") {
  //     return formattedPhoneNumber.length === 8;
  //   } else {
  //     return formattedPhoneNumber.length === 10;
  //   }
  // };

  // qulification
  const [setQulif, setSetQulif] = useState([])
  const [qulifsPrev, setQulifsPrev] = useState([])
  const [qulifModelData, setqulifModelData] = useState()
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  let storedname
  let origname

  qulifsPrev.find((x) => storedname = x?.orignalName)
  setQulif.find((x) => origname = x?.orignalName)

  const isShowModel = (eleData) => {
    handleShow()
    setqulifModelData(eleData)
  }

  const handleQualification = (e) => {
    const { value } = e.target
    setQualification(value);

    if (value.length === 0 && value === "") {
      setErrors((prevErrors) => ({ ...prevErrors, note: 'Please enter qulification details.' }));
      setErrors((prevErrors) => ({ ...prevErrors, image: 'Please upload image here' }));
    } else if (value.length < 3 || value.length > 50) {
      setErrors((prevErrors) => ({ ...prevErrors, note: 'Qulification details length minimum 3 letters and maximum 50' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, note: '' }));
      setErrors((prevErrors) => ({ ...prevErrors, image: '' }));
      setErrors((prevErrors) => ({ ...prevErrors, already: '' }));
    }

  };

  const OnQualificationFileChange = (event, value) => {
    const file_size = event.target.files[0].size;
    if (file_size > 1048000) {
      setImagesQuaPathList([]);
      alert("File size more than 1 MB. File size must under 1MB !");
      event.preventDefault();
    } else {
      const fileReader = new window.FileReader();
      const file = event.target.files[0];

      fileReader.onload = (fileLoad) => {

        let errormsg = {}
        let isValid = true
        if (qulifsPrev.find((x) => x?.name === qualification)) {
          errormsg.already = "This qualification already exist!";
          isValid = false;
        }
        if (qualification === "" && qulifsPrev) {
          errormsg.already = "First enter qulification details and select qualification image";
          isValid = false;
        }
        if (imagesQuaPathList?.length > 0 && (imagesQuaPathList.find((v) => v?.name === file?.name))) {
          errormsg.already = "This qualification image already exist!";
          isValid = false;
        }
        if (isValid) {
          const objeUrl = window.URL.createObjectURL(file)
          setQulif.push({ uri: file, path: objeUrl, orignalName: file.name, name: value })
          setSetQulif(setQulif)

          var maxId = imagesQuaPathList.length > 0 ? imagesQuaPathList.length + 1 : 1;

          imagesQuaPathList.push({
            uri: file,
            name: file.name,
            type: file.type,
            objeUrl: objeUrl,
            id: maxId,
            qualification: value,
          });

        }
        setErrors(errormsg)
        setImagesQuaPathList(imagesQuaPathList);
      };
      fileReader.readAsDataURL(file);
    }
  };

  const handleQualifications = (e) => {

    e.preventDefault();
    var errormsg = {};
    var isValid = true;

    if (qualification === "") {
      errormsg.note = "Please enter qulification details.";
      isValid = false;
    } else if (qualification.length < 3) {
      errormsg.note = "Qulification details length minimum 3 letters .";
      isValid = false;
    }

    if (setQulif?.length === 0) {
      errormsg.image = "Please upload image here";
      isValid = false;
    }

    if (qulifsPrev.find((x) => x?.name === qualification)) {
      errormsg.already = "This qualification already exist!";
      isValid = false;
    }

    if (imagesQuaPathList.length > 0 && imagesQuaPathList.find((x) => x?.name) === setQulif.find((x) => x?.orignalName)) {
      errormsg.already = "This qualification image already exists!";
      isValid = false;
    }

    if (qualificationslist?.length > 0 && qualificationslist.find((x) => x === qualification)) {
      errormsg.already = "This qualification already exists!";
      isValid = false;
    }

    if (qulifsPrev?.length > 0 && setQulif.length > 0 && origname === storedname) {
      setSetQulif([]);
      errormsg.already = "This qualification image already exists!";
      isValid = false;
    }


    setErrors(errormsg);
    if (isValid) {
      qualificationslist.push(qualification);
      setQualifications(qualificationslist);
      setQualification("");


      qulifsPrev.push(setQulif[0])
      setSetQulif([])

      const updatedList = qualificationslist.map((listItems, index) => {

        return (
          <div
            id={`qualification${index}`}
            className="control-group input-group"
            style={{ marginTop: "10px" }}
          >
            <div className="d-flex col-md-6 pl-0">
              <div name="qualifications" className="removeinput">
                {listItems}
              </div>
              <div className="input-group-btn position-relative">
                <button
                  onClick={() => {
                    removeQualifications(index);
                  }}
                  className="remove position-absolute"
                  type="button"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
            <div className="col-md-6 position-relative">
              <img src="/img/file.png" className="Fileicon" alt="File" />
            </div>
          </div>
        );
      });
      setHtmlQualifications(updatedList);
    }
  };

  const removeQualifications = (index) => {

    Swal.fire({
      // text: "Are you sure you want to removed this qulification ?",
      text: "Do you want to delete this qulification ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {

      if (result.isConfirmed) {

        qualificationslist.splice(index, 1);
        var qualificationslst = qualificationslist;
        setQualifications(qualificationslst);

        imagesQuaPathList.splice(index, 1);
        var imgQualificationslst = imagesQuaPathList;
        setImagesQuaPathList(imgQualificationslst);

        qulifsPrev.splice(index, 1)
        var prevQuliPath = qulifsPrev;
        setQulifsPrev(prevQuliPath)


        if (qulifsPrev.length === 0 && imagesQuaPathList.length === 0 && index === 0) {
          setErrors((prevErrors) => ({ ...prevErrors, image: 'Please enter qulification detail and upload image here' }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, image: '' }));
        }

        const updatedList = qualificationslist.map((listItems, index) => {
          return (
            <div
              key={"qualification" + index}
              className="control-group input-group"
              style={{ marginTop: "10px" }}
            >
              <div className="d-flex">
                <div name="qualifications" className="removeinput">
                  {listItems}
                </div>
                <div className="input-group-btn position-relative">
                  <button
                    onClick={() => {
                      removeQualifications(index);
                    }}
                    className="remove position-absolute"
                    type="button"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          );
        });
        setHtmlQualifications(updatedList);

      }
    })
  };

  // certification 
  const [setCertif, setSetCertif] = useState([])
  const [certifPrev, setCertifPrev] = useState([])
  const [certificationData, setCertificationData] = useState([])
  const [show1, setShow1] = useState(false)
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);

  let storedCertiName
  let originCertiName

  certifPrev.find((x) => storedCertiName = x?.orignalName)
  setCertif.find((x) => originCertiName = x.orignalName)

  const isShowModel1 = (eleData) => {
    console.log("eleData", eleData);
    handleShow1()
    setCertificationData(eleData)
  }

  const onCertificationFileChange = (event, value) => {
    const file_size = event.target.files[0].size;
    if (file_size > 1048000) {
      setCerImagesPathList([]);
      alert("File size more than 1 MB. File size must under 1MB !");
      event.preventDefault();
    } else {
      const fileReader = new window.FileReader();
      const file = event.target.files[0];

      fileReader.onload = (fileLoad) => {
        //const { result } = fileLoad.target;
        let errormsg = {}
        let isValid = true

        if (certifPrev.find((x) => x?.name === certification)) {
          errormsg.certificationImg = "This certification alredy exist!";
          isValid = false;
        }

        if (certification === "" && certifPrev) {
          errormsg.certificationImg = "First enter certification details and select certification image";
          isValid = false;
        }

        if (cerimagesPathList?.length > 0 && cerimagesPathList.find((x) => x?.name === file?.name)) {
          errormsg.certificationImg = "This certification image already exist!";
          isValid = false;
        }

        if (isValid) {
          const objeUrl = window.URL.createObjectURL(file)
          setCertif.push({ uri: file, path: objeUrl, orignalName: file.name, name: value })
          setSetCertif(setCertif)

          var maxId =
            cerimagesPathList.length > 0 ? cerimagesPathList.length + 1 : 1;
          cerimagesPathList.push({
            uri: file,
            name: file.name,
            type: file.type,
            objeUrl: objeUrl,
            id: maxId,
            certification: value,
          });

        }
        setErrors(errormsg)
        setCerImagesPathList(cerimagesPathList);
      };
      fileReader.readAsDataURL(file);
    }
  };

  const handleCertification = (e) => {
    const { value } = e.target
    setCertification(e.target.value);

    if (value.length === 0 && value === "") {
      setErrors((prevErrors) => ({ ...prevErrors, certificationslist: 'Please enter certifications' }));
      setErrors((prevErrors) => ({ ...prevErrors, certificationImg: 'Please upload image here' }));
    } else if (value.length < 3 || value.length > 50) {
      setErrors((prevErrors) => ({ ...prevErrors, certificationslist: 'Certifications details length minimum 3 letters and maximum 50' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, certificationslist: '' }));
      setErrors((prevErrors) => ({ ...prevErrors, certificationImg: '' }));
      setErrors((prevErrors) => ({ ...prevErrors, certificationAlready: '' }));
    }
  };

  const handleCertifications = (e) => {
    e.preventDefault();
    var errormsg = {};
    var isValid = true;

    if (certification === "") {
      errormsg.certificationslist = "Please enter certifications details.";
      isValid = false;
    } else if (certification.length < 3) {
      // Certifications details length minimum 3 letters
      errormsg.certificationslist = "Certifications details length minimum 3 letters.";
      isValid = false;
    }

    if (setCertif?.length === 0) {
      errormsg.certificationImg = "Please upload image here";
      isValid = false;
    }

    if (certifPrev.find((x) => x?.name === certification)) {
      errormsg.certificationAlready = "This certification already exist!"
      isValid = false;
    }


    if (certificationslist?.length > 0 && certificationslist.find((x) => x === certification)) {
      errormsg.certificationAlready = "This certification already exist!"
      isValid = false;
    }

    if (cerimagesPathList?.length > 0 && cerimagesPathList.find((x) => x?.name) === setCertif.find((x) => x?.orignalName)) {
      errormsg.certificationAlready = "This certification image already exist!"
      isValid = false;
    }

    if (certifPrev.length > 0 && setCertif.length > 0 && storedCertiName === originCertiName) {
      setSetCertif([])
      errormsg.certificationAlready = "This certification image already exist!"
      isValid = false;
    }



    setErrors(errormsg);
    if (isValid) {
      certificationslist.push(certification);
      setCertifications(certificationslist);
      setCertification("");

      certifPrev.push(setCertif[0])
      setSetCertif([])

      const updatedCList = certificationslist.map((ClistItems, index) => {
        return (
          <div
            key={"certification" + index}
            className="control-group input-group"
            style={{ marginTop: "10px" }}
          >
            <div className="d-flex">
              <div name="certifications" className="removeinput">
                {ClistItems}
              </div>
              <div className="input-group-btn position-relative">
                <button
                  onClick={() => {
                    removeCertifications(index);
                  }}
                  className="remove position-absolute"
                  type="button"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        );
      });
      setHtmlCertifications(updatedCList);
    }
  };

  const removeCertifications = (index) => {

    Swal.fire({
      // text: "Are you sure you want to removed this certification ?",
      text: "Do you want to delete this certification ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {

      if (result.isConfirmed) {
        certificationslist.splice(index, 1);
        var certificationslst = certificationslist;
        setCertifications(certificationslst);

        cerimagesPathList.splice(index, 1)
        var certificationpath = cerimagesPathList
        setCerImagesPathList(certificationpath)

        certifPrev.splice(index, 1)
        var certifecpre = certifPrev
        setCertifPrev(certifecpre)

        if (certifPrev.length === 0 && setCertif.length === 0 && index === 0) {
          setErrors((prevError) => ({ ...prevError, certificationslist: 'First enter certification details and select certification image' }))
        } else {
          setErrors((prevError) => ({ ...prevError, certificationslist: '' }))
        }

        const updatedCList = certificationslist.map((ClistItems, index) => {
          return (
            <div
              key={"certification" + index}
              className="control-group input-group"
              style={{ marginTop: "10px" }}
            >
              <div className="d-flex">
                <div name="certifications" className="removeinput">
                  {ClistItems}
                </div>
                <div className="input-group-btn position-relative">
                  <button
                    onClick={() => {
                      removeCertifications(index);
                    }}
                    className="remove position-absolute"
                    type="button"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          );
        });
        setHtmlCertifications(updatedCList);
      }
    })

  };

  // socialmedia
  const mediaIsRequired = (fieldName) => {
    return ['platform', 'url'].includes(fieldName)
  }

  const socialmediaHandle = (e) => {
    const { name, value } = e.target

    if (value.trim() === "" && mediaIsRequired(name)) {
      let errorMessage = '';

      if (name === 'platform') {
        errorMessage = "Please select name."
      } else if (name === 'url') {
        errorMessage = "Please enter link."
      }
      setErrors((prevError) => ({ ...prevError, [name]: errorMessage }));
    } else {
      setErrors((prevError) => ({ ...prevError, [name]: '' }));
    }
    setSocialmedia({ ...socialmedia, [name]: value });
  };

  const openModal = () => {
    setSocialmedia(initVal)
    setErrors({})
    setSocialmediaModal(true);
  };

  const socialmediaAdd = async () => {
    let isValid = true;
    let errormsg = {};
    let url = socialmedia.url;

    if (!url.match(/^https?:\/\//i)) {
      url = `https://${url}`;
    }

    if (!url.match(/^www\./i)) {
      url = `www.${url}`;
    }

    url = url.replace(/^(https?:\/\/)?(www\.)?/i, '');

    if (!url.endsWith("/")) {
      url += "/";
    }

    const regexString = `${socialmedia.platform.toLowerCase()}\\.com`;
    const regex = new RegExp(regexString, 'gi');
    const match = regex.exec(url);

    if (socialmedia.platform === "") {
      errormsg.platform = "Please select name.";
      isValid = false;
    }

    if (socialmedia.url === "") {
      errormsg.url = "Please enter link.";
      isValid = false;
    } else if (!match) {
      errormsg.url = "Invalid Url.";
      isValid = false;
    }

    if (Object.keys(errormsg).length > 0) {
      if (errormsg.platform) {
        iconRef.current.focus();
      } else if (errormsg.url) {
        urlRef.current.focus();
      }
    }

    setErrors(errormsg);
    if (isValid === true) {
      setErrors({})
      let icon = ""
      if (socialmedia.platform === "Facebook") {
        icon = "fab fa-facebook-f"
      } else if (socialmedia.platform === "Instagram") {
        icon = "fab fa-instagram"
      } else if (socialmedia.platform === "Twitter") {
        icon = "fab fa-twitter"
      } else if (socialmedia.platform === "Linkedin") {
        icon = "fab fa-linkedin"
      } else if (socialmedia.platform === "Youtube") {
        icon = "fab fa-youtube"
      } else if (socialmedia.platform === "Tiktok") {
        icon = "fab fa-tiktok"
      }

      let obj = {
        platform: socialmedia.platform,
        icon: icon,
        url: url,
      };

      const trainerId = trainerData._id;
      // document.querySelector(".loading").classList.remove("d-none");
      setPopuploader(true)
      await axios
        .post(`${apiUrl}/trainer/account/socialmedia/${trainerId}`, obj)
        .then((response) => {
          if (response.data.status === 1) {
            setTimeout(() => {
              sessionStorage.setItem("user", JSON.stringify(response.data.result));
              setTrainerData(response.data.result);
              setSocialmedia(initVal)
              swal({
                title: "success!",
                text: response.data.message,
                icon: "success",
                button: true,
              });

              setSocialmedia({})
              setSocialmediaModal(false);
              setPopuploader(false)
              // navigate("/myprofile");
            }, 500);
          } else {
            setPopuploader(false)
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
          }
        }).catch((erro) => {
          setPopuploader(false)
        })
    }
  };

  const removeMedi = async (mid) => {
    const trainerId = trainerData._id;

    Swal.fire({
      text: "Do you want to delete your social media account ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setDataLoader(true)
        // document.querySelector(".loading").classList.remove("d-none");
        axios
          .post(`${apiUrl}/trainer/account/deleteSocialmedia/${trainerId}`, { mid })
          .then(function (response) {
            if (response.data.status === 1) {
              setTimeout(() => {
                sessionStorage.setItem("user", JSON.stringify(response.data.result));
                setTrainerData(response.data.result);
                swal({
                  title: "Success!",
                  text: response.data.message,
                  icon: "success",
                  button: true,
                });
                setDataLoader(false)
              }, 500);

            } else {
              setDataLoader(false)
              // document.querySelector(".loading").classList.add("d-none");
              swal({
                title: "Error!",
                text: response.data.message,
                icon: "error",
                button: true,
              });
            }
          })
        // Swal.fire({
        //   icon: "success",
        //   title: "Your Social Media has been deleted.",
        //   timer: 2000,
        // });
      }
    });
  };

  // removed bank
  const removeBank = (e, bank_id) => {
    e.preventDefault();
    Swal.fire({
      text: "Do you want to delete your bank account ?",
      icon: "warning",
      /* button: true, */
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then((result) => {
      if (result.isConfirmed) {
        setDataLoader(true)
        axios
          .post(`${apiUrl}/trainer/accountinfo/removeBank`, {
            id: bank_id,
          })
          .then((response) => {
            if (response.data.status === 1) {
              setTimeout(() => {
                sessionStorage.setItem("user", JSON.stringify(response.data.result));
                setTrainerData(response.data.result)
                setDataLoader(false)
              }, 500);
            }
          })
          .catch((err) => { setDataLoader(false) });
      }
    })
  };


  const isRequiredField = (filedName) => {
    return ['firstname', 'lastname', 'phoneno', 'gender', 'aboutus', 'quote', 'qualification', 'certification'].includes(filedName)
  }

  const handleInputs = (e) => {
    const { name, value } = e.target
    if (value.trim() === "" && isRequiredField(name)) {
      let errorMessage = '';
      if (name === "firstname") {
        errorMessage = "Please enter first name";
      } else if (name === 'lastname') {
        errorMessage = "Please enter last name";
      } else if (name === 'gender') {
        errorMessage = "Please select gender";
      } else if (name === 'aboutus') {
        errorMessage = "Please enter aboutus";
      } else if (name === 'quote') {
        errorMessage = "Please enter quote";
      }
      setErrors((prevError) => ({ ...prevError, [name]: errorMessage }));
    } else if (((name === 'firstname') || (name === 'lastname')) && (value.length < 3 || value.length > 50)) {
      setErrors((prevError) => ({ ...prevError, [name]: "Please enter minimum 3 letters or maximum 50" }));
    } else if (((name === 'aboutus') || (name === 'quote')) && (value.length < 3)) {
      setErrors((prevError) => ({ ...prevError, [name]: "Please enter minimum 3 letters" }));
    } else {
      setErrors((prevError) => ({ ...prevError, [name]: '' }));
    }
    setUser({ ...user, [name]: value });
  };
  // submit
  const PostEditProfile = async (e) => {
    e.preventDefault();
    setIsEdit(false);

    let isValid = true;
    var errormsg = {};

    if (user.firstname === "") {
      errormsg.firstname = "Please enter first name.";
      isValid = false;
    } else if (user.firstname.length < 3 || user.firstname.length > 50) {
      errormsg.firstname = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    }

    if (user.lastname === "") {
      errormsg.lastname = "Please enter last name.";
      isValid = false;
    } else if (user.lastname.length < 3 || user.lastname.length > 50) {
      errormsg.lastname = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    }

    if (user.phoneno === "") {
      errormsg.phoneno = "Please enter phone number.";
      isValid = false;
    } else if (mobileNumberValidation ===false) {
      errormsg.phoneno = "Please enter valid phone number.";
      isValid = false;
    }
    if (user.aboutus === "") {
      errormsg.aboutus = "Please enter about us description.";
      isValid = false;
    } else if (user.aboutus.length < 3 || user.aboutus.length > 50) {
      errormsg.aboutus = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    }

    if (expVal === "") {
      errormsg.experience = "Please enter experience";
      isValid = false;
    } else if (expVal < 1) {
      errormsg.experience = "Experience must be at least 1 year";
      isValid = false;
    } else if (expVal > 50) {
      errormsg.experience='Experience must be at 50 year' ;
      isValid = false;
    } 

    if (tags.length === 0) {
      errormsg.trainingstyle = "Please select training style.";
      isValid = false;
    }

    if (user.quote === "") {
      errormsg.quote = "Please enter quote description.";
      isValid = false;
    } else if (user.quote.length < 3 || user.quote.length > 50) {
      errormsg.quote = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    }

    if (qualificationslist.length === 0) {
      errormsg.qualification = "Please enter qualifications.";
      isValid = false;
    }

    if (certificationslist.length === 0) {
      errormsg.certification = "Please enter certification.";
      isValid = false;
    }

    if (Object.keys(errormsg).length > 0) {
      if (errormsg.firstname) {
        userFirstNameRef.current.focus()
      } else if (errormsg.lastname) {
        userLastNameRef.current.focus()
      } else if (errormsg.phoneno) {
        userPhonenoRef.current.focus()
      } else if (errormsg.aboutus) {
        userAboutRef.current.focus()
      } else if (errormsg.trainingstyle) {
        userTrainingstyleRef.current.focus()
      } else if (errormsg.quote) {
        userquoteeRef.current.focus()
      } else if (errormsg.experience) {
        experienceInputRefSe.current.focus()
      } else if (errormsg.qualification) {
        userQulificationRef.current.focus()
      } else if (errormsg.certification) {
        userCertificationRef.current.focus()
      }
    }

    setErrors(errormsg);
    if (isValid === true) {
      let qualificationsObj = {
        name: qualificationslist.join(",") || "",
        path: imagesQuaPathList,
      };
      let certificationsObj = {
        name: certificationslist.join(",") || "",
        path: cerimagesPathList,
      };

      let obj = {
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        phoneno: user.phoneno,
        countryDialCode: user.countryDialCode,
        gender: user.gender,
        aboutus: user.aboutus,
        trainingstyle: tags.join(","),
        quote: user.quote,
        experience: parseInt(expVal), //parseInt(document.getElementById("experience").value),
        oldpassword: user.oldpassword || "",
        password: user.password || "",
        confirmpassword: user.confirmpassword || "",
        availablestatus: parseInt(user.availablestatus),
        country: user.country,
        countryCode: user.countryCode,
      };

      var formData = new FormData();
      for (var key in obj) {
        formData.append(key, obj[key]);
      }

      // PROFILE IMAGE
      let profile;
      if (typeof trainerimagepreview == "string") {
        profile = trainerimagepreview.split(apiUrl);
        profile = profile[1] || "";
        setTrainerImage(...trainerimagepreview, profile);
      } else {
        profile = trainerimagepreview;
      }

      if (trainerimagepreview?.type === undefined) {
        formData.append("edprofile", profile);
      } else {
        formData.append("profile", profile);
      }

      setDataLoader(true)
      // document.querySelector(".loading").classList.remove("d-none");
      await axios
        .post(`${apiUrl}/trainer/account/updateprofile`, formData)
        .then((response) => {
          // document.querySelector(".loading").classList.add("d-none");
          if (response.data.status === 1) {
            if (qualificationsObj != null) {
              setTrainerProfileImagePreview(response.data.result.profile !== "null" &&
                response.data.result.profile !== "undefined" &&
                response.data.result.profile !== ""
                ? apiUrl + response.data.result.profile
                : TrainerProfileImage_URL);

              setTrainerImage(response.data.result.profile !== "null" &&
                response.data.result.profile !== "undefined" &&
                response.data.result.profile !== ""
                ? apiUrl + response.data.result.profile
                : TrainerProfileImage_URL);

              setUser(response?.data?.result)
              updateTrainerPara(qualificationsObj, certificationsObj, user._id);
            } else {
              navigate("/myprofile");
            }
          } else {
            setDataLoader(false)
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
            //window.alert(response.data.message);
          }
        })
        .catch(function (error) {
          window.alert(error)
          setDataLoader(false)
          // document.querySelector(".loading").classList.add("d-none");
        });
    }
  };

  const updateTrainerPara = (qualificationsObj, certificationsObj, tid) => {

    var form_data = new FormData();
    for (var key in qualificationsObj?.path) {
      form_data.append(
        qualificationsObj?.path[key].name,
        qualificationsObj?.path[key].uri
      );
    }
    for (var key1 in certificationsObj?.path) {
      form_data.append(
        certificationsObj?.path[key1].name,
        certificationsObj?.path[key1].uri
      );
    }
    form_data.append("id", tid);
    form_data.append("qualifications", JSON.stringify(qualificationsObj));
    form_data.append("certifications", JSON.stringify(certificationsObj));

    // return false
    setDataLoader(true)
    // document.querySelector(".loading").classList.remove("d-none");
    axios
      .post(`${apiUrl}/trainer/account/updateTrainerPara`, form_data)
      .then((response) => {
        // document.querySelector(".loading").classList.add("d-none");
        if (response.data.status === 1) {
          sessionStorage.setItem("user", JSON.stringify(response.data.result));
          //window.alert(response.data.message);
          setCerImagesPathList(response?.data?.result?.certifications?.path)
          setImagesQuaPathList(response?.data?.result?.qualifications?.path)
          swal({
            title: "Success!",
            text: response.data.message,
            icon: "success",
            button: true,
          });
          setDataLoader(false)
          navigate("/myprofile");
        } else {
          setDataLoader(false)
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
          //window.alert(response.data.message);
        }
      })
      .catch(function (error) {
        setDataLoader(false)
      });
  };



  return (
    <>
      <section className="" ref={ref}>
        {usertype === "client" ? (
          <>
            <div className="sub-head-title">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <h1 className="main_title">My Profile</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-profile-sub">
              <div className="container-fluid">
                <div className="col-12 p-0">
                  <div className="row">
                    {dataLoader &&
                      <div className="loading height-full">
                        <div className="mainloader"></div>
                      </div>
                    }
                    <div className="col-lg-6 col-12 mb-4" >
                      <div className="trainer-main-box">
                        <div className="myprofile">
                          <div className="row">
                            <div className="col-lg-12 col-12">
                              <div className="avatar-upload">
                                <div className="avatar-preview">
                                  <div
                                    id="imagePreview"
                                    style={{
                                      backgroundImage: `url(${profileimagepreview || ProfileImage_URL})`,
                                    }}
                                  > </div>
                                  {/* <img
                                    className="user-img"
                                    src={profileimagepreview}
                                    onError={(e) => {
                                      e.target.src = "/img/Small-no-img.png";
                                    }}
                                    alt=""
                                    style={{ objectFit: "cover" }}
                                  /> */}
                                </div>
                              </div>
                              <div className="prof_name">
                                <h4>
                                  {client.firstname} {client.lastname}
                                  {/* {client.age} */}
                                </h4>
                              </div>
                            </div>
                            <div className="col-md-12 col-12">
                              <label>Equipment</label>
                              <div className="p_input">
                                <p>{client.equipmentavailable || "N/A"}</p>
                              </div>
                            </div>
                            <div className="col-md-12 col-12">
                              <label>Gender</label>
                              <div className="p_input">
                                <p>{client.gender || "N/A"}</p>
                              </div>
                            </div>
                            <div className="col-md-12 col-12">
                              <label>Age</label>
                              <div className="p_input">
                                <p>{client.age || "N/A"}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-12 mb-4">
                      <div className="trainer-main-box">
                        {/* {dataLoader && <div className="loading">
                    <div className="mainloader"></div>
                  </div>} */}
                        <div className="myprofile pro-lbl">
                          <div className="row">
                            <div className="col-md-12 col-12">
                              <label>Weight</label>
                              <div className="p_input">
                                <p>
                                  {client.weight || "N/A"}
                                  {client.weightiskg ? ".Kgs" : ".Pound" || "N/A"}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-12 col-12">
                              <label>Height</label>
                              <div className="p_input">
                                <p>
                                  {client.height || "N/A"}
                                  {client.heightisfeet ? ".Feet" : ".Meters" || "N/A"}
                                </p>
                              </div>
                            </div>
                            <div className="col-md-12 col-12">
                              <label>Fitness Goals</label>
                              <div className="p_input">
                                <p>{client?.fitnessgoals?.join(' , ') || "N/A"}</p>
                              </div>
                            </div>
                            <div className="col-md-12 col-12 mb-3">
                              <label>Injuries/Health Issues</label>
                              <div className="p_input">
                                <p>{client.injuriesorhelthissues || "N/A"}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>


                  <div className="col-xl-4 col-md-6 mx-auto">
                    <div className="row">
                      <div className="col-md-6 col-sm-6 col-12 mb-4">
                        <button
                          className="training_btn"
                          onClick={() => navigate("/trainer?status=1")}
                        >
                          Back
                        </button>
                      </div>
                      <div className="col-md-6 col-sm-6 col-12">
                        <Link to="/editprofile" className="edit-btn">
                          Edit Profile
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="sub-head-title">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 col-6">
                    <h1 className="main_title">My Profile</h1>
                  </div>
                  <div className="col-md-6 col-6 text-right">
                    <Link
                      className="noti-btn"
                      to={"/manageNotifications"}
                      title="Manage notifications"
                    >
                      <i className="fas fa-cog"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-profile-sub">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                {dataLoader && <div className="loading">
                  <div className="mainloader"></div>
                </div>}
                <div className="row">
                  <div className="col-md-6 col-12 mb-4">
                    <div className="trainer-main-box">
                      <div className="row trainer-profile">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-xl-4 col-md-12">
                              <div className="Profile subprofile">
                                <div className="avatar-upload">
                                  <div className="avatar-edit">
                                    <input
                                      preview="imagePreview"
                                      onChange={OnFileChangeTrainerProfile}
                                      type="file"
                                      id="imageUpload"
                                      accept=".png, .jpg, .jpeg"
                                    />
                                    {isEdit ? (
                                      <>
                                        <button
                                          className="img-cancel"
                                          onClick={OnFileDiacard}
                                        >
                                          <i className="fas fa-times"></i>
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <label htmlFor="imageUpload">
                                          <i className="fas fa-camera"></i>
                                        </label>
                                      </>
                                    )}
                                  </div>
                                  <div className="avatar-preview">
                                    <div
                                      id="imagePreview"
                                      style={{
                                        backgroundImage: `url(${
                                          /* apiUrl + */ trainerProfileImagePreview
                                          })`,
                                      }}
                                    >
                                      {/* <img id="imagePreview" src={`${trainerProfileImagePreview}`} alt="chealth-profile" onError={(e) => { e.target.src = "/img/Small-no-img.png" }} /> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-8 col-md-12">
                              <div className="mb-4">
                                <label className="lbl-name">First Name</label><span className="text-danger font-weight-bold">*</span>
                                <input
                                  onChange={(e) => handleInputs(e)}
                                  value={user.firstname}
                                  name="firstname"
                                  type="text"
                                  ref={userFirstNameRef}
                                  className="input-box"
                                  placeholder="First Name"
                                />
                                <div className="text-danger">
                                  {errors.firstname}
                                </div>
                              </div>
                              <div className="mb-4">
                                <label className="lbl-name">Last Name</label><span className="text-danger font-weight-bold">*</span>
                                <input
                                  onChange={(e) => handleInputs(e)}
                                  value={user.lastname}
                                  name="lastname"
                                  type="text"
                                  ref={userLastNameRef}
                                  className="input-box"
                                  placeholder="Last Name"
                                />
                                <div className="text-danger">
                                  {errors.lastname}
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 mb-3">
                              <label className="lbl-name">Mobile Number</label><span className="text-danger font-weight-bold">*</span>
                              <PhoneInput
                                country={"us"}
                                enableSearch={true}
                                countryCodeEditable={false}
                                name="phoneno"
                                inputProps={{ ref: userPhonenoRef }}
                                value={mobileNumber}
                                onChange={(value, country, e) => {
                                  setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
                                  const phone = value.replace(country.dialCode, "");
                                  const numberLength = phoneNumberLength(country)
                                  if (phone === "") {
                                    setErrors((prevError) => ({ ...prevError, phoneno: "Please enter phone number" }));
                                  }
                                  else if (numberLength > phone.length) {
                                    setMobileNumberValidation(false)
                                    setErrors((prevError) => ({ ...prevError, phoneno: `Please enter valid phone number` }));
                                  }
                                  // else if ((!validatePhoneNumber(phone, country.countryCode))) {
                                  //   setErrors((prevError) => ({ ...prevError, phoneno: "Invalid phone number for the selected country" }));
                                  // }
                                  else {
                                    setErrors((prevError) => ({ ...prevError, phoneno: "" }));
                                    setMobileNumberValidation(true)
                                  }

                                  setUser((prev) => ({ ...prev, phoneno: phone }));
                                  setUser((prev) => ({ ...prev, country: country.name }));
                                  setUser((prev) => ({ ...prev, countryCode: country.countryCode }));
                                  setUser((prev) => ({ ...prev, countryDialCode: `+${country.dialCode}` }));
                                }}
                              />

                              <div className="text-danger">
                                {errors.phoneno}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <label className="lbl-name">Gender</label><span className="text-danger font-weight-bold">*</span>
                              <div className="genderbox mb-4">
                                <div className="row">
                                  <div className="col-4">
                                    <div className="genderblock">
                                      <div className="custom-control custom-checkbox mb-3">
                                        <input
                                          onChange={(e) => handleInputs(e)}
                                          value="Male"
                                          type="radio"
                                          checked={
                                            user.gender === "Male"
                                              ? "checked"
                                              : ""
                                          }
                                          /* defaultChecked={user.gender === "Male"} */ className="custom-control-input"
                                          id="Male"
                                          name="gender"
                                        />
                                        <label
                                          className="custom-control-label w-100"
                                          htmlFor="Male"
                                        ></label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="genderblock genderblock1">
                                      <div className="custom-control custom-checkbox mb-3">
                                        <input
                                          onChange={(e) => handleInputs(e)}
                                          value="Female"
                                          type="radio"
                                          checked={
                                            user.gender === "Female"
                                              ? "checked"
                                              : ""
                                          }
                                          /* defaultChecked={user.gender === "Female"} */ className="custom-control-input"
                                          id="Female"
                                          name="gender"
                                        />
                                        <label
                                          className="custom-control-label w-100"
                                          htmlFor="Female"
                                        ></label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="genderblock genderblock2">
                                      <div className="custom-control custom-checkbox mb-3">
                                        <input
                                          onChange={(e) => handleInputs(e)}
                                          value="Non-Binary"
                                          type="radio"
                                          checked={
                                            user.gender === "Non-Binary"
                                              ? "checked"
                                              : ""
                                          }
                                          /* defaultChecked={user.gender === "Non-Binary"} */ className="custom-control-input"
                                          id="Non-Binary"
                                          name="gender"
                                        />
                                        <label
                                          className="custom-control-label w-100"
                                          htmlFor="Non-Binary"
                                        ></label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="text-danger">{errors.gender}</div>
                            </div>
                            <div className="col-md-12">
                              <label className="lbl-name">About</label><span className="text-danger font-weight-bold">*</span>
                              <textarea
                                onChange={(e) => handleInputs(e)}
                                value={user.aboutus}
                                name="aboutus"
                                ref={userAboutRef}
                                className="w-100 Sessionrej mb-3"
                                placeholder="About"
                              ></textarea>
                              <div className="text-danger">
                                {errors.aboutus}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="tags-content">
                                <label className="lbl-name">
                                  Describe your training style
                                </label><span className="text-danger font-weight-bold">*</span>
                                <div className="tags-input">
                                  <input
                                    type="text"
                                    className="w-100 mb-4"
                                    value={trainingstyle.current}
                                    // ref={trainingstyle}
                                    ref={userTrainingstyleRef}
                                    onClick={(e) => {
                                      tagChange(e);
                                    }}
                                    onKeyDown={(e) => {
                                      handleKeyDown(e);
                                    }}
                                    placeholder="Describe your training style"
                                  />
                                  {Boolean(filterWorkout.length) && (
                                    <div className="tags-suggestions">
                                      <span
                                        className="float-right"
                                        onClick={(e) => {
                                          setFilterWorkout([]);
                                        }}
                                      >
                                        <button className="btn btn-sm btn-danger" title="Remove">
                                          <i className="fa fa-times" aria-hidden="true"></i>
                                        </button>
                                      </span>
                                      {filterWorkout.map((suggest) => {
                                        return (
                                          <div
                                            className="suggestion-item"
                                            onClick={() =>
                                              AddTags(suggest.name)
                                            }
                                          >
                                            {capitalize(suggest.name)}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </div>
                                <div>
                                  {tags.map((tag, i) => (
                                    <div key={i} className="tag">
                                      {tag}
                                      <div
                                        title="Remove"
                                        className="remove-tag"
                                        onClick={() => handleDelete(i)}
                                      >
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              {/* <textarea onChange={(e) => handleInputs(e)} value={user.trainingstyle} name="trainingstyle" className="w-100 Sessionrej text-primary border-primary mb-3" placeholder="Describe your training style"></textarea> */}
                              <div className="text-danger">
                                {errors.trainingstyle}
                              </div>
                            </div>

                            <div className="col-md-12 pl-0" style={{ display: "none" }} >
                              <div className="col-md-10">
                                <div className="copy">
                                  {qualificationshtmllist}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 mb-4">
                    <div className="trainer-main-box">
                      <div className="row m-0">
                        <div className="col-md-12 mb-4 p-0">
                          <label className="lbl-name">
                            Your favorite quote
                          </label><span className="text-danger font-weight-bold">*</span>
                          <textarea
                            onChange={(e) => handleInputs(e)}
                            value={user.quote}
                            name="quote"
                            ref={userquoteeRef}
                            className="w-100 Sessionrej"
                            placeholder="Your favorite quote"
                          ></textarea>
                          <div className="text-danger">{errors.quote}</div>
                        </div>
                        <div className="col-md-12 p-0">
                          <div className="number">
                            <div className="row">
                              <div className="col-md-6 pt-3">
                                <label>Experience (in years)</label><span className="text-danger font-weight-bold">*</span>
                                <div class="tooltip"><i class="fas fa-info-circle"></i>
                                  <span class="tooltiptext p-1">If the value is 1 or less than, then button will be disabled.</span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="pmclass">
                                  {expVal < 1 ?
                                    <span
                                      className="minus"
                                      title="-"
                                      disabled
                                    >
                                      -
                                    </span>
                                    :
                                    <span
                                      className="minus"
                                      title="-"
                                      onClick={() => plusminus("-")}
                                    >
                                      -
                                    </span>
                                  }

                                  <input
                                    type="text"
                                    name="experience"
                                    value={expVal}
                                    maxLength={2}
                                    ref={experienceInputRefSe}
                                    onChange={handleExp}
                                  />
                                  <span
                                    title="+"
                                    className="plus"
                                    onClick={() => plusminus("+")}
                                  >
                                    +
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="text-danger">{errors.experience}</div>
                          </div>
                        </div>

                        <div className="col-lg-11 col-md-10 col-10 mt-4">
                          <div className="row unused">
                            <div className="input-group control-group after-add-more position-relative">
                              <label className="">
                                Qualification Details
                                <span className="text-danger font-weight-bold">
                                  *
                                </span>
                              </label>
                              <div class="tooltip"><i class="fas fa-info-circle"></i>
                                <span class="tooltiptext">Enter qualification details, Uploads document image from upload button and click on plus icon.</span>
                              </div>
                              <input
                                onChange={(e) => handleQualification(e)}
                                type="text"
                                name="qualification"
                                ref={userQulificationRef}
                                value={qualification}
                                className="input-box w-100"
                                placeholder="Qualification"
                                autocomplete="off"
                              />
                              <div className="input-group-btn">
                                <button
                                  onClick={(e) => {
                                    handleQualifications(e);
                                  }}
                                  className="add-more position-absolute"
                                  type="button"
                                >
                                  <i className="fas fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-1 col-md-1 col-2 mt-2">
                          <div className="uploadimg up-i">
                            <input
                              type="file"
                              id="uploadQualification"
                              onChange={(e) => {
                                OnQualificationFileChange(e, qualification);
                              }}
                              accept=".png, .jpg, .jpeg, .pdf, .doc"
                            />
                            <label htmlFor="uploadQualification">
                              <i className="fas fa-upload upl-icon"></i>
                            </label>
                          </div>
                        </div>
                        <div className="text-danger">
                          {errors.qualification}
                        </div>

                        <div className="text-danger">{errors.note}</div>
                        <div className="text-danger">{errors.image}</div>
                        <div className="text-danger">{errors.already}</div>
                      </div>
                      {qualificationslist.length > 0 &&
                        qualificationslist.map((listItems, index) => {
                          return (
                            <>
                              <div
                                key={`qualification${index}`}
                                className={`col-md-12 control-group${index}`}
                              >
                                <div className="row">
                                  <div className="col-lg-11 col-md-10 col-10 p-0">
                                    <div className="copy">
                                      <div
                                        className="input-group"
                                        style={{ marginTop: "10px" }}
                                        id={`qualification${index}`}
                                      >
                                        <div className="d-flex">
                                          <div
                                            name={"qualification" + index}
                                            className="removeinput"
                                          >
                                            {capitalize(listItems)}
                                          </div>
                                          <div className="input-group-btn position-relative">
                                            <button
                                              onClick={() => {
                                                removeQualifications(index);
                                              }}
                                              className="remove position-absolute"
                                              type="button"
                                            >
                                              <i className="fas fa-times"></i>
                                            </button>
                                          </div>

                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                  <div key={index} className="col-lg-1 col-md-1 col-2 mt-2">
                                    <div className="uploadimg">
                                      {imagesQuaPathList?.map((ele) => {

                                        if (listItems === ele?.qualification) {
                                          return (<>
                                            <div key={index} title={`view ${listItems}`} onClick={(e) => {
                                              isShowModel(ele);
                                            }}>
                                              <img
                                                src="/img/file.png"
                                                alt="File"
                                                className="Fileicon"
                                                style={{ position: "unset" }}
                                              />
                                            </div>
                                          </>
                                          );
                                        }
                                        return <></>
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}

                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-lg-11 col-md-10 col-10 mt-4">
                            <div className="row unused">
                              <div className="input-group control-group after-add-more position-relative">
                                <label>
                                  Certifications Details
                                  <span className="text-danger font-weight-bold">
                                    *
                                  </span>
                                </label>
                                <div class="tooltip"><i class="fas fa-info-circle"></i>
                                  <span class="tooltiptext">Enter certifications detail, Uploads document image from upload button and click on plus icon.</span>
                                </div>
                                <input
                                  onChange={(e2) => handleCertification(e2)}
                                  type="text"
                                  autoComplete={"off"}
                                  name="certification"
                                  value={certification}
                                  ref={userCertificationRef}
                                  className="input-box w-100 mb-2"
                                  placeholder="Certifications"
                                />
                                <div className="input-group-btn">
                                  <button
                                    onClick={(e2) => {
                                      handleCertifications(e2);
                                    }}
                                    className="add-more position-absolute"
                                    type="button"
                                  >
                                    <i className="fas fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-1 col-md-1 col-2 mt-2">
                            <div className="uploadimg up-i">
                              <input
                                type="file"
                                id="uploadCertifications"
                                onChange={(e) => {
                                  onCertificationFileChange(e, certification);
                                }}
                                accept=".png, .jpg, .jpeg, .pdf, .doc"
                              />
                              <label htmlFor="uploadCertifications">
                                {/* <img src="/img/upload.png" alt='Upload' /> */}
                                <i className="fas fa-upload upl-icon"></i>
                              </label>
                            </div>
                          </div>
                          <div className="text-danger">
                            {errors?.certificationAlready}
                          </div>
                          <div className="text-danger">
                            {errors.certification}
                          </div>
                          <div className="text-danger">
                            {errors?.certificationslist}
                          </div>
                          <div className="text-danger">
                            {errors?.certificationImg}
                          </div>

                        </div>
                      </div>
                      {certificationslist?.length > 0 &&
                        certificationslist?.map((ClistItems, index) => {
                          return (
                            <div
                              key={`certification${index}`}
                              className={`col-md-12 control-group${index}`}>
                              <div className="row">
                                <div className="col-lg-11 col-md-10 col-10 p-0">
                                  <div className="copy">
                                    <div
                                      key={"certification" + index}
                                      className="input-group"
                                    >
                                      <div className="d-flex">
                                        <div
                                          name="certifications"
                                          className="removeinput"
                                        >
                                          {capitalize(ClistItems)}
                                        </div>
                                        <div className="input-group-btn position-relative">
                                          <button
                                            onClick={() => {
                                              removeCertifications(index);
                                            }}
                                            className="remove position-absolute"
                                            type="button"
                                          >
                                            <i className="fas fa-times"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div key={index} className="col-lg-1 col-md-1 col-2 mt-2 text-center">
                                  {cerimagesPathList.length > 0 &&
                                    cerimagesPathList.map((ele) => {
                                      // const finalPath = apiUrl + ele?.path
                                      if (ele?.certification === ClistItems) {
                                        return (<>
                                          <div key={`certification${index}`} className={`${index}`}>
                                            <div
                                              key={"certification" + index}
                                              className="input-group"
                                            >
                                              <div key={index} title={`view ${ClistItems}`} onClick={(e) => {
                                                isShowModel1(ele);
                                              }}>
                                                <img
                                                  src="/img/file.png"
                                                  alt="File"
                                                  className="Fileicon"
                                                  style={{ position: "unset" }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </>)
                                      }
                                      return <></>
                                    })}
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      <div className="col-md-12 mt-4 p-0">
                        {trainerData?.bankaccount &&
                          trainerData?.bankaccount.length >= 0 ? (
                          <div className="row">
                            <div className="col-12 mb-3 mt-2">
                              <h4 className="font-weight-bold mb-3 float-left">
                                Bank Account(s)
                              </h4>
                              {/* <button onClick={addAnotherBank} className="btn btn-primary btn-sm float-right">Add another bank <span className='font-weight-bold'>+</span></button> */}
                              <h5 className="text-danger">
                                <Plaid />
                              </h5>
                            </div>

                            {trainerData?.bankaccount.map((bank,i) => {
                              return (
                                <>
                                  {bank?.accounts.map((elem) => {
                                    return (
                                      <>
                                        <div className="col-12">
                                          <h6>
                                            {i + 1}. {elem.name}
                                            <span
                                              onClick={(e) =>
                                                removeBank(e, elem.account_id)
                                              }
                                              className="btn btn-sm btn-primary float-right cursor-pointer"
                                              title="Remove"
                                            >
                                              <i className="fa fa-times" aria-hidden="true"></i>
                                            </span>
                                          </h6>
                                          <hr />
                                        </div>
                                      </>
                                    );
                                  })}
                                </>
                              );
                            })}
                          </div>
                        ) : (
                          <></>
                        )}
                        {!trainerData?.bankaccount ? (
                          <>
                            <h4 className="font-weight-bold mb-3 float-left mt-2">
                              Add Bank Account
                            </h4>
                            <h5 className="text-danger">
                              <Plaid />
                            </h5>
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      <div>
                        <div className="col-md-12 clr p-0">
                          <div className="socialmedia-block">
                            <div className="d-sm-flex justify-content-between align-items-center">
                              <h4 className="ticket-title" >Social Media</h4>
                              <button className="media-btn" onClick={openModal}>
                                <i className="fa fa-plus pr-2" aria-hidden="true" /> Add Social Media
                              </button>
                            </div>
                            {trainerData?.socialmedia?.length || trainerData?.socialmedia?.url?.length > 0 ? (
                              <>
                                <ul className="list-inlne social-media-icon">
                                  {trainerData?.socialmedia?.map((media, i) => (
                                    <li
                                      key={i}
                                      className="list-inline-item position-relative"
                                    >
                                      <Link
                                        className="text-decoration-none"
                                        to={media.url}
                                        target="_blank"
                                      >
                                        <span className="px-1">
                                          <i
                                            className={media.icon}
                                            aria-hidden="true"
                                            title={media.platform}
                                          ></i>
                                        </span>
                                        <span></span>
                                      </Link>
                                      <div
                                        onClick={(e) => removeMedi(media._id)}
                                        className="c-mediaicon"
                                        title="Remove"
                                      >
                                        <i
                                          className="fa fa-times"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </>
                            ) : (
                              <>
                                <div className="no-record-box">
                                  <img src="/img/no-record.png" alt="no-img" />
                                  <h4 className="font-weight-bold">
                                    No Social Media Added Yet
                                  </h4>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-md-6 mx-auto">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-12 mb-4">
                      <button
                        className="training_btn"
                        onClick={(e) => {
                          navigate("/schedulerequest");
                        }}
                      >
                        Back
                      </button>
                    </div>
                    <div className="col-md-6 col-sm-6 col-12 mb-4">
                      <a
                        href={() => false}
                        onClick={(e) => {
                          PostEditProfile(e);
                        }}
                        className="training_btn">
                        Save
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 text-center">
                  <Link
                    target="_blank"
                    // to="/termsncondition"
                    to="/public-view/termsandconditionapp"
                    className="text-center terms-condition"
                  >
                    Terms & Conditions
                  </Link>
                </div>
              </div>

              {/* add socialmedia data */}
              <Modal
                show={socialmediaModal}
                onHide={(e) => {
                  setSocialmediaModal(false);
                }}
                className="mbody socialmedia"
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                {popuploader && <div className="loading">
                  <div className="mainloader"></div>
                </div>}
                <Modal.Header className="session-m" closeButton>
                  <Modal.Title className="book-title d-flex align-items-center ml-3 mb-0">
                    <img src="/img/common-health.png" alt="" />
                    <h4 className="mb-0 ml-3 font-weight-bold">
                      Add Social Media
                    </h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                  <div className="col-md-12 col-12 mb-3">
                    <label className="lbl-name">Name </label><span className="text-danger font-weight-bold">*</span>

                    <select
                      ref={iconRef}
                      className="input-box"
                      name="platform"
                      onChange={socialmediaHandle}
                    >
                      <option value="" disabled selected="selected">
                        Select Name
                      </option>
                      <option value="Facebook">Facebook</option>
                      <option value="Instagram">Instagram</option>
                      <option value="Twitter">Twitter</option>
                      <option value="Linkedin">Linkedin</option>
                      <option value="Youtube">Youtube</option>
                      <option value="Tiktok">Tiktok </option>
                    </select>
                    <div className="text-danger">{errors.platform}</div>
                  </div>

                  <div className="col-md-12 col-12 mb-3">
                    <label className="lbl-name">Social Media Link </label><span className="text-danger font-weight-bold">*</span><br />
                    <input
                      className="input-box"
                      type="url"
                      ref={urlRef}
                      placeholder="Url"
                      onChange={socialmediaHandle}
                      name="url"
                    />
                    <div className="text-danger">{errors.url}</div>
                  </div>
                  <div className="col-12">
                    <button
                      className="training_btn mt-4"
                      onClick={socialmediaAdd}
                    >
                      Save
                    </button>
                  </div>
                </Modal.Body>
              </Modal>

              {/* view qulification data */}
              <Modal show={show} onHide={handleClose} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header closeButton className="preview-img">
                  <Modal.Title className="book-title d-flex align-items-center mb-0">
                    <img src="/img/common-health.png" alt="" />
                    <h4 className="mb-0 ml-3 font-weight-bold">Qulification preview</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                  {qulifModelData?.id ?
                    <img
                      src={qulifModelData?.objeUrl}
                      alt="Qulification"
                      height={250}
                      width={250}
                      onError={(e) => {
                        e.target.src = "/img/Small-no-img.png";
                      }} className="pre-img"
                    />
                    :
                    <img
                      src={apiUrl + qulifModelData?.path}
                      alt="Qulification"
                      height={250}
                      width={250}
                      onError={(e) => {
                        e.target.src = "/img/Small-no-img.png";
                      }} className="pre-img"
                    />
                  }
                </Modal.Body>
              </Modal>

              {/*  Certifications view model */}
              <Modal show={show1} onHide={handleClose1} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header closeButton className="preview-img">
                  <Modal.Title className="book-title d-flex align-items-center mb-0">
                    <img src="/img/common-health.png" alt="" />
                    <h4 className="mb-0 ml-3 font-weight-bold">Certification Preview</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                  {certificationData.id ?
                    <img
                      src={certificationData?.objeUrl}
                      alt="Certification"
                      onError={(e) => {
                        e.target.src = "/img/Small-no-img.png";
                      }} className="pre-img"
                    />
                    :
                    <img
                      src={apiUrl + certificationData?.path}
                      alt="Certification"
                      onError={(e) => {
                        e.target.src = "/img/Small-no-img.png";
                      }} className="pre-img"
                    />
                  }
                </Modal.Body>
              </Modal>


            </div>
          </>
        )}
      </section >
    </>
  );
}

export default MyProfile;
