function FAQ() {
    return (
        <>
            <div className="sub-head-title">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <h1 className="main_title">FAQs and Support</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="col-md-12 col-12 p-0">
                    <div className="trainer-main-box">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="accordion" id="accordionExample">
                                    <div className="card">
                                        <div className="card-head" id="headingOne">
                                            <h2 className="mb-0" data-toggle="collapse" data-target="#collapseOne" aria-controls="collapseOne">
                                            Is online training suitable for beginners?
                                            </h2>
                                        </div>
                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                            <div className="card-body">
                                            Yes, online training can be suitable for beginners. A good online trainer will provide clear instructions, video demonstrations, and support to ensure proper form and technique. It's important to communicate your fitness level and any limitations to your trainer so they can design a safe and effective program.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-head" id="headingTwo">
                                            <h2 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-controls="collapseTwo">
                                            What are the advantages of working with an online trainer for gym workouts?
                                            </h2>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                            <div className="card-body">
                                            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-head" id="headingThree">
                                            <h2 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseThree" aria-controls="collapseThree">
                                            What are the primary benefits of practicing yoga?
                                            </h2>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                            <div className="card-body">
                                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-head" id="headingFour">
                                            <h2 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseFour" aria-controls="collapseFour">
                                            How do I stay motivated with online training?
                                            </h2>
                                        </div>
                                        <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                            <div className="card-body">
                                            Staying motivated with online training involves setting clear goals, celebrating achievements, maintaining a consistent schedule, staying engaged with your trainer through communication, and finding an accountability partner or support system to keep you on track.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-head" id="headingFive">
                                            <h2 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseFive" aria-controls="collapseFive">
                                            How can I find a reliable online trainer?
                                            </h2>
                                        </div>
                                        <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                            <div className="card-body">
                                             Look for online trainers with relevant certifications, experience, and positive client reviews. Consider trainers who specialize in your fitness goals, whether it's weight loss, strength building, or endurance training. Check if they offer a free consultation to discuss your needs and ensure a good fit.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-head" id="headingSix">
                                            <h2 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseSix" aria-controls="collapseSix">
                                                What types of exercises can I expect in an online training program?
                                            </h2>
                                        </div>
                                        <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                            <div className="card-body">
                                             Online training programs can include a variety of exercises such as bodyweight movements, resistance training, cardiovascular workouts, flexibility exercises, and more. The specific exercises will depend on your goals and the resources you have available.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FAQ;
