import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { apiUrl } from "../../environment/environment";
import { getToken } from "./../../firebaseInit";
// import TrainerLoginSchema from "../Schemas/index";
// import { useFormik } from "formik";

import CryptoJS from 'crypto-js'
const secret_key = "devloper@godofwebsite"

const initialValues = {
  email: "",
  password: "",
  rememberMe: false
};

function Login() {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  if (token !== null || token === "" || token === "undefined") {
    navigate("/");
    // window.location.reload();
  }

  const [user, setUser] = useState(initialValues)
  const [error, setError] = useState({})

  const encriptData = CryptoJS.AES.encrypt(JSON.stringify(user), secret_key).toString()
  const storedEncriptData = localStorage.getItem("encripted")

  const [isHidden, setIsHidden] = useState(true);
  const [isTokenFound, setTokenFound] = useState(false);
  console.log(isTokenFound);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [, setButtonLoader] = useState(false);
  const [dataLoader, setDataLoader] = useState(false)

  const emailRef = useRef()
  const passwordRef = useRef()

  // USED DUMMY TOKEN
  const [pushToken, setPushToken] = useState("");
  // To load once
  useEffect(() => {
    let data;
    async function tokenFunc() {
      data = await getToken(setTokenFound);
      if (data) {
        setPushToken(data);
      }
      return data;
    }
    tokenFunc();
    if (storedEncriptData) {
      var bytes = CryptoJS.AES.decrypt(storedEncriptData, secret_key);
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      // console.log("decryptedData",decryptedData);
      setUser({ email: decryptedData?.email, password: decryptedData?.password, rememberMe: decryptedData?.rememberMe })
    }
  }, [setTokenFound, storedEncriptData]);

  const GotoTrainer = () => {
    navigate("/client/login");
  };

  const isRequiredField = (fieldName) => {
    return ['email', 'password'].includes(fieldName);
  };

  const handleInputs = (e) => {
    const { name, value, type, checked } = e.target;
    setUser({ ...user, [name]: type === 'checkbox' ? checked : value });

    let reg_email =/^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

    if (value.trim() === '' && isRequiredField(name)) {
      setError((prevError) => ({ ...prevError, [name]: `Please enter ${name}!` }));
    } else if (name === 'email' && value.trim() !== '' && !reg_email.test(value)) {
      setError((prevError) => ({ ...prevError, email: 'Please enter a valid email!' }));
    } else {
      // Clear the error message when the user starts entering values
      setError((prevError) => ({ ...prevError, [name]: '' }));
    }
  }

  const PostLogin = async (e) => {
    e.preventDefault();

    let isValid = true;
    var errormsg = {};
    let reg_email = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

    if (user.email === "") {
      errormsg.email = "Please enter email id!";
      isValid = false;
    } else if (!reg_email.test(user.email)) {
      errormsg.email = "Please enter a valid email!";
      isValid = false;
    }

    if (user.password === "") {
      errormsg.password = "Please enter password!";
      isValid = false;
    } 

    if (Object.keys(errormsg).length > 0) {
      if (errormsg.email) {
        emailRef.current.focus()
      } else if (errormsg.password) {
        passwordRef.current.focus()
      }
    }
    setError(errormsg);
    if (isValid) {
      sessionStorage.removeItem("token");
      setIsButtonDisabled(true)
      setButtonLoader(true)
      setDataLoader(true)
      // document.querySelector('.loading').classList.remove('d-none');
      await axios.post(`${apiUrl}/trainer/account/login`, { "email": user.email, "password": user.password, "deviceid": pushToken, "devicetype": "web" }, {
      }).then(function (response) {
        // document.querySelector('.loading').classList.add('d-none');
        if (response.data.status === 1) {
          setTimeout(() => {
            axios.defaults.headers.common['Authorization'] = response.data.result.token;
            sessionStorage.setItem('user', JSON.stringify(response.data.result.User));
            sessionStorage.setItem('usertype', 'trainer');
            sessionStorage.setItem('token', response.data.result.token);

            if (user.rememberMe) {
              localStorage.setItem("encripted", encriptData)
            } else {
              localStorage.removeItem("encripted", encriptData)
            }

            setIsButtonDisabled(false)
            setButtonLoader(false)
            setDataLoader(false)
            navigate('/schedulerequest')
          }, 500);
        }
        else {
          setIsButtonDisabled(false)
          setButtonLoader(false)
          setDataLoader(false)
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true
          })
          // alert( response.data.error)
        }
      }).catch(function (error) {
        // console.log(error)
        setDataLoader(false)
        alert(error)
        // alert("hello...");
        // document.querySelector('.loading').classList.add('d-none');
      });
    }

  }


  return (
    <>
      <div className="container-fluid">
        <div className="commonbox">
          <div className="row">
            <div className="col-md-6 p-0">
              <div className="loginimg">
                <div className="col-md-12 logoblock">
                  <h3>Welcome To Common Health</h3>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div className="loginbox">
                <div className="login-form">
                  {dataLoader &&
                    <div className="loading">
                      <div className="mainloader"></div>
                    </div>
                  }
                  <div className="col-12 text-center">
                    <a className="login-logo" href="/">
                      <img src="/img/common-health.png" alt="logo" />
                    </a>
                    <h4 className="log-text">Sign Into Your Account</h4>
                  </div>
                  <ul
                    className="nav nav-tabs col-lg-8 col-md-10 col-sm-8 col-11 mx-auto"
                    role="tablist"
                  >
                    <li className="nav-item col-md-6 col-6 w-100 p-0">
                      <div
                        onClick={(e) => GotoTrainer()}
                        className="nav-link text-center"
                        data-toggle="tab"
                        href="#MEMBER"
                      >
                        MEMBER
                      </div>
                    </li>
                    <li className="nav-item col-md-6 col-6 w-100 p-0">
                      <a
                        className="active nav-link text-center"
                        data-toggle="tab"
                        href="#TRAINER"
                      >
                        TRAINER
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <form
                      id="TRAINER"
                      // onSubmit={handleSubmit}
                      className="container tab-pane active"
                    >
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label>Email</label><span className="text-danger font-weight-bold">*</span>
                          <input
                            name="email"
                            type="text"
                            className="w-100 input-box"
                            placeholder="Email"
                            value={user.email}
                            ref={emailRef}
                            onChange={(e) => handleInputs(e)}

                          />
                          <div className="text-danger">{error.email}</div>
                        </div>

                        <div className="col-md-12 mb-3">
                          <div className="position-relative">
                            <label>Password</label><span className="text-danger font-weight-bold">*</span>
                            <input
                              name="password"
                              type={isHidden === true ? "password" : "text"}
                              className="w-100 input-box"
                              placeholder="Password"
                              value={user.password}
                              onChange={(e) => handleInputs(e)}
                              ref={passwordRef}
                            />
                            <i
                              className={`fa fa-eye${isHidden === false ? "" : "-slash"
                                } icon`}
                              onClick={() => setIsHidden(!isHidden)}
                            ></i>
                          </div>
                          <div className="text-danger">{error.password}</div>
                        </div>
                        <div className="col-md-12 text-right my-1">
                          <div className="d-sm-flex justify-content-between align-items-center">
                            <div className="custom-control custom-checkbox regi-chk remeber-me">
                              <input type="checkbox" className="custom-control-input" id="term" onChange={(e) => handleInputs(e)} checked={user.rememberMe} name="rememberMe" />
                              <label className="custom-control-label terms-text" htmlFor="term">
                                <span className="pl-2">Remember Me</span>
                              </label>
                            </div>
                            <Link to={`/trainer/forgotpassword`} className="linktext">
                              FORGOT PASSWORD ?
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <button disabled={isButtonDisabled} onClick={(e) => PostLogin(e)} type="submit" className="loginbtn mt-3">
                            Login
                            {/* {buttonLoader ? (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm ml-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </>
                            ) : (
                              ""
                            )} */}
                          </button>
                        </div>
                        <div className="col-md-12 text-center mt-3">
                          <span className="text-login">
                            Not registered yet?
                            <Link to="/trainersignup" className="linktext pl-1">
                              Sign Up
                            </Link>
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
