import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { apiUrl } from "../../environment/environment";
import swal from "sweetalert";
// import { verifytokenCall } from "../Others/Utils.js";
import { Modal } from "react-bootstrap";
import { Link,useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function ClientPayment() {
  // const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [isNewCard, setIsNewCard] = useState(false);
  const [newCardArr, setNewCardArr] = useState([]);
  const [selectedCard, setSelectedCard] = useState([]);
  const userData = JSON.parse(sessionStorage.getItem("user"));
  const [confirmReqModal, setConfirmReqModal] = useState(false);
  const initialState = {
    cardholdersName: "",
    cardNumber: "",
    expirationMonth: "",
    expirationYear: "",
    cvv: "",
  };
  const [
    { cardholdersName, cardNumber, expirationMonth, expirationYear, cvv },
    setNewCardDetail,
  ] = useState(initialState);

const navigate= useNavigate()

  const purchasePlan = JSON.parse(sessionStorage.getItem("PurchasePlan"));

  const retriveCustomer = useCallback(() => {
    const data = {
      email: userData.email,
    };
    document.querySelector('.loading').classList.remove('d-none');
    axios
      .post(`${apiUrl}/payment/retrieveCustomer`, data)
      .then((response) => {
        document.querySelector('.loading').classList.add('d-none');
        if (response.data.status === 1) {
          setNewCardArr(response.data.result.data);
          response.data.result.customer_id = response.data.customer_id;
          sessionStorage.setItem(
            "customer",
            JSON.stringify(response.data.result)
          );
          // navigate("/trainer");
        } else {
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
          //window.alert(response.data.message);
        }
      })
      .catch(function (error) {
        // document.querySelector('.loading').classList.add('d-none');
      });
  }, [userData.email]);

  useEffect(() => {
    retriveCustomer();
  }, [retriveCustomer]);

  const newCardhandleChange = (e) => {
    if (e.target.name === "cvv") {
      if (e.target.value.length > 3) return;
    }
    const { name, value } = e.target;
    setNewCardDetail((prevState) => ({ ...prevState, [name]: value }));
  };

  const deleteCardDetail = (ele) => {
    let deleteCard = {
      customer_id: ele.customer,
      card_id: ele.id,
    };

    Swal.fire({
      text: "Are you sure you want to delete this card ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        document.querySelector(".loading").classList.remove("d-none");
    axios
      .post(`${apiUrl}/payment/deleteCard`, deleteCard)
      .then((response) => {
        if (response.data.status === 1) {
          setTimeout(() => {
            setNewCardArr(newCardArr.filter((item) => item.id !== ele.id));
            setSelectedCard([]);
            document.querySelector(".loading").classList.add("d-none");
          }, 500);

          // navigate("/trainer");
        } else {
          swal({
            title: "Error!",
            text: response.data.message.code,
            icon: "error",
            button: true,
          });
          //window.alert(response.data.message);
        }
      })
      .catch(function (error) {
        document.querySelector(".loading").classList.add("d-none");
      });
    }})

  };

  const onPayNow = (e) => {
    e.preventDefault();
    const customer = JSON.parse(sessionStorage.getItem("customer"));
    let isValid = true;
 
    if (selectedCard.length === 0) {
      isValid = false;
      swal({
        title: "Info!",
        text: newCardArr.length > 0 ? `Please Choose Card` :`Please Add Card`,
        icon: "info",
        button: true,
        
      });
    }
    let savepayment = {
      date: new Date(),
      noofsession: purchasePlan.sessions,
      availablesessions: purchasePlan.sessions,
      plantype: purchasePlan.planType,
      amount: purchasePlan.amount,
      payment_method: selectedCard.id,
      customer_id: customer.customer_id,
      currency: "usd",
    };
    // setErrors(errormsg);
    if (isValid) {
      /*  let savepayment = {
                 date: new Date(),
                 noofsession: purchasePlan.sessions,
                 plantype: purchasePlan.planType,
                 amount: purchasePlan.amount
             } */
      document.querySelector(".loading").classList.remove("d-none");
      axios
        .post(`${apiUrl}/payment/savepayment`, savepayment)
        .then((response) => {
          document.querySelector(".loading").classList.add("d-none");
          if (response.data.status === 1) {
            console.log("response.data success",response.data);
            sessionStorage.setItem(
              "clientPayment",
              JSON.stringify(response.data.clientPayment)
            );
            setConfirmReqModal(true);
            // navigate("/trainer?status=1");
          } else {
            console.log("response.data error",response.data);
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
            //window.alert(response.data.message);
          }
        })
        .catch(function (error) {
          console.log("error catch",error);
          document.querySelector(".loading").classList.add("d-none");
        });
    }
  };

  const [errorCard, setErrorCard] = useState();

  const addNewCard = (e) => {
    e.preventDefault();

    let isValid = true;
    var errormsg = {};
    if (cardholdersName === "") {
      errormsg.newcardholdersName = "Please enter holder name!";
      isValid = false;
    }
    if (cardNumber === "") {
      errormsg.newcardNumber = "Please enter card number!";
      isValid = false;
    }

    if (expirationMonth === "") {
      errormsg.newexpirationMonth = "Please select expiration month!";
      isValid = false;
    }
    if (expirationYear === "") {
      errormsg.newexpirationYear = "Please select expiration year!";
      isValid = false;
    }
    if (cvv === "") {
      errormsg.newcvv = "Please enter cvv number!";
      isValid = false;
    }
    setErrors(errormsg);
   
    if (isValid) {
      const customer = JSON.parse(sessionStorage.getItem("customer"));
      let savepayment = {
        // date: new Date(),
        number: cardNumber,
        exp_month: expirationMonth,
        exp_year: expirationYear,
        cvc: cvv,
        customer_id: customer.customer_id,
        cardholdersName: cardholdersName,
      };
      
      document.querySelector(".loading").classList.remove("d-none");
      axios
        .post(`${apiUrl}/payment/addNewcard`, savepayment)
        .then((response) => {
          document.querySelector(".loading").classList.add("d-none");
          if (response.data.status === 1) {
            setNewCardArr(response.data.result.data);
            response.data.result.customer_id = response.data.customer_id;
            sessionStorage.setItem("customer",JSON.stringify(response.data.result));

            /* setNewCardArr(prev => ([...prev, response.data.record])); */
            setErrors({});
            setIsNewCard(false);
            // navigate("/trainer");
          } else {
            // swal({
            //     title: "Error!",
            //     text: response.data.message,
            //     icon: "error",
            //     button: true
            // })
            setErrorCard(response.data.message);
            return;
            //window.alert(response.data.message);
          }
        })
        .catch(function (error) {
          document.querySelector(".loading").classList.add("d-none");
        });
    }
  };

  return (
    <>
      <div className="container-fluid mob-view">
        <div className="commonbox position-relative">
        <div className="loading d-none height-full">
          <div className="mainloader"></div>
        </div>
          <div clasName="col-md-12 col-12 text-right pr-md-0">
            <h5 className="mb-3 text-right">
            </h5>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6 p-0">
                <div className="loginimg payment">
                  <div className="col-md-12 logoblock">
                    <h3>Payment</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-0">
                {!isNewCard ? (
                  <div className="loginbox clientpayment">
                    <div className="login-form">
                    <button className="backbtn" type="button" onClick={() => navigate(-1)}>Back</button>
                      <h6 className="mb-4 ml-3 font-weight-bold">Details:</h6>
                      <div className="col-md-12">
                        <div className="grayblock">
                          <div className="d-sm-flex justify-content-between">
                            <h6>Plan</h6>
                            <p className="text-sm-right">
                              {purchasePlan.planType} (
                              {purchasePlan.sessions === "1"
                                ? "Single"
                                : purchasePlan.sessions}{" "}
                              sessions)
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="grayblock">
                          <div className="d-sm-flex justify-content-between">
                            <h6>Amount</h6>
                            <p className="text-sm-right">
                              ${purchasePlan.amount}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="grayblock">
                          <div className="d-sm-flex justify-content-between">
                            <h6>Tax</h6>
                            <p className="text-sm-right">$02.00</p>
                          </div>
                        </div>
                      </div>
                      {newCardArr.length > 0 && (
                        <>
                          <h6 className="mb-4 ml-3 font-weight-bold">
                            Payment Method:
                          </h6>
                          <div className="cardblock">
                            <div className="col-md-12">
                              <div className="grid">

                                {newCardArr &&
                                  newCardArr.map((ele) => {
                                    return (
                                      <label
                                        className="card mb-3"
                                        onClick={(e) => {
                                          setSelectedCard(ele);
                                        }}
                                      >
                                        <input
                                          name="plan"
                                          className="radio"
                                          /* onChange={setCardData} */ type="radio"
                                          vlaue={ele.id}
                                        />
                                        <span className="plan-details">
                                          <span className="d-flex justify-content-between">
                                            <span>
                                              {ele.funding.toUpperCase()} CARD
                                            </span>
                                            {ele.brand === "Visa" ? (
                                              <img
                                                src="/img/visacard.png"
                                                className="cardimg visaimg"
                                                alt="Card"
                                              />
                                            ) : ele.brand === "MasterCard" ? (
                                              <img
                                                src="/img/mastercard.png"
                                                className="cardimg visaimg"
                                                alt="Card"
                                              />
                                            ) : ele.brand === "JCB" ? (
                                              <img
                                                src="/img/jcb.png"
                                                className="cardimg visaimg"
                                                alt="Card"
                                              />
                                            ) : ele.brand === "Discover" ? (
                                              <img
                                                src="/img/discover.jpg"
                                                className="cardimg visaimg"
                                                alt="Card"
                                              />
                                            ) : ele.brand ===
                                              "American Express" ? (
                                              <img
                                                src="/img/american_exp.jpg  "
                                                className="cardimg visaimg"
                                                alt="Card"
                                              />
                                            ) : (
                                              ele.brand
                                            )}
                                          </span>
                                          {/* <span>xxxx xxxx xxxx 2035</span> */}
                                          <span>
                                            xxxx xxxx xxxx {ele.last4}
                                          </span>
                                          <span className="d-flex justify-content-between">
                                            <span>
                                              {ele.exp_month}/{ele.exp_year}
                                            </span>
                                            {/* {newCardArr.length > 1 &&  */}
                                            <a
                                              href={() => false}
                                              className="cursor-pointer"
                                              onClick={(e) => {
                                                deleteCardDetail(ele);
                                              }}
                                            >
                                              <i className="fas fa-trash"></i>
                                            </a>
                                            {/* } */}
                                          </span>
                                        </span>
                                      </label>
                                    );
                                  })}

                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-xl-6 col-12 pr-xl-2">
                            <button
                              className="loginbtn payment-btn mt-2"
                              onClick={(e) => {
                                onPayNow(e);
                              }}
                            >
                              {" "}
                              Pay Now{" "}
                            </button>
                          </div>
                          <div className="col-xl-6 col-12 pl-xl-2">
                            <button
                              className="loginbtn payment-btn mt-2"
                              onClick={(e) => {
                                setIsNewCard(true);
                                setNewCardDetail({ ...initialState });
                              }}
                            >
                              Add New Card
                            </button>
                          </div>
                          {/* <Link to="/paymentSuccess" className="loginbtn mt-4">Pay Now</Link> */}
                        </div>
                        {/* <Button className="mt-3" data-toggle="modal" data-target="#send-request" onClick={(e) => { setConfirmReqModal(true); }}>
                                            Demo
                                        </Button> */}
                        {/* <button data-toggle="modal" data-target="#send-request" onClick={() => { callSendRequest() }} className="training_btn mb-3">Request future session</button> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="loginbox clientpayment">
                    <div className="login-form">
                      <h6 className="mb-4 ml-3 font-weight-bold">Details</h6>
                      <div className="col-md-12">
                        <div className="grayblock">
                          <div className="d-sm-flex justify-content-between">
                            <h6>Plan</h6>
                            <p className="text-sm-right">
                              {purchasePlan.planType} ({purchasePlan.sessions}{" "}
                              sessions)
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="grayblock">
                          <div className="d-sm-flex justify-content-between">
                            <h6>Amount</h6>
                            <p className="text-sm-right">
                              ${purchasePlan.amount}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="grayblock">
                          <div className="d-sm-flex justify-content-between">
                            <h6>Tax</h6>
                            <p className="text-sm-right">$02.00</p>
                          </div>
                        </div>
                      </div>
                      <h6 className="mb-4 ml-3 font-weight-bold">Payment</h6>
                      <h6 className="my-4 ml-3 font-weight-bold">
                        Card Details
                      </h6>
                      <div className="col-md-12">
                        <input
                          className="input-box w-100 mb-3"
                          placeholder="Cardholder's Name"
                          value={cardholdersName}
                          name="cardholdersName"
                          onChange={newCardhandleChange}
                        />
                        <div className="text-danger">
                          {errors.newcardholdersName}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <input
                          className="input-box w-100 mb-3"
                          placeholder="Card Number"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          value={cardNumber}
                          name="cardNumber"
                          onChange={(e) => {
                            if (e.target.value.length === 17) return;
                            newCardhandleChange(e);
                          }}
                        />
                        <div className="text-danger">
                          {errors.newcardNumber}
                        </div>
                        <div className="text-danger">{errorCard}</div>
                      </div>
                      <div className="col-md-12 p-0 d-xl-flex">
                        <div className="col-xl-8">
                          <label className="font-weight-bold">Expiration date</label>
                          <div className="d-flex justify-content-between">
                            <select
                              className="input-box w-100 mr-2"
                              value={expirationMonth}
                              name="expirationMonth"
                              onChange={newCardhandleChange}
                            >
                              <option>Month</option>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                              <option>6</option>
                              <option>7</option>
                              <option>8</option>
                              <option>9</option>
                              <option>10</option>
                              <option>11</option>
                              <option>12</option>
                            </select>
                            <select
                              className="input-box w-100"
                              value={expirationYear}
                              name="expirationYear"
                              onChange={newCardhandleChange}
                            >
                              <option>Year</option>
                              <option>2022</option>
                              <option>2023</option>
                              <option>2024</option>
                              <option>2025</option>
                              <option>2026</option>
                              <option>2027</option>
                              <option>2028</option>
                              <option>2029</option>
                              <option>2030</option>
                            </select>
                          </div>
                          <div className="text-danger">
                            {errors.newexpirationMonth}
                          </div>
                          <div className="text-danger">
                            {errors.newexpirationYear}
                          </div>
                        </div>
                        <div className="col-xl-4">
                          <label className="font-weight-bold">CVC/CVV</label>
                          <input
                            className="input-box w-100 mr-2"
                            type="text"
                            maxLength={"3"}
                            placeholder="CVV"
                            name="cvv"
                            value={cvv}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onChange={newCardhandleChange}
                          />
                          <div className="text-danger">{errors.newcvv}</div>
                        </div>
                      </div>
                      {/* <div className="col-md-12">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="Trainerreg" name="rememberMyCard" value={rememberMyCard}
                                                    onChange={newCardhandleChange} />
                                                <label className="custom-control-label" htmlFor="Trainerreg">Remember My Card</label>
                                            </div>
                                            <div className="text-danger">{errors.rememberMyCard}</div>
                                        </div> */}
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6 col-12 pr-xl-2">
                            <button className="loginbtn mt-4" onClick={(e) => { setIsNewCard(false); setErrors({}); }}>
                              Back</button>
                          </div>
                          <div className="col-md-6 col-12 pl-xl-2">
                            {/* <Link to="/paymentSuccess" className="loginbtn mt-4">Pay Now</Link> */}
                            <button className="loginbtn mt-4" onClick={(e) => { addNewCard(e); }}>Save </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={confirmReqModal} onHide={(e) => { setConfirmReqModal(false); }} className="mbody sessiondetail" size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header className="bg-transparent text-dark border-0 session-m">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-2">
          <div
            className="col-md-12 col-12 text-center"
            onClick={() => {
              setConfirmReqModal(false);
              setTimeout(() => {
                // setOpen(false)
              }, 5000);
            }}
          >
            <i className="far fa-check-circle check-i"></i>
            <h4 className="book-title mb-4">
              Your Session is Purchased Successfully !
            </h4>
            <Link to="/payment/clientpaymenthistory/1" className="session-btn mb-3">
              Payment Details
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ClientPayment;
