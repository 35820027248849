import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { apiUrl } from "../../environment/environment";
import Pagination from "../Pagination/Pagination";


function MySession() {

  const navigate = useNavigate();
  const [dataLoader, setDataLoader] = useState(false);

  // const [isLoader, setIsLoader] = useState(false);
  const [upcomingList, setUpcomingList] = useState([]);
  const [completedList, setCompletedList] = useState([]);
  const [ucpageNum, setucPageNum] = useState(1);
  const [ucnoOfRecords, setucNoOfRecords] = useState(0);
  const uclimitValue = 6;
  const [cpageNum, setcPageNum] = useState(1);
  const [cnoOfRecords, setcNoOfRecords] = useState(0);
  const climitValue = 6;

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let page_url = parseInt(query.get("p"));

  useEffect(() => {
    // callToken();
    // fetchList();
    setucPageNum(page_url || 1);
    setcPageNum(page_url || 1);
    getupcommingsessionList(page_url || 1);
    getcompeletedsessionList(page_url || 1);
  }, []);

  // const callToken = () => {
  //   verifytokenCall();
  //   setTimeout(() => {
  //     callToken();
  //   }, 3000);
  // };

  // const fetchList = async () => {
  //     setIsLoader(true);
  //     var obj = {
  //         limitValue: limitValue,
  //         pageNumber: (val || pageNum)
  //     };

  //     await axios.get(`${apiUrl}/client/session/getclientsession`, obj, {}
  //     ).then(function (response) {
  //         setIsLoader(false);
  //         if (response.data.status === 1) {
  //             var upcomingList = [];//response.data.result.upcomingList;
  //             var completedList = [];//response.data.result.completedList;
  //             var curTime = new Date().toDateString();
  //             var acnt = 0; var rcnt = 0;

  //             if (response?.data?.result) {
  //                 if (response?.data?.result?.upcomingList.length > 0) {
  //                     response?.data?.result?.upcomingList.forEach(element => {

  //                         var sessdate = new Date(element?.date).toDateString();
  //                         var currentdate = new Date();
  //                         var currenthours = new Date().getHours();
  //                         var currentminutes = new Date().getMinutes();
  //                         currentdate.setHours(currenthours);
  //                         currentdate.setMinutes(currentminutes);
  //                         currentdate.setSeconds(0);
  //                         currentdate.setMilliseconds(0);

  //                         var sessiondate = new Date(sessdate + " " + element?.endhour);
  //                         var sessionhours = new Date(sessdate + " " + element?.endhour).getHours();
  //                         sessiondate.setHours(sessionhours);
  //                         sessiondate.setMinutes(0);
  //                         sessiondate.setSeconds(0);
  //                         sessiondate.setMilliseconds(0);
  //                         if (currentdate < sessiondate) {
  //                             upcomingList.push(element);
  //                         }

  //                         rcnt++;
  //                         if (response.data.result.upcomingList.length === rcnt) {
  //                             setUpcomingList(upcomingList);
  //                         }
  //                     });
  //                 }
  //                 if (response?.data?.result?.completedList.length > 0) {
  //                     response?.data?.result?.completedList.forEach(element => {

  //                         var sessdate = new Date(element?.date).toDateString();
  //                         var currentdate = new Date();
  //                         var currenthours = new Date().getHours();
  //                         currentdate.setHours(currenthours);
  //                         currentdate.setMinutes(0);
  //                         currentdate.setSeconds(0);
  //                         currentdate.setMilliseconds(0);

  //                         var sessiondate = new Date(sessdate + " " + element?.endhour);
  //                         var sessionhours = new Date(sessdate + " " + element?.endhour).getHours();
  //                         sessiondate.setHours(sessionhours);
  //                         sessiondate.setMinutes(0);
  //                         sessiondate.setSeconds(0);
  //                         sessiondate.setMilliseconds(0);
  //                         if (currentdate >= sessiondate) {
  //                             completedList.push(element);
  //                         }

  //                         acnt++;
  //                         if (response.data.result.completedList.length === acnt) {
  //                             setCompletedList(completedList);
  //                         }
  //                     });
  //                 }
  //             } else {
  //                 setUpcomingList(upcomingList);
  //                 setCompletedList(completedList);
  //             }
  //         }
  //         return response;
  //     }).catch(function (error) {
  //         setIsLoader(false);
  //         window.alert(error);
  //     });
  // }

  const uccurPage = (pageNum) => {
    navigate(`/mysession/?p=${pageNum}`);
    setucPageNum(pageNum);
    getupcommingsessionList(pageNum);
  };

  const ccurPage = (pageNum) => {
    navigate(`/mysession/?p=${pageNum}`);
    setcPageNum(pageNum);
    getcompeletedsessionList(pageNum);
  };

  const getupcommingsessionList = async (val) => {
    // setIsLoader(true);
    setDataLoader(true)
    var obj = {
      limitValue: uclimitValue,
      pageNumber: val || ucpageNum,
    };

    await axios
      .post(`${apiUrl}/client/session/getupcommingsession`, obj, {})
      .then(function (response) {
        /* setIsLoader(false); */
        if (response.data.status === 1) {
          //var tempupcomingList = [];
          if (response?.data?.result) {
              setTimeout(() => {
                setucNoOfRecords(response.data?.result[0]?.totalCount[0]?.count || 0);
                setUpcomingList(response.data?.result[0]?.paginatedResults);
               setDataLoader(false)
              }, 500);
            } else {
              setUpcomingList([]);
              setDataLoader(false)
            }
          
        }
        return response;
      })
      .catch(function (error) {
        // setIsLoader(false);
        setDataLoader(false)
        window.alert(error);
      });
  };

  const getcompeletedsessionList = async (val) => {
    // setIsLoader(true);
    setDataLoader(true)
    var obj = {
      limitValue: climitValue,
      pageNumber: val || cpageNum,
    };

    await axios
      .post(`${apiUrl}/client/session/getcompeletedsession`, obj, {})
      .then(function (response) {
        // setIsLoader(false);
        if (response.data.status === 1) {
          //   var tempcompletedList = [];
          if (response?.data?.result) {
              setTimeout(() => {
                setcNoOfRecords(
                  response.data?.result[0]?.totalCount[0]?.count || 0
                );
                setCompletedList(response.data?.result[0]?.paginatedResults);
                setDataLoader(false)
              }, 500);
              
            } else {
              setCompletedList([]);
            }
         
        }
        return response;
      })
      .catch(function (error) {
        // setIsLoader(false);
        setDataLoader(false)
        window.alert(error);
      });
  };

  return (<>
      
      <div className="sub-head-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-12">
              <h1 className="main_title">Session Detail</h1>
            </div>
          </div>
        </div>
      </div>
       

      <div className="container-fluid">
         <div className="col-md-12 col-12 p-0">
         {dataLoader ? 
         <div id="loadermodal" className="loader"><div className="popuploader loginloader"></div></div>        
        :""}
          

          

         
            
            <div className="trainer-main-box mb-4" >
              <div className="row">
                  <div className="col-md-12 col-12 mb-3">
                    <h4 className="main_title session-sub-title">
                      My Sessions
                    </h4>
                  </div>
                  {upcomingList.length > 0 ? 
                      upcomingList.map((item,index)=>{
                      return(
                        <div
                        key={"index" + index}
                        className="col-xl-4 col-lg-4 col-md-6 col-12 mb-3 cursor-pointer"
                        >
                        <div className="image-box">
                          <div className="session-imgblock">
                            <img
                              src={`${apiUrl + item.trainer_data.profile}`}
                              onError={(e) => {
                                e.target.src = "/img/Back-No-Image.png";
                              }}
                              alt="Profile"
                            />
                          </div>
                          <div className="my-session-content">
                            <p className="session-title">
                              {item.trainer_data.firstname}
                            </p>
                            <div className="d-flex justify-content-between">
                              <div className="session-time">
                                <span className="s-whitebox">
                                  <i className="far fa-calendar pr-1"></i>
                                  {new Date(item.date).toLocaleDateString(
                                    "en-IN",
                                    { month: "short", day: "numeric" }
                                  )}
                                </span>
                                <span className="s-whitebox">
                                  <i className="far fa-clock pr-1"></i>
                                  {item.starthour.split(":")[0] +
                                    ":" +
                                    item.starthour.split(":")[1]}
                                </span>
                              </div>
                              <div
                                className="banner-btn mt-0 cursor-pointer"
                                onClick={() => {
                                  navigate(
                                    `/booksessionsdetail?id=${item._id}&type=My Session`
                                  );
                                }}
                              >
                                Start Training
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      )
                      }):(<>
                       {dataLoader===false && 
                        <div className="col-12">
                          <div className="trainer-main-box mb-4">
                            <div className="no-record-box">
                              <img src="/img/no-record.png" alt="no-img" />
                              <h4 className="font-weight-bold"> Sorry, You have not started a session yet.</h4>
                              <p>Do you want to start a session ? <Link className="ps-2" to={"/trainer?status=0"}>Click hear</Link></p>
                             </div>
                          </div>
                        </div>
                       } 
                       
                      </>)}
                <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                  <Pagination
                    className="pagination-bar"
                    currentPage={ucpageNum}
                    totalCount={ucnoOfRecords}
                    pageSize={uclimitValue}
                    onPageChange={(page) => uccurPage(page)}
                  />
                </div>
             </div>
          </div>
         
         
          <div className="trainer-main-box" >
            <div className="row">
              <div className="col-md-12 col-12 mb-3">
                <h4 className="main_title session-sub-title">
                  Completed Sessions
                </h4>
              </div>
                {completedList.length > 0 ? 
                  completedList.map((item, index)=>{
                    return(
                    <div key={"index" + index} className="col-xl-4 col-lg-4 col-md-6 col-12 mb-3 cursor-pointer">
                      <div className="image-box">
                        <div className="session-imgblock">
                          <img
                            src={`${apiUrl + item.trainer_data.profile}`}
                            onError={(e) => {
                              e.target.src = "/img/Back-No-Image.png";
                            }}
                            alt="Profile"
                          />
                        </div>
                        <div className="my-session-content">
                          <p className="session-title">
                            {item.trainer_data.firstname}
                          </p>
                          <div className="d-flex justify-content-between">
                            <div className="session-time">
                              <span className="s-whitebox">
                                <i className="far fa-calendar pr-1"></i>
                                {new Date(item.date).toLocaleDateString(
                                  "en-IN",
                                  { month: "short", day: "numeric" }
                                )}
                              </span>
                              <span className="s-whitebox">
                                <i className="far fa-clock pr-1"></i>
                                {item.starthour.split(":")[0] +
                                  ":" +
                                  item.starthour.split(":")[1]}
                              </span>
                            </div>
                            {/* <div
                              className="banner-btn mt-0 cursor-pointer"
                              onClick={() => {
                                navigate(
                                  `/booksessionsdetail?id=${item._id}&type=My Session`
                                );
                              }}>
                              Start Training
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    )
                  }):(<>
                  {dataLoader===false &&                        
                    <div className="col-12">
                      <div className="trainer-main-box mb-4">
                      <div className="no-record-box">
                          <img src="/img/no-record.png" alt="no-img" />
                          <h4 className="font-weight-bold">
                            No Completed Sessions Detail Found
                          </h4>
                          <p> When you have complete your session, It will display here</p>
                        </div>
                      </div>
                    </div>
                    }
                  </>) }
              <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                <Pagination
                  className="pagination-bar"
                  currentPage={cpageNum}
                  totalCount={cnoOfRecords}
                  pageSize={climitValue}
                  onPageChange={(page) => ccurPage(page)}
                />
              </div>
            </div>
          </div>
          
        </div> 

      </div> 

      </>)
}
export default MySession;
