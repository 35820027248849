/* eslint-disable array-callback-return */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../environment/environment";
import swal from "sweetalert";
import Moment from "react-moment";
function ViewProgresUpload() {

  const naviagte = useNavigate();
  const ProfileImage_URL = "/img/Small-no-img.png";

  const initState = { date: new Date(), list: [], base64Img: [] };
  const [getAllPhotos, setGetAllPhotos] = useState([]);
  const [isMountRender, setMountRender] = useState(true);
  const [trainerData, setTrainerData] = useState();
  const [profileimagepreview, setProfileImagePreview] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  let nowDate = new Date().toISOString();
  const curDate = nowDate.split("T")[0];

  useEffect(() => {
    if (isMountRender) return;
  }, [isMountRender]);
  useEffect(() => {
    setTrainerData(JSON.parse(sessionStorage.getItem("user")));
    setMountRender(false);
    fetchProgressPhotos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProgressPhotos = () => {
    document.querySelector(".loading").classList.remove("d-none");
    axios
      .get(`${apiUrl}/client/account/getprogressphotos`, {}, {})
      .then(function (response) {
        if (response.data.status === 1) {
          setTimeout(() => {
            if (response.data.result && response.data.result.length > 0) {
              if (response.data.result.length > 0) {
                if (
                  response.data.result.filter(
                    (x) =>
                      new Date(x.date).toLocaleDateString() ===
                      new Date().toLocaleDateString()
                  ).length === 0
                ) {
                  response.data.result.push(initState);
                } else {
                  response.data.result.forEach((ele) => {
                    ele["base64Img"] = ele.list;
                  });
                }

              }
              setGetAllPhotos(response.data.result.reverse());
            } else {
              response.data.result.push(initState);
              setGetAllPhotos(response.data.result.reverse());
            }
            document.querySelector(".loading").classList.add("d-none");

          }, 500);

        } else {
          document.querySelector(".loading").classList.add("d-none");

          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
          //window.alert(response.data.message);
        }
      })
      .catch(function (error) {
        document.querySelector(".loading").classList.add("d-none");
        window.alert(error);
      });
  };

  const OnFileChange = (event, obj) => {
    if (event.target.files.length === 0) {
      return false;
    }
    const file_size = event.target.files[0].size;
    if (file_size > 1048000) {
      setProfileImagePreview(ProfileImage_URL)
      alert("File size more than 1 MB. File size must under 1MB !");
      event.preventDefault();
    } else {
      const fileReader = new window.FileReader();
      const file = event.target.files[0];
      if (file) {
        fileReader.onload = (fileLoad) => {
          const { result } = fileLoad.target;
          setIsEdit(true);
          setProfileImagePreview(result)
          if (getAllPhotos.length > 0) {

            if (
              obj &&
              obj.date ===
              getAllPhotos.filter((x) => x.date === obj.date)[0]?.date
            ) {
              getAllPhotos
                .filter((x) => x.date === obj.date)[0]
                .list.push(file);
              getAllPhotos
                .filter((x) => x.date === obj.date)[0]
                .base64Img.push(result);
            }
          } else {
            obj.list.push(file);
            obj.base64Img.push(result);
            setGetAllPhotos((old) => [...old, obj]);
          }

        };
        fileReader.readAsDataURL(file);
      }
    }
  };

  const uploadImages = async (e) => {
    e.preventDefault();
    // document.querySelector('.loading').classList.remove('d-none');
    const formData = new FormData();
    var filelist = [];
    var myIndex = [];
    // debugger
    getAllPhotos.forEach((index) => {
      if (typeof index.date == "object") {
        index.date = index.date.toISOString();
      }

      for (var key in index.list) {
        if (index.list[key].type !== undefined) {
          formData.append(index.list[key].name, index.list[key]);
          filelist.push(index.list[key].name);
        } else {

          if (index.date.split("T")[0] === curDate) {
            let isCheckBase64 = index.list[key]?.indexOf("data:image/") > -1;
            if (!isCheckBase64) {
              formData.append(
                index.list[key].split("/")[
                index.list[key].split("/").length - 1
                ],
                index.list[key]
              );
              myIndex.push(
                index.list[key].split("/")[
                index.list[key].split("/").length - 1
                ],
                index.list[key]
              );
              filelist.push(
                index.list[key].split("/")[
                index.list[key].split("/").length - 1
                ]
              );
            }
          }
        }
      }
      if (index.date.split("T")[0] === curDate) {
        formData.append("progressphotos", new Date(index.date));
      }
    });

    filelist = filelist.filter(function (element) {
      return element !== undefined;
    });
    formData.append("filelist", JSON.stringify(filelist));

    await axios
      .post(`${apiUrl}/client/account/saveprogressphotos`, formData, {})
      .then(function (response) {
        document.querySelector(".loading").classList.add("d-none");
        setIsEdit(false)
        if (response.data.status === 1) {
          swal({
            title: "Success!",
            text: response.data.message,
            icon: "success",
            button: true,
          }).then(() => {
            // window.location.reload();
          });
          // naviagte("/editprofile")
        } else {
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
          //window.alert(response.data.message);
        }
      })
      .catch(function (error) {
        document.querySelector(".loading").classList.add("d-none");
        window.alert(error);
      });
  };

  const OnFileDiacard = (value, index) => {
    value.splice(index, 1);
    console.log("value", value);
    setProfileImagePreview(value);
    // setGetAllPhotos(value)
  };

  return (
    <>
      <div className="sub-head-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-12">
              <h1 className="main_title">Progress Photos</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-md-12 col-12 p-0">
          <div className="trainer-main-box">
            <div className="loading d-none">
              <div className="mainloader"></div>
            </div>
            <div className="row">
              {getAllPhotos.length > 0 &&
                // eslint-disable-next-line array-callback-return
                getAllPhotos.map((res, index) => {
                  if (res !== "") {
                    console.log(" res?.base64Img?.length", res?.base64Img)
                    return (
                      <>
                        <div key={index} className="col-12 col-md-6 mb-3">
                          <div className="progress-box">
                            <div className="row" dataId={index}>
                              <div className="col-md-12 col-12 mb-3 px-md-2 pb-3 viewimg-bor">
                                <div className="d-flex">
                                  <div className="p-photo">
                                    <img
                                      src={apiUrl + trainerData.profile}
                                      onError={(e) => {
                                        e.target.src = "/img/Small-no-img.png";
                                      }}
                                      alt="Profile"
                                    />
                                  </div>
                                  <div className="progress-content">
                                    <p>Photos</p>
                                    {/* <span>{new Date(res.date).toDateString()}</span> */}
                                    <span>
                                      <Moment
                                        format="DD MMMM YYYY"
                                        date={res.date}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>

                              {/* show horizontal after upload */}
                              {res?.base64Img &&
                                res?.base64Img?.length > 0 &&
                                res?.base64Img
                                ? res?.base64Img.map((ele, index) => {
                                  if (ele?.type?.indexOf("image/") > -1) {
                                    return <></>;
                                  } else {
                                    return (
                                      <div
                                        key={index}
                                        className="col-lg-3 col-md-6 col-12 mb-2 px-md-2">
                                        <div className="prog-img">
                                          <img
                                            src={`${ele?.indexOf("data:image/") > -1
                                              ? ele
                                              : apiUrl + ele
                                              }`}
                                            onError={(e) => {
                                              e.target.src =
                                                "/img/Small-no-img.png";
                                            }}
                                            alt="img"
                                          />
                                          {isEdit ? (
                                            <>
                                              <button
                                                className="img-cancel"
                                                onClick={() => OnFileDiacard(res?.base64Img, index)}
                                              >
                                                <i className="fas fa-times"></i>
                                              </button>
                                            </>
                                          ) : (
                                            <>
                                              <label htmlFor="imageUpload"></label>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }
                                })
                                : res?.list &&
                                res?.list?.length > 0 &&
                                res?.list.map((ele, index) => {
                                  let isFile = ele.type
                                    ? ele.type.indexOf("image") > -1
                                    : false;
                                  if (!isFile) {
                                    return (
                                      <div
                                        key={index}
                                        className="col-lg-3 col-md-6 col-12 mb-2 px-md-2">
                                        <div className="prog-img">
                                          <img
                                            src={apiUrl + ele}
                                            onError={(e) => {
                                              e.target.src =
                                                "/img/Small-no-img.png";
                                            }}
                                            alt="img"
                                          />


                                        </div>
                                      </div>
                                    );
                                  }
                                })}


                              {new Date().toDateString() ===
                                new Date(res.date).toDateString() ? (
                                <div className="col-12 -col-lg-3 -col-md-6 px-md-2">
                                  <div className="prog-img">
                                    <input
                                      className="border rounded shadow-sm btn-primary img-upload"
                                      style={{ color: "rgba(0, 0, 0, 0)" }}
                                      type="file"
                                      // multiple
                                      id="imgupload"
                                      onChange={(e) => {
                                        OnFileChange(e, res);
                                      }}
                                    />

                                    <div className="col-xl-9 col-md-10 mx-auto col-12 mt-4 p-0">
                                      <div className="d-sm-flex justify-content-center">
                                        <button className="upload_btn prog-upload mr-sm-3" onClick={() => naviagte("/editprofile")}>Back</button>
                                        <button className="upload_btn prog-upload" onClick={(e) => {
                                          uploadImages(e);
                                        }}> Submit
                                        </button>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </div>
                          </div>

                        </div>
                      </>
                    );
                  }
                })}
            </div>


          </div>
        </div>
      </div>

    </>
  );
}

export default ViewProgresUpload;
