import React, { createContext, useMemo, useContext, } from "react";
import { io } from "socket.io-client";
import { apiUrl } from '../environment/environment';

const SocketContext = createContext(null);

export const useSocket = () => {
  const socket = useContext(SocketContext);
  return socket;
};

export const SocketProvider = (props) => {
  const socket = useMemo(() => io(apiUrl), []);
  // const socket = useMemo(() => io(`http://localhost:3155`), []);

  return (
    <SocketContext.Provider value={socket}>
      {props.children}
    </SocketContext.Provider>
  );
};
