/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { apiUrl } from '../../environment/environment';
import swal from 'sweetalert';
import Moment from 'react-moment';
import { Link, useNavigate } from 'react-router-dom';

function UploadProgressPhotos() {
  const naviagte = useNavigate();

  const user = JSON.parse(sessionStorage.getItem('user'))
  const uid = user._id

  const [isLoading, setIsLoading] = useState(false)
  const [filesUploads, setFilesUploads] = useState([]);
  const [prewDocs, setPrewDocs] = useState([]);
  const initState = { date: new Date(), list: [], base64Img: [] };
  const [getAllPhotos, setGetAllPhotos] = useState([]);

  useEffect(() => {
    fetchProgressPhotos()
  }, [])

  const fetchProgressPhotos = () => {
    setIsLoading(true)
    axios
      .get(`${apiUrl}/client/account/getprogressphotos`, {}, {})
      .then(function (response) {
        if (response.data.status === 1) {
          if (response.data.result && response.data.result.length > 0) {
            setTimeout(() => {
              if (response.data.result.length > 0) {
                if (
                  response.data.result.filter(
                    (x) =>
                      new Date(x.date).toLocaleDateString() ===
                      new Date().toLocaleDateString()
                  ).length === 0
                ) {
                  response.data.result.push(initState);
                } else {
                  response.data.result.forEach((ele) => {
                    ele["base64Img"] = ele.list;
                  });
                }
              }
              setGetAllPhotos(response.data.result.reverse());
              setIsLoading(false)
            }, 500);
          } else {
            response.data.result.push(initState);
            setGetAllPhotos(response.data.result.reverse());
            setIsLoading(false)
          }
        } else {
          setIsLoading(false)
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        setIsLoading(false)
        window.alert(error);
      });
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFileError()
    }
    const files = e.target.files;
    const newPhotos = Array.from(files).map((file) => ({
      file,
      preview: window.URL.createObjectURL(file),
    }));
    setFilesUploads(e.target.files);
    const newArr = [];
    newPhotos.map((x) => {
      newArr.push({ preview: x?.preview, name: x?.file?.name });
    });
    setPrewDocs((prevPhotos) => [...prevPhotos, ...newArr]);
  };

  const cancelFile = (e, photo) => {
    e.preventDefault();
    
    const imgGetId = photo?.preview.split("/").pop();
    let imgStoreIndex = -1; // Initialize with an invalid index

    prewDocs.forEach((ele, index) => {
      const imgStoreId = ele?.preview?.split("/").pop();
      if (imgStoreId === imgGetId) {
        imgStoreIndex = index;
      }
    });

    const removeUpdate = Array.from(filesUploads).map((file) => ({
      file,
    }));
    const newArr = [];
    removeUpdate.find((v) => {
      if (photo?.name !== v?.file?.name) {
        newArr.push(v.file);
      }
    });

    setFilesUploads(newArr);

    // return false
    if (imgStoreIndex !== -1) {
      prewDocs.splice(imgStoreIndex, 1);
      setPrewDocs([...prewDocs]);
    }
  };

  const [fileError, setFileError] = useState()
  const submithandle = async (e) => {

    e.preventDefault()
    const formData = new FormData();

    if (filesUploads.length === 0) {
      let msg = "Please select at least one file."
      setFileError(msg)
      return;
    }

    for (let i = 0; i < filesUploads.length; i++) {
      formData.append("file", filesUploads[i]);
      // console.log("file", filesUploads);
    }

    formData.append("id", uid)
    setIsLoading(true)
    await axios.post(`${apiUrl}/client/account/saveprogressphotos`, formData, uid)
      .then((response) => {
        if (response.data.status === 1) {
          setTimeout(() => {
            setGetAllPhotos(response.data.data)
            setFilesUploads([])
            setFileError("")
            setPrewDocs([])
            swal({
              title: "Success!",
              text: response.data.message,
              icon: "success",
              button: true,
            })
            setIsLoading(false)
          }, 500);
        } else {
          setIsLoading(false)
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
  }

  return (<>
    <div className="sub-head-title">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-12">
            <h1 className="main_title">Progress Photos</h1>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div className="container-fluid">
        <div className="col-md-12 col-12">
          {isLoading && <div className="loading">
            <div className="mainloader"></div>
          </div>}
          <div className="trainer-main-box">
            <div className="row">
              <div className="col-12">
                <button class="download-table-xls-button btn btn-primary mb-4" onClick={() => naviagte("/editprofile")}>Back</button>
              </div>
           
            {getAllPhotos.length > 0 &&
              getAllPhotos.map((res, index) => {
                return (
                  <>
                    {new Date().toDateString() ===
                      new Date(res.date).toDateString() &&
                      <div key={index} className="col-12 col-md-6 mb-3">
                        <div className="progress-box">
                          <div className="row" dataId={index}>
                            <div className="col-md-12 col-12 mb-3 px-md-2 pb-3 viewimg-bor">
                              <div className="d-flex">
                                <div className="p-photo">
                                  <img
                                    src={apiUrl + user.profile}
                                    onError={(e) => {
                                      e.target.src = "/img/Small-no-img.png";
                                    }}
                                    alt="Profile"
                                  />
                                </div>
                                <div className="progress-content">
                                  <p>Photos</p>
                                  {/* <span>{new Date(res.date).toDateString()}</span> */}
                                  <span>
                                    <Moment
                                      format="DD MMMM YYYY"
                                      date={res.date}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>

                            {new Date().toDateString() ===
                              new Date(res.date).toDateString() &&
                              <>
                                <div className="col-lg-12 col-md-12 col-12 mb-2">
                                  <div className="prog-img">
                                    <input
                                      type='file'
                                      multiple onError={(e) => {
                                        e.target.src =
                                          "/img/Small-no-img.png";
                                      }}
                                      alt='img'
                                      name='file'
                                      onChange={handleFileChange}
                                      style={{ color: "rgba(0, 0, 0, 0)" }}
                                      className="border rounded shadow-sm btn-primary img-upload"
                                    />
                                    <span className='text-danger'>{fileError}</span>
                                  </div>
                                </div>
                               <div className="col-12">
                                <div className='mt-3 row d-flex w-100'>
                                  {prewDocs.map((photo) => {
                                    return (<>
                                      <div className="col-xl-4 col-md-6 mb-3">
                                        <button type="button" className="img-cancel upload-close" title={`Removed ${photo?.name}`} onClick={(e) => cancelFile(e, photo)}>  <i className="fas fa-times"></i> </button>
                                        <img className="prog-upload-img p-1 border" height={100} width={100} src={photo?.preview} alt='' />
                                      </div>
                                    </>)
                                  })}
                                </div>
                                </div>
                                <div className="col-xl-12 col-md-12 col-12 mt-3 text-center">
                                    <button className="upload_btn prog-upload mx-auto" type="submit" onClick={(e) => submithandle(e)}>Upload</button>
                                </div>
                                <div className="col-12 mt-3 border-top pt-3">
                                  <div className="d-flex row w-100">
                                  {res.list && res.list.length > 0 &&
                                    res.list.slice(0, 3).map((path, index) => (
                                      <div key={index} className="col-xl-4 col-md-6 mb-2">
                                        <div className="prog-img">
                                          <img
                                            src={apiUrl + path}
                                            onError={(e) => {
                                              e.target.src = "/img/Small-no-img.png";
                                            }}
                                            alt="img"
                                          />
                                        </div>
                                      </div>
                                    ))
                                  }
                                  {res.list.length > 3 &&
                                  <div className="col-xl-12 col-md-12 col-12 text-right">
                                    <Link to={'/viewphoto'} className="see-btn">See more</Link>
                                    </div>
                                  }
                                </div>
                                </div>
                              </>
                            }
                          </div>
                        </div>

                      </div>
                    }
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default UploadProgressPhotos