import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { apiUrl } from "../environment/environment";
import ToggleButton from "react-toggle-button";
import Moment from "react-moment";
// import Allnotifications from "../components/Others/Allnotifications";
// import swal from "sweetalert";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
// import Cookies from "js-cookie";

function MainLayout({ filterPanel, setFilterPanel, setSerachValue }) {
  const usertype = sessionStorage.getItem("usertype");
  const navigate = useNavigate();
  const location = useLocation();

  const [loader, setloader] = useState(false);
  const [pathnameUrl, setPathnameUrl] = useState(window.location.pathname);
  const [loginUser, setLoginUser] = useState({});
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [allNotification, setAllNotification] = useState([]);
  const [serachText, setSerachText] = useState("");
  const [notificationOff, setNotificationOff] = useState("");
  const [notiShow, setNotiShow] = useState("");
  const [trainerStatus, setTrainerStatus] = useState({
    ParentOn: loginUser.availablestatus === 0 ? true : false,
  });

  // const [CookiesData, setCookiesData] = useState()

  // console.log("CookiesData",CookiesData?._id);
  // console.log("loginUser",loginUser?._id);

  // if (CookiesData?._id ===loginUser?._id) {
  //   navigate("/trainer?status=1")
  // }

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    // const cookieData=JSON.parse(Cookies.get('user'));
    // setCookiesData(cookieData)
    setLoginUser(user);
    setPathnameUrl(location.pathname);
  }, [pathnameUrl, location]);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const showclick = async (e) => {
    document.querySelector(".page-wrapper").classList.add("toggled");
  };

  const hideclick = async (e) => {
    document.querySelector(".page-wrapper").classList.remove("toggled");
  };

  const callTrainer = async (e, _url) => {
    setSerachText("");
    setSerachValue("");
    if (_url === "/trainer" || _url === "/savedtrainer")
      document.body.classList.add("scrollHide");

    setPathnameUrl(_url);
    if (_url === "/savedtrainer") {
      e.preventDefault();
      setPathnameUrl("/savedtrainer");
      navigate(_url);
    }
  };

  const allNotifications = async () => {
    await axios
      .get(`${apiUrl}/notification/getAll`, {}, {})
      .then(function (response) {
        const sortList = response.data.result.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setAllNotification(sortList);
      })
      .catch(function (err) { });
  };

  useEffect(() => {
    allNotifications();
  }, []);

  const markAllRead = () => {
    axios
      .post(`${apiUrl}/notification/markAllread`)
      .then((response) => {
        if (response.data.status === 1) {
          setNotiShow("show");
          setNotificationOff("");
        }
      })
      .catch((err) => { });
  };

  const changeTrainerStatus = (availablestatus) => {
    axios
      .post(`${apiUrl}/trainer/account/changeVideoStatus`, {
        availablestatus: availablestatus,
      })
      .then((response) => {
        if (response.data.status === 1) {
          // swal({
          //   title: "Success!",
          //   text: response.data.message,
          //   icon: "success",
          //   button: true,
          // });
          sessionStorage.setItem(
            "user",
            JSON.stringify(response.data.userdata)
          );
          sessionStorage.setItem(
            "user",
            JSON.stringify(response.data.userdata)
          );
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const LogOut = async (e) => {
    e.preventDefault();
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to logout ?",
      showCancelButton: true,
      confirmButtonText: "Logout",
    }).then((result) => {
      if (result.isConfirmed) {
        if (usertype === "trainer") {
          const token = sessionStorage.getItem("token");
          if (token) {
            axios.defaults.headers.common["Authorization"] = token;
            setloader(true);
            axios
              .get(`${apiUrl}/trainer/account/logout`, {}, {})
              .then(function (response) {
                if (response.data.status === 1) {
                  setTimeout(() => {
                    sessionStorage.clear();                 
                    localStorage.clear();
                    Cookies.remove('token', { path: '' })
                    Cookies.remove('user', { path: '' })
                    window.location.href = "/";
                    navigate("/",{replace:true})
                    setloader(false);
                  }, 500);
                }
              })
              .catch(function (error) {
                setloader(false);
              });
          }
        } else {
          const token = sessionStorage.getItem("token");
          if (token) {
            axios.defaults.headers.common["Authorization"] = token;
            setloader(true);
            axios
              .get(`${apiUrl}/client/account/logout`, {}, {})
              .then(function (response) {
                if (response.data.status === 1) {
                  setTimeout(() => {
                    sessionStorage.clear();
                    localStorage.clear();
                    Cookies.remove('token', { path: '' })
                    Cookies.remove('user', { path: '' })
                    window.location.href = "/";
                    // navigate("/",{replace:true})
                    setloader(false);
                  }, 500);
                }
              })
              .catch(function (error) {
                setloader(false);
              });
          }
        }
      }
    });
  };

  const detail = allNotification.filter((x) => x.isRead === "0").length;
  useEffect(() => {
    setNotificationOff(detail);
    if (detail === 0) {
      setNotificationOff("");
    }
  }, [detail]);

  // const location = useLocation();
  let currentUrl = location.pathname.split('/');
  currentUrl = currentUrl[1];


  return (
    <>
      {loader && (
        <div id="loader" className="loading">
          <div className="mainloader"></div>
        </div>
      )}
      <div className="page-wrapper chiller-theme toggled">
        <button
          id="show-sidebar"
          className="btn btn-sm btn-dark"
          l
          onClick={(e) => showclick(e)}
        >
          <i className="fas fa-bars"></i>
        </button>
        <nav id="sidebar" className="sidebar-wrapper">
          <div className="sidebar-content">
            <div className="sidebar-header">
              {usertype === "client" ? (
                <>
                  {/* <NavLink to="/trainer?status=1" onClick={(e) => { callTrainer(e, '/trainer'); }} className={({ isActive }) => isActive ? 'active' : ''}><img src="/img/common-health.png" alt="logo" /></NavLink> */}

                  <div
                    // to="/"
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    <img src="/img/common-health.png" alt="logo" />
                  </div>
                </>
              ) : (
                <>
                  {/* <a href="/" className={({ isActive }) => isActive ? 'active' : ''}><img src="/img/common-health.png" alt="logo" /></a> */}
                  <div
                    // to="/"
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    <img src="/img/common-health.png" alt="logo" />
                  </div>
                </>
              )}
            </div>
            <div className="sidebar-menu">
              <ul className="px-2 pt-4">
                {usertype === "client" ? (
                  <>
                    <li className="sidebar-dropdown">
                      <NavLink
                        to="/trainer?status=1"
                        onClick={(e) => {
                          callTrainer(e, "/trainer");
                        }}
                        className={(currentUrl === 'trainer?status=1' ? "active" : "")}
                      >
                        <i className="fas fa-home"></i>
                        <span>Trainers</span>
                      </NavLink>
                    </li>
                    <li className="sidebar-dropdown">
                      <NavLink
                        to="/savedtrainer"
                        onClick={(e) => {
                          document.body.classList.add("scrollHide");
                          callTrainer(e, "/trainer");
                        }}
                        className={(currentUrl === 'savedtrainer' ? "active" : "")}
                      >
                        <i className="far fa-bookmark"></i>
                        <span>Saved Trainers</span>
                      </NavLink>
                    </li>
                    <li className="sidebar-dropdown">
                      <NavLink
                        to="/mysession"
                        onClick={(e) => {
                          document.body.classList.add("scrollHide");
                          setPathnameUrl("/mysession");
                        }}
                        className={(currentUrl === 'mysession' ? "active" : "")}
                      >
                        <i className="far fa-calendar"></i>
                        <span>My Sessions</span>
                      </NavLink>
                    </li>
                    <li className="sidebar-dropdown">
                      <NavLink
                        to="/myWorkoutHistory/1"
                        onClick={(e) => {
                          callTrainer(e, "/myWorkout");
                          setPathnameUrl("/myWorkoutHistory/1");
                        }}
                        className={(currentUrl === 'myWorkoutHistory' ? "active" : "")}
                      >
                        <i className="fas fa-walking"></i>
                        <span>My Workout History</span>
                      </NavLink>
                    </li>
                    {/* <li className="sidebar-dropdown">
                            <NavLink to='/videosessionhistory' onClick={(e) => { callTrainer(e, '/videosessionhistory'); }} className={({ isActive }) => isActive ? 'active' : ''}>
                              <i className="fas fa-wallet"></i>
                              <span>Video Session History</span>
                            </NavLink>
                          </li> */}
                    <li className="sidebar-dropdown">
                      <NavLink
                        to="/purchasesession"
                        onClick={(e) => {
                          callTrainer(e, "/purchasesession");
                        }}
                        className={(currentUrl === 'purchasesession' ? "active" : "")}
                      >
                        <i className="fas fa-wallet"></i>
                        <span>Purchase Sessions</span>
                      </NavLink>
                    </li>

                    <li className="sidebar-dropdown">
                      <NavLink
                        to="/blockedtrainers/1"
                        onClick={(e) => {
                          callTrainer(e, "/blockedtrainers/1");
                        }}
                        className={(currentUrl === 'blockedtrainers' ? "active" : "")}
                      >
                        <i className="fas fa-ban"></i>
                        <span>Blocked Trainers</span>
                      </NavLink>
                    </li>
                    <li className="sidebar-dropdown">
                      <NavLink
                        to="/reporttrainer/1"
                        onClick={(e) => {
                          callTrainer(e, "/reporttrainer/1");
                        }}
                        className={(currentUrl === 'reporttrainer' ? "active" : "")}
                      ><i class="fas fa-file-alt"></i>
                        <span>Report Trainers</span>
                      </NavLink>
                    </li>

                    <li className="sidebar-dropdown">
                      <NavLink
                        to="/cancellationpolicy"
                        onClick={(e) => {
                          callTrainer(e, "/cancellationpolicy");
                        }}
                        className={(currentUrl === 'cancellationpolicy' ? "active" : "")}
                      >
                        <i className="far fa-times-circle"></i>
                        <span>Cancellation Policy</span>
                      </NavLink>
                    </li>
                    <li className="sidebar-dropdown">
                      <NavLink
                        to="/faq"
                        onClick={(e) => {
                          callTrainer(e, "/faq");
                        }}
                        className={(currentUrl === 'faq' ? "active" : "")}
                      >
                        <i className="fas fa-headphones"></i>
                        <span>FAQ and Support</span>
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="sidebar-dropdown">
                      <NavLink
                        to="/schedulerequest"
                        onClick={(e) => {
                          callTrainer(e, "/schedulerequest");
                        }}
                        className={(currentUrl === 'schedulerequest' ? "active" : "")}
                      >
                        <i className="fas fa-calendar"></i>
                        <span>Scheduling Requests</span>
                      </NavLink>
                    </li>
                    <li className="sidebar-dropdown">
                      <NavLink
                        to="/myratings"
                        onClick={(e) => {
                          callTrainer(e, "/myratings");
                        }}
                        className={(currentUrl === 'myratings' ? "active" : "")}
                      >
                        <i className="far fa-heart"></i>
                        <span>My Ratings</span>
                      </NavLink>
                    </li>
                    <li className="sidebar-dropdown">
                      <NavLink
                        to="/paymenthistory/1"
                        onClick={(e) => {
                          callTrainer(e, "/paymenthistory/1");
                        }}
                        className={(currentUrl === 'paymenthistory' ? "active" : "")}
                      >
                        <i className="fas fa-wallet"></i>
                        <span>Payment History</span>
                      </NavLink>
                    </li>
                    <li className="sidebar-dropdown">
                      <NavLink
                        to="/pendingworkout/1"
                        onClick={(e) => {
                          callTrainer(e, "/pendingworkout/1");
                        }}
                        className={(currentUrl === 'pendingworkout' ? "active" : "")}
                      >
                        {/* <i className="fas fa-wallet"></i> */}
                        <i className="fa fa-list"></i>
                        <span>Pending Workout Forms</span>
                      </NavLink>
                    </li>
                    {/* <li className="sidebar-dropdown">
                            <NavLink to='/videosessionhistory' onClick={(e) => { callTrainer(e, '/videosessionhistory'); }} className={({ isActive }) => isActive ? 'active' : ''}>
                              <i className="fas fa-wallet"></i>
                              <span>Video Session History</span>
                            </NavLink>
                          </li> */}
                    <li className="sidebar-dropdown">
                      <NavLink
                        to="/memberblock/1"
                        onClick={(e) => {
                          callTrainer(e, "/memberblock/1");
                        }}
                        className={(currentUrl === 'memberblock' ? "active" : "")}
                      >
                        {/* <i className="fas fa-wallet"></i> */}
                        <i className="fas fa-ban"></i>
                        <span>Blocked Member</span>
                      </NavLink>
                    </li>
                    <li className="sidebar-dropdown">
                      <NavLink
                        to="/memberreport/1"
                        onClick={(e) => {
                          callTrainer(e, "/memberreport/1");
                        }}
                        className={(currentUrl === 'memberreport' ? "active" : "")}
                      >
                        {/* <i className="fas fa-wallet"></i> */}
                        <i class="fas fa-file-alt"></i>
                        <span>Report Member</span>
                      </NavLink>
                    </li>
                    <li className="sidebar-dropdown">
                      <NavLink
                        to="/termsncondition"
                        onClick={(e) => {
                          callTrainer(e, "/termsncondition");
                        }}
                        className={(currentUrl === 'termsncondition' ? "active" : "")}
                      >
                        <i className="far fa-file-alt"></i>
                        <span>Terms & Conditions</span>
                      </NavLink>
                    </li>
                    <li className="sidebar-dropdown">
                      <NavLink
                        to="/privacy"
                        onClick={(e) => {
                          callTrainer(e, "/privacy");
                        }}
                        className={(currentUrl === 'privacy' ? "active" : "")}
                      >
                        <i className="fa fa-lock"></i>
                        <span>Privacy Policy</span>
                      </NavLink>
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div className="sidebar-footer">
              <ul className="px-2">
                <li>
                  <button onClick={(e) => LogOut(e)}>
                    <i className="fas fa-sign-out-alt"></i>Logout
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* main */}
        <main className="page-content pb-3" role="main">
          <header>
            <nav className="navbar navbar-expand-sm navbar-toggleable-sm nav-head">
              <div className="container-fluid">
                <div className="col-md-12 col-12 p-0">
                  <div className="row">
                    <div className="col-lg-5 col-12">
                      <div className="sidebar-brand">
                        <div id="close-sidebar" onClick={(e) => hideclick(e)}>
                          <i className="fas fa-bars"></i>
                        </div>
                      </div>
                      <h4 className="head-title">
                        Hello  {loginUser?.firstname} {loginUser?.lastname}! Let's get started
                      </h4>
                      <button
                        className="custom-toggler navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarsExample09"
                        aria-controls="navbarsExample09"
                        aria-expanded={!isNavCollapsed ? true : false}
                        aria-label="Toggle navigation"
                        onClick={handleNavCollapse}
                      >
                        <i className="fas fa-ellipsis-v"></i>
                      </button>
                    </div>
                    <div className="col-lg-7 col-12 text-right">
                      <div
                        className={`${isNavCollapsed ? "collapse" : ""
                          } navbar-collapse float-right`}
                        id="navbarsExample09"
                      >
                        <ul className="head-icon list-inline mb-0">

                          {/* <li className="list-inline-item">
                                <input className="input-box" type="text" 
                                    placeholder="Search" 
                                    onChange={(e) => handlsearchInputs(e)} 
                                    name="searchinput" value={searchname}
                                />
                              </li> */}
                          {/* {(pathnameUrl == "/trainer?status=1" || pathnameUrl == "/trainer?status=2" || pathnameUrl == "/trainer?status=0" || pathnameUrl == "/trainer" || pathnameUrl === "/savedtrainer") || (usertype === "trainer") ? */}
                          {(pathnameUrl === "/trainer?status=1" ||
                            pathnameUrl === "/trainer?status=2" ||
                            pathnameUrl === "/trainer?status=0" ||
                            pathnameUrl === "/trainer" ||
                            pathnameUrl === "/savedtrainer") &&
                            usertype === "client" ? (
                            <>
                              <li className="list-inline-item">
                                <input
                                  className="input_box"
                                  placeholder="Search"
                                  onChange={(e) => {
                                    setSerachValue(e.target.value);
                                    setSerachText(e.target.value);
                                  }}
                                  value={serachText}
                                />
                                <i
                                  className="fas fa-search search-i"
                                /* onClick={() => {
                                setSerachValue(serachText);                                  
                              }} */
                                ></i>
                              </li>
                            </>
                          ) : (
                            <></>
                          )}
                          {(pathnameUrl === "/trainer?status=1" ||
                            pathnameUrl === "/trainer?status=2" ||
                            pathnameUrl === "/trainer?status=0" ||
                            pathnameUrl === "/trainer" ||
                            pathnameUrl ===
                            "/savedtrainer") /* || pathnameUrl === "/mysession" */ &&
                            usertype === "client" ? (
                            <>
                              <li
                                className="list-inline-item"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setFilterPanel(
                                    pathnameUrl === "/trainer"
                                      ? "openFilter"
                                      : "closeFilter"
                                  );
                                }}
                              >
                                <NavLink
                                  to={
                                    pathnameUrl === "/savedtrainer"
                                      ? "/trainer?status=1"
                                      : "/savedtrainer"
                                  }
                                  onClick={(e) => {
                                    document.body.classList.add("scrollHide");
                                    setPathnameUrl(
                                      pathnameUrl === "/savedtrainer"
                                        ? "/trainer"
                                        : "/savedtrainer"
                                    );
                                  }}
                                  className={({ isActive }) =>
                                    isActive ? "active" : ""
                                  }
                                  title="Saved"
                                >
                                  <i
                                    className={`${pathnameUrl === "/savedtrainer"
                                      ? "fa"
                                      : "far"
                                      } fa-bookmark`}
                                  ></i>
                                </NavLink>
                              </li>
                              {(pathnameUrl === "/trainer?status=1" ||
                                pathnameUrl === "/trainer?status=2" ||
                                pathnameUrl === "/trainer?status=0" ||
                                pathnameUrl === "/trainer") &&
                                usertype === "client" ? (
                                <>
                                  <li className="list-inline-item">
                                    {filterPanel === "openFilter" ? (
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setFilterPanel("closeFilter");
                                        }}
                                        title="Filter"
                                      >
                                        <i className="fas fa-sliders-h"></i>
                                      </button>
                                    ) : (
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setFilterPanel("openFilter");
                                        }}
                                        title="Filter"
                                      >
                                        <i className="fas fa-sliders-h"></i>
                                      </button>
                                    )}
                                  </li>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          <li className="list-inline-item dropdown">
                            <button
                              onClick={() => {
                                markAllRead();
                              }}
                              className="dropdown-toggle"
                              data-toggle="dropdown"
                              title="Notification"
                            >
                              <i className="far fa-bell"></i>
                              {notificationOff === "" ? (
                                <div className="qtynew">{notificationOff}</div>
                              ) : (
                                <div className="qty">{notificationOff}</div>
                              )}
                            </button>
                            <ul className={`dropdown-menu ${notiShow}`}>
                              <li>
                                <ul className="list-inline noti_submenu">
                                  {allNotification.length > 0 ? (
                                    allNotification.map((elem, index) => {
                                      return (
                                        <>
                                          <li key={index}>
                                            <NavLink
                                              to="/allnotification"
                                              className="p-0 text-decoration-none"
                                            >
                                              <button
                                                onClick={(e) => {
                                                        /* e.preventDefault(); */ /* markRead(elem._id); */ setNotiShow(
                                                  "show"
                                                );
                                                }}
                                                className="dropdown-item my-1"
                                                style={
                                                  elem.isRead === "0"
                                                    ? {
                                                      background: "#e8f8fa",
                                                      borderRadius: "6px",
                                                      fontWeight: "bold",
                                                    }
                                                    : {}
                                                }
                                              >
                                                <strong>
                                                  <div className="text-right">
                                                    <span>
                                                      <Moment
                                                        format="hh:mm A, DD MMM"
                                                        date={elem.date}
                                                      />
                                                    </span>
                                                  </div>
                                                  <div className="d-flex notification_i">
                                                    <i className="far fa-check-circle green-text"></i>
                                                    <div className="noti_content">
                                                      <h5>
                                                        {elem.isRead === "0" ? (
                                                          <strong /* style={{textTransform: "capitalize"}} */
                                                          >
                                                            {elem.title}
                                                          </strong>
                                                        ) : (
                                                          elem.title
                                                        )}
                                                      </h5>
                                                      <p className="mb-2">
                                                        {elem.message}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </strong>
                                              </button>
                                            </NavLink>
                                          </li>
                                        </>
                                      );
                                    })
                                  ) : (
                                    <>
                                      <div className="no-notification">
                                        <i className="fas fa-bell"></i>
                                        <h4>No notifications</h4>
                                      </div>
                                    </>
                                  )}
                                </ul>
                              </li>
                              {allNotification.length > 3 && (<>
                                <hr />
                                <Link
                                  className="text-success"
                                  to="/allnotification"
                                >
                                  Show More
                                </Link>
                              </>)}
                            </ul>
                          </li>

                          {usertype === "trainer" ? (
                            <>
                              {/* <li className="list-inline-item" title='Change status'>
                                      
                                      <ToggleButton
                                        value={trainerStatus.ParentOn}
                                        onClick={(event) => { event.preventDefault(); changeTrainerStatus(!trainerStatus.ParentOn) }}
                                        onToggle={value => {
                                          setTrainerStatus({ ...trainerStatus, ParentOn: !value })
                                        }}
                                      />
                                    </li> */}
                              <li
                                className="list-inline-item t-btn"
                                title="Change status"
                              >
                                {/* <span>Change Status</span> */}
                                <label className="availabel_btn">
                                  Available / Offline
                                </label>
                                <div className="toggle_btn">
                                  <ToggleButton
                                    value={trainerStatus.ParentOn}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      changeTrainerStatus(
                                        trainerStatus.ParentOn
                                      );
                                    }}
                                    onToggle={(value) => {
                                      setTrainerStatus({
                                        ...trainerStatus,
                                        ParentOn: !value,
                                      });
                                    }}
                                  />
                                </div>
                              </li>
                            </>
                          ) : (
                            ""
                          )}
                          <li className="list-inline-item" title="My profile">
                            <NavLink
                              to="/myprofile"
                              className={({ isActive }) =>
                                isActive
                                  ? "active d-block text-decoration-none"
                                  : "d-block text-decoration-none"
                              }
                            >
                              <img
                                className="user-img"
                                src={`${apiUrl}${loginUser?.profile}`}
                                onError={(e) => {
                                  e.target.src = "/img/Small-no-img.png";
                                }}
                                alt=""
                                style={{ objectFit: "cover" }}
                              />

                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </header>

          <Outlet />
        </main>
      </div>
    </>
  );
}

export default MainLayout;
