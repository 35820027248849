import React, { useEffect, useState } from "react";
import { apiUrl } from "../environment/environment";
import axios from "axios";
import Slider from "react-slick";
import { Link } from "react-router-dom";

function EliteTrainer() {

  // const settings = {
  //   infinite: false,
  //   centerMode: true,
  //   centerPadding: '10px',
  //   speed: 500,
  //  // slidesToShow: 4,
  //   slidesToScroll: 1,
  //   variableWidth: true,
  //   arrows: true,
  //   dots: false,
  // };


  var settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplay:true,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    arrows: true,
  }

  const [list, setList] = useState([]);
  // const [currentPage, setCurrentPage] = useState(6);

  const getEliteTrainerList = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/trainer/trainer/getelite`
      );
      setList(response.data?.result);
    } catch (error) {
      console.log("error", error);
    }
  };
  const isLogin = sessionStorage.usertype === "client";
  useEffect(() => {
    getEliteTrainerList();
  }, []);
  // const nextPage = async () => {
  //   setCurrentPage(currentPage + 1);

  //   try {
  //     const response = await axios.get(
  //       `${apiUrl}/trainer/trainer/getelite?page=${currentPage}&limit=${1}`
  //     );
  //     const data = response.data?.result;
  //     setList((previous) => [...previous, ...data]);
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  //

  return (


    <section className="slider-block">
      <Slider {...settings}>
        {/* {renderSlides} */}
        {list.map((trainer) => (
          <div className="team-box">
            <div className="team-img-box">
              <Link to={isLogin ? `/trainerinformation?Id=${trainer?._id}` : `/client/login`} className="team-img">
                {trainer.profile ? (
                  <img src={`${apiUrl}${trainer?.profile}`} alt="img" />
                ) : (
                  <img src="/img/Small-no-img.png" alt="img" />
                )}
              </Link>
            </div>
            <div className="team-content">
              <a href="/" tabindex="-1">
                <h3 className="h3-title">{trainer?.firstname}</h3>
              </a>
              <span>{trainer?.trainingstyle}</span>
              <div className="team-social">
                {trainer?.socialmedia.map((social) => (
                  <ul className="list-inline">
                    <li>
                      <a href={social?.url} target="_blank" rel="noreferrer" tabindex="-1">
                        <i className={social?.icon}></i>
                      </a>
                    </li>
                    {/* <li>
                      <a href="/" tabindex="-1">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/" tabindex="-1">
                        <i className="fab fa-twitter"></i>
                      </a>
                  </li>  */}
                  </ul>
                ))}
              </div>
            </div>
          </div>
        ))}

      </Slider>
    </section>

  );
}

export default EliteTrainer;
