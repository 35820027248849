import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import axios from "axios";
import { apiUrl } from "./environment/environment";
import{Link, useNavigate} from 'react-router-dom'
import Cookies from "js-cookie";
// import styles from "./public/css/main.css";

const LandingPage = () => {
  const [isLogin, setIsLogin] = useState(false);
  const usertype = sessionStorage.getItem("usertype");
  const token = sessionStorage.getItem("token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
    axios
      .get(`${apiUrl}/account/verifytoken`, {}, {})
      .then(function (response) {
        if (response.data.status === 1) {
          if (usertype === "client") setIsLogin(true);
          else if (usertype === "trainer") setIsLogin(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // socket io
  const redirect =
  sessionStorage.usertype === "client"
    ? "/trainer?status=1"
    : "/client/login"

    // const navigate=useNavigate()
    // const cookieData=Cookies.get('token');
    // console.log("cookieData",cookieData);

    // useEffect(() => {
    //   veryfyFunctions()
    // }, [])
    

    // const veryfyFunctions = async () =>
    // await axios.post(`${apiUrl}/client/account/restore-login`, cookieData).then(() => {
    //   navigate("/trainer?status=1");
    // })
    //   .catch(() => {
    //     Cookies.remove("token");
    //     navigate("/");
    //   }); 

    const initialValues = {
      email: "",
      password: "",
      rememberMe: false
    };
    const [user, setUser] = useState(initialValues)
    console.log("alredy login",user);
    useEffect(() => {
      const storedEmail=localStorage.getItem("email")
      const storedPassword=localStorage.getItem("password")
      const storedRememberMe=localStorage.getItem("rememberMe")
      if (storedRememberMe) {
        setUser({email:storedEmail,password:storedPassword,rememberMe:storedRememberMe})
      }
    }, [])
    
   
  return (
    <>
      <div className="">
        <Header isLogin={isLogin} />
        <section className="main-banner">
          <div className="banner-overlay-bg animate-this">
            <img
              src="/img/banner-overlay.png"
              className="w-100"
              alt="Overlay"
            />
          </div>
          <div className="banner-blur-bg">
            <img src="/img/blur-1.png" alt="Blur" />
          </div>
          <div className="Waitlist-section">
            <div
              className="container-fluid" /* data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine" */
            >
              <div className="col-12">
                <div className="row align-item-center">
                  <div className="col-md-7 col-12">
                    <div className="image-title-wrapper">
                      <p className="sm-title">Common Helath</p>
                      <h4 className="img-title">
                        World wide online trainers available
                      </h4>
                      <p className="wrap-p">
                        With online coaching, you also get personal support from
                        your coaches throughout each month. The level of
                        communication ranges from e-mail and in-app text
                        messaging for our most basic plan, to unlimited text and
                        video messaging.
                      </p>
                      <Link to={redirect} className="wait-listbtn">
                        Join the Workout
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-5 col-12">
                    <div className="wait-mob-img">
                      {/* <img className="app-img" src="/img/gym-1.png" alt="w-img" /> */}
                      <img
                        className="mob-img"
                        src="/img/mobile-live-training-b.png"
                        alt="app"
                      />

                      <div className="banner-circle-first">
                        <img src="/img/banner-circle-one.png" alt="Circle" />
                      </div>
                      <div className="banner-circle-second">
                        <img src="/img/banner-circle-two.png" alt="Circle" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="Index-page-content">
        <div
          className="container-fulid" /* data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine" */
        >
          <div className="col-md-12">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="personal-training-box">
                  <img
                    className="p-traning"
                    src="/img/gymtrainer.png"
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="trainer-content">
                  <h1>On-Demand Online Training</h1>
                  <p>
                    We provide personalized training, coach clients through a
                    workout program, and help them set realistic fitness goals.
                  </p>
                  <br />
                  <p>
                    We don't give clients prescribed meal plans for the simple
                    reason that they don't work. A diet plan is not empowering;
                    it takes you away from your own decision-making process -
                    which you need for long-term success. Not to mention,
                    fitness coaches are not within their scope of practice to
                    provide meal plans, even though many do so anyway! Only
                    registered dieticians are legally qualified to create
                    specific meal plans for their clients.{" "}
                  </p>
                  <br />
                  <p>
                    Instead of restrictive meal plans, we empower you to take
                    charge of your own nutrition in a way that works for you -
                    and get amazing results in the process.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="choose-commonhealth">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className="choose-content" /* data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine" */
              >
                <h3>Choose Common Health</h3>
                <h2>Our Experience With Client</h2>
              </div>
            </div>
            <div
              className="col-lg-6 col-12" /* data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine" */
            >
              <div className="choose-block">
                <div className="ch-content">
                  <h2>
                    <span className="bottom-bor">Flexibility</span>
                  </h2>
                  <p>
                    Online fitness coaching offers flexibility that works with
                    your schedule. If you work late into the night, face-to-face
                    personal trainers are limited to the hours of the gym.
                    Online fitness coaches are available to you at whatever time
                    you need.
                  </p>
                </div>
                <div className="ch-content">
                  <h2>
                    <span className="bottom-bor">Selection of Trainers</span>
                  </h2>
                  <p>
                    Online personal training provides you with a wide selection
                    of trainers. Each trainer will have a different area of
                    expertise, making it simple to find one that suits you.
                    You’ll also be able to verify their certifications and
                    credentials before signing up for a consultation.
                  </p>
                </div>
                <div className="ch-content">
                  <h2>
                    <span className="bottom-bor">Access to Trainers</span>
                  </h2>
                  <p>
                    You don't just get a wide selection of remote personal
                    trainers; you also have complete access to him or her. Some
                    online personal trainers offer 24-hour support!
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-12" /* data-aos="fade-up" data-aos-duration="1500" data-aos-easing="ease-in-sine" */
            >
              <div className="choose-img">
                <img src="/img/exp-client.png" alt="img" />
                <div className="about-img-bg-circle-two"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-training-block">
        <div
          className="container-fluid" /* data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine" */
        >
          <div className="row">
            <div className="col-lg-5 col-12">
              <div className="dapp-img">
                <img className="mob-img" src="/img/mobile-app.png" alt="app" />
              </div>
            </div>
            <div className="col-lg-7 col-12">
              <h2 className="p-training-text">
                Common Health an Android/iOS app that provides online fitness
                coaching with a real, certified personal trainer. Common Health
                is ideal for anyone who wants to lose weight or build muscle but
                doesn't have the budget or the schedule to meet with an
                in-person trainer.
              </h2>
              <div className="appstore d-sm-flex">
                <a className="custom_btn mr-sm-3" href="/">
                  <i className="fab fa-google-play"></i>
                  <p>
                    <span>Available on</span>
                    <br />
                    Google Play
                  </p>
                </a>
                <a className="custom_btn" href="/">
                  <i className="fab fa-apple"></i>
                  <p>
                    <span>Download on</span>
                    <br />
                    App Store
                  </p>
                </a>
                {/* <a className="mr-xl-5 mr-md-4" href="/"><img src="/img/App+Store+Badge.png" alt="playstore" /></a>
                                <a href="/"><img src="/img/google-play-badge.png" alt="playstore" /></a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our-mission">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className="our-mission-content" /* data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine" */
              >
                <h3>OUR MISSION</h3>
                <h2>
                  We are providing expert trainers with their schedule and
                  fitness experience history.
                </h2>
              </div>
              <div className="mission-block">
                <ul className="mission-list">
                  <li>
                    <p>
                      We are providing expert trainers with their schedule and
                      fitness experience history.
                    </p>
                  </li>
                  <li>
                    <p>Our system is 24/7 session providing.</p>
                  </li>
                  <li>
                    <p>
                      You can join the session online from anywhere as per your
                      flexibility.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="interested-tainer-block">
        <div
          className="container-fluid" /* data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine" */
        >
          <div className="col-12">
            <div className="interested-tainer-content text-center">
              <h3>Are you a Proficient Trainer?</h3>
              <h2>Are you interested to work with us?</h2>
              <Link to="/trainer/login" className="apply-btn">
               Register Now
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section
        className="sqs-layout" /* data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine" */
      >
        <div className="col-md-12">
          <div className="row">
            <div className="col-md">
              <img className="sqs-img" src="/img/workout1.jpg" alt="img" />
            </div>
            <div className="col-md">
              <img className="sqs-img" src="/img/workout10.jpg" alt="img" />
            </div>
            <div className="col-md">
              <img className="sqs-img" src="/img/workout4.jpg" alt="img" />
            </div>
            <div className="col-md">
              <img className="sqs-img" src="/img/workout5.jpg" alt="img" />
            </div>
            <div className="col-md">
              <img className="sqs-img" src="/img/workout7.jpg" alt="img" />
            </div>
            <div className="col-md">
              <img className="sqs-img" src="/img/workout8.jpg" alt="img" />
            </div>
          </div>
        </div>
      </section>
      {/* <VideoCall /> */}
      <Footer />
    </>
  );
};

export default LandingPage;
