import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { apiUrl } from "../../environment/environment";
// import { verifytokenCall } from "../Others/Utils.js";
import Plaid from "../Others/Plaid";

function SessionDetails() {
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let sesId = query.get("id");

  const [basicMovementsArr, setBasicMovementsArr] = useState([]);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [sessionId, setSessionId] = useState();
  const [sessionUser, setSessionUser] = useState();
  const [weightList, setWeight] = useState([]);
  const [restDurationList, setRestDuration] = useState([]);
  const [setsList, setSets] = useState([]);
  const [repsList, setReps] = useState([]);
  const [trainerData, setTrainerData] = useState();
  const [isLoader, setIsLoader] = useState(false);
  
  const [workout, setWorkout] = useState({
    id: sesId,
    fitnessGoals: "",
    desiredOne: "",
    desiredTwo: "",
    basicMovements: [],
    additionalNotes: "",
    format: "",
    createdate: "",
  });

  useEffect(() => {
    setTrainerData(JSON.parse(sessionStorage.getItem("user")));
    setSessionId(sesId);
    getUserDetails(sesId);
    GetMasterList();
  }, [sesId]);

  async function getUserDetails(sesId) {
    setIsLoader(true);
    await axios
      .post(`${apiUrl}/client/session/clientdetails`, { id: sesId })
      .then(function (response) {
        if (response.data.status === 1) {
          if (response?.data?.result) {
            setTimeout(() => {
              setSessionUser(response?.data?.result);
              setIsLoader(false);
            }, 500);
          }
        } else {
          setIsLoader(false);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        setIsLoader(false);
        swal({
          title: "Error!",
          text: error,
          icon: "error",
          button: true,
        });
      });
  }

  let newDesireOne;

  const [desiredOneArr, setDesiredOneArr] = useState([]);

  const changeDesiredOne = (bool, value) => {
    if (bool === true) {
      desiredOneArr.push(value);
      setDesiredOneArr([...new Set(desiredOneArr)]);
      handleChange("desiredOne", desiredOneArr);
    } else {
      // setDesiredOneArr([...new Set(desiredOneArr.filter(item => item !== value))]);
      newDesireOne = desiredOneArr.filter((item) => item !== value);

      setDesiredOneArr([...new Set(newDesireOne)]);
      handleChange("desiredOne", newDesireOne);
    }
  };

  async function GetMasterList() {
    setIsLoader(true);
    await axios
      .get(`${apiUrl}/admin/getmovementlist`)
      .then(function (response) {
        if (response.data.status === 1) {
          setTimeout(() => {
            if (response?.data?.result) {
              setWeight(response?.data?.result.filter((x) => x?.name === "Weight"));
              setRestDuration(response?.data?.result.filter((x) => x?.name === "Rest Duration"));
              setSets(response?.data?.result.filter((x) => x?.name === "Sets"));
              setReps(response?.data?.result.filter((x) => x?.name === "Reps"));
        setIsLoader(false);
            }
          }, 500);
        
        } else {
        setIsLoader(false);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        setIsLoader(false);
        swal({
          title: "Error!",
          text: error,
          icon: "error",
          button: true,
        });
      });
  }
  const [desiredTwoArr, setDesiredTwoArr] = useState([]);
  let newDesireTwo;

  const changeDesiredTwo = (bool, value) => {
    if (bool === true) {
      desiredTwoArr.push(value);
      setDesiredTwoArr([...new Set(desiredTwoArr)]);
      handleChange("desiredTwo", desiredTwoArr);
    } else {
      // setDesiredTwoArr([...new Set(desiredTwoArr.filter(item => item !== value))]);
      newDesireTwo = desiredTwoArr.filter((item) => item !== value);
      setDesiredTwoArr([...new Set(newDesireTwo)]);
      handleChange("desiredTwo", newDesireTwo);
    }
    // handleChange("desiredTwo", desiredTwoArr);
  };

  const changebBasicMovements = (bool, value) => {
    let basicMovements;
    if (bool === true) {
      basicMovementsArr.push({
        movementName: value,
        specifyMovement: "",
        weight: "",
        restDuration: "",
        sets: "",
        reps: "",
      });
      setBasicMovementsArr([...new Set(basicMovementsArr)]);
      handleChange("basicMovements", basicMovementsArr);
    } else {
      // setBasicMovementsArr([...new Set(basicMovementsArr.filter(item => item.movementName !== value))]);
      basicMovements = basicMovementsArr.filter(
        (item) => item.movementName !== value
      );
      setBasicMovementsArr([...new Set(basicMovements)]);
      handleChange("basicMovements", basicMovements);
    }
  };

  const handleDyanamicVal = (name, type, val) => {
    let obj = workout.basicMovements.filter((x) => x.movementName === name)[0];
    obj[type] = val;
    handleChange("basicMovements", workout.basicMovements);
  };

  const trainerPayment = async () => {
    const paymentData = {
      paymentStatus: 1,
      method: "update",
      sessionid: sessionId,
    };

    await axios
      .post(`${apiUrl}/payment/trainerpaymenthistory`, paymentData)
      .then((response) => {})
      .catch((err) => {});
  };

  const handleChange = (objName, val) => {
    if (val){
      setWorkout((prevState) => ({ ...prevState, [objName]: val }));
      setErrors((prevErrors) => ({ ...prevErrors, [objName]: "" })); }
  };

  const fitnessGoalsRef=useRef()
  const basicMovements=useRef()
  const format=useRef()

  const onSubmit = (e) => {
    e.preventDefault();

    let isValid = true;
    // $('#plaidbutton > button').click();
    var errormsg = {};
    workout.id = sessionId;
    if (workout.fitnessGoals === "") {
      errormsg.fitnessGoals = "Please select fitness goals!";
      isValid = false;
    }
    if (workout.basicMovements.length === 0) {
      errormsg.basicMovements = "Please select atleast one movement!";
      isValid = false;
    }

    // if (workout.additionalNotes === "") {
    //   errormsg.additionalNotes = "Please write additional notes!";
    //   isValid = false;
    // }
    if (workout.format === "") {
      errormsg.format = "Please select format!";
      isValid = false;
    }
    if (Object.keys(errormsg)) {
      if (errormsg.fitnessGoals) {
        fitnessGoalsRef.current.focus()
      }else if (errormsg.basicMovements) {
        basicMovements.current.focus()
      }else if (errormsg.format) {
        format.current.focus()
      }
    }
    workout.createdate = new Date();
    setErrors(errormsg);
    // isValid = false;
    if (isValid) {
      setIsLoader(true);
      axios
        .post(`${apiUrl}/trainer/session/workout`, workout)
        .then((response) => {
          if (response.data.status === 1) {
            setTimeout(() => {
              trainerPayment();
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
                button: true,
              });
              navigate("/schedulerequest");
              setIsLoader(false);
            }, 500);
           
            // setTimeout(() => {
            // }, 1500);
          } else {
            setIsLoader(false);
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
            //window.alert(response.data.message);
          }
        })
        .catch(function (error) {
          setIsLoader(false);
        });
    }
  };

  return (
    <>
      {/* {isLoader && (
        <div className="loading">
          <div className="mainloader"></div>
        </div>
      )} */}
      <div className="sub-head-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-12">
              <h1 className="main_title">Workout From</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-md-12 col-12 p-0">
        {isLoader && 
         <div className="loading">
          <div className="mainloader"></div>
        </div>}
          <div className="trainer-main-box">
            <div className="fixedblock">
              {!trainerData?.bankaccount ? (
                <h5 className="text-danger">
                  <Plaid />
                </h5>
              ) : (
                ""
              )}
              {/* <div onClick={(e) => { addBank(e) }} className="loginbtn w-25 d-block float-right">Add Bank</div> */}
              <div className="row">
                <div className="col-md-4">
                  <div className="grayarea d-flex">
                    <h6>Name : </h6>
                    <p>{sessionUser?.firstname || "Guest"} {sessionUser?.lastname}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="grayarea d-flex">
                    <h6>Age : </h6>
                    <p>{sessionUser?.age || "N/A"}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="grayarea d-flex">
                    <h6>Injuries : </h6>
                    <p>
                      {sessionUser?.injuriesorhelthissues?.substr(0, 60) ||
                        "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row scrollblock sessionbox">
              <div className="col-md-6">
              <div className="position-relative mb-4" >
                  <label className="font-weight-bold mb-3">Fitness Goals</label>
                  <select
                  style={errors.fitnessGoals ? { border: '1px solid red' }:{}}
                    className="input-box cursor-pointer"
                    value={workout.fitnessGoals}
                    ref={fitnessGoalsRef}
                    onChange={(e) => {
                      handleChange("fitnessGoals", e.target.value);
                    }}
                    placeholder="Select fitness goals"
                  >
                    <option value="">Select fitness goals</option>
                    <option value="Lean">Lean</option>
                    <option value="Fitness">Fitness</option>
                  </select>
                  <i className="fas fa-chevron-down arrow_i"></i>
                  <div className="text-danger">{errors.fitnessGoals}</div>
                </div>
                <label className="font-weight-bold mb-3">
                  What did you focus on today to achieve. Your Client desired
                  fitness goals?
                </label>
                <div className="checkboxblock filter-box">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="goal1"
                          onChange={(e) => {
                            changeDesiredOne(
                              e.currentTarget.checked,
                              "Mobility"
                            );
                          }}
                        />
                        <label className="custom-control-label" htmlFor="goal1">
                          Mobility{" "}
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="goal2"
                          name="goal2"
                          onChange={(e) => {
                            changeDesiredOne(
                              e.currentTarget.checked,
                              "Strength"
                            );
                          }}
                        />
                        <label className="custom-control-label" htmlFor="goal2">
                          Strength
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="goal3"
                          name="goal3"
                          onChange={(e) => {
                            changeDesiredOne(
                              e.currentTarget.checked,
                              "Endurance"
                            );
                          }}
                        />
                        <label className="custom-control-label" htmlFor="goal3">
                          Endurance
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="goal4"
                          name="goal4"
                          onChange={(e) => {
                            changeDesiredOne(
                              e.currentTarget.checked,
                              "Pain Management"
                            );
                          }}
                        />
                        <label className="custom-control-label" htmlFor="goal4">
                          Pain Management{" "}
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="goal5"
                          name="goal5"
                          onChange={(e) => {
                            changeDesiredOne(
                              e.currentTarget.checked,
                              "Hypertrophy"
                            );
                          }}
                        />
                        <label className="custom-control-label" htmlFor="goal5">
                          Hypertrophy
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="goal6"
                          name="goal6"
                          onChange={(e) => {
                            changeDesiredOne(
                              e.currentTarget.checked,
                              "Conditioning"
                            );
                          }}
                        />
                        <label className="custom-control-label" htmlFor="goal6">
                          Conditioning
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <label className="font-weight-bold my-3">
                  What did you focous on today to achieve your client's desired
                  fitness goals?
                </label>
                <div className="checkboxblock filter-box">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="goal7"
                          ref={basicMovements}
                          name="goal7"
                          onChange={(e) => {
                            changeDesiredTwo(
                              e.currentTarget.checked,
                              "Lateral"
                            );
                          }}
                        />
                        <label className="custom-control-label" htmlFor="goal7">
                          Lateral
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="goal8"
                          name="goa8"
                          onChange={(e) => {
                            changeDesiredTwo(
                              e.currentTarget.checked,
                              "Saggital"
                            );
                          }}
                        />
                        <label className="custom-control-label" htmlFor="goal8">
                          Saggital
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="goal9"
                          name="goal9"
                          onChange={(e) => {
                            changeDesiredTwo(
                              e.currentTarget.checked,
                              "Transversv"
                            );
                          }}
                        />
                        <label className="custom-control-label" htmlFor="goal9">
                          Transversv{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <label className="font-weight-bold my-3">
                  Which of the 7 basic Movements did you work on today? (check
                  all the apply)
                </label>
                <div className="checkboxblock filter-box">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="goal10"
                          name="goal10"
                          ref={basicMovements}
                          onChange={(e) => {
                            changebBasicMovements(
                              e.currentTarget.checked,
                              "Vertical Push"
                            );
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="goal10"
                        >
                          Vertical Push
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="goal11"
                          name="goal11"
                          onChange={(e) => {
                            changebBasicMovements(
                              e.currentTarget.checked,
                              "Horizontal Push"
                            );
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="goal11"
                        >
                          Horizontal Push
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="goal12"
                          name="goal12"
                          onChange={(e) => {
                            changebBasicMovements(
                              e.currentTarget.checked,
                              "Horizontal Pull"
                            );
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="goal12"
                        >
                          Horizontal Pull
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="goal13"
                          name="goal13"
                          onChange={(e) => {
                            changebBasicMovements(
                              e.currentTarget.checked,
                              "Core"
                            );
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="goal13"
                        >
                          Core
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="goal14"
                          name="goal14"
                          onChange={(e) => {
                            changebBasicMovements(
                              e.currentTarget.checked,
                              "Vertical Pull"
                            );
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="goal14"
                        >
                          Vertical Pull
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="goal15"
                          name="goal15"
                          onChange={(e) => {
                            changebBasicMovements(
                              e.currentTarget.checked,
                              "Hip Dominant"
                            );
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="goal15"
                        >
                          Hip Dominant
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="goal16"
                          name="goal16"
                          onChange={(e) => {
                            changebBasicMovements(
                              e.currentTarget.checked,
                              "Knee Dominant"
                            );
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="goal16"
                        >
                          Knee Dominant
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="text-danger">{errors.basicMovements}</div>
                </div>
                <div className="mb-3">
                  <label className="font-weight-bold my-3">
                    Additional Notes
                  </label>
                  <textarea
                    className="w-100 Sessionrej"
                    placeholder="Write Your Notes"
                    onChange={(e) => {
                      handleChange("additionalNotes", e.target.value);
                    }}
                  ></textarea>
                  <div className="text-danger">{errors.additionalNotes}</div>
                </div>
                <div className="position-relative">
                  <label className="font-weight-bold mb-3">Format</label>
                  <select
                    style={errors.format ? { border: '1px solid red' }:{}}
                    className="input-box cursor-pointer"
                    onChange={(e) => {
                      handleChange("format", e.target.value);
                    }}
                    placeholder="Select format"
                  >
                    <option value="">Select format</option>
                    <option value="Circuits">Circuits</option>
                    <option value="Format">Format </option>
                  </select>
                  <i className="fas fa-chevron-down arrow_i"></i>
                  <div className="text-danger">{errors.format}</div>
                </div>
              </div>
              <div className="col-md-6 position-relative">
                <div className="subscrollbox">
                  <div className="dashbgimg">
                    <img src="/img/gym-img.png" alt="Logo" />
                  </div>
                  {workout.basicMovements.length > 0 ? (
                    <>
                      {workout.basicMovements.map((ele, index) => {
                        return (
                          <>
                            <div key={index} className="col-md-12 mb-3">
                              <h6 className="font-weight-bold my-3">
                                <strong>{ele.movementName}</strong>
                              </h6>
                              <label className="">
                                Specify Movement
                              </label>
                              <input
                                className="input-box"
                                placeholder="pushup"
                                value={ele.specifyMovement}
                                onChange={(e) => {
                                  handleDyanamicVal(
                                    ele.movementName,
                                    "specifyMovement",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <div className="d-flex">
                              <div className="col-md-6 mb-3">
                                <label>Weight</label>
                                <select
                                  className="input-box cursor-pointer"
                                  placeholder="Select weight"
                                  onChange={(e) => {
                                    handleDyanamicVal(
                                      ele.movementName,
                                      "weight",
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option value="">Select weight</option>
                                  {weightList.length > 0 &&
                                    weightList.map((w) => {
                                      return (
                                        <option value={w.value}>
                                          {w.value}
                                        </option>
                                      );
                                    })}
                                </select>
                                <i className="fas fa-chevron-down arrow_i"></i>
                              </div>
                              <div className="col-md-6 mb-3 position-relative">
                                <label>Rest Duration</label>
                                <select
                                  className="input-box cursor-pointer"
                                  placeholder="Select rest duration"
                                  onChange={(e) => {
                                    handleDyanamicVal(
                                      ele.movementName,
                                      "restDuration",
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option value="">
                                    Select rest duration{" "}
                                  </option>
                                  {restDurationList.length > 0 &&
                                    restDurationList.map((w) => {
                                      return (
                                        <option value={w.value}>
                                          {w.value}
                                        </option>
                                      );
                                    })}
                                </select>
                                <i className="fas fa-chevron-down arrow_i"></i>
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="col-md-6 mb-3 position-relative">
                                <label>Sets</label>
                                <select
                                  className="input-box cursor-pointer"
                                  placeholder="Select sets"
                                  onChange={(e) => {
                                    handleDyanamicVal(
                                      ele.movementName,
                                      "sets",
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option value="">Select sets</option>
                                  {setsList.length > 0 &&
                                    setsList.map((w) => {
                                      return (
                                        <option value={w.value}>
                                          {w.value}
                                        </option>
                                      );
                                    })}
                                </select>
                                <i className="fas fa-chevron-down arrow_i"></i>
                              </div>
                              <div className="col-md-6 mb-3 position-relative">
                                <label>Reps</label>
                                <select
                                  className="input-box cursor-pointer"
                                  placeholder="Select reps"
                                  onChange={(e) => {
                                    handleDyanamicVal(
                                      ele.movementName,
                                      "reps",
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option value="">Select reps</option>
                                  {repsList.length > 0 &&
                                    repsList?.map((w) => {
                                      return (
                                        <option value={w.value}>
                                          {w.value}
                                        </option>
                                      );
                                    })}
                                </select>
                                <i className="fas fa-chevron-down arrow_i"></i>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="col-12">
                        <div className="no-record-box">
                          <img src="/img/no-record.png" alt="no-img" />
                          <h4 className="font-weight-bold">
                            No any one apply 7 basic movements!
                          </h4>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                {/* {!trainerData?.bankaccount ? <h5 className='text-danger'><Plaid /></h5> : ''} */}
                {!trainerData?.bankaccount ? (
                  // <div onClick={(e) => { alert('Bank doesn\'t added.') }} className="loginbtn w-25 mx-auto d-block mt-5 disabled">Submit</div>
                  <a
                    className="loginbtn w-25 mx-auto d-block mt-5"
                    data-toggle="modal"
                    data-dismiss="modal"
                    href="#add_bank_modal"
                  >
                    Submit
                  </a>
                ) : (
                  <button
                    onClick={(e) => {
                      onSubmit(e);
                    }}
                    className="loginbtn w-25 mx-auto d-block mt-5"
                  >
                    Submit
                  </button>
                )}
                {/* <div onClick={(e) => { onSubmit(e) }} className="loginbtn w-25 mx-auto d-block mt-5">Submit</div> */}
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="add_bank_modal"
            role="dialog"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered mbody sessiondetail"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <div className="col-md-12 col-12">
                    <h6 className="book-title text-center">Bank not added, Add bank here</h6>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="col-12 d-flex">
                          <span className="mr-3">
                            <Plaid />
                          </span>
                          <button
                            className="training_btn bg-transparent text-primary cnl-bnt"
                            data-toggle="modal"
                            data-target="#cancel-b"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                        </div>
                        {/* <button className="training_btn mb-3" data-toggle="modal" data-target="#confirm-t" data-dismiss="modal">Add Bank</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SessionDetails;
