import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import swal from 'sweetalert';
import { apiUrl } from '../../environment/environment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Pagination from '../../Pagination/Pagination';
// import { verifytokenCall } from '../Others/Utils.js';
function MyWorkout() {
    const { page } = useParams();
    const navigate = useNavigate()
    const limitValue = 10;
    const [pageNum, setPageNum] = useState(+page);
    const [noOfRecords, setNoOfRecords] = useState(0);

    const [showModel, setShowModel] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [list, setList] = useState([]);
    const [getlist, setGetlist] = useState();
    const handleClose = () => setShowModel(false);
    useEffect(() => {
        GetList();
    }, [])

    async function GetList(val) {
        setIsLoader(true);
        let obje = {
            limitValue: limitValue,
            pageNumber: val || pageNum,
        }
        await axios.post(`${apiUrl}/client/session/getworkoutlist`, obje)
            .then(function (response) {
                if (response.data.status === 1)
                    setTimeout(() => {
                        setList(response?.data?.result[0]?.paginatedResults)
                        setNoOfRecords(response?.data?.result[0]?.totalCount[0]?.count || 0)
                        setIsLoader(false);
                    }, 500);
                else {
                    setIsLoader(false);
                    swal({
                        title: "Error!",
                        text: response.data.message,
                        icon: "error",
                        button: true
                    })
                    //window.alert(response.data.message);
                }
            }).catch(function (error) {
                setIsLoader(false);
                window.alert(error);
            });
    };

    const curPage = (pageNum) => {
        navigate(`/myWorkoutHistory/${pageNum}`);
        setPageNum(pageNum);
        if (isSearching) {
            setIsLoader(true);
            setTimeout(async () => {
                await searchClick(searchname, pageNum)
                setIsLoader(false);

            }, 500);
        } else {
            GetList(pageNum);
        }
    };

    const isEdit = (obj) => {
        setIsLoader(true);
        setGetlist(obj);
        setShowModel(true);
        setIsLoader(false);
    }

    const [isSearching, setSearching] = useState(false);
    const [searchname, setSearchName] = useState("");

    const handlsearchInputs = (e) => {
        setSearchName(e.target.value);
        if (e.target.value !== "") {
            setSearching(true);
            searchClick(e.target.value);
        } else {
            setSearching(false);
            GetList(pageNum);
            setPageNum(pageNum)
        }
    };

    const clearClick = async (e) => {
        setSearchName("");
        setSearching(false);
        GetList(pageNum);
    };

    const searchClick = async (searchData, pageNumber) => {
        const obj = {
            limitValue: limitValue,
            pageNumber: pageNumber || pageNum,
        };

        const response = await axios.post(`${apiUrl}/client/session/searchworkoutlist`, { searchname: searchData, obj }, {})
        if (response.data.status === 1) {
            setList(response?.data?.result.paginatedResults)
            setNoOfRecords(response?.data?.result?.totalCount[0]?.count || 0)
            setSearching(true);
        }
    }


    return (
        <>

            <div className="sub-head-title">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <h1 className="main_title">My Workout History</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="col-md-12 col-sm-12 col-12 p-0">
                    <div className="trainer-main-box">
                        {/* <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
                                <div className="input-group position-relative">
                                    <input
                                        className="input-box search-input"
                                        type="text"
                                        placeholder="Search"
                                        onChange={(e) => handlsearchInputs(e)}
                                        name="searchinput"
                                        value={searchname}
                                        autoComplete="off"
                                    />
                                    {isSearching ? (
                                        <>
                                            <button className="search_btn mt-0" onClick={clearClick}>
                                                <i className="fa fa-times"></i>
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                className="search_btn mt-0"
                                                onClick={() => searchClick("")}
                                            >
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div> */}
                        <div className="box-card">
                            <div className="row">
                                {isLoader &&
                                    <div id="loadermodal" className="loader"><div className="popuploader loginloader"></div></div>
                                }
                                <div className="col-md-12 col-12 record_table workout-tbl mt-2">
                                    {list.length > 0 ? <>
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-hover mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="wd70">Sr. No</th>
                                                        <th>Trainer Name</th>
                                                        <th>Basic Movements</th>
                                                        <th>Fitness Goals </th>
                                                        <th className="wd225">Injuries </th>
                                                        <th>Date</th>
                                                        <th className="text-center w130">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {list.length > 0 ?
                                                        list.map((ele, index) => {
                                                            return (
                                                                <tr key={'index' + index}>
                                                                    <td>{index + 1 + (pageNum - 1) * limitValue}</td>
                                                                    <td >{`${ele?.trainer_data?.firstname} ${ele?.trainer_data?.lastname}`}</td>
                                                                    <td>{ele?.sessionworkout?.basicMovements.map((rs, ind) => {
                                                                        return (<p key={'ind' + ind}>{rs.movementName}</p>)
                                                                    })}</td>
                                                                    <td>{ele?.sessionworkout?.fitnessGoals}</td>
                                                                    <td><span className="injuries">{ele?.client_data?.injuriesorhelthissues ? ele?.client_data?.injuriesorhelthissues : 'N/A'}</span></td>
                                                                    <td className="text-nowrap">{new Date(ele.date).toDateString()}</td>
                                                                    <td className="text-center">
                                                                        <div className="d-flex justify-content-center">
                                                                            <button className="btn btn-info mr-2" onClick={(e) => { isEdit(ele) }}><i className="ace-icon fa fa-eye"></i></button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        :
                                                        <> {!isLoader &&
                                                            <td className="text-center" colSpan="9">
                                                                <div className="col-12">
                                                                    <div className="mt-3 text-center">
                                                                        <div className="no-record-box">
                                                                            <img src="/img/no-record.png" alt="no-img" />
                                                                            <h4 className="font-weight-bold">
                                                                                Session Detail Data Empty
                                                                            </h4>
                                                                            <p>When you have start session after data display hear</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        }</>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </> : (<>{isLoader === false && (<>
                                        <div className="col-12">
                                            <div className="no-record-box">
                                                <img src="/img/no-record.png" alt="no-img" />
                                                <h4 className="font-weight-bold">
                                                    No Workout History Found
                                                </h4>
                                                <p className="mb-0">When your session will be finished it will display here</p>
                                                <p>
                                                    Would you like to start your session ? <Link to={'/mysession'}>Click here</Link>
                                                </p>
                                                
                                            </div>
                                        </div>
                                    </>)}
                                    </>)}
                                </div>
                                <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={pageNum}
                                        totalCount={noOfRecords}
                                        pageSize={limitValue}
                                        onPageChange={(page) => curPage(page)}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/*---------- workform view Detail Modal--------------*/}

            <Modal show={showModel} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton className="d-block text-center wrokform-modal">
                    <Modal.Title className="d-flex align-items-center mb-0"><img src="/img/common-health.png" alt="logo" /><h4 className="mb-0 ml-3 font-weight-bold">Workout Form Detail</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12 pb-3 pt-3 Workform">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-12">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active mr-lg-3" data-toggle="tab" href="#trainer-info">Trainer Information</a>
                                    </li>
                                    <li className="nav-item mr-lg-3">
                                        <a className="nav-link" data-toggle="tab" href="#trainer-workout">Trainer Workout</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#basic-movement">Basic Movements</a>
                                    </li>
                                </ul>
                                <div className="tab-content workformdetail">
                                    <div id="trainer-info" className="tab-pane trainer_info active">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-12 mb-2">
                                                <div className="row">
                                                    <label className="col-lg-6 col-md-6 col-12">Name <span className="colon">:</span></label>
                                                    <div className="col-lg-6 col-md-6 col-12">{getlist?.client_data?.firstname}</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12 mb-2">
                                                <div className="row">
                                                    <label className="col-lg-4 col-md-6 col-12">Age <span className="colon">:</span></label>
                                                    <div className="col-lg-8 col-md-6 col-12">{getlist?.client_data?.age}</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12 mb-2">
                                                <div className="row">
                                                    <label className="col-lg-6 col-md-6 col-12">Fitness Goals <span className="colon">:</span></label>
                                                    <div className="col-lg-6 col-md-6 col-12">{getlist?.sessionworkout?.fitnessGoals}</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-12 mb-2">
                                                <div className="row">
                                                    <label className="col-lg-4 col-md-6 col-12">Format <span className="colon">:</span></label>
                                                    <div className="col-lg-8 col-md-6 col-12">{getlist?.sessionworkout?.format}</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-12 mb-2">
                                                <div className="row">
                                                    <label className="col-lg-3 col-md-6 col-12">Injuries <span className="colon">:</span></label>
                                                    <div className="col-lg-9 col-md-6 col-12">{getlist?.client_data?.injuriesorhelthissues}</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-12 mb-2">
                                                <div className="row">
                                                    <label className="col-lg-3 col-md-6 col-12">Additional Notes <span className="colon">:</span></label>
                                                    <div className="col-lg-9 col-md-6 col-12">{getlist?.sessionworkout?.format}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="trainer-workout" className="tab-pane fade">
                                        <div className="row">
                                            <div className="col-12">
                                                <h4 className="work-name font-weight-bold">WorkOut Question</h4>
                                            </div>
                                            <div className="col-md-12 col-12">
                                                <label className="w-100">Q. What did you focus on today to achieve. Your client's desired fitness goals?</label>
                                                <p className="font-weight-bold">Ans. {getlist?.sessionworkout?.desiredOne ? getlist?.sessionworkout?.desiredOne?.join(',') : 'N/A'}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 col-12">
                                                <label className="w-100">Q. What did you focus on today to achieve your client's desired fitness goals?</label>
                                                <p className="font-weight-bold">Ans. {getlist?.sessionworkout?.desiredTwo ? getlist?.sessionworkout?.desiredTwo?.join(',') : "N/A"}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 col-12">
                                                <label className="w-100">Q. Which of the 7 basic movements did you work on today? (Check all that apply) </label>
                                                <p className="font-weight-bold">Ans. {getlist?.sessionworkout?.basicMovements.length > 0 && getlist?.sessionworkout?.basicMovements.map(x => x.movementName).join(',')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="basic-movement" className="tab-pane trainer_info fade">
                                        {getlist?.sessionworkout?.basicMovements.length > 0 && getlist?.sessionworkout?.basicMovements.map((ele, i) => {
                                            return (
                                                <div key={'i_' + i} className="row bor-b">
                                                    <div className="col-md-12 col-12">
                                                        <h4 className="work-name font-weight-bold">{ele.movementName}</h4>
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-12 mb-2">
                                                                <div className="row">
                                                                    <label className="col-lg-3 col-md-6 col-12">Specify Movement <span className="colon">:</span></label>
                                                                    <div className="col-lg-9 col-md-6 col-12">{ele.specifyMovement ? ele.specifyMovement : "N/A"}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-12 col-12 mb-2">
                                                                <div className="row">
                                                                    <label className="col-lg-6 col-md-6 col-12">Weight <span className="colon">:</span></label>
                                                                    <div className="col-lg-6 col-md-6 col-12">{ele.weight ? ele.weight : "N/A"}</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-12 col-12 mb-2">
                                                                <div className="row">
                                                                    <label className="col-lg-5 col-md-6 col-12">Rest Duration <span className="colon">:</span></label>
                                                                    <div className="col-lg-7 col-md-6 col-12">{ele.restDuration ? ele.restDuration : "N/A"}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-12 col-12 mb-2">
                                                                <div className="row">
                                                                    <label className="col-lg-6 col-md-6 col-12">Sets <span className="colon">:</span></label>
                                                                    <div className="col-lg-6 col-md-6 col-12">{ele.sets ? ele.sets : "N/A"}</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-12 col-12 mb-2">
                                                                <div className="row">
                                                                    <label className="col-lg-5 col-md-6 col-12">Reps <span className="colon">:</span></label>
                                                                    <div className="col-lg-7 col-md-6 col-12">{ele.reps ? ele.reps : "N/A"}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                </Modal.Footer> */}
            </Modal>

            {/*---------- workform view Detail Modal End--------------*/}
        </>
    );
}
export default MyWorkout;