function CancellationPolicyApp() {
    return (
        <>
            <div className="container-fluid">
                <div className="col-md-12 col-12 p-0">
                 <h1 className="App-title">Cancellation Policy</h1>
                    <div className="trainer-main-box mb-3">
                        <div className="row">
                            <div className="col-12">
                                <div className="cancel_policy">
                                    {/* <h4>Bookable activities:</h4> */}
                                    <ul className="list-inline cnl_bullet">
                                        <li>Members or subscribers can cancel their - membership or subscription at any time.</li>
                                        <li>Cancellations must be requested through the user's account settings or by contacting customer support.</li>
                                        <li> Membership fees or subscription charges are non-refundable and non-transferable.</li>
                                        <li> Cancellation requests made before the next billing cycle will prevent future charges.</li>
                                        <li> Cancellation or rescheduling of personal training or coaching sessions should be done within a specified      timeframe (e.g., 24 hours) before the scheduled session.</li>
                                        <li> Failure to cancel or reschedule within the specified timeframe may result in a session being deducted or     charged.</li>
                                        <li> Participants should cancel their enrollment in a class or workshop within a specified timeframe (e.g., 24     hours) before the scheduled start time.</li>
                                        <li> Late cancellations or no-shows may result in a forfeiture of the class fee.</li>
                                        <li> Membership fees, subscription charges, and payments for services are generally non-refundable.</li>
                                        <li> Refunds may be considered on a case-by-case basis for extenuating circumstances, and such requests should be    submitted in writing.</li>
                                        <li> The fitness website reserves the right to modify the cancellation policy at any time. </li>
                                        <li> Any changes will be communicated to members and subscribers via email or notifications on the website.</li>
                                        <li> Clearly communicate the cancellation policy on the fitness website's terms and conditions page, membership or   subscription details, and booking pages.</li>
                                        <li> Ensure that the cancellation policy is easy to understand and transparent to users.</li>
                                        <li> Make sure users are aware of any timeframes for cancellations and the associated consequences.</li>
                                        <li> If there are any exceptions to the cancellation policy, clearly state them in the policy.</li>
                                        <li> Comply with relevant consumer protection laws and regulations.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CancellationPolicyApp;
