import axios from "axios";
import React, { useRef, useState } from "react";
import { apiUrl } from "../environment/environment";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";

const initialValues = {
  password: "",
  confirmpassword: "",
};

function ResetPassword() {
  sessionStorage.clear();

  let url = window.location.href
  let data = url.split('/');
  const { credentials } = useParams()
  let usertype = "trainer";
  let token = credentials;
  for (let i = 0; i <= data.length; i++) {
    if (data[i] === 'client') {
      usertype = 'client';
    }
    if (data[i] === 'resetpassword') {
      token = data[i + 1]
    }
  }

  // COMMENTED OLD
  /* var uri = window.location.pathname;
    let splituri = uri.split("/");
    let usertype = splituri[1]; */

  const [user, setUser] = useState(initialValues);
  const navigate = useNavigate()
  const [isHidden, setIsHidden] = useState(true);
  const [isCPwdHidden, setIsCPwdHidden] = useState(true);
  const [error, setError] = useState({})
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [dataLoader, setDataLoader] = useState(false)

  const passwordRef = useRef()
  const conformPasswordRef = useRef()

  const isRequiredField = (fieldName) => {
    return ['password', 'confirmpassword'].includes(fieldName);
  };

  const handleInputs = (e) => {
    const { name, value } = e.target

    if (value.trim() === "" && isRequiredField(name)) {
      let errorMessage = '';
      if (name === 'password') {
        errorMessage = "Please enter new password."
      } else if (name === 'confirmpassword') {
        errorMessage = "Please enter confirm password."
      }
      setError((prevError) => ({ ...prevError, [name]: errorMessage }));
    } else if (name === 'password' && value.length < 6) {
      setError((prevError) => ({ ...prevError, [name]: "New password length must be at least 6 characters" }));
    } else if (name === 'confirmpassword' && value.length < 6) {
      setError((prevError) => ({ ...prevError, [name]: "Confirm password length must be at least 6 characters" }));
    } else if (name === 'confirmpassword' && value !== user.password) {
      setError((prevError) => ({ ...prevError, confirmpassword: "Password and confirm password doesn't match" }));
    }else {
      setError((prevError) => ({ ...prevError, [name]: '' }));
    }  

    setUser({ ...user, [name]: value });
    // if (value.trim() === '' && isRequiredField(name)) {
    //   setError((prevError) => ({ ...prevError, [name]: `Please enter ${name}!` }));
    // } else if (name === 'password' && (value.length < 6)) {
    //   setError((prevError) => ({ ...prevError, password: 'Password length must be at least 6 !' }));
    // } else if (name === 'confirmpassword' && (value.length < 6)) {
    //   setError((prevError) => ({ ...prevError, confirmpassword: 'Confirm password length must be at least 6 !' }));
    // } else {
    //   setError((prevError) => ({ ...prevError, [name]: '' }));
      
    // }

  };

  const Reset = async (e) => {
    e.preventDefault();
    let isValid = true;
    var errormsg = {};

    if (user.password === "") {
      errormsg.password = "Please enter new password.";
      isValid = false;
    }else if (user.password.length < 6) {
      errormsg.password = "New password length must be at least 6 characters";
      isValid = false;
    }

    if (user.confirmpassword === "") {
      errormsg.confirmpassword = "Please enter confirm password.";
      isValid = false;
    }else if (user.confirmpassword.length < 6) {
      errormsg.confirmpassword = "Confirm password length must be at least 6 characters";
      isValid = false;
    }else if (user.password !== user.confirmpassword) {
      errormsg.confirmpassword = "Password and confirm password do not match";
      isValid = false;
    }
    if (Object.keys(errormsg).length > 0) {
      if (errormsg.password) {
        passwordRef.current.focus()
      } else if (errormsg.confirmpassword) {
        conformPasswordRef.current.focus()
      }
    }
    setError(errormsg);

    if (isValid) {
      setIsButtonDisabled(true)
      setDataLoader(true)
      await axios
        .post(
          `${apiUrl}/${usertype}/account/resetpassword/${token}`,
          { password: user.password, confirmpassword: user.confirmpassword },
          {}
        )
        .then(function (response) {
          if (response.data.status === 1) {
            setTimeout(() => {

              setIsButtonDisabled(false)
              setDataLoader(false)
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
                button: true,
              });
              navigate(`/${usertype}/login`)
            }, 500);
            // window.location.href = `/${usertype}/login`;
          } else {
            setIsButtonDisabled(false)
            setDataLoader(false)
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
          }
        })
        .catch(function (error) {
          setDataLoader(false)

        });
    }

  };

  return (
    <>
      <div className="container-fluid">
        <div className="commonbox">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6 p-0">
                <div className="loginimg">
                  <div className="col-md-12 logoblock">
                    <h3>New Password</h3>
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-0">
                <div className="loginbox create-new-pass">
                  <div className="login-form">
                    {dataLoader &&
                      <div className="loading">
                        <div className="mainloader"></div>
                      </div>}
                    <div className="col-12 text-center">
                      <a className="login-logo" href="/">
                        <img src="/img/common-health.png" alt="logo" />
                      </a>
                      <h4 className="log-text">Create New Password</h4>
                    </div>
                    <div className="col-md-12">
                      <div className="position-relative">
                        <input
                          onChange={(e) => {
                            handleInputs(e);
                          }}
                          name="password"
                          ref={passwordRef}
                          type={isHidden === true ? "password" : "text"}
                          className="w-100 mb-3 input-box"
                          placeholder="Password"
                        />
                        <i
                          className={`fa fa-eye${isHidden === false ? "" : "-slash"
                            } icon`}
                          onClick={() => setIsHidden(!isHidden)}
                        ></i>
                      </div>
                      {error.password &&
                        <div className="text-danger">{error.password}</div>
                      }
                    </div>
                    <div className="col-md-12">
                      <div className="position-relative">
                        <input
                          onChange={(e) => {
                            handleInputs(e);
                          }}
                          name="confirmpassword"
                          ref={conformPasswordRef}
                          type={isCPwdHidden === true ? "password" : "text"}
                          className="w-100 mb-2 input-box"
                          placeholder="Confirm Password"
                        />
                        <i
                          className={`fa fa-eye${isCPwdHidden === false ? "" : "-slash"
                            } icon`}
                          onClick={() => setIsCPwdHidden(!isCPwdHidden)}
                        ></i>
                      </div>
                      <div className="text-danger">{error.confirmpassword}</div>
                    </div>
                    <div className="col-md-12">
                      <button
                        disabled={isButtonDisabled}
                        onClick={(e) => {
                          Reset(e);
                        }}
                        className="loginbtn mt-4"
                      >
                        {/* {buttonLoader ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </>
                      ) : (
                        ""
                      )} */}
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
