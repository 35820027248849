import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiUrl } from "../../environment/environment";
import swal from "sweetalert";
import PhoneInput from "react-phone-input-2";
import { phoneNumberLength } from "../utility/Utility";


function SignUp() {
  const navigate = useNavigate();

  const [isHidden, setIsHidden] = useState(true);
  const [isPwdHidden, setIsPwdHidden] = useState(true);
  const [isCPwdHidden, setIsCPwdHidden] = useState(true);

  const ProfileImage_URL = "/img/Small-no-img.png";
  const [profileimagepreview, setProfileImagePreview] = useState(ProfileImage_URL);
  const [profileimage, setProfileImage] = useState();
  const [IsNext, setIsNext] = useState(0);
  const [fitnessgoal, setFitnessGoal] = useState([]);
  const [firstStepNext, setFirstStepNext] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [emailnotifications, setEmailnotifications] = useState(true);
  const [maillinglist, setMaillinglist] = useState(true);
  const [textnotifications, setTextnotifications] = useState(true);
  const [webnotifications, setWebnotifications] = useState(true);
  const [mobilenotifications, setMobilenotifications] = useState(true);
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmpassword: "",
    phoneno: "",
    countryDialCode: "",
    countryCode: "",
    country: "",
    age: "",
    gender: "Male",
    heightisfeet: true,
    height: "",
    weightiskg: true,
    weight: "",
    equipmentavailable: "",
    fitnessgoals: "",
    otherfitnessgoals: "",
    injuriesorhelthissues: "",
    emailnotifications: emailnotifications,
    maillinglist: maillinglist,
    textnotifications: textnotifications,
    webnotifications: webnotifications,
    mobilenotifications: mobilenotifications,
  });
  const [selectedGoals, setSelectedGoals] = useState([])

  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [dataLoader, setDataLoader] = useState(false)

  const [IsTAndC, setIsTAndC] = useState(false);
  const [activeClass, setActiveClass] = useState({ number: "", class: "" })
  const [selectActiv, setSelectActiv] = useState({ number: "", class: "" })

  // first step
  const nameInputRef = useRef()
  const passwordInputRef = useRef()
  const emailInputRef = useRef()

  // second and final step
  const userNameRef = useRef()
  const userLastnameRef = useRef()
  const userEmailRef = useRef()
  const userPasswordRef = useRef()
  const userCoPasswordRef = useRef()
  const userPhonenoRef = useRef()
  const userAgeRef = useRef()
  const userHeightRef = useRef()
  const userWeightRef = useRef()
  const userFitnesRef = useRef()
  const userInjuriesRef = useRef()
  const isAgreeInputRefSe = useRef()

  const [isEdit, setIsEdit] = useState(false);
  const [mobileNumberValidation, setMobileNumberValidation] = useState(false);

  const OnFileChange = (event) => {
    const file_size = event.target.files[0].size;
    if (file_size > 1048000) {
      setProfileImagePreview(ProfileImage_URL);
      alert("File size more than 1 MB. File size must under 1MB !");
      event.preventDefault();
    } else {
      const fileReader = new window.FileReader();
      const file = event.target.files[0];

      setProfileImage(event.target.files[0]);

      fileReader.onload = (fileLoad) => {
        const { result } = fileLoad.target;
        setIsEdit(true)
        setProfileImagePreview(result);
      };
      fileReader.readAsDataURL(file);
    }
  };

  const OnFileDiacard = () => {
    setIsEdit(false)
    setProfileImagePreview(ProfileImage_URL)
    setProfileImage(ProfileImage_URL)
  }

  const SelectHeight = (e, number) => {
    if (number) {
      setErrors((prevError) => ({ ...prevError, height: "" }));
    }

    if (document.querySelector("#heightul li.active") !== null) {
      document.querySelector("#heightul li.active").classList.remove("active");
      setActiveClass({ number: number, class: '' })
    }
    e.currentTarget.classList.add("active");
    setActiveClass({ number: number, class: 'active' })
    user.height = number;

  };

  const SelectWeight = (e, number) => {
    if (number) {
      setErrors((prevError) => ({ ...prevError, weight: "" }));
    }

    if (document.querySelector("#weightul li.active") !== null) {
      document.querySelector("#weightul li.active").classList.remove("active");
      setSelectActiv({ number: number, class: '' })
    }

    e.currentTarget.classList.add("active");
    setSelectActiv({ number: number, class: 'active' })
    user.weight = number;

  };

  const range = (start, end, step) => {
    return Array.from(
      Array.from(Array(Math.ceil((end - start) / step)).keys()),
      (x) => start + x * step
    );
  };

  const handleFitnessGoal = (e) => {
    setSelectedGoals({ ...selectedGoals, [e.target.name]: e.currentTarget.checked ? e.target.value : "" })
  };

  const handleTAndC = (e) => {
    const isChecked = e.currentTarget.checked;
    setIsTAndC(isChecked);

    if (!isChecked) {
      setErrors((prevErrors) => ({ ...prevErrors, isAgree: 'Please check Terms & Conditions' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, isAgree: '' }));
    }
  };

  useEffect(() => {
    Getgoals()
  }, [])

  async function Getgoals() {
    await axios.get(`${apiUrl}/client/getallgoals`).then((res) => {
      setFitnessGoal(res.data.result)
    })
  }

  const isRequiredField = (fieldName) => {
    return ['name', 'email', 'password'].includes(fieldName);
  };

  const handleChange = (objName, val) => {
    setFirstStepNext((prevState) => ({ ...prevState, [objName]: val }));

    let reg_email = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

    if (val.trim() === '' && isRequiredField(objName)) {
      setErrors((prevError) => ({ ...prevError, [objName]: `Please enter ${objName}.` }));
    } else if (objName === 'name' && (val.length < 3 || val.length > 50)) {
      setErrors((prevError) => ({ ...prevError, name: "Please enter minimum 3 letters or maximum 50" }));
    } else if (objName === 'email' && val.trim() !== '' && !reg_email.test(val)) {
      setErrors((prevError) => ({ ...prevError, email: 'Please enter valid email' }));
    } else if (objName === 'password' && (val.length < 6)) {
      setErrors((prevError) => ({ ...prevError, password: "Password length must be at least 6 characters" }));
    } else {
      setErrors((prevError) => ({ ...prevError, [objName]: '' }));
    }

  };

  const firstStepSignUpNext = (e) => {
    e.preventDefault();
    let isSubmit = true;
    var errormsg = {};
    let reg_email = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;


    if (firstStepNext.name === "") {
      errormsg.name = "Please enter name.";
      isSubmit = false;
    } else if ((firstStepNext.name.length >= 3) === false || (firstStepNext.name.length <= 50) === false) {
      errormsg.name = "Please enter minimum 3 letters or maximum 50";
      isSubmit = false;
    }
    if (firstStepNext.password === "") {
      errormsg.password = "Please enter password.";
      isSubmit = false;
    } else if (firstStepNext.password.length < 6) {
      errormsg.password = "Password length must be at least 6 characters";
      isSubmit = false;
    }

    if (firstStepNext.email === "") {
      errormsg.email = "Please enter email.";
      isSubmit = false;
    } else if (reg_email.test(firstStepNext.email) === false) {
      errormsg.email = "Please enter valid email";
      isSubmit = false;
    }

    if (Object.keys(errormsg).length > 0) {
      if (errormsg.name) {
        nameInputRef.current.focus();
      } else if (errormsg.email) {
        emailInputRef.current.focus();
      } else if (errormsg.password) {
        passwordInputRef.current.focus();
      }
    }
    setErrors(errormsg);
    if (isSubmit) {
      setIsButtonDisabled(true)
      setDataLoader(true)
      setTimeout(() => {

        let nameList = (firstStepNext.name || "").split(" ");
        if (nameList.length > 1) {
          user.lastname = nameList[nameList.length - 1];
          nameList.pop();
        }
        user.firstname = nameList.map((s) => s).join(" ");
        user.email = firstStepNext.email;
        user.password = firstStepNext.password;
        setIsNext(1);
        setIsButtonDisabled(false)
        setDataLoader(false)
        sessionStorage.setItem("firstStepNext", JSON.stringify(firstStepNext));
      }, 1000);
    }
  };

  // const validatePhoneNumber = (phoneNumber, countryCode) => {
  //   const formattedPhoneNumber = phoneNumber.replace(/[^0-9]/g, "");

  //   if (countryCode === "in") {
  //     return formattedPhoneNumber.length === 10;
  //   } else if (countryCode === "no" || countryCode === "am") {
  //     return formattedPhoneNumber.length === 8;
  //   } else if (countryCode === "al") {
  //     return formattedPhoneNumber.length === 12;
  //   } else if (countryCode === "hu" || countryCode === "id" || countryCode === "af") {
  //     return formattedPhoneNumber.length === 13;
  //   } else if (countryCode === "is") {
  //     return formattedPhoneNumber.length === 7;
  //   } else {
  //     return formattedPhoneNumber.length === 10;
  //   }
  // };

  const isRequiredSecond = (fieldName) => {
    return ['firstname', 'lastname', 'email', 'password', 'confirmpassword', 'phoneno', 'age', 'gender', 'height', 'weight', 'fitnessgoals', 'injuriesorhelthissues', 'isAgree'].includes(fieldName)
  }

  const handleInputs = (e) => {
    const { name, value } = e.target

    if (name === "heightisfeet" || name === "weightiskg")
      setUser({ ...user, [name]: !!JSON.parse(String(value).toLowerCase()) });
    else if (name !== "heightisfeet")
      setUser({ ...user, [name]: value });

    if (name === "emailnotifications") {
      setEmailnotifications(!emailnotifications);
    }
    if (name === "maillinglist") {
      setMaillinglist(!maillinglist);
    }
    if (name === "textnotifications") {
      setTextnotifications(!textnotifications);
    }
    if (name === "webnotifications") {
      setWebnotifications(!webnotifications);
    }
    if (name === "mobilenotifications") {
      setMobilenotifications(!mobilenotifications);
    }

    let reg_email = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

    if (value.trim() === "" && isRequiredSecond(name)) {
      let errorMessage = '';

      if (name === "firstname") {
        errorMessage = "Please enter first name";
      } else if (name === 'lastname') {
        errorMessage = "Please enter last name";
      } else if (name === "email") {
        errorMessage = "Please enter email";
      } else if (name === "password") {
        errorMessage = "Please enter password";
      } else if (name === "confirmpassword") {
        errorMessage = "Please enter confirm password";
      } else if (name === "age") {
        errorMessage = "Please enter age";
      } else if (name === "gender") {
        errorMessage = "Please select gender";
      } else if (name === "injuriesorhelthissues") {
        errorMessage = "Please enter injuries Or helth issues";
      } else if (name === "isAgree" && !IsTAndC) {
        errorMessage = "Please check Terms & Conditions";
      }


      setErrors((prevError) => ({ ...prevError, [name]: errorMessage }));
    } else if (((name === 'firstname') || (name === 'lastname')) && (value.length < 3 || value.length > 50)) {
      setErrors((prevError) => ({ ...prevError, [name]: "Please enter minimum 3 letters or maximum 50" }));
    } else if (name === 'email' && value.trim() !== '' && !reg_email.test(value)) {
      setErrors((prevError) => ({ ...prevError, email: 'Please enter valid email' }));
    } else if (name === 'password' && (value.length < 6)) {
      setErrors((prevError) => ({ ...prevError, password: "Password length must be at least 6 characters" }));
    } else if (name === 'confirmpassword' && (value.length < 6)) {
      setErrors((prevError) => ({ ...prevError, confirmpassword: "Confirm password length must be at least 6 characters" }));
    } else if (name === 'confirmpassword' && value !== user.password) {
      setErrors((prevError) => ({ ...prevError, confirmpassword: "Password and confirm password doesn't match" }));
    } else if (name === 'age' && (value < 5 || value > 75)) {
      setErrors((prevError) => ({ ...prevError, age: "Age must be between 5 and 75 years" }));
    } else {
      setErrors((prevError) => ({ ...prevError, [name]: '' }));
    }

  };

  const Next = async (e) => {
    e.preventDefault();
    let isValid = true;
    var errormsg = {};
    let reg_email = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

    if (user.firstname === "") {
      errormsg.firstname = "Please enter first name.";
      isValid = false;
    } else if ((user.firstname.length >= 3) === false || (user.firstname.length <= 50) === false) {
      errormsg.firstname = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    }

    if (user.lastname === "") {
      errormsg.lastname = "Please enter last name.";
      isValid = false;
    } else if ((user.lastname.length >= 3) === false || (user.lastname.length <= 50) === false) {
      errormsg.lastname = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    }

    if (user.email === "") {
      errormsg.email = "Please enter email";
      isValid = false;
    } else if (reg_email.test(user.email) === false) {
      errormsg.email = "Please enter valid email";
      isValid = false;
    } else if ((user.email.length <= 50) === false) {
      errormsg.email = "Please enter maximum 256";
      isValid = false;
    }

    if (user.password === "") {
      errormsg.password = "Please enter Password";
      isValid = false;
    } else if (user.password.length < 6) {
      errormsg.password = "Password length must be at least 6 characters";
      isValid = false;
    }

    if (user.confirmpassword === "") {
      errormsg.confirmpassword = "Please enter confirm password";
      isValid = false;
    } else if (user.confirmpassword.length < 6) {
      errormsg.confirmpassword = "Confirm password length must be at least 6 characters";
      isValid = false;
    } else if (user.confirmpassword !== user.password) {
      errormsg.confirmpassword = "Password and confirm password doesn't match";
      isValid = false;
    }

    if (user.phoneno === "") {
      errormsg.phoneno = "Please choose country and enter phone number";
      isValid = false;
    } else if (!mobileNumberValidation) {
      errormsg.phoneno = "Please enter valid phone number";
      isValid = false;
    }

    if (Object.keys(errormsg).length > 0) {
      if (errormsg.firstname) {
        userNameRef.current.focus();
      } else if (errormsg.lastname) {
        userLastnameRef.current.focus();
      } else if (errormsg.email) {
        userEmailRef.current.focus();
      } else if (errormsg.password) {
        userPasswordRef.current.focus();
      } else if (errormsg.confirmpassword) {
        userCoPasswordRef.current.focus();
      } else if (errormsg.phoneno) {
        userPhonenoRef.current.focus();
      }
    }

    setErrors(errormsg);
    if (isValid) {
      setIsButtonDisabled(true)

      setDataLoader(true)
      // document.querySelector(".loading").classList.remove("d-none");
      await axios
        .post(
          `${apiUrl}/client/account/verifyemailexists`,
          { email: user.email },
          {}
        )
        .then(function (response) {
          if (response.data.status === 2) {
            setIsButtonDisabled(false)

            setDataLoader(false)
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
            //window.alert(response.data.message);
          } else {
            setTimeout(() => {
              setIsButtonDisabled(false)

              setDataLoader(false)
              setIsNext(2);
            }, 1000);
            // sessionStorage.setItem("secoundStepNext", JSON.stringify(user));
          }
          // document.querySelector(".loading").classList.add("d-none");
        })
        .catch(function (error) {
          window.alert(error)
          // document.querySelector(".loading").classList.add("d-none");
        });
    } else {
      setIsButtonDisabled(false)

      setDataLoader(false)
      setIsNext(1);
    }
  };

  const PostSignUp = async (e) => {
    e.preventDefault();
    let arry = []
    for (const [key, value] of Object.entries(selectedGoals)) {
      arry.push(value)
      console.log(key);
    }

    user.fitnessgoals = arry
    // return false
    let isValid = true;
    var errormsg = {};

    let reg_numbers = /^[0-9]+$/;

    if (user.age === "") {
      errormsg.age = "Please enter age";
      isValid = false;
    } else if (user.age < 5 || user.age > 99 || !reg_numbers.test(user.age)) {
      errormsg.age = "Age must be between 5 and 75 years";
      isValid = false;
    }
    if (user.gender === "") {
      errormsg.gender = "Please select gender";
      isValid = false;
    }
    // if (user.equipmentavailable === "") {
    //   errormsg.equipmentavailable = "Please enter Available Equipment.";
    //   isValid = false;
    // }
    if (user.height === "") {
      errormsg.height = "Please select height";
      isValid = false;
    }
    if (user.weight === "") {
      errormsg.weight = "Please select weight";
      isValid = false;
    }
    if (user?.fitnessgoals === "" && user.otherfitnessgoals === "") {
      errormsg.fitnessgoal = "Please select or enter fitness goal";
      isValid = false;
    }
    if (user.injuriesorhelthissues === "") {
      errormsg.injuriesorhelthissues = "Please enter injuries Or helth issues";
      isValid = false;
    } else if (user.injuriesorhelthissues.length < 3) {
      errormsg.injuriesorhelthissues = "Injuries Or helth issues length must be at least 3 characters";
      isValid = false;
    }
    if (!IsTAndC) {
      errormsg.isAgree = "Please check Terms & Conditions";
      isValid = false;
    }
    if (Object.keys(errormsg).length > 0) {
      if (errormsg.firstname) {
        userNameRef.current.focus();
      } else if (errormsg.lastname) {
        userLastnameRef.current.focus();
      } else if (errormsg.email) {
        userEmailRef.current.focus();
      } else if (errormsg.password) {
        userPasswordRef.current.focus();
      } else if (errormsg.confirmpassword) {
        userCoPasswordRef.current.focus();
      } else if (errormsg.phoneno) {
        userPhonenoRef.current.focus();
      } else if (errormsg.age) {
        userAgeRef.current.focus();
      } else if (errormsg.height) {
        userHeightRef.current.focus();
      } else if (errormsg.weight) {
        userWeightRef.current.focus();
      } else if (errormsg.fitnessgoal) {
        userFitnesRef.current.focus();
      } else if (errormsg.injuriesorhelthissues) {
        userInjuriesRef.current.focus();
      } else if (errormsg.isAgree) {
        isAgreeInputRefSe.current.focus();
      }
    }
    setErrors(errormsg);
    if (isValid === true) {
      let obj = {
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        password: user.password,
        confirmpassword: user.confirmpassword,
        phoneno: user.phoneno,
        countryDialCode: user.countryDialCode,
        age: user.age,
        gender: user.gender,
        heightisfeet: user.heightisfeet,
        height: user.height,
        weightiskg: user.weightiskg,
        weight: user.weight,
        equipmentavailable: user.equipmentavailable,
        fitnessgoals: JSON.stringify(user.fitnessgoals),
        otherfitnessgoals: user.otherfitnessgoals,
        injuriesorhelthissues: user.injuriesorhelthissues,
        emailnotifications: emailnotifications,
        maillinglist: maillinglist,
        textnotifications: textnotifications,
        webnotifications: webnotifications,
        mobilenotifications: mobilenotifications,
        country: user.country,
        countryCode: user.countryCode,
      };

      var form_data = new FormData();
      for (var key in obj) {
        // document.querySelector(".loading").classList.remove("d-none");
        form_data.append(key, obj[key]);
      }
      form_data.append("profile", profileimage || "");
      setIsButtonDisabled(true)
      setIsEdit(false);
      setDataLoader(true)
      await axios
        .post(`${apiUrl}/client/account/register`, form_data, {})
        .then(function (response) {
          // document.querySelector(".loading").classList.add("d-none");
          if (response.data.status === 1) {
            setIsButtonDisabled(false)

            setDataLoader(false)
            sessionStorage.setItem("finalSignUpRegisterStep", JSON.stringify(user));
            sessionStorage.clear();
            navigate("/signupsuccess", { state: "client" });
          }
          setIsButtonDisabled(false)
          setDataLoader(false)
          swal({
            title: response.data.status === 1 ? "Success!" : "Error",
            text: response.data.message,
            icon: response.data.status === 1 ? "success" : "error",
            button: true,
          });
        })
        .catch(function (error) {
          alert(error)
          setIsButtonDisabled(false)

          setDataLoader(false)
          sessionStorage.removeItem("finalSignUpRegisterStep");
          // document.querySelector(".loading").classList.add("d-none");
        });
    }
  };


  return (
    <>

      <div className="container-fluid">
        <div className="commonbox">
          <div className={`row ${IsNext === 0 ? "" : "d-none"}`}>

            <div className="col-md-6 p-0">
              <div className="loginimg">
                <div className="col-md-12 logoblock">
                  <h3>Everyday is a Fresh Start.</h3>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div className="loginbox registerbox">
                <div className="login-form">
                  {dataLoader &&
                    <div className="loading">
                      <div className="mainloader"></div>
                    </div>
                  }
                  <div className="loading d-none">
                    <div className="mainloader"></div>
                  </div>
                  <div className="col-12 text-center">
                    <a className="login-logo" href="/">
                      <img src="/img/common-health.png" alt="logo" />
                    </a>
                    <h4 className="log-text">
                      Let's get you started on this fitness journey
                    </h4>
                  </div>
                  <ul
                    className="nav nav-tabs col-lg-8 col-md-10 col-sm-8 col-11 mx-auto"
                    role="tablist"
                  >
                    <li className="nav-item col-md-6 col-6 w-100 p-0">
                      <a
                        className="nav-link text-center active"
                        href={() => false}
                        onClick={() => {
                          navigate("/client/login");
                        }}
                        data-toggle="tab"
                      >
                        MEMBER
                      </a>
                    </li>
                    <li className="nav-item col-md-6 col-6 w-100 p-0">
                      <a
                        className="nav-link text-center"
                        data-toggle="tab"
                        href={() => false}
                        onClick={() => {
                          navigate("/trainersignup");
                        }}
                      >
                        TRAINER
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div id="MEMBER" className="container tab-pane active">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label>First Name</label> <span className="text-danger font-weight-bold">*</span>
                          <input
                            type="text"
                            className="w-100 input-box"
                            placeholder="First name"
                            ref={nameInputRef}
                            onChange={(e) => {
                              handleChange("name", e.target.value);
                            }}
                          />
                          <div className="text-danger">{errors.name}</div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label>Email Address</label> <span className="text-danger font-weight-bold">*</span>
                          <input
                            type="text"
                            className="w-100 input-box"
                            placeholder="Email address"
                            ref={emailInputRef}
                            onChange={(e) => {
                              handleChange("email", e.target.value);
                            }}
                          />
                          <div className="text-danger">{errors.email}</div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="position-relative">
                            <label>Password</label> <span className="text-danger font-weight-bold">*</span>
                            <input
                              id="password-field"
                              onChange={(e) => {
                                handleChange("password", e.target.value);
                              }}
                              name="password"
                              ref={passwordInputRef}
                              type={isHidden === true ? "password" : "text"}
                              className="w-100 input-box"
                              placeholder="Password"
                            />
                            <span
                              toggle="#password-field"
                              className={`fa fa-eye${isHidden === false ? "" : "-slash"
                                } icon field-icon toggle-password`}
                              onClick={() => setIsHidden(!isHidden)}
                            ></span>
                          </div>
                          <div className="text-danger">{errors.password}</div>
                        </div>

                        <div className="col-md-12">
                          <button
                            disabled={isButtonDisabled}
                            className="loginbtn mt-3"
                            onClick={(e) => {
                              firstStepSignUpNext(e);
                            }}
                          >

                            Next
                          </button>
                        </div>
                        <div className="col-md-12 text-center mt-3">
                          <span className="text-login">
                            Already Registered ?{" "}
                            <Link to="/client/login" className="linktext">
                              Login
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div id="TRAINER" className="container tab-pane fade">
                      <div className="row my-4">
                        <div className="col-md-12 mb-3">
                          <input
                            type="text"
                            className="w-100 input-box"
                            placeholder="Name First"
                          />
                        </div>
                        <div className="col-md-12 mb-3">
                          <input
                            type="text"
                            className="w-100 input-box"
                            placeholder="Email Address"
                          />
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="position-relative ">
                            <input
                              id="password-field"
                              type="password"
                              className="form-control w-100 input-box"
                              name="password"
                              value="secret"
                            />
                            <span
                              toggle="#password-field"
                              className="fa fa-eye icon field-icon toggle-password"
                            ></span>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="position-relative">
                            <input
                              id="password-field"
                              type="password"
                              className="form-control w-100 input-box"
                              name="password"
                              value="secret"
                            />
                            <span
                              toggle="#password-field"
                              className="fa fa-eye icon field-icon toggle-password"
                            ></span>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="custom-control custom-checkbox mb-3">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="Trainerreg"
                              name="example1"
                            />
                            <label
                              className="custom-control-label terms-text "
                              htmlFor="Trainerreg"
                            >
                              <span className="pl-2">
                                I agree with the{" "}
                                <Link
                                  to="/termsandcondition"
                                  className="gray-text"
                                >
                                  Terms & Conditions
                                </Link>
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <button className="loginbtn mt-4">Next</button>
                        </div>
                        <div className="col-md-12 text-center mt-3">
                          <span>
                            Already Registered ?{" "}
                            <Link to="/client/login" className="linktext">
                              Login
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`row  ${IsNext === 1 ? "" : "d-none"}`}>
            <div className="col-md-6 p-0">
              <div className="loginimg">
                <div className="col-md-12 logoblock">
                  <h3>Register Yourself</h3>
                </div>
              </div>
            </div>
            <div className={`col-md-6 p-0`}>
              <div className="loginbox regyourself">
                <div className="login-form">
                  {dataLoader &&
                    <div className="loading">
                      <div className="mainloader"></div>
                    </div>
                  }
                  <div className="col-12 text-center">
                    <a className="login-logo" href="/">
                      <img src="/img/common-health.png" alt="logo" />
                    </a>
                    <h4 className="log-text">Register Yourself</h4>
                  </div>
                  <div className="row mb-2">
                    <div className="col-xl-4">
                      <div className="Profile">
                        <div className="avatar-upload">
                          <div className="avatar-edit">
                            <input
                              type="file"
                              onChange={OnFileChange}
                              id="imageUpload"
                              accept=".png, .jpg, .jpeg"
                            />
                            {isEdit ? (<>
                              <button
                                className="img-cancel"
                                onClick={OnFileDiacard}
                              >
                                <i className="fas fa-times"></i>
                              </button>
                            </>)
                              : (<>
                                <label htmlFor="imageUpload">
                                  <i className="fas fa-camera"></i>
                                </label>
                              </>)}
                          </div>
                          <div className="avatar-preview">
                            <div
                              id="imagePreview"
                              style={{
                                backgroundImage: `url(${profileimagepreview})`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 mt-2">
                      <div className="col-md-12 mb-3">
                        <label>First Name</label> <span className="text-danger font-weight-bold">*</span>
                        <input
                          onChange={(e) => handleInputs(e)}
                          value={user.firstname}
                          name="firstname"
                          type="text"
                          ref={userNameRef}
                          className="w-100 input-box"
                          placeholder="First Name"
                          onFocus={true}
                        />
                        <div className="text-danger">{errors.firstname}</div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <label>Last Name</label> <span className="text-danger font-weight-bold">*</span>
                        <input
                          onChange={(e) => handleInputs(e)}
                          value={user.lastname}
                          name="lastname"
                          ref={userLastnameRef}
                          type="text"
                          className="w-100 input-box"
                          placeholder="Last Name"
                        />
                        <div className="text-danger">{errors.lastname}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label>Email Address</label> <span className="text-danger font-weight-bold">*</span>
                    <input
                      onChange={(e) => handleInputs(e)}
                      value={user.email}
                      name="email"
                      type="text"
                      ref={userEmailRef}
                      className="w-100 input-box"
                      placeholder="Email address"
                    />
                    <div className="text-danger">{errors.email}</div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="position-relative">
                      <label>Password</label> <span className="text-danger font-weight-bold">*</span>
                      <input
                        onChange={(e) => handleInputs(e)}
                        value={user.password}
                        name="password"
                        ref={userPasswordRef}
                        type={isPwdHidden === true ? "password" : "text"}
                        className="w-100 input-box"
                        placeholder="Password"
                      />
                      <i
                        className={`fa fa-eye${isPwdHidden === false ? "" : "-slash"
                          } icon`}
                        onClick={() => setIsPwdHidden(!isPwdHidden)}
                      ></i>

                      <div className="text-danger">{errors.password}</div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="position-relative">
                      <label>Confirm Password</label> <span className="text-danger font-weight-bold">*</span>
                      <input
                        onChange={(e) => handleInputs(e)}
                        value={user.confirmpassword}
                        name="confirmpassword"
                        ref={userCoPasswordRef}
                        type={isCPwdHidden === true ? "password" : "text"}
                        className="w-100 input-box"
                        placeholder="Confirm password"
                      />
                      <i
                        className={`fa fa-eye${isCPwdHidden === false ? "" : "-slash"
                          } icon`}
                        onClick={() => setIsCPwdHidden(!isCPwdHidden)}
                      ></i>
                      <div className="text-danger">
                        {errors.confirmpassword}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <label>Phone Number</label> <span className="text-danger font-weight-bold">*</span>
                        <PhoneInput
                          country={"in"}
                          enableSearch={true}
                          countryCodeEditable={false}
                          name="phoneno"
                          inputProps={{ ref: userPhonenoRef }}
                          placeholder="Phone number"
                          onChange={(value, country) => {
                            const phone = value.replace(country.dialCode, "");
                            const numberLength = phoneNumberLength(country)
                            if (phone === "") {
                              setErrors((prevError) => ({ ...prevError, phoneno: "Please enter phone number" }));
                            }
                            else if (numberLength > phone.length) {
                              setMobileNumberValidation(false)
                              setErrors((prevError) => ({ ...prevError, phoneno: `Please enter valid phone number` }));
                            }
                            else {
                              setMobileNumberValidation(true);
                              setErrors((prevError) => ({ ...prevError, phoneno: "" }));
                            }
                            setUser((prev) => ({ ...prev, phoneno: phone }));
                            setUser((prev) => ({ ...prev, countryCode: country.countryCode }));
                            setUser((prev) => ({ ...prev, country: country.name }));
                            setUser((prev) => ({ ...prev, countryDialCode: `+${country.dialCode}` }));
                          }}
                        />

                        <div className="text-danger">{errors.phoneno}</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6">
                        <button
                          disabled={isButtonDisabled}
                          onClick={(e) => {
                            setIsNext(0);
                          }}
                          className="loginbtn my-4"
                        >

                          Back
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          disabled={isButtonDisabled}
                          onClick={(e) => {
                            Next(e);
                          }}
                          className="loginbtn mt-4"
                        >

                          Next
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`row ${IsNext === 2 ? "" : "d-none"}`}>
            <div className="col-md-6 p-0">
              <div className="loginimg">
                <div className="col-md-12 logoblock">
                  <h3>Register Yourself</h3>
                </div>
              </div>
            </div>

            <div className={`col-md-6 p-0 `}>
              <div className="loginbox regyourself">
                <div className="login-form">
                  {dataLoader &&
                    <div className="loading">
                      <div className="mainloader"></div>
                    </div>
                  }
                  <div className="col-12 text-center">
                    <a className="login-logo" href="/">
                      <img src="/img/common-health.png" alt="logo" />
                    </a>
                    <h4 className="log-text">Register Yourself</h4>
                  </div>

                  <div className="col-md-12 mb-3">
                    <label>Age</label> <span className="text-danger font-weight-bold">*</span>
                    <input
                      onChange={(e) => handleInputs(e)}
                      value={user.age}
                      name="age"
                      ref={userAgeRef}
                      type="number"
                      className="w-100 input-box"
                      placeholder="Age"
                    />
                    <div className="text-danger">{errors.age}</div>
                  </div>

                  <div className="col-md-12">
                    <label>Gender <span className="text-danger font-weight-bold">*</span></label>
                    <div className="genderbox">
                      <div className="row">
                        <div className="col-4">
                          <div className="genderblock">
                            <div className="custom-control custom-checkbox mb-3 ">
                              <input
                                onChange={(e) => handleInputs(e)}
                                value="Male"
                                type="radio"
                                checked={user.gender === "Male"}
                                className="custom-control-input"
                                id="Male"
                                name="gender"
                              />
                              <label
                                className="custom-control-label w-100"
                                htmlFor="Male"
                              ></label>
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="genderblock genderblock1">
                            <div className="custom-control custom-checkbox mb-3 ">
                              <input
                                onChange={(e) => handleInputs(e)}
                                value="Female"
                                type="radio"
                                checked={user.gender === "Female"}
                                className="custom-control-input"
                                id="Female"
                                name="gender"
                              />
                              <label
                                className="custom-control-label w-100"
                                htmlFor="Female"
                              ></label>
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="genderblock genderblock2">
                            <div className="custom-control custom-checkbox mb-3 ">
                              <input
                                onChange={(e) => handleInputs(e)}
                                value="Non-Binary"
                                type="radio"
                                checked={user.gender === "Non-Binary"}
                                className="custom-control-input"
                                id="Non-Binary"
                                name="gender"
                              />
                              <label
                                className="custom-control-label w-100"
                                htmlFor="Non-Binary"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-danger">{errors.gender}</div>
                  </div>

                  <div className="col-md-12 col-12 mb-4 mt-2">
                    <div className="d-flex justify-content-between mb-4">
                      <label className="pt-2 mb-0">Height <span className="text-danger font-weight-bold">*</span></label>
                      <div className="feet-metter d-flex">
                        <input
                          onChange={(e) => handleInputs(e)}
                          checked={user.heightisfeet === true}
                          value={true}
                          ref={userHeightRef}
                          type="radio"
                          name="heightisfeet"
                          id="radio1"
                        />
                        <label className="input_lbl mr-2" htmlFor="radio1">
                          Feet
                        </label>
                        <input
                          onChange={(e) => handleInputs(e)}
                          checked={user.heightisfeet === false}
                          value={false}
                          type="radio"
                          ref={userHeightRef}
                          name="heightisfeet"
                          id="radio2"
                        />
                        <label className="input_lbl" htmlFor="radio2">
                          Meters
                        </label>
                      </div>
                    </div>
                    <div className="scale-hw">
                      <ul key="heightul" id="heightul" className="scroll mb-0">
                        {user.heightisfeet === true
                          ? range(4, 8, 1).map((number, index) => {

                            return (<>
                              <span
                                key={Math.random().toString(36).substr(2, 9)}
                              >
                                <li
                                  onClick={(e) =>
                                    SelectHeight(e, number - 1 + 0.1)
                                  }
                                  title={number - 1 + 0.1}
                                  className={`list-item ${(activeClass.number === number - 1 + 0.1) && activeClass.class}`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) =>
                                    SelectHeight(e, number - 1 + 0.2)
                                  }
                                  title={number - 1 + 0.2}
                                  className={`list-item ${(activeClass.number === number - 1 + 0.2) && activeClass.class}`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) =>
                                    SelectHeight(e, number - 1 + 0.3)
                                  }
                                  title={number - 1 + 0.3}
                                  className={`list-item ${(activeClass.number === number - 1 + 0.3) && activeClass.class}`}

                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) =>
                                    SelectHeight(e, number - 1 + 0.4)
                                  }
                                  title={number - 1 + 0.4}
                                  className={`list-item ${(activeClass.number === number - 1 + 0.4) && activeClass.class}`}

                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) =>
                                    SelectHeight(e, number - 1 + 0.5)
                                  }
                                  title={number - 1 + 0.5}
                                  className={`list-item ${(activeClass.number === number - 1 + 0.5) && activeClass.class}`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) =>
                                    SelectHeight(e, number - 1 + 0.6)
                                  }
                                  title={number - 1 + 0.6}
                                  className={`list-item ${(activeClass.number === number - 1 + 0.6) && activeClass.class}`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) =>
                                    SelectHeight(e, number - 1 + 0.7)
                                  }
                                  title={number - 1 + 0.7}
                                  className={`list-item ${(activeClass.number === number - 1 + 0.7) && activeClass.class}`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) =>
                                    SelectHeight(e, number - 1 + 0.8)
                                  }
                                  title={number - 1 + 0.8}
                                  className={`list-item ${(activeClass.number === number - 1 + 0.8) && activeClass.class}`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) =>
                                    SelectHeight(e, number - 1 + 0.9)
                                  }
                                  title={number - 1 + 0.9}
                                  className={`list-item ${(activeClass.number === number - 1 + 0.9) && activeClass.class}`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectHeight(e, number)}
                                  title={number}
                                  className={`list-item ${(activeClass.number === number) && activeClass.class}`}
                                >
                                  <span className="line-height"></span>
                                  <label>{number}</label>
                                </li>
                              </span>
                            </>)
                          })
                          : range(100, 240, 10).map((number, index) => (
                            <span
                              key={Math.random().toString(36).substr(2, 9)}
                            >
                              <li
                                onClick={(e) => SelectHeight(e, number - 9)}
                                title={number - 9}
                                className={`list-item ${(activeClass.number === number - 9) && activeClass.class}`}

                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectHeight(e, number - 8)}
                                title={number - 8}
                                className={`list-item ${(activeClass.number === number - 8) && activeClass.class}`}

                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectHeight(e, number - 7)}
                                title={number - 7}
                                className={`list-item ${(activeClass.number === number - 7) && activeClass.class}`}

                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectHeight(e, number - 6)}
                                title={number - 6}
                                className={`list-item ${(activeClass.number === number - 6) && activeClass.class}`}

                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectHeight(e, number - 5)}
                                title={number - 5}
                                className={`list-item ${(activeClass.number === number - 5) && activeClass.class}`}

                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectHeight(e, number - 4)}
                                title={number - 4}
                                className={`list-item ${(activeClass.number === number - 4) && activeClass.class}`}

                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectHeight(e, number - 3)}
                                title={number - 3}
                                className={`list-item ${(activeClass.number === number - 3) && activeClass.class}`}

                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectHeight(e, number - 2)}
                                title={number - 2}
                                className={`list-item ${(activeClass.number === number - 2) && activeClass.class}`}

                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectHeight(e, number - 1)}
                                title={number - 1}
                                className={`list-item ${(activeClass.number === number - 1) && activeClass.class}`}

                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectHeight(e, number)}
                                title={number}
                                className={`list-item ${(activeClass.number === number) && activeClass.class}`}

                              >
                                <span className="line-height"></span>
                                <label>{number}</label>
                              </li>
                            </span>
                          ))}
                      </ul>
                    </div>
                    {errors.height &&
                      <div className="text-danger">{errors.height}</div>
                    }
                  </div>
                  <div className="col-md-12 col-12 mb-4">
                    <div className="d-flex justify-content-between mb-4">
                      <label className="pt-2 mb-0">Weight<span className="text-danger font-weight-bold">*</span> </label>
                      <div className="feet-metter d-flex">
                        <input
                          onChange={(e) => handleInputs(e)}
                          checked={user.weightiskg === true}
                          value={true}
                          type="radio"
                          ref={userWeightRef}
                          name="weightiskg"
                          id="radio3"
                        />
                        <label className="input_lbl mr-2" htmlFor="radio3">
                          Kg
                        </label>
                        <input
                          onChange={(e) => handleInputs(e)}
                          checked={user.weightiskg === false}
                          value={false}
                          type="radio"
                          ref={userWeightRef}
                          name="weightiskg"
                          id="radio4"
                        />
                        <label className="input_lbl" htmlFor="radio4">
                          Pound
                        </label>
                      </div>
                    </div>
                    <div className="scale-hw">
                      <ul id="weightul" className="scroll ">
                        {user.weightiskg === true
                          ? range(30, 210, 10).map((number, index) => (
                            <span
                              key={Math.random().toString(36).substr(2, 9)}
                            >
                              <li
                                onClick={(e) => SelectWeight(e, number - 9)}
                                title={number - 9}
                                className={`list-item ${(selectActiv.number === number - 9) && selectActiv.class}`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 8)}
                                title={number - 8}
                                className={`list-item ${(selectActiv.number === number - 8) && selectActiv.class}`}

                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 7)}
                                title={number - 7}
                                className={`list-item ${(selectActiv.number === number - 7) && selectActiv.class}`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 6)}
                                title={number - 6}
                                className={`list-item ${(selectActiv.number === number - 6) && selectActiv.class}`}

                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 5)}
                                title={number - 5}
                                className={`list-item ${(selectActiv.number === number - 5) && selectActiv.class}`}

                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 4)}
                                title={number - 4}
                                className={`list-item ${(selectActiv.number === number - 4) && selectActiv.class}`}

                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 3)}
                                title={number - 3}
                                className={`list-item ${(selectActiv.number === number - 3) && selectActiv.class}`}

                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 2)}
                                title={number - 2}
                                className={`list-item ${(selectActiv.number === number - 2) && selectActiv.class}`}

                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 1)}
                                title={number - 1}
                                className={`list-item ${(selectActiv.number === number - 1) && selectActiv.class}`}

                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number)}
                                title={number}
                                className={`list-item ${(selectActiv.number === number) && selectActiv.class}`}
                              >
                                <span className="line-height"></span>
                                <label>{number}</label>
                              </li>
                            </span>
                          ))
                          : range(30, 300, 5).map((number, index) => (
                            <span
                              key={Math.random().toString(36).substr(2, 9)}
                            >
                              <li
                                onClick={(e) => SelectWeight(e, number - 4)}
                                title={number - 4}
                                className={`list-item ${(selectActiv.number === number - 4) && selectActiv.class}`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 3)}
                                title={number - 3}
                                className={`list-item ${(selectActiv.number === number - 3) && selectActiv.class}`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 2)}
                                title={number - 2}
                                className={`list-item ${(selectActiv.number === number - 2) && selectActiv.class}`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number - 1)}
                                title={number - 1}
                                className={`list-item ${(selectActiv.number === number - 1) && selectActiv.class}`}
                              >
                                <span className="line-height subline"></span>
                              </li>
                              <li
                                onClick={(e) => SelectWeight(e, number)}
                                title={number}
                                className={`list-item ${(selectActiv.number === number) && selectActiv.class}`}
                              >
                                <span className="line-height"></span>
                                <label>{number}</label>
                              </li>
                            </span>
                          ))}
                      </ul>
                    </div>
                    {errors.weight &&
                      <div className="text-danger">{errors.weight}</div>
                    }
                  </div>

                  <div className="col-md-12 mb-3">
                    <label>Equipment Available</label>
                    <input
                      onChange={(e) => handleInputs(e)}
                      value={user.equipmentavailable}
                      name="equipmentavailable"
                      type="text"
                      className="input-box w-100"
                      placeholder="Please write available equipment or type 'none' - you'll still get sweaty either way!"
                    />
                    <div className="text-danger">
                      {errors.equipmentavailable}
                    </div>
                  </div>

                  <div className="col-md-12 mb-4">
                    <label>Fitness Goals</label>
                    <div className="fitnessgoal">
                      <div className="cat action wrapper">
                        {fitnessgoal?.map((ele, index) => {
                          return (<>
                            <label className="option option-1">
                              <input
                                type="checkbox"
                                value={ele?.name}
                                ref={userFitnesRef}
                                name={`fitnessgoals${index + 1}`}
                                onChange={(e) => handleFitnessGoal(e)}
                                id={`fitnessgoals${index + 1}`}
                              />
                              <span>{ele?.name}</span>
                            </label>
                          </>)
                        })}
                      </div>
                    </div>
                    <div className="text-danger">{errors.fitnessgoal}</div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <label>Others</label>
                    <textarea
                      onChange={(e) => handleInputs(e)}
                      value={user.otherfitnessgoals}
                      name="otherfitnessgoals"
                      className="w-100"
                      placeholder="Please Write Any Other Fitness Goals."
                    ></textarea>
                  </div>

                  <div className="col-md-12 mb-3">
                    <label>Injuries/Health Issues</label> <span className="text-danger font-weight-bold">*</span>
                    <textarea
                      onChange={(e) => handleInputs(e)}
                      value={user.injuriesorhelthissues}
                      name="injuriesorhelthissues"
                      className="w-100"
                      ref={userInjuriesRef}
                      placeholder="Let us know of any injuries or health issues you have you have or write 'none' if you have no concerns."
                    ></textarea>
                    {errors.injuriesorhelthissues &&
                      <div className="text-danger">
                        {errors.injuriesorhelthissues}
                      </div>
                    }
                  </div>
                  <div className="col-md-12">
                    <ul className="list-inline togglebox">
                      <li>
                        Email Notification
                        <span className="float-right">
                          <label className="switch">
                            <input
                              onChange={(e) => handleInputs(e)}
                              name="emailnotifications"
                              defaultChecked="true"
                              type="checkbox"
                              value={user.emailnotifications}
                              className="default"
                            />
                            <span className="slider round"></span>
                          </label>
                        </span>
                      </li>
                      <li>
                        Mailing List
                        <span className="float-right">
                          <label className="switch">
                            <input
                              onChange={(e) => handleInputs(e)}
                              value={!user.maillinglist}
                              name="maillinglist"
                              defaultChecked="false"
                              type="checkbox"
                              className="default"
                            />
                            <span className="slider round"></span>
                          </label>
                        </span>
                      </li>
                      <li>
                        Text Notifications
                        <span className="float-right">
                          <label className="switch">
                            <input
                              onChange={(e) => handleInputs(e)}
                              value={!user.textnotifications}
                              name="textnotifications"
                              defaultChecked="false"
                              type="checkbox"
                              className="default"
                            />
                            <span className="slider round"></span>
                          </label>
                        </span>
                      </li>
                      <li>
                        Web Notifications
                        <span className="float-right">
                          <label className="switch">
                            <input
                              onChange={(e) => handleInputs(e)}
                              value={!user.webnotifications}
                              name="webnotifications"
                              defaultChecked="false"
                              type="checkbox"
                              className="default"
                            />
                            <span className="slider round"></span>
                          </label>
                        </span>
                      </li>
                      <li>
                        Mobile Notifications
                        <span className="float-right">
                          <label className="switch">
                            <input
                              onChange={(e) => handleInputs(e)}
                              value={!user.emailnotifications}
                              name="mobilenotifications"
                              defaultChecked="false"
                              type="checkbox"
                              className="default"
                            />
                            <span className="slider round"></span>
                          </label>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-12">
                    <div className="custom-control custom-checkbox my-2 regi-chk">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="term"
                        ref={isAgreeInputRefSe}
                        name="example2"
                        onChange={(e) => {
                          handleTAndC(e);
                        }}
                      />
                      <label
                        className="custom-control-label terms-text"
                        htmlFor="term"
                      >
                        <span className="pl-2">
                          I agree to the{" "}
                          <Link
                            to="/public-view/termsandconditionapp"
                            target="_blank"
                            className="gray-text"
                            rel="noopener noreferrer"
                          >
                            Terms & Conditions
                          </Link>
                        </span>
                      </label>
                    </div>
                    <div className="text-danger">{errors.isAgree}</div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6">
                        <button
                          disabled={isButtonDisabled}
                          onClick={(e) => {
                            setIsNext(1);
                          }}
                          className="loginbtn mt-md-4 mb-4"
                        >
                          Back
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          disabled={isButtonDisabled}
                          onClick={(e) => {
                            PostSignUp(e);
                          }}
                          className="loginbtn my-4"
                        >
                          Register
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
