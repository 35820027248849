/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import $ from "jquery";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { PlaidLink } from "react-plaid-link";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { apiUrl } from "../../environment/environment";
// import InputMask from 'react-input-mask';
// import { setCountryMask } from '../Others/Utils.js';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { phoneNumberLength } from "../utility/Utility";

function SignUp() {
  const [token, setToken] = useState(null);
  // const stripeUid = "acct_1KnsVtBI5VuvGZzp";

  const navigate = useNavigate();
  /* const token = sessionStorage.getItem('token');
    if (token !== null) {
        navigate('/');
        window.location.reload();
    } */
  const [IsNext, setIsNext] = useState(0);
  const [isHidden, setIsHidden] = useState(true);
  const [isCHidden, setIsCHidden] = useState(true);
  const [workoutList, setWorkOutList] = useState([]);
  const [filterWorkout, setFilterWorkout] = useState([]);
  const [tags, settags] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [mobileNumberValidation, setMobileNumberValidation] = useState(false);

  // const [mask, setMask] = useState("");
  const ref = useRef(null);

  useEffect(() => {
    async function createLinkToken() {
      axios
        .post(`${apiUrl}/exchange/getLinkToken`, {}, {})
        .then(function (response) {
          if (response.data.status === 1) {
            setToken(response.data.link_token);
          }
          return true;
        })
        .catch(function (error) { });
    }
    createLinkToken();
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token !== null) {
      navigate("/");
      window.location.reload();
    }

    getTypeOfWorkout();
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      user.trainingstyle = "";
      setUser(user);
      setFilterWorkout([]);
    }
  };

  const onSuccess = useCallback(
    (publicToken, metadata) => {
      setDataLoader(true)
      // document.querySelector(".loading").classList.remove("d-none");
      // Exchange a public token for an access one.

      async function exchangeTokens() {
        var obj = {
          public_token: publicToken,
          account_id: metadata.account_id,
        };
        axios
          .post(`${apiUrl}/exchange/receivePublicToken`, obj, {})
          .then(function (response) {
            // document.querySelector(".loading").classList.remove("d-none");
            if (response.data.status === 1) {
              // Bank account update
              var bankobj = {
                id: sessionStorage.getItem("trainerId"),
                stripebanktoken:
                  response.data.result.stripebankaccountToken
                    .stripe_bank_account_token,
                accountInfo: response.data.result.accountResponse,
              };

              axios
                .post(
                  `${apiUrl}/trainer/accountinfo/saveaccountinfo`,
                  bankobj,
                  {}
                )
                .then(function (response) {
                  // document.querySelector(".loading").classList.add("d-none");
                  if (response.data.status === 1) {
                    setTimeout(() => {
                      sessionStorage.removeItem("trainerId");
                      setDataLoader(false)
                      navigate("/signupsuccess", { state: "trainer" });
                    }, 500);
                  } else {
                    setDataLoader(false)
                    swal({
                      title: "Error!",
                      text: response.data.message,
                      icon: "error",
                      button: true,
                    });
                  }
                })
                .catch(function (error) {
                  // document.querySelector(".loading").classList.add("d-none");
                  setDataLoader(false)
                });
            }
            // document.querySelector(".loading").classList.add("d-none");
            return true;
          })
          .catch(function (error) {
            // document.querySelector(".loading").classList.add("d-none");
          });
      }
      exchangeTokens();
    },
    [navigate]
  );

  async function getTypeOfWorkout() {
    // document.querySelector(".loading").classList.remove("d-none");
    await axios
      .get(`${apiUrl}/trainer/trainer/getworkoutcategory`, {}, {})
      .then(function (response) {
        // document.querySelector(".loading").classList.add("d-none");
        if (response.data.status === 1) {
          setWorkOutList(response.data.result);
          /* setFilterWorkout(response.data.result); */
        } else {
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        // document.querySelector(".loading").classList.add("d-none");
      });
  }

  const ProfileImage_URL = "/img/Small-no-img.png";

  const [profileimagepreview, setProfileImagePreview] = useState(ProfileImage_URL);
  const [profileimage, setProfileImage] = useState();
  // const [secondStepNext, setSecondStepNext] = useState({});
  const [firstStepNext, setFirstStepNext] = useState({
    name: "",
    email: "",
    password: "",
    confirmpassword: "",
    // isAgree: false,
  });
  const [emailnotifications, setEmailnotifications] = useState(true);
  const [maillinglist, setMaillinglist] = useState(true);
  const [textnotifications, setTextnotifications] = useState(true);
  const [notifications, setNotifications] = useState(true);

  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmpassword: "",
    phoneno: "",
    countryDialCode: "",
    countryCode: "",
    country: "",
    gender: "Male",
    aboutus: "",
    trainingstyle: "",
    quote: "",
    experience: 0,
    qualifications: "",
    specialitys: "",
    introduction: "",
    certifications: "",
    emailnotifications: emailnotifications,
    maillinglist: maillinglist,
    textnotifications: textnotifications,
  });
  const [qualificationslist, setQualifications] = useState([]);
  const [qualificationshtmllist, setHtmlQualifications] = useState([]);
  const [qualification, setQualification] = useState("");
  const [imagesQuaPathList, setImagesQuaPathList] = useState([]);

  const [IsTAndC, setIsTAndC] = useState(false);
  const [specialityslist, setSpecialitys] = useState([]);
  const [speciality, setSpeciality] = useState("");

  const [certificationslist, setCertifications] = useState([]);
  const [certificationshtmllist, setHtmlCertifications] = useState([]);
  const [certification, setCertification] = useState("");
  const [cerimagesPathList, setCerImagesPathList] = useState([]);


  const [errors, setErrors] = useState({});
  const [expVal, setExp] = useState(1);
  const [dataLoader, setDataLoader] = useState(false);

  const [isEdit, setIsEdit] = useState(false);


  const OnFileChange = (event) => {
    const file_size = event.target.files[0].size;
    if (file_size > 1048000) {
      setProfileImagePreview(ProfileImage_URL);
      alert("File size more than 1 MB. File size must under 1MB !");
      event.preventDefault();
    } else {
      const fileReader = new window.FileReader();
      const file = event.target.files[0];
      setProfileImage(event.target.files[0]);

      fileReader.onload = (fileLoad) => {
        const { result } = fileLoad.target;
        setProfileImagePreview(result);
        setIsEdit(true)
      };

      fileReader.readAsDataURL(file);
    }
  };

  const OnFileDiacard = () => {
    setIsEdit(false)
    setProfileImagePreview(ProfileImage_URL)
    setProfileImage(ProfileImage_URL)
  }

  const plusminus = (e) => {
    var count;
    if (e === "-") {

      count = expVal;
      count = parseInt(count) - 1;
      count = count < 1 ? 1 : count;
    } else {
      count = expVal;
      if (count > 49) {
        count = 50;
      } else {
        count++;
      }
    }
    if (count === "") {
      setErrors((prevErrors) => ({ ...prevErrors, experience: 'Please enter experience' }));
    } else if (count < 1) {
      setErrors((prevErrors) => ({ ...prevErrors, experience: 'Experience must be at least 1 year' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, experience: '' }));
    }
    setExp(count);
    return false;
  };


  const handleExp = (e) => {
    const newValue = e.target.value.replace(/\D/g, "");

    if (newValue === "") {
      setErrors((prevErrors) => ({ ...prevErrors, experience: 'Please enter experience' }));
    } else if (newValue < 1) {
      setErrors((prevErrors) => ({ ...prevErrors, experience: 'Experience must be at least 1 year' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, experience: '' }));
    }

    if (newValue?.length <= 50) {
      setExp(newValue);
    }
  };

  const [setQulif, setSetQulif] = useState([])
  const [qulifsPrev, setQulifsPrev] = useState([])
  const [qulifModelData, setqulifModelData] = useState()
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  let storedname
  let origname

  qulifsPrev.find((x) => storedname = x?.orignalName)
  setQulif.find((x) => origname = x?.orignalName)


  const isShowModel = (eleData) => {
    handleShow()
    setqulifModelData(eleData)
  }

  const handleQualification = (e) => {
    const { value } = e.target
    setQualification(value);

    if (value.length === 0 && value === "") {
      setErrors((prevErrors) => ({ ...prevErrors, note: 'Please enter qulification details.' }));
      setErrors((prevErrors) => ({ ...prevErrors, image: 'Please upload image here' }));
    } else if (value.length < 3 || value.length > 50) {
      setErrors((prevErrors) => ({ ...prevErrors, note: 'Qulification details length minimum 3 letters and maximum 50' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, note: '' }));
      setErrors((prevErrors) => ({ ...prevErrors, image: '' }));
      setErrors((prevErrors) => ({ ...prevErrors, already: '' }));
    }

  };

  const OnQualificationFileChange = (event, value) => {
    const file_size = event.target.files[0].size;
    if (file_size > 1048000) {
      setImagesQuaPathList([]);
      alert("File size more than 1 MB. File size must under 1MB !");
      event.preventDefault();
    } else {
      const fileReader = new window.FileReader();
      const file = event.target.files[0];

      fileReader.onload = (fileLoad) => {

        let errormsg = {}
        let isValid = true
        if (qulifsPrev.find((x) => x?.name === qualification)) {
          errormsg.already = "This qualification already exist!";
          isValid = false;
        }
        if (qualification === "" && qulifsPrev) {
          errormsg.already = "First enter qulification details and select qualification image";
          isValid = false;
        }
        if (imagesQuaPathList?.length > 0 && (imagesQuaPathList.find((v) => v?.name === file?.name))) {
          errormsg.already = "This qualification image already exist!";
          isValid = false;
        }
        if (isValid) {
          const objeUrl = window.URL.createObjectURL(file)
          setQulif.push({ uri: file, path: objeUrl, orignalName: file.name, name: value })
          setSetQulif(setQulif)

          var maxId = imagesQuaPathList.length > 0 ? imagesQuaPathList.length + 1 : 1;

          imagesQuaPathList.push({
            uri: file,
            name: file.name,
            type: file.type,
            id: maxId,
            qualification: value,
          });

        }
        setErrors(errormsg)
        setImagesQuaPathList(imagesQuaPathList);
      };
      fileReader.readAsDataURL(file);
    }
  };

  const handleQualifications = (e) => {

    e.preventDefault();
    var errormsg = {};
    var isValid = true;

    if (qualification === "") {
      errormsg.note = "Please enter qulification details.";
      isValid = false;
    } else if (qualification.length < 3) {
      errormsg.note = "Qulification details length minimum 3 letters .";
      isValid = false;
    }

    if (setQulif?.length === 0) {
      errormsg.image = "Please upload image here";
      isValid = false;
    }

    if (qulifsPrev.find((x) => x?.name === qualification)) {
      errormsg.already = "This qualification already exist!";
      isValid = false;
    }

    if (imagesQuaPathList.length > 0 && imagesQuaPathList.find((x) => x?.name) === setQulif.find((x) => x?.orignalName)) {
      errormsg.already = "This qualification image already exists!";
      isValid = false;
    }

    if (qualificationslist?.length > 0 && qualificationslist.find((x) => x === qualification)) {
      errormsg.already = "This qualification already exists!";
      isValid = false;
    }

    if (qulifsPrev?.length > 0 && setQulif.length > 0 && origname === storedname) {
      setSetQulif([]);
      errormsg.already = "This qualification image already exists!";
      isValid = false;
    }


    setErrors(errormsg);
    if (isValid) {
      qualificationslist.push(qualification);
      setQualifications(qualificationslist);
      setQualification("");


      qulifsPrev.push(setQulif[0])
      setSetQulif([])

      const updatedList = qualificationslist.map((listItems, index) => {

        return (
          <div
            id={`qualification${index}`}
            className="control-group input-group"
            style={{ marginTop: "10px" }}
          >
            <div className="d-flex col-md-6 pl-0">
              <div name="qualifications" className="removeinput">
                {listItems}
              </div>
              <div className="input-group-btn position-relative">
                <button
                  onClick={() => {
                    removeQualifications(index);
                  }}
                  className="remove position-absolute"
                  type="button"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
            <div className="col-md-6 position-relative">
              <img src="/img/file.png" className="Fileicon" alt="File" />
            </div>
          </div>
        );
      });
      setHtmlQualifications(updatedList);
    }
  };

  const removeQualifications = (index) => {

    Swal.fire({
      text: "Are you sure you want to removed this qulification ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {

      if (result.isConfirmed) {

        qualificationslist.splice(index, 1);
        var qualificationslst = qualificationslist;
        setQualifications(qualificationslst);

        imagesQuaPathList.splice(index, 1);
        var imgQualificationslst = imagesQuaPathList;
        setImagesQuaPathList(imgQualificationslst);

        qulifsPrev.splice(index, 1)
        var prevQuliPath = qulifsPrev;
        setQulifsPrev(prevQuliPath)


        if (qulifsPrev.length === 0 && imagesQuaPathList.length === 0 && index === 0) {
          setErrors((prevErrors) => ({ ...prevErrors, image: 'Please enter qulification detail and upload image here' }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, image: '' }));
        }

        const updatedList = qualificationslist.map((listItems, index) => {
          return (
            <div
              key={"qualification" + index}
              className="control-group input-group"
              style={{ marginTop: "10px" }}
            >
              <div className="d-flex">
                <div name="qualifications" className="removeinput">
                  {listItems}
                </div>
                <div className="input-group-btn position-relative">
                  <button
                    onClick={() => {
                      removeQualifications(index);
                    }}
                    className="remove position-absolute"
                    type="button"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          );
        });
        setHtmlQualifications(updatedList);

      }
    })
  };


  const handleSpeciality = (e) => {
    const { value } = e.target
    setSpeciality(value);
    if (value.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, speciality: 'Please enter speciality.' }));
    } else if ((value.length < 6)) {
      setErrors((prevErrors) => ({ ...prevErrors, speciality: 'Please enter minimum 6 letters.' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, speciality: '' }));
    }
  };

  const handleSpecialitys = (e) => {
    if (speciality === "") {
      setErrors((prevErrors) => ({ ...prevErrors, speciality: 'Please enter speciality.' }));
    } else if ((speciality.length < 6)) {
      setErrors((prevErrors) => ({ ...prevErrors, speciality: 'Please enter minimum 6 letters.' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, speciality: '' }));
      specialityslist.push(speciality);
      setSpecialitys(specialityslist);
      setSpeciality("");
    }
  };

  const removeSpecialitys = (index) => {
    if (index === 0) {
      setErrors((prevErrors) => ({ ...prevErrors, speciality: 'Please enter speciality.' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, speciality: '' }));
    }
    specialityslist.splice(index, 1);
    var specialityslst = [...specialityslist];

    setSpecialitys(specialityslst);
    setSpeciality("");
  };

  const [setCertif, setSetCertif] = useState([])
  const [certifPrev, setCertifPrev] = useState([])
  const [certificationData, setCertificationData] = useState([])
  const [show1, setShow1] = useState(false)
  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);

  let storedCertiName
  let originCertiName

  certifPrev.find((x) => storedCertiName = x?.orignalName)
  setCertif.find((x) => originCertiName = x.orignalName)


  const isShowModel1 = (eleData) => {
    handleShow1()
    setCertificationData(eleData)
  }

  const onCertificationFileChange = (event, value) => {
    const file_size = event.target.files[0].size;
    if (file_size > 1048000) {
      setCerImagesPathList([]);
      alert("File size more than 1 MB. File size must under 1MB !");
      event.preventDefault();
    } else {
      const fileReader = new window.FileReader();
      const file = event.target.files[0];

      fileReader.onload = (fileLoad) => {
        //const { result } = fileLoad.target;
        let errormsg = {}
        let isValid = true

        if (certifPrev.find((x) => x?.name === certification)) {
          errormsg.certificationImg = "This certification alredy exist!";
          isValid = false;
        }

        if (certification === "" && certifPrev) {
          errormsg.certificationImg = "First enter certification details and select certification image";
          isValid = false;
        }

        if (cerimagesPathList?.length > 0 && cerimagesPathList.find((x) => x?.name === file?.name)) {
          errormsg.certificationImg = "This certification image already exist!";
          isValid = false;
        }

        if (isValid) {
          const objeUrl = window.URL.createObjectURL(file)
          setCertif.push({ uri: file, path: objeUrl, orignalName: file.name, name: value })
          setSetCertif(setCertif)

          var maxId =
            cerimagesPathList.length > 0 ? cerimagesPathList.length + 1 : 1;
          cerimagesPathList.push({
            uri: file,
            name: file.name,
            type: file.type,
            id: maxId,
            certification: value,
          });

        }
        setErrors(errormsg)
        setCerImagesPathList(cerimagesPathList);
      };
      fileReader.readAsDataURL(file);
    }
  };


  const handleCertification = (e) => {
    const { value } = e.target
    setCertification(e.target.value);

    if (value.length === 0 && value === "") {
      setErrors((prevErrors) => ({ ...prevErrors, certificationslist: 'Please enter certifications' }));
      setErrors((prevErrors) => ({ ...prevErrors, certificationImg: 'Please upload image here' }));
    } else if (value.length < 3 || value.length > 50) {
      setErrors((prevErrors) => ({ ...prevErrors, certificationslist: 'Certifications details length minimum 3 letters and maximum 50' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, certificationslist: '' }));
      setErrors((prevErrors) => ({ ...prevErrors, certificationImg: '' }));
      setErrors((prevErrors) => ({ ...prevErrors, certificationAlready: '' }));
    }
  };

  const handleCertifications = (e) => {
    e.preventDefault();
    var errormsg = {};
    var isValid = true;

    if (certification === "") {
      errormsg.certificationslist = "Please enter certifications details.";
      isValid = false;
    } else if (certification.length < 3) {
      // Certifications details length minimum 3 letters
      errormsg.certificationslist = "Certifications details length minimum 3 letters.";
      isValid = false;
    }

    if (setCertif?.length === 0) {
      errormsg.certificationImg = "Please upload image here";
      isValid = false;
    }

    if (certifPrev.find((x) => x?.name === certification)) {
      errormsg.certificationAlready = "This certification already exist!"
      isValid = false;
    }

    if (certificationslist?.length > 0 && certificationslist.find((x) => x === certification)) {
      errormsg.certificationAlready = "This certification already exist!"
      isValid = false;
    }

    if (cerimagesPathList?.length > 0 && cerimagesPathList.find((x) => x?.name) === setCertif.find((x) => x?.orignalName)) {
      errormsg.certificationAlready = "This certification image already exist!"
      isValid = false;
    }

    if (certifPrev.length > 0 && setCertif.length > 0 && storedCertiName === originCertiName) {
      setSetCertif([])
      errormsg.certificationAlready = "This certification image already exist!"
      isValid = false;
    }



    setErrors(errormsg);
    if (isValid) {
      certificationslist.push(certification);
      setCertifications(certificationslist);
      setCertification("");

      certifPrev.push(setCertif[0])
      setSetCertif([])

      const updatedCList = certificationslist.map((ClistItems, index) => {
        return (
          <div
            key={"certification" + index}
            className="control-group input-group"
            style={{ marginTop: "10px" }}
          >
            <div className="d-flex">
              <div name="certifications" className="removeinput">
                {ClistItems}
              </div>
              <div className="input-group-btn position-relative">
                <button
                  onClick={() => {
                    removeCertifications(index);
                  }}
                  className="remove position-absolute"
                  type="button"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        );
      });
      setHtmlCertifications(updatedCList);
    }

  };

  const removeCertifications = (index) => {

    Swal.fire({
      text: "Are you sure you want to removed this certification ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {

      if (result.isConfirmed) {
        certificationslist.splice(index, 1);
        var certificationslst = certificationslist;
        setCertifications(certificationslst);

        cerimagesPathList.splice(index, 1)
        var certificationpath = cerimagesPathList
        setCerImagesPathList(certificationpath)

        certifPrev.splice(index, 1)
        var certifecpre = certifPrev
        setCertifPrev(certifecpre)

        if (certifPrev.length === 0 && setCertif.length === 0 && index === 0) {
          setErrors((prevError) => ({ ...prevError, certificationslist: 'First enter certification details and select certification image' }))
        } else {
          setErrors((prevError) => ({ ...prevError, certificationslist: '' }))
        }

        const updatedCList = certificationslist.map((ClistItems, index) => {
          return (
            <div
              key={"certification" + index}
              className="control-group input-group"
              style={{ marginTop: "10px" }}
            >
              <div className="d-flex">
                <div name="certifications" className="removeinput">
                  {ClistItems}
                </div>
                <div className="input-group-btn position-relative">
                  <button
                    onClick={() => {
                      removeCertifications(index);
                    }}
                    className="remove position-absolute"
                    type="button"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          );
        });
        setHtmlCertifications(updatedCList);
      }
    })

  };


  const handleTAndC = (e) => {
    const isChecked = e.currentTarget.checked;
    setIsTAndC(isChecked);

    if (!isChecked) {
      setErrors((prevErrors) => ({ ...prevErrors, isAgree: 'Please check Terms & Conditions' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, isAgree: '' }));
    }
  };

  const tagChange = (e) => {
    setUser({ ...user, trainingstyle: e.target.value });
    handleSuggestion();

    // if (!e.target.value.trim()) {
    //   setErrors((prevErrors) => ({ ...prevErrors, trainingstyle: 'Please select training style' }));
    // } else {
    //   setErrors((prevErrors) => ({ ...prevErrors, trainingstyle: '' }));
    // }
  };


  const handleKeyDown = (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
    }
    handleSuggestion();
  };

  const handleSuggestion = () => {
    const suggestFilterInput = workoutList.filter((suggest) =>
      suggest.name.toLowerCase().includes(user.trainingstyle?.toLowerCase())
    );

    const suggestFilterTags = suggestFilterInput.filter(
      (suggest) => !tags.includes(suggest.name)
    );
    setFilterWorkout(suggestFilterTags);

    if (suggestFilterInput.length === 0) {
      setErrors((prevErrors) => ({ ...prevErrors, trainingstyle: 'Please select training style.' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, trainingstyle: '' }));
    }
  };


  const handleDelete = (i) => {
    const newTags = tags.filter((tag, j) => i !== j);
    settags(newTags);
    setFilterWorkout([]);
    const trainingStyle = newTags.join(' '); // Assuming tags are space-separated

    if (trainingStyle.trim() === '') {
      setErrors((prevErrors) => ({ ...prevErrors, trainingstyle: 'Please select training style.' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, trainingstyle: '' }));
    }
  };

  const AddTags = (text) => {
    settags([...tags, text]);
    user.trainingstyle = "";
    setUser(user);
    setFilterWorkout([]);
  };

  const nameInputRef = useRef()
  const emailInputRef = useRef()
  const passwordInputRef = useRef()
  const confirmPasswordInputRef = useRef()

  const isRequiredField = (fieldName) => {
    return ['name', 'email', 'confirmpassword', 'password'].includes(fieldName);
  };

  const handleChange = (objName, val) => {
    let reg_email = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

    setFirstStepNext((prevState) => ({ ...prevState, [objName]: val }));

    if (val.trim() === '' && isRequiredField(objName)) {
      setErrors((prevError) => ({ ...prevError, [objName]: `Please enter ${objName}.` }));
    } else if (objName === 'name' && (val.length < 3 || val.length > 50)) {
      setErrors((prevError) => ({ ...prevError, name: "Please enter minimum 3 letters or maximum 50" }));
    } else if (objName === 'email' && val.trim() !== '' && !reg_email.test(val)) {
      setErrors((prevError) => ({ ...prevError, email: 'Please enter valid email' }));
    } else if (objName === 'password' && (val.length < 6)) {
      setErrors((prevError) => ({ ...prevError, password: "Password length must be at least 6 characters" }));
    } else if (objName === 'confirmpassword' && (val.length < 6)) {
      setErrors((prevError) => ({ ...prevError, confirmpassword: "Confirm password length must be at least 6 characters" }));
    } else if (objName === 'confirmpassword' && val !== firstStepNext.password) {
      setErrors((prevError) => ({ ...prevError, confirmpassword: "Password and confirm password doesn't match" }));
    } else {
      setErrors((prevError) => ({ ...prevError, [objName]: '' }));
    }
  };

  const firstStepSignUpNext = (e) => {
    e.preventDefault();
    let isSubmit = true;
    var errormsg = {};
    let reg_email = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

    if (firstStepNext.name === "") {
      errormsg.name = "Please enter name.";
      isSubmit = false;
    } else if ((firstStepNext.name.length >= 3) === false || (firstStepNext.name.length <= 50) === false) {
      errormsg.name = "Please enter minimum 3 letters or maximum 50";
      isSubmit = false;
    }

    if (firstStepNext.password === "") {
      errormsg.password = "Please enter password";
      isSubmit = false;
    } else if (firstStepNext.password.length < 6) {
      errormsg.password = "Password length must be at least 6 characters";
      isSubmit = false;
    }

    if (firstStepNext.confirmpassword === "") {
      errormsg.confirmpassword = "Please enter confirm password";
      isSubmit = false;
    } else if (firstStepNext.confirmpassword.length < 6) {
      errormsg.confirmpassword = "Confirm password length must be at least 6 characters";
      isSubmit = false;
    } else if (firstStepNext.password !== firstStepNext.confirmpassword) {
      errormsg.confirmpassword = "Password and confirm password doesn't match";
      isSubmit = false;
    }

    if (firstStepNext.email === "") {
      errormsg.email = "Please enter email";
      isSubmit = false;
    } else if (reg_email.test(firstStepNext.email) === false) {
      errormsg.email = "Please enter valid email";
      isSubmit = false;
    }

    if (Object.keys(errormsg).length > 0) {

      if (errormsg.name) {
        nameInputRef.current.focus();
      } else if (errormsg.email) {
        emailInputRef.current.focus();
      } else if (errormsg.password) {
        passwordInputRef.current.focus();
      } else if (errormsg.confirmpassword) {
        confirmPasswordInputRef.current.focus();
      }
    }

    setErrors(errormsg);
    if (isSubmit) {
      setIsButtonDisabled(true);
      setDataLoader(true);
      setTimeout(() => {
        let nameList = (firstStepNext.name || "").split(" ");
        if (nameList.length > 1) {
          user.lastname = nameList[nameList.length - 1];
          nameList.pop();
        }
        user.firstname = nameList.map((s) => s).join(" ");
        user.email = firstStepNext.email;
        user.password = firstStepNext.password;
        setIsButtonDisabled(false);
        setDataLoader(false);
        setIsNext(1);
        sessionStorage.setItem("firstStepNext", JSON.stringify(firstStepNext));
      }, 1000);
    }
  };

  // const validatePhoneNumber = (phoneNumber, countryCode) => {
  //   const formattedPhoneNumber = phoneNumber.replace(/[^0-9]/g, ""); // Remove non-numeric characters

  //   if (countryCode === "in") {
  //     return formattedPhoneNumber.length === 10;
  //   } else if (countryCode === "no" || countryCode === "am") {
  //     return formattedPhoneNumber.length === 8;
  //   } else if (countryCode === "al") {
  //     return formattedPhoneNumber.length === 12;
  //   } else if (countryCode === "hu" || countryCode === "id" || countryCode === "af") {
  //     return formattedPhoneNumber.length === 13;
  //   } else if (countryCode === "is") {
  //     return formattedPhoneNumber.length === 7;
  //   } else {
  //     return formattedPhoneNumber.length === 10;
  //   }
  // };

  const nameInputRefSe = useRef()
  const lastnameInputRefSe = useRef()
  const phonenoInputRefSe = useRef()
  const aboutusInputRefSe = useRef()
  const trainingstyleInputRefSe = useRef()
  const quoteInputRefSe = useRef()
  const experienceInputRefSe = useRef()
  const qualificationsInputRefSe = useRef()
  const certificationImgInputRefSe = useRef()
  const introductionInputRefSe = useRef()
  const specialityInputRefSe = useRef()
  const isAgreeInputRefSe = useRef()

  const isRequiredSecond = (fieldName) => {
    return ['firstname', 'lastname', 'email', 'phoneno', 'gender', 'password', 'aboutus', 'quote', 'qualification', 'speciality', 'introduction', 'certification'].includes(fieldName)
  }

  const [setsecondaryData, setsetsecondaryData] = useState({
    lastname: "",
    phoneno: "",
    country: "",
    countryDialCode: "",
    countryCode: "",
    aboutus: "",
    quote: "",
  })

  const [thirdStep, setThirdStep] = useState({ introduction: '' })

  const handleInputs = (e) => {
    let errormsg = {};
    const { name, value } = e.target

    if (name === "emailnotifications") {
      errormsg.firstname = "";
      setEmailnotifications(!emailnotifications);
    }
    if (name === "maillinglist") {
      setMaillinglist(!maillinglist);
    }
    if (name === "textnotifications") {
      setTextnotifications(!textnotifications);
    }
    if (name === "notifications") {
      setNotifications(!notifications);
    }
    setUser({ ...user, [name]: value });
    setsetsecondaryData({ ...setsecondaryData, [name]: value })
    setThirdStep({ ...thirdStep, [name]: value })

    if (value.trim() === "" && isRequiredSecond(name)) {
      let errorMessage = '';
      if (name === "firstname") {
        errorMessage = "Please enter first name";
      } else if (name === 'lastname') {
        errorMessage = "Please enter last name";
      } else if (name === 'aboutus') {
        errorMessage = "Please enter aboutus";
      } else if (name === 'quote') {
        errorMessage = "Please enter quote";
      } else if (name === 'introduction') {
        errorMessage = "Please enter introduction";
      } else if (name === "isAgree" && !IsTAndC) {
        errorMessage = "Please check Terms & Conditions";
      }


      setErrors((prevError) => ({ ...prevError, [name]: errorMessage }));
    } else if (((name === 'firstname') || (name === 'lastname')) && (value.length < 3 || value.length > 50)) {
      setErrors((prevError) => ({ ...prevError, [name]: "Please enter minimum 3 letters or maximum 50" }));
    } else if ((name === 'aboutus') && (value.length < 3)) {
      setErrors((prevError) => ({ ...prevError, [name]: "Please enter minimum 3 letters" }));
    } else if ((name === 'quote') && (value.length < 3)) {
      setErrors((prevError) => ({ ...prevError, [name]: "Please enter minimum 3 letters" }));
    } else if ((name === 'introduction') && (value.length < 3)) {
      setErrors((prevError) => ({ ...prevError, [name]: "Please enter minimum 3 letters" }));
    } else {
      setErrors((prevError) => ({ ...prevError, [name]: '' }));
    }


  };

  const secondStepSignUpNext = async (e) => {
    e.preventDefault();
    let isValid = true;
    var errormsg = {};

    if (user.firstname === "") {
      errormsg.firstname = "Please enter first name";
      isValid = false;
    } else if ((user.firstname.length >= 3) === false || (user.firstname.length <= 50) === false) {
      errormsg.firstname = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    }

    if (setsecondaryData.lastname === "") {
      errormsg.lastname = "Please enter last name";
      isValid = false;
    } else if ((setsecondaryData.lastname.length >= 3) === false || (setsecondaryData.lastname.length <= 50) === false) {
      errormsg.lastname = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    }

    if (setsecondaryData.phoneno === "") {
      errormsg.phoneno = "Please enter phone number";
      isValid = false;
    } else if (!mobileNumberValidation) {
      errormsg.phoneno = "Please enter valid phone number";
      isValid = false;
    }

    if (setsecondaryData.aboutus === "") {
      errormsg.aboutus = "Please enter about us description";
      isValid = false;
    } else if ((setsecondaryData.aboutus.length >= 3) === false) {
      errormsg.aboutus = "About us length must be at least 3 characters";
      isValid = false;
    }

    if (expVal === "") {
      errormsg.experience = "Please enter experience";
      isValid = false;
    } else if (expVal < 1) {
      errormsg.experience = "Experience must be at least 1 year";
      isValid = false;
    }

    if (tags.length === 0) {
      errormsg.trainingstyle = "Please select training style";
      isValid = false;
    }
    if (setsecondaryData.quote === "") {
      errormsg.quote = "Please enter quote description";
      isValid = false;
    } else if ((setsecondaryData.quote.length >= 3) === false) {
      errormsg.quote = "Quote length must be at least 3 characters";;
      isValid = false;
    }

    if (qualificationslist.length === 0 && imagesQuaPathList.length === 0) {
      errormsg.note = "First enter qulification details and select qualification image";
      isValid = false;
    }
    if (qulifsPrev.length === 0) {
      errormsg.note = "Please upload qualifications";
      isValid = false;
    }

    if (Object.keys(errormsg).length > 0) {
      if (errormsg.firstname) {
        nameInputRefSe.current.focus()
      } else if (errormsg.lastname) {
        lastnameInputRefSe.current.focus()
      } else if (errormsg.phoneno) {
        phonenoInputRefSe.current.focus()
      } else if (errormsg.aboutus) {
        aboutusInputRefSe.current.focus()
      } else if (errormsg.trainingstyle) {
        trainingstyleInputRefSe.current.focus()
      } else if (errormsg.quote) {
        quoteInputRefSe.current.focus()
      } else if (errormsg.experience) {
        experienceInputRefSe.current.focus()
      } else if (errormsg.note) {
        qualificationsInputRefSe.current.focus()
      }
    }

    setErrors(errormsg);
    if (isValid) {
      setIsButtonDisabled(true);
      setDataLoader(true);
      setTimeout(() => {
        user.experience = parseInt(expVal); // parseInt(document.getElementById("experience").value);
        user.qualifications = qualificationslist.toString();
        user.lastname = setsecondaryData.lastname;
        user.aboutus = setsecondaryData.aboutus;
        user.quote = setsecondaryData.quote;
        user.phoneno = setsecondaryData.phoneno;
        user.country = setsecondaryData.country;
        user.countryCode = setsecondaryData.countryCode
        user.countryDialCode = setsecondaryData.countryDialCode;
        setIsButtonDisabled(false);
        setDataLoader(false);
        setIsNext(2);
        sessionStorage.setItem("secoundStepNext", JSON.stringify(user));
      }, 1000);
    }
  };

  const PostSignUp = async (e) => {
    e.preventDefault();
    let isValid = true;
    var errormsg = {};

    if (user.firstname === "") {
      errormsg.firstname = "Please enter First Name";
      isValid = false;
    }
    if (user.lastname === "") {
      errormsg.lastname = "Please enter Last Name";
      isValid = false;
    }
    if (user.phoneno === "") {
      errormsg.phoneno = "Please enter phone Number";
      isValid = false;
    }
    if (user.aboutus === "") {
      errormsg.aboutus = "Please enter about us description";
      isValid = false;
    }
    if (tags.length === 0) {
      errormsg.trainingstyle = "Please enter training style";
      isValid = false;
    }
    if (user.quote === "") {
      errormsg.quote = "Please enter quote description";
      isValid = false;
    }
    if (qualificationslist.length === 0) {
      errormsg.qualification = "Please enter qualifications";
      isValid = false;
    }


    if (thirdStep.introduction === "") {
      errormsg.introduction = "Please enter introduction";
      isValid = false;
    } else if (thirdStep.introduction.length < 3) {
      errormsg.introduction = "Please enter minimum 3 letters";
      isValid = false;
    }


    if (certificationslist.length === 0 && cerimagesPathList.length === 0) {
      errormsg.certificationslist = "First enter certification details and select certification image";
      isValid = false;
    }
    if (certifPrev.length === 0) {
      errormsg.certificationslist = "Please upload certification";
      isValid = false;
    }

    if (specialityslist.length === 0) {
      errormsg.speciality = "Please enter specialitys";
      isValid = false;
    }

    if (!IsTAndC) {
      errormsg.isAgree = "Please Check Terms & Conditions";
      isValid = false;
    }

    setErrors(errormsg);

    if (Object.keys(errormsg).length > 0) {
      if (errormsg.firstname) {
        nameInputRefSe.current.focus()
      } else if (errormsg.lastname) {
        lastnameInputRefSe.current.focus()
      } else if (errormsg.phoneno) {
        phonenoInputRefSe.current.focus()
      } else if (errormsg.aboutus) {
        aboutusInputRefSe.current.focus()
      } else if (errormsg.trainingstyle) {
        trainingstyleInputRefSe.current.focus()
      } else if (errormsg.quote) {
        quoteInputRefSe.current.focus()
      } else if (errormsg.qualification) {
        qualificationsInputRefSe.current.focus()
      } else if (errormsg.speciality) {
        specialityInputRefSe.current.focus()
      } else if (errormsg.introduction) {
        introductionInputRefSe.current.focus()
      } else if (errormsg.certificationslist) {
        certificationImgInputRefSe.current.focus()
      } else if (errormsg.isAgree) {
        isAgreeInputRefSe.current.focus()
      }

    }

    user.introduction = thirdStep.introduction

    if (isValid === true) {
      let getFirstStepNextObj = JSON.parse(
        sessionStorage.getItem("firstStepNext")
      );
      let qualificationsObj = {
        name: qualificationslist.join(","),
        path: imagesQuaPathList,
      };
      let certificationsObj = {
        name: certificationslist.join(","),
        path: cerimagesPathList,
      };

      const formData = new FormData();
      formData.append("profile", profileimage);
      formData.append("firstname", user.firstname);
      formData.append("lastname", user.lastname);
      formData.append("email", getFirstStepNextObj.email);
      formData.append("password", getFirstStepNextObj.password);
      formData.append("confirmpassword", getFirstStepNextObj.confirmpassword);
      formData.append("phoneno", user.phoneno);
      formData.append("countryDialCode", user.countryDialCode);
      formData.append("gender", user.gender);
      formData.append("aboutus", user.aboutus);
      formData.append("trainingstyle", tags.join(","));
      formData.append("quote", user.quote);
      formData.append("experience", parseInt(expVal)); //parseInt(document.getElementById("experience").value));
      formData.append("specialitys", specialityslist.toString());
      formData.append("introduction", user.introduction);

      formData.append("emailnotifications", emailnotifications);
      formData.append("maillinglist", maillinglist);
      formData.append("textnotifications", textnotifications);
      formData.append("notifications", notifications);
      formData.append("country", user.country);
      formData.append("countryCode", user.countryCode);

      setIsButtonDisabled(true);
      setDataLoader(true);
      setIsEdit(false)

      axios
        .post(`${apiUrl}/trainer/account/register`, formData)
        .then((response) => {
          if (response.data.status === 1) {
            sessionStorage.setItem("trainerId", response.data.result._id);

            if (qualificationsObj != null || certificationsObj != null) {
              updateTrainerPara(
                qualificationsObj,
                certificationsObj,
                response.data.result._id
              );
            } else {
              $("#plaidbutton > button").click();
              setIsButtonDisabled(false);
              setDataLoader(false);
            }
          } else {
            setIsButtonDisabled(false);
            setDataLoader(false);
            // document.querySelector(".loading").classList.add("d-none");
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
          }
        })
        .catch(function (error) {
          // document.querySelector(".loading").classList.add("d-none");
        });
    }
  };

  const updateTrainerPara = (qualificationsObj, certificationsObj, tid) => {
    var form_data = new FormData();
    for (var key in qualificationsObj?.path) {
      form_data.append(
        qualificationsObj?.path[key].name,
        qualificationsObj?.path[key].uri
      );
    }
    for (var key1 in certificationsObj?.path) {
      form_data.append(
        certificationsObj?.path[key1].name,
        certificationsObj?.path[key1].uri
      );
    }
    form_data.append("id", tid);
    form_data.append("qualifications", JSON.stringify(qualificationsObj));
    form_data.append("certifications", JSON.stringify(certificationsObj));

    axios
      .post(`${apiUrl}/trainer/account/updateTrainerPara`, form_data)
      .then((response) => {
        if (response.data.status === 1) {
          $("#add_bank_modal").click();
          setIsButtonDisabled(false);
          setDataLoader(false);
        } else {
          setIsButtonDisabled(false);
          setDataLoader(false);

          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
          setIsButtonDisabled(false);
          setDataLoader(false);
        }
      })
      .catch(function (error) {
        setIsButtonDisabled(false);
        setDataLoader(false);
      });
  };

  return (
    <>
      {
        <div id="plaidbutton">
          <PlaidLink
            style={{ display: "none" }}
            token={token}
            onSuccess={onSuccess}
          >
            Connect a bank account
          </PlaidLink>
        </div>
      }
      {
        <>
          <button
            id="add_bank_modal"
            type="button"
            className="btn btn-primary d-none"
            data-toggle="modal"
            data-target="#add_bank"
          ></button>
          <div
            className="modal fade"
            id="add_bank"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content p-4">
                <div className="modal-body">
                  <h2 className="text-center">
                    <i className="far fa-check-circle check-i m-0"></i>
                  </h2>
                  <h4 className="text-center my-4 font-weight-bold">
                    Registered Successfully
                  </h4>
                  <div className="d-sm-flex">
                    <button
                      type="button"
                      onClick={() => $("#plaidbutton > button").click()}
                      className="training_btn bg-transparent text-primary my-3 pb-2 mr-sm-3"
                      data-toggle="modal"
                      data-dismiss="modal" /* data-target="#plaidbutton" */
                    >
                      Add Bank
                    </button>
                    <button
                      type="button"
                      onClick={() =>
                        navigate("/signupsuccess", { state: "trainer" })
                      }
                      className="training_btn my-3"
                      data-dismiss="modal"
                    >
                      Skip Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }

      <div className="container-fluid" ref={ref}>
        <div className="commonbox" ref={ref}>
          <div className={`row ${IsNext === 0 ? "" : "d-none"}`}>
            <div className="col-md-6 p-0">
              <div className="loginimg">
                <div className="col-md-12 logoblock">
                  <h3>Everyday is a Fresh Start.</h3>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div className="loginbox registerbox">
                <div className="login-form">
                  {dataLoader && (
                    <div className="loading">
                      <div className="mainloader"></div>
                    </div>
                  )}
                  <div className="col-12 text-center">
                    <a className="login-logo" href="/">
                      <img src="/img/common-health.png" alt="logo" />
                    </a>
                    <h4 className="log-text">
                      Let's get you started on this fitness journey
                    </h4>
                  </div>
                  <ul
                    className="nav nav-tabs col-lg-8 col-md-10 col-sm-8 col-11 mx-auto"
                    role="tablist"
                  >
                    <li className="nav-item col-md-6 col-6 w-100 p-0">
                      <div
                        className="nav-link text-center"
                        onClick={() => {
                          navigate("/clientsignup");
                        }}
                        data-toggle="tab"
                      >
                        MEMBER
                      </div>
                    </li>
                    <li className="nav-item col-md-6 col-6 w-100 p-0">
                      <div
                        className="nav-link text-center active"
                        data-toggle="tab"
                        onClick={() => {
                          navigate("/trainer/login");
                        }}
                      >
                        TRAINER
                      </div>
                    </li>
                  </ul>

                  <div className="tab-content">
                    <div
                      id="TRAINER"
                      className="container tab-pane fade active"
                    >
                      <div className="row my-4">
                        <div className="col-md-12 mb-3">
                          <label>First Name</label>{" "}
                          <span className="text-danger font-weight-bold">
                            *
                          </span>
                          <input
                            type="text"
                            className="w-100 input-box"
                            placeholder="First Name"
                            ref={nameInputRef}
                            value={firstStepNext.name}
                            onChange={(e) => {
                              handleChange("name", e.target.value);
                            }}
                          />
                          <div className="text-danger">{errors.name}</div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <label>Email</label>{" "}
                          <span className="text-danger font-weight-bold">
                            *
                          </span>
                          <input
                            type="email"
                            className="w-100 input-box"
                            placeholder="Email"
                            ref={emailInputRef}
                            value={firstStepNext.email}
                            onChange={(e) => {
                              handleChange("email", e.target.value);
                            }}
                          />
                          <div className="text-danger">{errors.email}</div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="position-relative">
                            <label>Password</label>{" "}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                            <input
                              id="password-field"
                              onChange={(e) => {
                                handleChange("password", e.target.value);
                              }}
                              value={firstStepNext.password}
                              name="password"
                              ref={passwordInputRef}
                              type={isHidden === true ? "password" : "text"}
                              className="w-100 input-box"
                              placeholder="Password"
                            />
                            <span
                              toggle="#password-field"
                              className={`fa fa-eye${isHidden === false ? "" : "-slash"
                                } icon field-icon toggle-password`}
                              onClick={() => setIsHidden(!isHidden)}
                            ></span>
                          </div>
                          <div className="text-danger">{errors.password}</div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="position-relative">
                            <label>Confirm Password</label>{" "}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                            <input
                              id="confirmpassword-field"
                              onChange={(e) => {
                                handleChange("confirmpassword", e.target.value);
                              }}
                              value={firstStepNext.confirmpassword}
                              name="password"
                              ref={confirmPasswordInputRef}
                              type={isCHidden === true ? "password" : "text"}
                              className="w-100 input-box"
                              placeholder="Confirm Password"
                            />
                            <span
                              toggle="#confirmpassword-field"
                              className={`fa fa-eye${isCHidden === false ? "" : "-slash"
                                } icon field-icon toggle-password`}
                              onClick={() => setIsCHidden(!isCHidden)}
                            ></span>
                            <div className="text-danger">
                              {errors.confirmpassword}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <button
                            className="loginbtn mt-3"
                            disabled={isButtonDisabled}
                            onClick={(e) => {
                              firstStepSignUpNext(e);
                            }}
                          >
                            Next
                            {/* {buttonLoader ? (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </>
                            ) : (
                              ""
                            )} */}
                          </button>
                          {/* <Link to="/Home/Registeryourself" className="loginbtn mt-4">Next</Link> */}
                        </div>
                        <div className="col-md-12 text-center mt-3">
                          <span className="text-login">
                            Already Registered ?{" "}
                            <Link to="/trainer/login" className="linktext">
                              Login
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`row ${IsNext === 1 ? "" : "d-none"}`}>
            <div className="col-md-6 p-0">
              <div className="loginimg">
                <div className="overlay"></div>
                <div className="col-md-12 logoblock">
                  <h3>Register Yourself</h3>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div className="loginbox regyourself">
                <div className="login-form">
                  {dataLoader && (
                    <div className="loading">
                      <div className="mainloader"></div>
                    </div>
                  )}
                  <div className="col-12 text-center">
                    <a className="login-logo" href="/">
                      <img src="/img/common-health.png" alt="logo" />
                    </a>
                    <h4 className="log-text">Register Yourself</h4>
                  </div>

                  <div>

                  </div>

                  <div className="row">
                    <div className="col-lg-4">
                      <div className="Profile">
                        <div className="avatar-upload">
                          <div className="avatar-edit">
                            <input
                              type="file"
                              onChange={OnFileChange}
                              id="imageUpload"
                              accept=".png, .jpg, .jpeg"
                            />
                            {isEdit ? (<>
                              <button
                                className="img-cancel"
                                onClick={OnFileDiacard}
                              >
                                <i className="fas fa-times"></i>
                              </button>
                            </>) : (<>
                              <label htmlFor="imageUpload">
                                <i className="fas fa-camera"></i>
                              </label>
                            </>)}
                          </div>
                          <div className="avatar-preview">
                            <div
                              id="imagePreview"
                              style={{
                                backgroundImage: `url(${profileimagepreview})`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-8 mt-2 pl-xl-1">
                      <div className="col-md-12 mb-3">
                        <label>First Name</label>{" "}
                        <span className="text-danger font-weight-bold">*</span>
                        <input
                          onChange={(e) => handleInputs(e)}
                          value={user.firstname}
                          name="firstname"
                          ref={nameInputRefSe}
                          disabled
                          type="text"
                          className="w-100 input-box"
                          placeholder="First Name"
                        />
                        <div className="text-danger">{errors.firstname}</div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <label>Last Name</label>{" "}
                        <span className="text-danger font-weight-bold">*</span>
                        <input
                          onChange={(e) => handleInputs(e)}
                          // value={user.lastname}
                          value={setsecondaryData.lastname}
                          ref={lastnameInputRefSe}
                          name="lastname"
                          type="text"
                          className="w-100 input-box"
                          placeholder="Last Name"
                        />
                        <div className="text-danger">{errors.lastname}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row mx-0">
                    <div className="col-12 col-md-12 mb-3 mt-md-2">
                      <label>Phone Number</label>{" "}
                      <span className="text-danger font-weight-bold">*</span>
                      <PhoneInput
                        country={"in"}
                        name="phoneno"
                        enableSearch={true}
                        countryCodeEditable={false}
                        inputProps={{ ref: phonenoInputRefSe }}
                        onChange={(value, country) => {
                          const phone = value.replace(country.dialCode, "");
                          const numberLength = phoneNumberLength(country)
                          if (phone === "") {
                            setErrors((prevError) => ({ ...prevError, phoneno: "Please enter phone number" }));
                          } 
                          // else if ((!validatePhoneNumber(phone, country.countryCode))) {
                          //   setErrors((prevError) => ({ ...prevError, phoneno: "Invalid phone number for the selected country" }));
                          // }
                          else if (numberLength > phone.length) {
                            setMobileNumberValidation(false)
                            setErrors((prevError) => ({ ...prevError, phoneno: `Please enter valid phone number` }));
                          }
                           else {
                            setMobileNumberValidation(true)
                            setErrors((prevError) => ({ ...prevError, phoneno: "" }));
                          }
                          setUser((prev) => ({ ...prev, phoneno: phone }));
                          setUser((prev) => ({ ...prev, country: country.name }));
                          setUser((prev) => ({ ...prev, countryCode: country.countryCode }));
                          setUser((prev) => ({ ...prev, countryDialCode: `+${country.dialCode}` }));

                          setsetsecondaryData((prev) => ({ ...prev, phoneno: phone }));
                          setsetsecondaryData((prev) => ({ ...prev, country: country.name }));
                          setsetsecondaryData((prev) => ({ ...prev, countryCode: country.countryCode }));
                          setsetsecondaryData((prev) => ({ ...prev, countryDialCode: `+${country.dialCode}` }));
                        }}
                      />
                      <div className="text-danger">{errors.phoneno}</div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label>
                      Gender{" "}
                      <span className="text-danger font-weight-bold">*</span>
                    </label>
                    <div className="genderbox mb-4">
                      <div className="row">
                        <div className="col-4">
                          <div className="genderblock">
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                onChange={(e) => handleInputs(e)}
                                value="Male"
                                type="radio"
                                defaultChecked={user.gender === "Male"}
                                className="custom-control-input"
                                id="Male"
                                name="gender"
                              />
                              <label
                                className="custom-control-label w-100"
                                htmlFor="Male"
                              ></label>
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="genderblock genderblock1">
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                onChange={(e) => handleInputs(e)}
                                value="Female"
                                type="radio"
                                defaultChecked={user.gender === "Female"}
                                className="custom-control-input"
                                id="Female"
                                name="gender"
                              />
                              <label
                                className="custom-control-label w-100"
                                htmlFor="Female"
                              ></label>
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="genderblock genderblock2">
                            <div className="custom-control custom-checkbox mb-3">
                              <input
                                onChange={(e) => handleInputs(e)}
                                value="Non-Binary"
                                type="radio"
                                defaultChecked={user.gender === "Non-Binary"}
                                className="custom-control-input"
                                id="Non-Binary"
                                name="gender"
                              />
                              <label
                                className="custom-control-label w-100"
                                htmlFor="Non-Binary"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-danger">{errors.gender}</div>
                  </div>
                  <div className="col-md-12 mb-4">
                    <label>
                      About{" "}
                      <span className="text-danger font-weight-bold">*</span>
                    </label>
                    <textarea
                      onChange={(e) => handleInputs(e)}
                      // value={user.aboutus}
                      value={setsecondaryData.aboutus}
                      ref={aboutusInputRefSe}
                      name="aboutus"
                      className="w-100"
                      placeholder="About"
                    ></textarea>
                    <div className="text-danger">{errors.aboutus}</div>
                  </div>
                  <div className="col-md-12 mb-4">
                    <div className="tags-content">
                      <label>
                        Describe your training style
                        <span className="text-danger font-weight-bold">*</span>
                      </label>
                      <div className="tags-input">
                        <input type="text" className="w-100 "
                          ref={trainingstyleInputRefSe}
                          value={user.trainingstyle}
                          onChange={(e) => {
                            tagChange(e);
                          }}
                          onClick={(e) => {
                            handleKeyDown(e);
                          }}
                          placeholder="Describe your training style"
                        />
                        {Boolean(filterWorkout.length) && (
                          <div className="tags-suggestions">
                            <span
                              className="float-right"
                              onClick={(e) => {
                                setFilterWorkout([]);
                              }}
                            >
                              <button className="btn btn-sm btn-danger">
                                X
                              </button>
                            </span>
                            {filterWorkout.map((suggest) => {
                              return (
                                <div
                                  className="suggestion-item"
                                  onClick={() => AddTags(suggest.name)}
                                >
                                  {suggest.name}
                                </div>
                              );
                            })}
                          </div>
                        )}

                        {tags.map((tag, i) => (
                          <div key={i} className="tag">
                            {tag}
                            <div
                              className="remove-tag"
                              onClick={() => handleDelete(i)}
                            >
                              ×
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="text-danger col-md-12 p-0 clr">{errors.trainingstyle}</div>
                  </div>
                  <div className="col-md-12 mb-4 clr">
                    <label>
                      Your favorite quote{" "}
                      <span className="text-danger font-weight-bold">*</span>{" "}
                    </label>
                    <textarea
                      onChange={(e) => handleInputs(e)}
                      // value={user.quote}
                      value={setsecondaryData.quote}
                      ref={quoteInputRefSe}
                      name="quote"
                      className="w-100"
                      placeholder="Your favorite quote"
                    ></textarea>
                    <div className="text-danger">{errors.quote}</div>
                  </div>
                  <div className="col-md-12">
                    <div className="row m-0">
                      <div className="col-md-12 p-0">
                        <div className="number">
                          <div className="row">
                            <div className="col-lg-6 pt-2">
                              <label>
                                Experience(in years){" "}
                                <span className="text-danger font-weight-bold">
                                  *
                                </span>
                                <div class="tooltip"><i class="fas fa-info-circle"></i>
                                  <span class="tooltiptext p-1">If the value is 1 or less than, then button will be disabled.</span>
                                </div>
                              </label>
                            </div>
                            <div className="col-lg-6">
                              <div className="pmclass">
                                {expVal < 1 ?
                                  <span
                                    title="-"
                                    className="minus"
                                  >
                                    -
                                  </span>
                                  :
                                  <span
                                    title="-"
                                    className="minus"
                                    onClick={() => plusminus("-")}
                                  >
                                    -
                                  </span>
                                }
                                <input
                                  type="text"
                                  name="experience"
                                  maxLength={2}
                                  value={expVal}
                                  ref={experienceInputRefSe}
                                  onChange={handleExp}
                                />
                                <span
                                  className="plus"
                                  title="+"
                                  onClick={() => plusminus("+")}
                                >
                                  +
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="text-danger">{errors.experience}</div>
                        </div>
                      </div>
                      <div className="col-md-10 col-10 mt-4">
                        <div className="row unused">
                          <div className="input-group control-group after-add-more position-relative">
                            <label className="">
                              Qualification Details
                              <span className="text-danger font-weight-bold">
                                *
                              </span>
                              <div class="tooltip"><i class="fas fa-info-circle"></i>
                                <span class="tooltiptext">Enter qualification details, Uploads document image from upload button and click on plus icon.</span>
                              </div>
                            </label>
                            <input
                              onChange={(e) => handleQualification(e)}
                              autoComplete={"off"}
                              type="text"
                              ref={qualificationsInputRefSe}
                              name="qualification"
                              value={qualification}
                              className="input-box w-100"
                              placeholder="Qualification Details"
                            />
                            <div className="input-group-btn">
                              <button
                                onClick={(e) => {
                                  handleQualifications(e);
                                }}
                                className="add-more position-absolute"
                                type="button"
                              >
                                <i className="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-2 mt-4 pt-4 pr-0 text-center">
                        <div className="uploadimg">
                          <input
                            type="file"
                            id="uploadQualification"
                            onChange={(e) => {
                              OnQualificationFileChange(e, qualification);
                            }}
                            accept=".png, .jpg, .jpeg, .pdf, .doc"
                          />
                          <label htmlFor="uploadQualification">
                            <i className="fas fa-upload upl-icon"></i>
                          </label>
                        </div>
                      </div>
                      <div className="text-danger">{errors.already}</div>
                      <div className="text-danger ms-2">{errors.note}</div>
                      <div className="text-danger ms-2">{errors.image}</div>
                    </div>
                  </div>
                  {qualificationslist.length > 0 &&
                    qualificationslist.map((listItems, index) => {
                      return (
                        <>
                          <div
                            key={`qualification${index}`}
                            className={`col-md-12 control-group${index}`}
                          >
                            <div className="row">
                              <div className="col-md-10 col-10">
                                <div className="copy">
                                  <div
                                    className=" input-group"
                                    style={{ marginTop: "10px" }}
                                    id={`qualification${index}`}
                                  >
                                    <div className="d-flex">
                                      <div
                                        name={"qualification" + index}
                                        className="removeinput"
                                      >
                                        {listItems}
                                      </div>
                                      <div className="input-group-btn position-relative">
                                        <button
                                          onClick={() => {
                                            removeQualifications(index);
                                          }}
                                          className="remove position-absolute"
                                          type="button"
                                        >
                                          <i className="fas fa-times"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-toggle="modal" data-target="#exampleModal" className="col-md-2 col-2 mt-2">
                                <div className="uploadimg">
                                  {qulifsPrev?.map((ele) => {
                                    if (listItems === ele?.name) {
                                      return (<>
                                        <div key={index} onClick={(e) => {
                                          isShowModel(ele);
                                        }}>
                                          <img
                                            title={ele?.name}
                                            src="/img/file.png"
                                            // src={ele?.path}
                                            alt="File"
                                            className="Fileicon"
                                            style={{ position: "unset" }}
                                          />
                                        </div>
                                      </>
                                      );
                                    }
                                    return <></>
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6">
                        <button
                          disabled={isButtonDisabled}
                          onClick={(e) => {
                            setIsNext(0);
                          }}
                          className="loginbtn mt-4 mb-2"
                        >
                          Back
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          disabled={isButtonDisabled}
                          className="loginbtn mt-4"
                          onClick={(e) => {
                            secondStepSignUpNext(e);
                          }}
                        >
                          Next

                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`row ${IsNext === 2 ? "" : "d-none"}`}>
            <div className="col-md-6 p-0">
              <div className="loginimg">
                <div className="col-md-12 logoblock">
                  <h3>Set Schedule</h3>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div className="loginbox regyourself">
                <div className="login-form">
                  {dataLoader && (
                    <div className="loading">
                      <div className="mainloader"></div>
                    </div>
                  )}
                  <div className="col-12 text-center">
                    <a className="login-logo" href="/">
                      <img src="/img/common-health.png" alt="logo" />
                    </a>
                    <h4 className="log-text">
                      Hey Set the schedule for yourself!
                    </h4>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="row unused">
                      <div className="input-group control-group after-add-more position-relative">
                        <div className="col-md-10 col-10">
                          <label className="">
                            Fitness Speciality{" "}
                            <span className="text-danger font-weight-bold">
                              *
                            </span>
                          </label>
                          <input
                            onChange={(e1) => handleSpeciality(e1)}
                            type="text"
                            autoComplete={"off"}
                            name="speciality"
                            ref={specialityInputRefSe}
                            value={speciality}
                            className="input-box w-100"
                            placeholder="Fitness Speciality"
                          />
                        </div>
                        <div className="col-md-2 col-2 d-flex p-0">
                          <div className="input-group-btn">
                            <button
                              onClick={(e1) => {
                                handleSpecialitys(e1);
                              }}
                              className="add-more addschdule"
                              type="button"
                            >
                              <i className="fas fa-plus"></i>
                            </button>
                          </div>
                        </div>
                        <div className="text-danger pl-3">
                          {errors.speciality}
                        </div>
                      </div>
                    </div>
                  </div>

                  {specialityslist.length > 0 &&
                    specialityslist.map((SlistItems, index) => {
                      return (
                        <div
                          key={`specialitys${index}`}
                          className={`col-md-12 hidespecialitys${index} `}
                        >
                          <div className="row">
                            <div className="col-md-10 col-10">
                              <div className="copy">
                                <div
                                  key={"speciality" + index}
                                  className="control-group input-group"
                                >
                                  <div className="col-md-10 col-10 p-0 ">
                                    <div
                                      name="specialitys"
                                      className="input-box mb-3 w-100"
                                    >
                                      {SlistItems}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex col-md-2 col-2 p-0">
                              <button
                                onClick={() => {
                                  removeSpecialitys(index);
                                }}
                                className="remove minus"
                                type="button"
                              >
                                <i className="fas fa-minus"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  <div className="col-md-12 mb-2">
                    <label>
                      Introduction{" "}
                      <span className="text-danger font-weight-bold">*</span>
                    </label>
                    <textarea
                      onChange={(e) => handleInputs(e)}
                      // value={user.introduction}
                      value={thirdStep.introduction}
                      name="introduction"
                      className="w-100"
                      ref={introductionInputRefSe}
                      placeholder="Introduction"
                    ></textarea>
                    <div className="text-danger">{errors.introduction}</div>
                  </div>

                  <div className="col-md-12">
                    <div className="row m-0">
                      <div className="col-md-10 col-10 mt-3">
                        <div className="row unused">
                          <div className="input-group control-group after-add-more position-relative">
                            <label>
                              Certifications Details
                              <span className="text-danger font-weight-bold">
                                *
                              </span>
                              <div class="tooltip"><i class="fas fa-info-circle"></i>
                                <span class="tooltiptext">Enter certifications detail, Uploads document image from upload button and click on plus icon.</span>
                              </div>
                            </label>
                            <input
                              onChange={(e2) => handleCertification(e2)}
                              type="text"
                              autoComplete={"off"}
                              name="certification"
                              value={certification}
                              ref={certificationImgInputRefSe}
                              className="input-box w-100 mb-3"
                              placeholder="Certifications"
                            />
                            <div className="input-group-btn">
                              <button
                                onClick={(e2) => {
                                  handleCertifications(e2);
                                }}
                                className="add-more position-absolute"
                                type="button"
                              >
                                <i className="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-2 mt-4 pt-3 pr-0 text-center">
                        <div className="uploadimg">
                          <input
                            type="file"
                            id="uploadCertifications"
                            onChange={(e) => {
                              onCertificationFileChange(e, certification);
                            }}
                            accept=".png, .jpg, .jpeg, .pdf, .doc"
                          />
                          <label htmlFor="uploadCertifications">
                            {/* <img src="/img/upload.png" alt='Upload' /> */}
                            <i className="fas fa-upload upl-icon"></i>
                          </label>
                        </div>
                      </div>
                      <div className="text-danger">
                        {errors.certificationAlready}
                      </div>
                      <div className="text-danger">
                        {errors.certificationslist}
                      </div>
                      <div className="text-danger">
                        {errors.certificationImg}
                      </div>
                    </div>
                  </div>
                  {certificationslist.length > 0 &&
                    certificationslist.map((ClistItems, index) => {
                      return (
                        <div
                          key={`certification${index}`}
                          className={`col-md-12 control-group${index}`}
                        >
                          <div className="row">
                            <div className="col-md-10 col-10">
                              <div className="copy">
                                <div
                                  key={"certification" + index}
                                  className="input-group"
                                  style={{ marginTop: "10px" }}
                                >
                                  <div className="d-flex">
                                    <div
                                      name="certifications"
                                      className="removeinput"
                                    >
                                      {ClistItems}
                                    </div>
                                    <div className="input-group-btn position-relative">
                                      <button
                                        onClick={() => {
                                          removeCertifications(index);
                                        }}
                                        className="remove position-absolute"
                                        type="button"
                                      >
                                        <i className="fas fa-times"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 col-2 mt-2 text-center">
                              <div className="uploadimg">
                                {certifPrev.map((ele, index) => {
                                  if (ClistItems && ele && ele?.name === ClistItems) {
                                    return (<>
                                      <div key={index} onClick={(e) => {
                                        isShowModel1(ele);
                                      }}>
                                        <label htmlFor="uploadQualification1">
                                          <img
                                            title={ele?.name}
                                            src="/img/file.png"
                                            alt="File"
                                            className="Fileicon"
                                            style={{ position: "unset" }}
                                          />

                                        </label>
                                      </div>
                                    </>)
                                  }
                                  return <></>
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  <div className="col-md-12">
                    <ul className="list-inline togglebox my-3">
                      <li>
                        Email Notification
                        <span className="float-right">
                          <label className="switch">
                            <input
                              onChange={(e) => handleInputs(e)}
                              name="emailnotifications"
                              defaultChecked="false"
                              type="checkbox"
                              className="default"
                            />
                            <span className="slider round"></span>
                          </label>
                        </span>
                      </li>
                      <li>
                        Mailing List
                        <span className="float-right">
                          <label className="switch">
                            <input
                              onChange={(e) => handleInputs(e)}
                              name="maillinglist"
                              defaultChecked="false"
                              type="checkbox"
                              className="default"
                            />
                            <span className="slider round"></span>
                          </label>
                        </span>
                      </li>
                      <li>
                        Text Notifications
                        <span className="float-right">
                          <label className="switch">
                            <input
                              onChange={(e) => handleInputs(e)}
                              name="textnotifications"
                              defaultChecked="false"
                              type="checkbox"
                              className="default"
                            />
                            <span className="slider round"></span>
                          </label>
                        </span>
                      </li>
                      <li>
                        Web Notifications
                        <span className="float-right">
                          <label className="switch">
                            <input
                              onChange={(e) => handleInputs(e)}
                              name="notifications"
                              defaultChecked="false"
                              type="checkbox"
                              className="default"
                            />
                            <span className="slider round"></span>
                          </label>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-12">
                    <div className="custom-control custom-checkbox my-2 regi-chk">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="term"
                        ref={isAgreeInputRefSe}
                        name="example2"
                        onChange={(e) => {
                          handleTAndC(e);
                        }}
                      />
                      <label
                        className="custom-control-label terms-text"
                        htmlFor="term"
                      >
                        <span className="pl-2">
                          I agree to the{" "}
                          <Link to="/public-view/termsandconditionapp" target="_blank" className="gray-text">
                            Terms & Conditions
                          </Link>
                        </span>
                      </label>
                    </div>
                    <div className="text-danger">{errors.isAgree}</div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6">
                        <button
                          disabled={isButtonDisabled}
                          onClick={(e) => {
                            setIsNext(1);
                          }}
                          className="loginbtn mt-md-4"
                        >
                          Back
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          disabled={isButtonDisabled}
                          onClick={(e) => {
                            PostSignUp(e);
                          }}
                          className="loginbtn my-4">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  Qulification view model */}
      <Modal show={show} onHide={handleClose} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered >
        <Modal.Header closeButton className="preview-img">
          <Modal.Title className="book-title d-flex align-items-center mb-0">
            <img src="/img/common-health.png" alt="" />
            <h4 className="mb-0 ml-3 font-weight-bold">Qulification preview</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img
            src={qulifModelData?.path}
            alt="Qulification"
            height={250}
            width={250}
            onError={(e) => {
              e.target.src = "/img/Small-no-img.png";
            }} className="pre-img"
          />
        </Modal.Body>
      </Modal>

      {/*  Certifications view model */}
      <Modal show={show1} onHide={handleClose1} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered >
        <Modal.Header closeButton className="preview-img">
          <Modal.Title className="book-title d-flex align-items-center mb-0">
            <img src="/img/common-health.png" alt="" />
            <h4 className="mb-0 ml-3 font-weight-bold">Certification Preview</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img
            src={certificationData?.path}
            alt="Certification"
            onError={(e) => {
              e.target.src = "/img/Small-no-img.png";
            }} className="pre-img"
          />
        </Modal.Body>
      </Modal>

    </>
  );
}
export default SignUp;
