import axios from "axios";
import $ from "jquery";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { apiUrl } from "../../environment/environment";
import Pagination from "../Pagination/Pagination";
import { useSocket } from "../../context/SocketProvider";

function ScheduleRequest() {
  const  getMediaDevice = JSON.parse(sessionStorage.getItem("mediaDevice"));
  const navigate = useNavigate();
  const [Id, setId] = useState("");
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState({});
  const [requestList, setRequestList] = useState([]);
  const [rnoOfRecords, setrNoOfRecords] = useState(0);
  const [rpageNum, setrPageNum] = useState(1);
  const rlimitValue = 4;
  const [isLoader, setIsLoader] = useState(false);

  const [acceptList, setAcceptList] = useState([]);
  const [anoOfRecords, setaNoOfRecords] = useState(0);
  const [apageNum, setaPageNum] = useState(1);
  const alimitValue = 6;


  const [declineList, setDeclineList] = useState([]);
  const [declineNoofRecord, setDeclineNoofRecord] = useState(0);
  const [dpageNum, setDpageNum] = useState(1);
  const dlimit = 6;

 
  const [sessionResponseModal, setSessionResponseModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [userDetail, setUserDetail] = useState();
  const handleClose = () => setDetailModal(false);
  const rejectRequestClose = () => setSessionResponseModal(false);
  var isFirstLoader = false;

  useEffect(() => {
    if (isFirstLoader === false) {
      navigate("/schedulerequest");
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isFirstLoader = true;
    }
    // callToken();
    PendingcallToken();
    //fetchList(false, null);
    getrequestsessionList(rpageNum, false, null);
    getacceptsessionList(apageNum);
    getdeclinesessionList(dpageNum);
  }, []);
  useEffect(() => {}, [requestList, acceptList]);

  // const callToken = () => {
  //   verifytokenCall();
  //   setTimeout(() => {
  //     callToken();
  //   }, 5000);
  // };

  const PendingcallToken = () => {
    verifyPendingRequestCall();
    setTimeout(() => {
      PendingcallToken();
    }, 15000);
  };

  const verifyPendingRequestCall = async () => {
    getrequestsessionList(apageNum, false, null);
  };

  const getrequestsessionList = async (val, bolval, objdata) => {
    var obj = {
      limitValue: rlimitValue,
      pageNumber: val || rpageNum,
    };
    //setIsLoader(true);
    await axios
      .post(`${apiUrl}/trainer/session/getPendingRequest`, obj, {})
      .then(function (response) {
        if (response.data.status === 1) {
          if (response?.data?.result) {
            setTimeout(() => {
              setrNoOfRecords(response.data?.result[0]?.totalCount[0]?.count || 0 );
              setRequestList(response.data?.result[0]?.paginatedResults.reverse());
            }, 500);
          } else {
            setrNoOfRecords(0);
            setRequestList([]);
          }
          if (bolval) {
            if (objdata?.requestType === 1) {
              callingRequest(objdata);
            }
          }
        }
        return response;
      })
      .catch(function (error) {
        //setIsLoader(false);
        console.log(error);
      });
  };

  const getacceptsessionList = async (val) => {
    var obj = {
      limitValue: alimitValue,
      pageNumber: val || apageNum,
    };
    // setIsLoader(true);
    await axios
      .post(`${apiUrl}/trainer/session/getAcceptRequest`, obj, {})
      .then(function (response) {
        if (response.data.status === 1) {
          if (response?.data?.result) {
            setTimeout(() => {
              setaNoOfRecords(response.data?.result[0]?.totalCount[0]?.count || 0);
              setAcceptList(response.data?.result[0]?.paginatedResults.reverse());
              setIsLoader(false);
            }, 500);
          } else {
            setIsLoader(false);
            setaNoOfRecords(0);
            setAcceptList([]);
          }
        }
        return response;
      })
      .catch(function (error) {
        setIsLoader(false);
        window.alert(error);
      });
  };

  const getdeclinesessionList = async (val) => {
    setIsLoader(true);
    var obj = {
      limitValue: alimitValue,
      pageNumber: val || apageNum,
    };
    setIsLoader(true);
    await axios
      .post(`${apiUrl}/trainer/session/getDeclineRequest`, obj, {})
      .then(function (response) {
        if (response.data.status === 1) {
          if (response?.data?.result) {
            setTimeout(() => {
              setDeclineNoofRecord(response.data?.result[0]?.totalCount[0]?.count || 0);
              setDeclineList(response.data?.result[0]?.paginatedResults.reverse());
              setIsLoader(false);
            }, 500);
          } else {
            setIsLoader(false);
            setaNoOfRecords(0);
            setDeclineList([]);
          }
        }
        return response;
      })
      .catch(function (error) {
        setIsLoader(false);
        window.alert(error);
      });
  };

  const rcurPage = (pageNum) => {
    setrPageNum(pageNum);
    getrequestsessionList(pageNum, false, null);
  };

  const acurPage = (pageNum) => {
    setaPageNum(pageNum);
    getacceptsessionList(pageNum);
    // getdeclinesessionList(pageNum);
  };

  const dcurPage = (pageNum) => {
    setDpageNum(pageNum);
    getdeclinesessionList(pageNum);
  };

  const handleInputs = (e) => {
    setReason(e.target.value);
  };
  //socket io
  const socket = useSocket();
  const userId = JSON.parse(sessionStorage.user)._id;
  const usertype = sessionStorage.getItem("usertype");
  const userEmail = JSON.parse(sessionStorage.user).email;
  const handleJoinRoom = useCallback(
    (data) => {
      const { meetingId } = data;
      navigate(`/videosession/${meetingId}`, { state: usertype });
      // navigate(`/calling?mid=${room}`);
    },
    [navigate, usertype]
  );

  useEffect(() => {
    socket.on("room:join", handleJoinRoom);
    return () => {
      socket.off("room:join", handleJoinRoom);
    };
  }, [socket, handleJoinRoom]);

  const acceptRequest = async (e) => {
    if(getMediaDevice?.camera || getMediaDevice?.microphone){// const { userid, ...others } = request;
    // const meetingId = Date.now().toString();

    // socket.emit("acceptReq", { to: userid, others, meetingId, trainerId });
    // socket.emit("room:join", { room: meetingId });

    const formData = new FormData();
    formData.append("id", e._id);
    formData.append("status", 1); // CHANGED FOR UPCOMING SESSION
    // setIsLoader(true);
    await axios
      .post(`${apiUrl}/trainer/session/schedulerequestupdate`, formData, {})
      .then(function (response) {
        setIsLoader(false);
        if (response.data.status === 1) {
          trainerPayment(formData);
          getrequestsessionList(rpageNum, true, e);
          getacceptsessionList(apageNum);
          getdeclinesessionList(dpageNum);
        } else {
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        window.alert(error);
        setIsLoader(false);
      });
    }else{
      swal({
        // title: "Error!",
        text: "Can't find your media device camera and microphone",
        icon: "info",
        button: true,
      });
    }
  };

  const trainerPayment = async (data) => {
    data.append("amount", 35);
    data.append("type", "Standard");
    data.append("paymentStatus", "0");
    data.append("method", "insert");
    await axios
      .post(`${apiUrl}/payment/trainerpaymenthistory`, data)
      .then((response) => {})
      .catch((err) => {});
  };

  const rejectRequest = async () => {
    var errormsg = {};
    var isValid = true;

    if (reason === "") {
      errormsg.reason = "Please enter reason!";
      isValid = false;
    }
    setErrors(errormsg);
    if (isValid) {
      const formData = new FormData();
      formData.append("id", Id);
      formData.append("status", 2);
      formData.append("reason", reason);
      setIsLoader(true);
      await axios
        .post(`${apiUrl}/trainer/session/schedulerequestupdate`, formData, {})
        .then(function (response) {
          setIsLoader(false);
          if (response.data.status === 1) {
            setErrors({});
            setReason("");
            setSessionResponseModal(false);
            $(".modal-backdrop").removeClass("show");
            getrequestsessionList(rpageNum, true, null);
            getacceptsessionList(apageNum);
            getdeclinesessionList(dpageNum);
          } else {
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
          }
        })
        .catch(function (error) {
          window.alert(error);
          setIsLoader(false);
        });
    }
  };

  const callClientProfile = (id) => {
    navigate("/clientprofile?Id=" + id);
  };

  const acceptSessionActivateRequest = async (e) => {
    if(getMediaDevice?.camera || getMediaDevice?.microphone){var sessdate = new Date(e.date).toDateString();

    var currentdate = new Date();
    var currenthours = new Date().getHours();
    currentdate.setHours(currenthours);
    currentdate.setMinutes(0);
    currentdate.setSeconds(0);
    currentdate.setMilliseconds(0);

    var sessiondate = new Date(sessdate);
    var sessionhours = new Date(sessdate + " " + e?.starthour).getHours();
    sessiondate.setHours(sessionhours);
    sessiondate.setMinutes(0);
    sessiondate.setSeconds(0);
    sessiondate.setMilliseconds(0);

    if (currentdate >= sessiondate) {
      // if ((new Date(sessdate + " " + e?.starthour).getTime() < (new Date().getTime())) && (new Date().setMinutes(new Date().getMinutes() + 60) < new Date(sessdate + " " + e?.endhour).getTime())) {
      const formData = new FormData();
      formData.append("id", e._id);
      formData.append("availablestatus", 1);
      setIsLoader(true);
      await axios
        .post(
          `${apiUrl}/trainer/session/sessionactivestatusupdate`,
          formData,
          {}
        )
        .then(function (response) {
          setIsLoader(false);
          if (response.data.status === 1) {
            callingRequest(e);
          } else {
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
          }
        })
        .catch(function (error) {
          //window.alert(error);
          setIsLoader(false);
        });
    } else {
      setIsLoader(false);
      swal({
        title: "Error!",
        text: "Session time not match.",
        icon: "error",
        button: true,
      });
    }
  }else{
    swal({
      // title: "Error!",
      text: "Can't find your media device camera and microphone",
      icon: "info",
      button: true,
    });
  }
  };

  const callingRequest = async (e) => {
    setIsLoader(true);
    let mid = new Date().getTime();
    var obj = {
      meetingid: mid.toString(),
      sessionid: e._id,
      fromid: e.tId,
      toid: e.cId,
      starttime: new Date(),
    };
    const meetingId = obj.meetingid;

    axios
      .post(`${apiUrl}/meeting/startvideosession`, obj, {})
      .then(function (response) {
        if (response.data.status === 1) {
          setTimeout(() => {          
            setIsLoader(false);
            const { userid, ...others } = e;
            socket.emit("acceptReq", { to: userid, others, meetingId, userId });
            socket.emit("room:join", { meetingId: meetingId, email: userEmail });
          }, 500);
        } else {
          setIsLoader(false);
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        setIsLoader(false);
        window.alert(error);
      });
  };

  const setUserDetails = (obj) => {
    setUserDetail(obj);
    setDetailModal(true);
  };
  return (
    <>
      <div className="sub-head-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-12">
              <h1 className="main_title">Scheduling Requests</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid position-relative">
      {/* {isLoader && (
            <div id="loader" className="loading">
              <div className="mainloader"></div>
            </div>
          )} */}
             {/* request list */}
        <div className="col-md-12 p-0">
          {isLoader && (
            <div id="loader" className="loading schedul-req">
              <div className="mainloader"></div>
            </div>
          )}
          <div className="trainer-main-box mb-3">
          <h4 className="main_title session-sub-title">Session Request</h4>
            <div className="row">
              {requestList.length > 0 ? (
                <>
                  {requestList.map((item, index) => {
                    return (
                      <div
                        key={"index" + index}
                        className="col-xl-3 col-md-6"
                        onClick={(e) => {
                          e.stopPropagation();
                          setUserDetails(item);
                        }}
                      >
                        <div className="lightbg">
                          <img
                            src={`${apiUrl}${item.user_data.profile}`}
                            alt="Profile"
                            onError={(e) => {
                              e.target.src = "/img/Small-no-img.png";
                            }}
                            className="mx-auto d-block"
                            style={{ objectFit: "cover" }}
                          />
                          {/* {item.user_data.coverprofile ? */}
                          {/* /* :
                                                <div>{item.user_data.firstname.substring(0, 1).toUpperCase()}</div>
                                            } */}
                          <h6 className="text-center font-weight-bold">
                            {item.user_data.firstname}
                            {item.user_data.lastname}
                          </h6>
                          <ul className="list-inline d-flex justify-content-between pl-0 mb-3">
                            <li>
                              <span className="timeicon">
                                <i className="far fa-calendar pr-2"></i>
                              </span>
                              {new Date(item.date).toLocaleDateString("en-IN", {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })}
                            </li>
                            <li>
                              <span className="timeicon">
                                <i className="fas fa-clock pr-2"></i>
                              </span>
                              {item.starthour.split(":")[0] +
                                ":" +
                                item.starthour.split(":")[1]}
                              -
                              {item.endhour.split(":")[0] +
                                ":" +
                                item.endhour.split(":")[1]}
                            </li>
                          </ul>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            className="adblock d-flex justify-content-between"
                          >
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                acceptRequest(item);
                              }}
                              className="Accept"
                            >
                              Accept
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setId(item._id);
                                setSessionResponseModal(true);
                              }}
                              className="Decline mr-0 w-100"
                              data-toggle="modal"
                              data-target="#Rejection"
                            >
                              Decline
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                    <Pagination
                      className="pagination-bar"
                      currentPage={rpageNum}
                      totalCount={rnoOfRecords}
                      pageSize={rlimitValue}
                      onPageChange={(page) => rcurPage(page)}
                    />
                  </div>
                </>
              ) : (
                <>
                  {isLoader === false && (
                    <div className="col-12 p-0 mb-4">                       
                        <div className="trainer-main-box">
                          <div className="no-record-box">
                            <img src="/img/no-record.png" alt="no-img" />
                            <h4 className="font-weight-bold">
                              Sorry, You Have No Session Requests
                            </h4>
                            <p>
                              {/* No member has made any session requests, */}
                              You can see here when a member requests a session
                             </p>
                          </div>
                        </div>
                      </div>
                  )}
                </>
              )}
            </div>
          </div>
      

        {/* upcoming list */}
        <div className="col-md-12 p-0">
          {/* {isLoader && (
            <div id="loader" className="loading">
              <div className="mainloader"></div>
            </div>
          )} */}
          <div className="trainer-main-box pb-0 mb-4">
            <h4 className="main_title session-sub-title">Upcoming Session</h4>
            <div className="row">
              {acceptList.length > 0 ? (
                <>
                  {acceptList.map((item, index) => {
                    return (
                      <div key={"index" + index} className="col-xl-4 col-lg-6">
                        <div className="upcomingblock">
                          <div className="d-sm-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <img
                                src={`${apiUrl}${item.user_data.profile}`}
                                onError={(e) => {
                                  e.target.src = "/img/Small-no-img.png";
                                }}
                                alt="Profile"
                              />
                              <div className="sessioninfo">
                                <h6
                                  className="font-weight-bold"
                                  onClick={() => {
                                    callClientProfile(item.user_data._id);
                                  }}
                                >
                                  {item.user_data.firstname}
                                  {item.user_data.lastname}
                                </h6>
                                <ul className="list-inline mb-0">
                                  <li className="list-inline-item">
                                    {new Date(item.date).toLocaleDateString(
                                      "en-IN",
                                      {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                      }
                                    )}
                                  </li>
                                  <li className="list-inline-item">
                                    {item.starthour.split(":")[0] +
                                      ":" +
                                      item.starthour.split(":")[1]}
                                    -
                                    {item.endhour.split(":")[0] +
                                      ":" +
                                      item.endhour.split(":")[1]}
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="">
                              {item.isVideocall > 0 ? (
                                <>
                                  <div
                                    onClick={() => {
                                      acceptSessionActivateRequest(item);
                                    }}
                                  >
                                    <i className="fas fa-video sessionicon"></i>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div>
                                    <i className="fas fa-video sessionicon-dis"></i>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                    <Pagination
                      className="pagination-bar"
                      currentPage={apageNum}
                      totalCount={anoOfRecords}
                      pageSize={alimitValue}
                      onPageChange={(page) => acurPage(page)}
                    />
                  </div>
                </>
              ) : (
                <>
                  {isLoader === false && (
                    <div className="col-12 p-0">
                        <div className="trainer-main-box mb-4">
                          <div className="no-record-box">
                            <img src="/img/no-record.png" alt="no-img" />
                            <h4 className="font-weight-bold">
                              Sorry, You Have No Upcomming Session Requests
                            </h4>
                            <p>
                              {/* No member has made any Upcomming sssion requests, */}
                               You can see here when a member upcomming requests a session
                             </p>
                          </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        {/* decline list */}
        <div className="col-md-12 p-0">
          {/* {isLoader && (
            <div id="loader" className="loading">
              <div className="mainloader"></div>
            </div>
          )} */}
          <div className="trainer-main-box pb-0">
            <h4 className="main_title session-sub-title mb-4">
             Decline Session
            </h4>
            <div className="row">
              {declineList.length > 0 ? (
                <>
                  {declineList.map((item, index) => {
                    return (
                      <div key={"index" + index} className="col-xl-4 col-lg-6">
                        <div className="upcomingblock">
                          <div className="d-sm-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <img
                                src={`${apiUrl}${item.user_data.profile}`}
                                onError={(e) => {
                                  e.target.src = "/img/Small-no-img.png";
                                }}
                                alt="Profile"
                              />
                              <div className="sessioninfo">
                                <h6
                                  className="font-weight-bold"
                                  onClick={() => {
                                    callClientProfile(item.user_data?._id);
                                  }}
                                >
                                  {item.user_data.firstname}
                                  {item.user_data.lastname}
                                </h6>
                                <ul className="list-inline mb-0">
                                  <li className="list-inline-item">
                                    {new Date(item.date).toLocaleDateString(
                                      "en-IN",
                                      {
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                      }
                                    )}
                                  </li>
                                  <li className="list-inline-item">
                                    {item.starthour.split(":")[0] +
                                      ":" +
                                      item.starthour.split(":")[1]}
                                    -
                                    {item.endhour.split(":")[0] +
                                      ":" +
                                      item.endhour.split(":")[1]}
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="">
                              {item.isVideocall > 0 ? (
                                <>
                                  <div
                                    onClick={() => {
                                      acceptSessionActivateRequest(item);
                                    }}
                                  >
                                    <i className="fas fa-video sessionicon"></i>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div>
                                    <i className="fas fa-video sessionicon-dis"></i>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                    <Pagination
                      className="pagination-bar"
                      currentPage={dpageNum}
                      totalCount={declineNoofRecord}
                      pageSize={dlimit}
                      onPageChange={(page) => dcurPage(page)}
                    />
                  </div>
                </>
              ) : (
                <>
                  {isLoader === false && (
                    <div className="col-12 p-0 mb-4">
                        <div className="trainer-main-box">
                          <div className="no-record-box">
                            <img src="/img/no-record.png" alt="no-img" />
                            <h4 className="font-weight-bold">
                            Sorry, No Session Declined Yet
                            </h4>
                            <p>You can decline a session if a member has requested a session</p>
                          </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        </div>
     
      </div>

      <Modal
        show={sessionResponseModal}
        onHide={rejectRequestClose}
        className="sessionbody"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="session-m" closeButton>
          <Modal.Title className="book-title d-flex align-items-center ml-3 mb-0">
            <img src="/img/common-health.png" alt="" />
            <h4 className="mb-0 ml-3 font-weight-bold">Reason For Rejection</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12 col-12">
            <h6 className="mb-3">Write reason for rejection</h6>
            <div className="mb-4">
              <textarea
                onChange={(e) => handleInputs(e)}
                value={reason}
                className="w-100 Sessionrej mb-0"
                placeholder="Enter Reason"
              ></textarea>
              <div className="text-danger">{errors.reason}</div>
            </div>
          </div>
          <div className="col-md-12 col-12">
            <Button
              className="training_btn mb-3"
              onClick={(e) => {
                rejectRequest();
              }}
            >
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={detailModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="" closeButton>
          <Modal.Title className="book-title d-flex align-items-center ml-2 mb-0">
            <img src="/img/common-health.png" alt="" />
            <h4 className="mb-0 ml-3 font-weight-bold">Member Details</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-4 request-detail">
          <div className="col-md-12 col-12 mt-2 mb-2">
            {/* <div className="row">
              <div className="col-md-4 col-12">
                <div className="profile-img py-1">
                  <img
                    className="p-photo"
                    src={apiUrl + userDetail?.user_data?.profile}
                    onError={(e) => {
                      e.target.src = "/img/Small-no-img.png";
                    }}
                    style={{ width: "100%", height: "100px" }}
                    alt="Profile"
                  />
                </div>
              </div>
              <div className="col-md-8 col-12">
                <table className="profile-detail">
                  <tr>
                    <th>
                      Name <span className="colon-th">:</span>
                    </th>
                    <td>
                      {userDetail?.user_data?.firstname}
                      {userDetail?.user_data?.lastname}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Email <span className="colon-th">:</span>
                    </th>
                    <td>{userDetail?.user_data?.email}</td>
                  </tr>
                  <tr>
                    <th>
                      Phone No <span className="colon-th">:</span>
                    </th>
                    <td>{userDetail?.user_data?.phoneno}</td>
                  </tr>
                </table>
              </div>
            </div> */}

            <div className="row">
              <div className="col-lg-3 col-12">
                <div className="req-detail text-center">
                  <img
                    className="p-photo"
                    src={apiUrl + userDetail?.user_data?.profile}
                    onError={(e) => {
                      e.target.src = "/img/Small-no-img.png";
                    }}
                    alt="Profile"
                  />
                  {/* <div id="imagePreview" style={{ backgroundImage: `url(${profileimagepreview})` }}></div> */}
                  <div className="prof_name">
                    <h4>
                      {" "}
                      {userDetail?.user_data?.firstname}{" "}
                      {userDetail?.user_data?.lastname},{" "}
                      {userDetail?.user_data?.age}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-12">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="d-flex">
                      <label className="lbl-wd">Age</label>
                      <p>{userDetail?.user_data?.age}</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="d-flex">
                      <label className="lbl-wd">Gender</label>
                      <p>{userDetail?.user_data?.gender}</p>
                    </div>
                  </div>

                  <div className="col-lg-6 col-12">
                    <div className="d-flex">
                      <label className="lbl-wd">Height</label>
                      <p>
                        {userDetail?.user_data?.height}{" "}
                        {userDetail?.user_data?.heightisfeet
                          ? "Feet"
                          : "Meters"}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="d-flex">
                      <label className="lbl-wd">Weight</label>
                      <p>
                        {userDetail?.user_data?.weight}{" "}
                        {userDetail?.user_data?.weightiskg ? "Kgs" : "Pound"}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12 col-12">
                    <div className="d-flex">
                      <label className="lbl-wd">Equipment</label>
                      <p>{userDetail?.user_data?.equipmentavailable}</p>
                    </div>
                  </div>
                  <div className="col-lg-12 col-12">
                    <div className="d-flex">
                      <label className="lbl-wd">Fitness Goals</label>
                      <p>{userDetail?.user_data?.otherfitnessgoals}</p>
                    </div>
                  </div>
                  <div className="col-lg-12 col-12">
                    <div className="d-flex">
                      <label className="lbl-wd">Injuries/Health Issues</label>
                      <p>{userDetail?.user_data?.injuriesorhelthissues}</p>
                    </div>
                  </div>
                  <div className="col-lg-12 col-12">
                    {userDetail?.user_data?.socialmedia?.length > 0 ? (
                      <>
                      <label className="lbl-wd">Social Media</label>
                        <ul className="list-inlne social-media-icon mb-0">
                          {userDetail?.user_data?.socialmedia?.map(
                            (media, i) => (
                              <li
                                key={i}
                                className="list-inline-item position-relative"
                              >
                                <Link
                                  className="text-decoration-none"
                                  to={media.url}
                                  target="_blank"
                                >
                                  <span className="px-1">
                                    <i
                                      className={media.icon}
                                      aria-hidden="true"
                                      title={media.platform}
                                    ></i>
                                  </span>
                                  {/* <span>{media.platform}</span> */}
                                </Link>
                              </li>
                            )
                          )}
                        </ul>
                      </>
                    ) : (
                      <>
                        {/*<div className="no-record-box">
                                  <h4 className="font-weight-bold">
                                 Youre socialmedia detail is empty !
                                  </h4>
                                </div> */}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="col-12">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <div className="d-flex">
                      <label className="lbl-wd">Height</label>
                      <p>{userDetail?.user_data?.height} {userDetail?.user_data?.heightisfeet ? 'Feet' : 'Meters'}</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="d-flex">
                      <label className="lbl-wd">Weight</label>
                      <p>{userDetail?.user_data?.weight} {userDetail?.user_data?.weightiskg ? 'Kgs' : 'Pound'}</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="d-flex">
                      <label className="lbl-wd">Fitness Goals</label>
                      <p>{userDetail?.user_data?.otherfitnessgoals}</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="d-flex">
                      <label className="lbl-wd">Injuries/Health Issues</label>
                      <p>{userDetail?.user_data?.injuriesorhelthissues}</p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn cancel_btn" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <div className={`modal fade ${detailModal === false ? "" : "show"}`} style={{ display: `${detailModal === false ? "none" : "block"}` }} id="detail" role="dialog">
                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered detailmodal" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="mb-0">Details</h6>
                            <button type="button" onClick={() => { setDetailModal(false); }} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-md-12 col-12">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover">
                                        {<tr>
                                            <th>CoverProfile</th>
                                            <td>
                                                <img className="p-photo" src={userDetail?.user_data?.coverprofile} onError={(e) => { e.target.src = "/img/Back-No-Image.png" }} style={{ width: 'auto', height: '100px' }} alt="Cover Profile" />
                                            </td>
                                        </tr>}
                                        <tr>
                                            <th>Profile</th>
                                            <td>
                                                <img className="p-photo" src={apiUrl + userDetail?.user_data?.profile} onError={(e) => { e.target.src = "/img/Small-no-img.png" }} style={{ width: 'auto', height: '100px' }} alt="Profile" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Name</th>
                                            <td>{userDetail?.user_data?.firstname} {userDetail?.user_data?.lastname}</td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td>{userDetail?.user_data?.email}</td>
                                        </tr>
                                        <tr>
                                            <th>Phone No</th>
                                            <td>{userDetail?.user_data?.phoneno}</td>
                                        </tr>
                                        <tr>
                                            <th>Qualifications</th>
                                            <td>{userDetail?.user_data?.qualifications?.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Certifications</th>
                                            <td>{userDetail?.user_data?.certifications?.name}</td>
                                        </tr>
                                        <tr>
                                            <th>About Us</th>
                                            <td>{userDetail?.user_data?.aboutus}</td>
                                        </tr>
                                        <tr>
                                            <th>Quote</th>
                                            <td>{userDetail?.user_data?.quote}</td>
                                        </tr>
                                        <tr>
                                            <th>Training Style</th>
                                            <td>{userDetail?.user_data?.trainingstyle}</td>
                                        </tr>
                                    </table>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer text-center">
                            <div className="col-md-6 col-12 mx-auto">
                                <div className="training_btn" onClick={() => { setDetailModal(false); }} >Close</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
}

export default ScheduleRequest;