function TermsAndCondition() {
    return (
      <>
        <div className="sub-head-title terms">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-12">
                <h1 className="main_title">Terms & Conditions</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid" style={{ marginBottom: `20px` }}>
          <div className="col-md-12 col-12 p-0">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="trainer-main-box mb-4">
                  <div className="cancel_policy">
                    {/* <h4>Bookable activities:</h4> */}
                    <ul className="list-inline cnl_bullet">
                      <li>
                        The Bookable Activity service allows fitness trainers to
                        offer training sessions, classes, or appointments to
                        clients for a specified fee.
                      </li>
                      <li>
                        Trainers are responsible for setting their availability,
                        session types, duration, fees, and any additional terms
                        associated with the Bookable Activity.
                      </li>
                      <li>
                        Clients can book training sessions through the Bookable
                        Activity service based on the availability provided by the
                        trainer.
                      </li>
                      <li>
                        Trainers agree to honor the bookings made by clients and
                        to provide the agreed-upon services at the specified time
                        and date.
                      </li>
                      <li>
                        Payment processing for the Bookable Activity will be
                        managed according to the Website's payment policies.
                      </li>
                      <li>
                        Trainers must specify their cancellation and refund policy
                        for Bookable Activity sessions. Clients must be informed
                        of this policy before booking.
                      </li>
                      <li>
                        Trainers should clearly outline the process for
                        rescheduling or canceling a Bookable Activity session.
                      </li>
                      <li>
                        Trainers are responsible for ensuring that the Bookable
                        Activity information, including availability and pricing,
                        is accurate and up to date.
                      </li>
                      <li>
                        Trainers must conduct themselves professionally and
                        provide a safe and appropriate environment for the
                        Bookable Activity sessions.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="trainer-main-box">
                  <h2 className="main_title mb-4">Privacy Policy</h2>
                  <div className="cancel_policy">
                    <ul className="list-inline cnl_bullet">
                      <li>
                        We may share your information with third-party service
                        providers who assist us in operating the Website and
                        delivering services to you.
                      </li>
                      <li>
                        We implement reasonable security measures to protect your
                        information from unauthorized access and misuse.
                      </li>
                      <li>
                        These providers are obligated to protect your data.We may
                        share information when required by law, to protect our
                        rights, or in response to legal requests.
                      </li>
                      <li>
                        In the event of a business transfer, your information may
                        be transferred to the new owners.
                      </li>
                      <li>
                        Permanent copying or storage of whole or part of this
                        website or the information contained in it or reproduction
                        or incorporation of any part of it in any other work or
                        publication whether on paper or electronic media or any
                        other form is expressly prohibited.
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  
  export default TermsAndCondition;
  