import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
function SignupSuccess() {
  const { state } = useLocation();
  const [userType, setuserType] = useState();

  useEffect(() => {
    setuserType(state);
  }, [state]);

  return (
    <>
      <div className="container-fluid">
        <div className="commonbox">
          <div className="row">
            <div className="col-md-6 p-0">
              <div className="loginimg">
                <div className="col-md-12 logoblock">
                  <h3>Register Success</h3>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div className="loginbox signup-success">
                <div className="login-form">
                  <div className="col-md-12 text-center">
                    <i className="far fa-check-circle iconsize"></i>
                    <p className="font-weight-bold font-19 mt-2">
                      You are Registered Successfully.
                    </p>
                  </div>
                  <div className="col-md-12">
                    <Link
                      to={`/${userType}/login`}
                      className="loginbtn mt-4 mb-3"
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignupSuccess;
