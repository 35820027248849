import axios from "axios";
import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { apiUrl } from "../../environment/environment";
const initialValues = {
  email: "",
};
function ForgotPassword() {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  if (token !== null) {
    navigate("/");
    window.location.reload();
  }
  const emailRef = useRef()
  const [user, setUser] = useState(initialValues);
  const [error, setError] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [dataLoader, setDataLoader] = useState(false)

  const isRequiredField = (fieldName) => {
    return ['email'].includes(fieldName);
  };

  const handleInputs = (e) => {
    const { name, value } = e.target
    setUser({ ...user, [name]: value });
    let reg_email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (value.trim() === '' && isRequiredField(name)) {
      setError((prevError) => ({ ...prevError, [name]: `Please enter ${name}!` }));
    } else if (name === 'email' && value.trim() !== '' && !reg_email.test(value)) {
      setError((prevError) => ({ ...prevError, email: 'Please enter a valid email!' }));
    } else {
      setError((prevError) => ({ ...prevError, [name]: '' }));
    }
  };

  const PostForgotPassword = async (e) => {
    e.preventDefault();
    let errormsg = {}
    let isValid = true
    let reg_email = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
  
    if (user.email === "") {
      errormsg.email = "Please enter email"
      isValid = false
    }else if (!reg_email.test(user.email)) {
      errormsg.email = "Please enter a valid email!";
      isValid = false;
    }

    if (Object.keys(errormsg).length > 0) {
      if (errormsg.email) {
        emailRef.current.focus()
      }
    }
    setError(errormsg)
    if (isValid) {
      setIsButtonDisabled(true)
      setDataLoader(true)
      // document.querySelector(".loading").classList.remove("d-none");
      await axios
        .post(`${apiUrl}/client/account/passwordlink`, { email: user.email }, {})
        .then(function (response) {
          // document.querySelector(".loading").classList.add("d-none");
          if (response.data.status === 1) {
            setIsButtonDisabled(false)
            setDataLoader(false)
            swal({
              title: "Success!",
              text: response.data.message,
              icon: "success",
              button: true,
            });

            navigate("/client/login");
            // window.location.href = "/client/login";
          } else {
            setDataLoader(false)
            setIsButtonDisabled(false)
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
            //window.alert(response.data.message);
          }
        })
        .catch(function (error) {
          window.alert(error)
          setDataLoader(false)
        });
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="commonbox">
          <div className="row">
            <div className="col-md-6 p-0">
              <div className="loginimg">
                <div className="col-md-12 logoblock">
                  <h3>Forgot Password</h3>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div className="loginbox Forgotbox">
                <div className="login-form">
                  {dataLoader &&
                    <div className="loading">
                      <div className="mainloader"></div>
                    </div>}
                  <div className="col-12 text-center">
                    <a className="login-logo" href="/">
                      <img src="/img/common-health.png" alt="logo" />
                    </a>
                    <h4 className="log-text">
                      Link to reset password will be sent to your email account
                    </h4>
                  </div>
                  {/* <h6 className="text-center mb-4">Link to reset password will be sent to your email account</h6> */}
                  <div className="col-md-12 mb-2">
                    <label>Email</label>
                    <input
                      onChange={(e) => handleInputs(e)}
                      name="email"
                      type="text"
                      ref={emailRef}
                      className="w-100 input-box"
                      placeholder="Email"
                    />
                    {error.email && 
                    <span className="text-danger">{error.email}</span>
                    }
                  </div>
                  <div className="col-md-12">
                    <button
                      disabled={isButtonDisabled}
                      onClick={(e) => PostForgotPassword(e)}
                      className="loginbtn mt-4 mb-3"
                    >
                      {/* {buttonLoader ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </>
                      ) : (
                        ""
                      )} */}
                      Submit
                    </button>
                  </div>
                  <div className="col-12 text-center">
                    <Link to="/client/login" className="linktext text-login">
                      <i className="fa fa-arrow-circle-left pr-2"></i>Back To Login
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
