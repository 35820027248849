import React, { useRef, useState } from "react";
import { Link } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";
import { apiUrl } from "../environment/environment";
import swal from "sweetalert";
import axios from "axios";

const intitValue = {
  firstname: "",
  lastname: "",
  email: "",
  subject: "",
  message: "",
}

const ContactUs = () => {

  // const navigate = useNavigate();
  const firstNameRef = useRef()
  const lastnameNameRef = useRef()
  const emailRef = useRef()
  const subjectRef = useRef()
  const messageRef = useRef()

  const [contact, setContact] = useState(intitValue)
  const [errors, setErrors] = useState({});

  const isRequiredField = (fieldName) => {
    return ['firstname', 'lastname', 'email', 'subject', 'message'].includes(fieldName);
  }
  const inputSet = (e) => {
    const { name, value } = e.target
    setContact({ ...contact, [name]: value })

    let reg_email = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

    if (value.trim() === '' && isRequiredField(name)) {
      let errorMessage = '';

      if (name === "firstname") {
        errorMessage = "Please enter first name";
      } else if (name === 'lastname') {
        errorMessage = "Please enter last name";
      } else if (name === "email") {
        errorMessage = "Please enter email";
      } else if (name === "subject") {
        errorMessage = "Please enter subject";
      } else if (name === "message") {
        errorMessage = "Please enter message";
      }
      setErrors((prevError) => ({ ...prevError, [name]: errorMessage }));
    } else if (((name === 'firstname') || (name === 'lastname') || (name === 'subject') || (name === 'message')) && (value.length < 3 || value.length > 50)) {
      setErrors((prevError) => ({ ...prevError, [name]: "Please enter minimum 3 letters or maximum 50" }));
    } else if (name === 'email' && value.trim() !== '' && !reg_email.test(value)) {
      setErrors((prevError) => ({ ...prevError, email: 'Please enter valid email' }));
    } else {
      setErrors((prevError) => ({ ...prevError, [name]: '' }));
    }
  }

  const contactSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;
    var errormsg = {};
    let reg_email = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

    if (contact.firstname === "") {
      errormsg.firstname = "Please enter First Name.";
      isValid = false;
    } else if (contact.firstname.length < 3 || contact.firstname.length > 50) {
      errormsg.firstname = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    }

    if (contact.lastname === "") {
      errormsg.lastname = "Please enter Last Name.";
      isValid = false;
    } else if (contact.lastname.length < 3 || contact.lastname.length > 50) {
      errormsg.lastname = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    }

    if (contact.email === "") {
      errormsg.email = "Please enter Email.";
      isValid = false;
    } else if (reg_email.test(contact.email) === false) {
      errormsg.email = "Please enter valid Email.";
      isValid = false;
    }

    if (contact.subject === "") {
      errormsg.subject = "Please enter subject.";
      isValid = false;
    } else if (contact.subject.length < 3 || contact.subject.length > 50) {
      errormsg.subject = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    }

    if (contact.message === "") {
      errormsg.message = "Please enter message.";
      isValid = false;
    } else if (contact.message.length < 3 || contact.message.length > 50) {
      errormsg.message = "Please enter minimum 3 letters or maximum 50";
      isValid = false;
    }

    if (Object.keys(errormsg)) {
      if (errormsg.firstname) {
        firstNameRef.current.focus()
      } else if (errormsg.lastname) {
        lastnameNameRef.current.focus()
      } else if (errormsg.email) {
        emailRef.current.focus()
      } else if (errormsg.subject) {
        subjectRef.current.focus()
      } else if (errormsg.message) {
        messageRef.current.focus()
      }
    }

    setErrors(errormsg);
    if (isValid === true) {
      let obj = {
        firstname: contact.firstname,
        lastname: contact.lastname,
        email: contact.email,
        subject: contact.subject,
        message: contact.message,
      }

      document.querySelector(".loading").classList.remove("d-none");
      await axios
        .post(`${apiUrl}/admin/contactus`, obj)
        .then(function (response) {
          if (response.data.status === 1) {
            setTimeout(() => {
              swal({
                title: "Success!",
                text: response.data.message,
                icon: "success",
                button: true,
              });
              setContact(intitValue)
              document.querySelector(".loading").classList.add("d-none");
            }, 500);

          } else {
            document.querySelector(".loading").classList.add("d-none");
            swal({
              title: "Info!",
              text: response.data.message,
              icon: "info",
              button: true,
            });
          }
          // setContact("");
        })
        .catch(function (error) {
          window.alert(error)
          document.querySelector(".loading").classList.add("d-none");
        });
    }

  }

  return (
    <div className="">
      <div className="loading d-none">
        <div className="mainloader"></div>
      </div>
      <Header />
      <div className="contact-img">
        <div className="container-fluid">
          <h4 className="contact-text">
            Contact <span className="bottom-bor">Us!</span>
          </h4>
        </div>
      </div>
      <div className="contactus-block">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="contactus-content">
                <h1>Keep In Touch With Us</h1>
                <div className="row">
                  <div className="col-xl-6 col-12 mb-3">
                    <Link to="mailto:info@izonnet.com" className="contact-box">
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                      <h5>Email</h5>
                      <p>info@izonnet.com</p>
                    </Link>
                  </div>
                  {/* <div className="col-md-6 col-12">
                    <Link to="tel:9427790290" className="contact-box">
                      <i className="fa fa-phone-alt" aria-hidden="true"></i>
                      <h5>Call</h5>
                      <p>9427790290</p>
                    </Link>
                  </div> */}
                  <div className="col-xl-6 col-12 mb-3">
                    <Link to="https://www.google.com/maps/place/Izonnet+Web+Solution+Pvt.Ltd/@20.759741,73.055346,13z/data=!4m6!3m5!1s0x3be0edf1017ec3bf:0xa549dc1885e04e5d!8m2!3d20.759741!4d73.0553462!16s%2Fg%2F11rkf339wd?hl=en-GB&entry=ttu"
                      target="_blank" className="contact-box">
                      <i className="fas fa-map-marker-alt"></i>
                      <h5>Address</h5>
                      <p>
                        3rd Floor,Queens Arcade, Bilimora Road,Chikhli,Gujarat 396521{" "}
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <form onSubmit={contactSubmit} className="col-lg-6 col-12">
              <div className="contact-form">
                <div className="row">
                  <div className="col-lg-6 col-md-6 mb-3 pr-lg-2">
                    <input
                      className="input-field"
                      onChange={inputSet}
                      type="text"
                      name="firstname"
                      maxLength={50}
                      ref={firstNameRef}
                      value={contact.firstname}
                      autoComplete="off"
                      placeholder="First Name *"
                    />
                    <div className="text-danger">{errors.firstname}</div>
                  </div>
                  <div className="col-lg-6 col-md-6 mb-3 pl-lg-2">
                    <input
                      className="input-field"
                      onChange={inputSet}
                      type="text"
                      maxLength={50}
                      ref={lastnameNameRef}
                      value={contact.lastname}
                      name="lastname"
                      autoComplete="off"
                      placeholder="Last Name *"
                    />
                    <div className="text-danger">{errors.lastname}</div>
                  </div>
                  <div className="col-md-6 col-12 mb-3 pr-lg-2">
                    <input
                      className="input-field"
                      onChange={inputSet}
                      type="Email"
                      name="email"
                      ref={emailRef}
                      maxLength={50}
                      value={contact.email}
                      autoComplete="off"
                      placeholder="Email *"
                    />
                    <div className="text-danger">{errors.email}</div>
                  </div>
                  <div className="col-md-6 col-12 mb-3 pl-lg-2">
                    <input
                      className="input-field"
                      onChange={inputSet}
                      type="text"
                      name="subject"
                      ref={subjectRef}
                      maxLength={50}
                      value={contact.subject}
                      autoComplete="off"
                      placeholder="Subject *"
                    />
                    <div className="text-danger">{errors.subject}</div>
                  </div>
                  <div className="col-md-12 col-12 mb-4">
                    <textarea
                      onChange={inputSet}
                      className="text_area"
                      name="message"
                      autoComplete="off"
                      value={contact.message}
                      ref={messageRef}
                      maxLength={100}
                      placeholder="Message *"
                    ></textarea>
                    <div className="text-danger">{errors.message}</div>
                  </div>
                  <div className="col-12">
                    <button type="submit" className="send-btn">Send My Message</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
