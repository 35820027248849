import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { apiUrl } from "../../environment/environment";
import { useSocket } from "../../context/SocketProvider";
import call from "../../videocall/call/calling.mp3";

function Incoming() {
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let mid = query.get("mid");
  const meetingId = query.get("mid");
  const navigate = useNavigate();
  const [time, setTime] = useState(0);
  const [firstname, setFirstname] = useState();
  const [profile, setProfile] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const [addData, setAddData] = useState()
  // let mid = new URLSearchParams(window.location.search).get("mid");
  // let mid = window.location.href.split('id=')[1];
  var loginUser = {};
  const loginuserdetail = sessionStorage.getItem("user");
  const loginuserrole = sessionStorage.getItem("usertype");
  if (loginuserdetail) {
    loginUser = JSON.parse(loginuserdetail);
  }
  // Sound Codeing
  // const [playing, setPlaying] = useState(false);
  // const audio = useRef(
  //   new Audio("https://www.soundhelix.com/examples/mp3/SoundHelix-Song-3.mp3")
  // );
  // audio.current.onended = function () {
  //   setPlaying(false);
  // };
  // audio.current.onplay = function () {
  //   setHasError(false);
  // };
  // useEffect(() => {
  //   if (playing) {
  //     audio.current
  //       .play()
  //       .then(() => {
  //         // Audio is playing.
  //       })
  //       .catch((error) => {
  //         setHasError(true);
  //       });
  //   } else if (!hasError) {
  //     audio.current.pause();
  //   }
  // });
  // Sound Codeing

  const [audio] = useState(new Audio(call));

  // const audioMute = async () => {
  //   await audio.pause();
  //   setPlaying(true);
  // };
  // const audioPlay = async () => {
  //   await audio.play();
  //   setPlaying(false);
  // };

  useEffect(() => {
    if (mid) {
      audio.play();
      audio.loop= true;
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [audio, mid]);

  useEffect(() => {
    //   if (!mid) {
    //     navigate(-1);
    //     window.location.reload()
    //   }
    setTimeout(() => {
      setTime(time + 1);
      // After 30 second to back in page
      if (time === 32) {
        // Stop Sound
        // setPlaying(false);
        navigate(-1);
      }
    }, 1000);

    var obj = {
      meetingid: mid,
    };
    axios
      .post(`${apiUrl}/meeting/getconnectvideosession`, obj, {})
      .then(function (response) {
        // debugger
        if (response.data.status === 1) {
          setFirstname(response.data?.result?.senderData?.firstname);
          setProfile(
            apiUrl + response.data?.result?.senderData?.profile ||
              "/img/Small-no-img.png"
          );
          sessionStorage.setItem("remoteUser", JSON.stringify(response.data?.result?.senderData))
          setAddData(response?.data?.result?.senderData?._id)
          //
          if (response.data?.result?.videoSessions?.statusid === 0) {
            return true;
          } else if (response.data?.result?.videoSessions?.statusid === 1) {
            //navigate("/videosession?mid=" + response.data?.result?.videoSessions?.meetingid);
            /* if(!window.location.hash) {
                        window.location = window.location + '#loaded';
                        window.location.reload();
                    } */
            // window.location.href =
            //   "/#/videosession?mid=" +
            //   response.data?.result?.videoSessions?.meetingid;
          } else if (response.data?.result?.videoSessions?.statusid === 3) {
            // debugger
            navigate(
              loginuserrole === "client"
                ? "/trainer?status=1"
                : "/schedulerequest"
            );
          } else {
            navigate(-1);
          }
        }
        return true;
      })
      .catch(function (error) {});
  });
  //socket io
  const userEmail = JSON.parse(sessionStorage.user).email;
  const socket = useSocket();
  const handleJoinRoom = useCallback(
    (data) => {
      const { meetingId } = data;
      navigate(`/videosession/${meetingId}`);
    },
    [navigate]
  );

  useEffect(() => {
    socket.on("room:join", handleJoinRoom);
    return () => {
      socket.off("room:join", handleJoinRoom);
    };
  }, [socket, handleJoinRoom]);

  const acceptMeeting = async () => {
    socket.emit("pickCall", { to:addData, meetingId });
    socket.emit("room:join", { meetingId: meetingId, email: userEmail });
    var obj = {
      meetingid: mid,
    };
    setIsLoader(true);
    await axios
      .post(`${apiUrl}/meeting/joinvideosession`, obj, {})
      .then(function (response) {
        setIsLoader(false);
        // Stop Sound
        // setPlaying(false);
        if (response.data.status === 1) {
          // navigate("/videosession?mid=" + mid);
        } else {
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
          //window.alert(response.data.message);
        }
      })
      .catch(function (error) {
        //window.alert(error);
        /* document.querySelector('.loading').classList.add('d-none'); */
        setIsLoader(false);
      });
  };
  const rejectMeeting = async () => {
    var obj = {
      meetingid: mid,
      toid: loginUser?._id,
      type: "to",
    };

    await axios
      .post(`${apiUrl}/meeting/disconnectvideosession`, obj, {})
      .then(function (response) {
        // Stop Sound
        // setPlaying(false);
        if (response.data.status === 1) {
          navigate(-1);
        } else {
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            button: true,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {isLoader && (
        <div id="loader" className="loading">
          <div className="mainloader"></div>
        </div>
      )}

      <head>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>@ViewData["Title"] - Fitness Management</title>
        <link rel="icon" href="/img/favicon.png" />
        <link
          rel="stylesheet"
          href="~/lib/bootstrap/dist/css/bootstrap.min.css"
        />
        <link rel="stylesheet" href="~/css/site.css" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
        />
      </head>

      <div className="bg-black">
        <div className="col-md-12">
          <div className="row">
            <div className="vediosession mx-auto d-block my-5 py-5">
              {profile && (
                <>
                  <img
                    src={`${profile}`}
                    className="mt-5 w-100"
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = "/img/Small-no-img.png";
                    }}
                  />
                </>
              )}
              <h3 className="text-center text-white mt-4">
                {firstname}
              </h3>
              <p className="text-center text-white font-weight-bold">
                Wants to Join Session
              </p>
              <ul className="list-inline d-flex justify-content-between">
                <li className="list-inline-item">
                  <a
                    href={() => false}
                    onClick={(e) => {
                      e.stopPropagation();
                      rejectMeeting();
                    }}
                  >
                    <i className="fas fa-phone bg-danger p-4 rounded-circle text-white"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href={() => false}
                    onClick={(e) => {
                      e.stopPropagation();
                      acceptMeeting();
                    }}
                  >
                    <i className="fas fa-video bg-success p-4 rounded-circle text-white"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <script src="~/lib/jquery/dist/jquery.min.js"></script>
      <script src="~/lib/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
      <script src="~/js/site.js" asp-append-version="true"></script>
    </>
  );
}

export default Incoming;
