import axios from "axios";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { apiUrl } from "../../environment/environment";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Allnotifications = () => {
  const navigate = useNavigate();
  const [notification, setNotification] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    setIsLoader(true);
    axios
      .get(`${apiUrl}/notification/getAll`)
      .then((response) => {
        if (response.status === 200) {
          // const read = notification.filter(x=>x.isRead === '0')
          setTimeout(() => {
            response.data.result.reverse();
            setNotification(response.data.result);
            setIsLoader(false);
          }, 500);
        }
      })
      .catch(function (error) {
        setIsLoader(false);
      });
  }, []);

  const markRead = (id) => {
    axios
      .post(`${apiUrl}/notification/markread`, { id: id })
      .then((response) => { })
      .catch((err) => { });
  };

  const deleteNotification = (id) => {
    // console.log("id", id);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        reverseButtons: false,
        confirmButtonClass: "mx-2",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
    axios
      .post(`${apiUrl}/notification/removed`, { id })
      .then((response) => {
        if (response.status === 200) {
          const updateList = notification.filter((x) => x._id !== id);
          setNotification(updateList)
          navigate('/allnotification')
        }
      })
      .catch((err) => { });
    }})
  }

 

  return (
    <>
     
      <div className="sub-head-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-12">
              <h1 className="main_title">All Notifications</h1>
            </div>
          </div>
        </div>
      </div>
     
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-12">
            {isLoader && (
              <div id="loadermodal" className="loader"><div className="popuploader loginloader"></div></div>
            )}
            <div className="row">
              {notification.length > 0
                ? notification.map((element, index) => {
                  return (
                    <div
                      className="col-12"
                      onClick={(e) => {
                        e.preventDefault();
                        markRead(element._id);
                      }}
                      // key={`notification_${element}`}
                      key={index}
                      style={element.isRead === "0" ? {} : {}}
                    >
                      <div className="notification-box">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="">
                            <h5 className="noti-title">{element.title}</h5>
                            <div className="date-time">
                              <Moment
                                format="DD MMMM YYYY, hh:mm A"
                                date={element.createdAt}
                              />
                            </div>
                            <p className="noti-content">{element.message}</p>
                          </div>
                          <i onClick={() => deleteNotification(element._id)} className="fa fa-times-circle close-icon"></i>
                        </div>
                      </div>
                    </div>
                  );
                })
                : isLoader === false && (
                  <>
                    <div className="col-md-12">
                      <div className="no-notification notification-box">
                      <div className="no-notification"><i className="fas fa-bell"></i><h4>No notifications Found</h4></div>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
        {/* <div className="col-md-12 col-12 text-center mt-5">
                            <button className="training_btn w-25 mx-auto">Export Report</button>
                        </div> */}
      </div>
    </>
  );
};

export default Allnotifications;
