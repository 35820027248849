import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { PlaidLink } from "react-plaid-link";
import swal from "sweetalert";
import { apiUrl } from "../../environment/environment";

const Plaid = () => {
  const [token, setToken] = useState(null);
  const stripeUid = "acct_1KnsVtBI5VuvGZzp";

  useEffect(() => {
    async function createLinkToken() {
      axios
        .post(`${apiUrl}/exchange/getLinkToken`, {}, {})
        .then(function (response) {
          if (response.data.status === 1) {
            setToken(response.data.link_token);
          }
          return true;
        })
        .catch(function (error) {});
    }
    createLinkToken();
  }, []);

  const onSuccess = useCallback(
    (publicToken, metadata) => {
      // document.querySelector('.loading').classList.remove('d-none');
      // Exchange a public token for an access one.
      async function exchangeTokens() {
        var obj = {
          public_token: publicToken,
          account_id: metadata.account_id,
        };
        axios
          .post(`${apiUrl}/exchange/receivePublicToken`, obj, {})
          .then(function (response) {
            if (response.data.status === 1) {
              const trainerId = JSON.parse(sessionStorage.getItem("user"));
              // Bank account update
              var bankobj = {
                id: trainerId._id,
                stripebanktoken:
                  response.data.result.stripebankaccountToken
                    .stripe_bank_account_token,
                accountInfo: response.data.result.accountResponse,
              };
              axios
                .post(
                  `${apiUrl}/trainer/accountinfo/saveaccountinfo`,
                  bankobj,
                  {}
                )
                .then(function (response) {
                  // document.querySelector('.loading').classList.add('d-none');
                  if (response.data.status === 1) {
                    sessionStorage.setItem(
                      "user",
                      JSON.stringify(response.data.result)
                    );
                    window.location.reload(false);
                    // navigate("/signupsuccess");
                  } else {
                    swal({
                      title: "Error!",
                      text: response.data.message,
                      icon: "error",
                      button: true,
                    });
                  }
                })
                .catch(function (error) {
                  // document.querySelector('.loading').classList.add('d-none');
                });
            }
            // document.querySelector('.loading').classList.add('d-none');
            return true;
          })
          .catch(function (error) {
            // document.querySelector('.loading').classList.add('d-none');
          });
      }
      exchangeTokens();
    },
    [stripeUid]
  );
  return (
    <>
      <div id="plaidbutton">
        <PlaidLink
          style={{
            float: "right",
            background: "#243881",
            color: "#fff",
            padding: "8px",
            fontSize: "15px",
          }}
          token={token}
          onSuccess={onSuccess}
        >
          <i className="fa fa-plus pr-2" aria-hidden="true" /> Add Bank Account
        </PlaidLink>
      </div>
    </>
  );
};

export default Plaid;
