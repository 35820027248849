import axios from "axios";
import React, { useEffect, useState } from "react";
import { apiUrl } from "../../environment/environment";
import Moment from "react-moment";
import { useLocation } from "react-router-dom";

function SessionPaymentDetail() {
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let paymentId = query.get("id");
  // const queryStringPara = new URLSearchParams(window.location.search);
  // let paymentId = queryStringPara.get("id");
  // let paymentId = window.location.href.split('id=')[1];
  const [paymentData, setPaymentData] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [, setcancelTime] = useState(false);

  useEffect(() => {
    axios
      .post(`${apiUrl}/payment/paymentdetails`, { id: paymentId })
      .then((response) => {
        if (response.status === 200) {
          const then = new Date(response.data.result.createdAt);
          const now = new Date();
          const msBetweenDates = Math.abs(then.getTime() - now.getTime());

          // 👇️ convert ms to hours                  min  sec   ms
          const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
          if (hoursBetweenDates < 24) setcancelTime(true);
          setIsLoader(false)
          setPaymentData(response.data.result);
        }
      })
      .catch((err) => {
        setIsLoader(false)
      });
  }, [paymentId]);

  return (
    <>
      {isLoader && (
        <div id="loader" className="loading">
          <div className="mainloader"></div>
        </div>
      )}
      <div className="sub-head-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-12">
              <h1 className="main_title">Session Payment Details</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container mob-view">
        <div className="row mt-lg-5">
          <div className="col-xl-9 col-md-12 col-12 mx-lg-auto">
            <div className="payment-detail">
              <div className="row">
                <div className="col-md-4 col-12 pr-md-0">
                  <div className="p-detail-bg">
                    <img src="/img/payment-img.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-8 col-12 pl-0">
                  <div className="p-detail-content">
                    <div className="row">
                      <div className="col-xl-5 col-md-4 col-sm-4 col-12 position-relative">
                        <label>
                          Plan Name <span className="titlecolon">:</span>
                        </label>
                      </div>

                      <div className="col-xl-7 col-md-8 col-sm-8 col-12">
                        <p>{paymentData.plantype}</p>
                      </div>

                      <div className="col-xl-5 col-md-4 col-sm-4 col-12 position-relative">
                        <label>
                          Session <span className="titlecolon">:</span>
                        </label>
                      </div>

                      <div className="col-xl-7 col-md-8 col-sm-8 col-12">
                        <p>
                          {paymentData.noofsession === "1"
                            ? "Single session"
                            : `${paymentData.noofsession} sessions`}{" "}
                        </p>
                      </div>
                      {/* <div className="col-xl-3 col-md-3 col-sm-4 col-12 position-relative">
                                    <label>No of session(s) <span className="titlecolon">:</span></label>
                                </div>
                                <div className="col-xl-9 col-md-9 col-sm-8 col-12">
                                    <p>{paymentData.noofsession}</p>
                                </div> */}
                      <div className="col-xl-5 col-md-4 col-sm-4 col-12 position-relative">
                        <label>
                          Purchased Date <span className="titlecolon">:</span>
                        </label>
                      </div>
                      <div className="col-xl-7 col-md-8 col-sm-8 col-12">
                        <p>
                          <Moment
                            format="DD MMMM YYYY, hh:mm A"
                            date={paymentData.updatedAt}
                          />
                        </p>
                      </div>
                      <div className="col-xl-5 col-md-4 col-sm-4 col-12 position-relative">
                        <label>
                          Amount <span className="titlecolon">:</span>
                        </label>
                      </div>
                      <div className="col-xl-7 col-md-8 col-sm-8 col-12">
                        <p>$ {paymentData.amount}</p>
                      </div>
                      <div className="col-xl-5 col-md-4 col-sm-4 col-12 position-relative">
                        <label>
                          Status <span className="titlecolon">:</span>
                        </label>
                      </div>
                      <div className="col-xl-7 col-md-8 col-sm-8 col-12">
                            <p className="text-success font-weight-bold">Paid</p>
                          {/* {cancelTime === true ?
                                            <>
                                                <div className="col-4">
                                                    <button onClick={cancelOrder} className="btn btn-danger w-100">Cancel Order</button>
                                                </div>
                                            </>
                                            : <></>
                                        } */}
                        </div>
                        {/* <p><span className="btn-success p-status m-0 text-center">Paid</span></p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SessionPaymentDetail;
