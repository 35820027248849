/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react";
import { apiUrl } from "../../environment/environment";
import Moment from "react-moment";
import { Modal } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Pagination from "../../Pagination/Pagination";
// import DwonloadExcel from "../DownloadExcel";

function ClientPaymentHistory() {
  const navigate = useNavigate()
  const [isLoader, setIsLoader] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const handleClose = () => setShowModel(false);
  const [getData, setGetData] = useState({
    _id: "",
    plantype: "",
    noofsession: "",
    amount: ""
  })
  const [paymentList, setPaymentList] = useState([]);

  const { page } = useParams();

  const limitValue = 10;
  const [pageNum, setPageNum] = useState(+page);
  const [noOfRecords, setNoOfRecords] = useState(0);

  useEffect(() => {
    paymentData(1)
    // handleDownload()
  }, []);

  async function paymentData(val) {
    let obje = {
      limitValue: limitValue,
      pageNumber: val || pageNum,
    }
    setIsLoader(true);
    axios
      .post(`${apiUrl}/payment/clientpaymenthistory`, obje)
      .then((response) => {
        if (response.status === 200) {
          console.log("response.data.result",response.data.result);
          setTimeout(() => {
            setNoOfRecords(response.data.result.noOfRecords || 0)
            setPaymentList(response.data.result.paymentlist);
            setIsLoader(false);
          }, 500);
        }
      })
      .catch(function (error) {
        setIsLoader(false);
      });
  }

  const curPage = (pageNumCur) => {
    navigate(`/payment/clientpaymenthistory/${pageNum}`);
    setPageNum(pageNumCur);
    paymentData(pageNumCur);
  };


  const viewPaymentDetail = (obj) => {
    setShowModel(true);
    setGetData(obj)
    setGetData({
      ...getData,
      _id: obj._id,
      plantype: obj.plantype,
      noofsession: obj.noofsession,
      amount: obj.amount
    })
  }

  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Months are zero-based
  const year = currentDate.getFullYear();

  


  return (
    <>

      <div className="sub-head-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-12">
              <h1 className="main_title">Payment Histroy</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="col-md-12 col-12 p-0">
          <div className="trainer-main-box">
            <div className="row">
              {isLoader && 
               <div className="loading">
                   <div className="mainloader"></div>
               </div>
              }
              <div className="col-md-12 col-12 mb-4">
                {paymentList.length > 0 ? (
                  <>
                    {/* <button  className="download-table-xls-button btn btn-primary float-right" onClick={()=>handleDownload("test-table-xls-button")}>
                      Download
                    </button> */}
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button btn btn-primary float-right"
                      table="client_payment_history"
                      filename={`Payment_histroy_${day}/${month}/${year}`}
                      sheet="tablexls"
                      buttonText="Export to excel"
                    />

                  </>
                ) : (
                  <></>
                )}
                <button className="download-table-xls-button btn btn-primary" onClick={() => navigate("/purchasesession")}>Back</button>
              </div>
              <div className="col-md-12 col-12">
                {paymentList.length > 0 ? (
                  <>
                    <div className="history-table table-responsive payment-histroy">
                      <table className="table" id="client_payment_history">
                        <thead>
                          <tr>
                            <th className="wd70">Sr. No</th>
                            <th>Date</th>
                            <th>Plan type</th>
                            <th>No of session(s)</th>
                            <th>Amount</th>
                            <th className="text-center">Status</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paymentList.length > 0 ? (
                            paymentList.map((element, index) => {
                              return (
                                <>
                                  <tr key={`payment_${element}`}>
                                    <td>{index + 1 + (pageNum - 1) * limitValue}</td>
                                    <td>
                                      <Moment
                                        format="DD MMMM YYYY, hh:mm A"
                                        date={element.createdAt}
                                      />
                                    </td>
                                    <td>{element.plantype}</td>
                                    <td>
                                      {element.noofsession === 1
                                        ? "Single session"
                                        : element.noofsession + " sessions"}
                                    </td>
                                    <td>$ {element.amount.toFixed(2)}</td>
                                    <td className="text-center">
                                      <span className="text-success paid-s p-status font-weight-bold">
                                        Paid
                                      </span>
                                    </td>
                                    <td className="text-center">
                                      <Link
                                        className="detail-btn"
                                        onClick={() => viewPaymentDetail(element)}
                                      // to={`/sessionpaymentdetail?id=${element._id}`}
                                      >
                                        <i className="fas fa-eye"></i>
                                      </Link>
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          ) : (
                            <>
                              <tr>
                                <td colSpan="10" className="-text-center">
                                  <div className="col-12 p-0 mb-4">
                                    <div className="trainer-main-box pb-0">
                                      <div className="trainer-main-box">
                                        <div className="no-record-box">
                                          <img
                                            src="/img/no-record.png"
                                            alt="no-img"
                                          />
                                          <h4 className="font-weight-bold">
                                            Payment Histroy Empty !
                                          </h4>
                                          <p>
                                            You have no Payment transection yet
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <>
                    {isLoader === false && (
                      <>
                        <div className="col-12">
                          <div className="trainer-main-box pb-0">
                            <div className="trainer-main-box">
                              <div className="no-record-box">
                                <img src="/img/no-record.png" alt="no-img" />
                                <h4 className="font-weight-bold">
                                  Payment Histroy Empty !
                                </h4>
                                <p>You have no Payment transection yet</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                <Pagination
                  className="pagination-bar"
                  currentPage={pageNum}
                  totalCount={noOfRecords}
                  pageSize={limitValue}
                  onPageChange={(page) => curPage(page)}
                />
              </div>
              {/* <div className="col-md-12 col-12 text-center mt-5">
                            <button className="training_btn w-25 mx-auto">Export Report</button>
                        </div> */}
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModel} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton className="d-block text-center wrokform-modal">
          <Modal.Title className="d-flex align-items-center mb-0"><img src="/img/common-health.png" alt="logo" /><h4 className="mb-0 ml-3 font-weight-bold">Payment Histroy Detail</h4></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="payment-detail">
            <div className="row">
              <div className="col-lg-4 col-12 pr-md-0">
                <div className="p-detail-bg">
                  <img src="/img/payment-img.jpg" alt="" />
                </div>
              </div>
              <div className="col-lg-8 col-12 pl-0">
                <div className="p-detail-content">
                  <div className="row">
                    <div className="col-xl-5 col-md-4 col-sm-4 col-12 position-relative">
                      <label>Plan Name </label>
                    </div>
                    <div className="col-xl-7 col-md-8 col-sm-8 col-12">
                      <p>{getData.plantype}</p>
                    </div>
                    <div className="col-xl-5 col-md-4 col-sm-4 col-12 position-relative">
                      <label>Session </label>
                    </div>
                    <div className="col-xl-7 col-md-8 col-sm-8 col-12">
                      <p>
                        {getData.noofsession === 1
                          ? `${getData.noofsession} session`
                          : `${getData.noofsession} sessions`}
                      </p>
                    </div>
                    {/* <div className="col-xl-3 col-md-3 col-sm-4 col-12 position-relative">
                                    <label>No of session(s) <span className="titlecolon">:</span></label>
                                </div>
                                <div className="col-xl-9 col-md-9 col-sm-8 col-12">
                                    <p>{getData.noofsession}</p>
                                </div> */}
                    <div className="col-xl-5 col-md-4 col-sm-4 col-12 position-relative">
                      <label>Purchased Date</label>
                    </div>
                    <div className="col-xl-7 col-md-8 col-sm-8 col-12">
                      <p><Moment format="DD MMMM YYYY, hh:mm A" date={getData.updatedAt} /></p>
                    </div>
                    <div className="col-xl-5 col-md-4 col-sm-4 col-12 position-relative">
                      <label>Amount</label>
                    </div>
                    <div className="col-xl-7 col-md-8 col-sm-8 col-12">
                      <p>$ {getData.amount}</p>
                    </div>
                    <div className="col-xl-5 col-md-4 col-sm-4 col-12 position-relative">
                      <label>Status</label>
                    </div>
                    <div className="col-xl-7 col-md-8 col-sm-8 col-12">
                      <p className="text-success paid-s p-status font-weight-bold m-0">Paid</p>
                      {/* {cancelTime === true ?
                                            <>
                                                <div className="col-4">
                                                    <button onClick={cancelOrder} className="btn btn-danger w-100">Cancel Order</button>
                                                </div>
                                            </>
                                            : <></>
                                        } */}
                    </div>
                    {/* <p><span className="btn-success p-status m-0 text-center">Paid</span></p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      {/* <div hidden className="history-table table-responsive payment-histroy">
                      <table className="table" id="client_payment_history">
                        <thead>
                          <tr>
                            <th>Sr. No</th>
                            <th>Date</th>
                            <th>Plan type</th>
                            <th>No of session(s)</th>
                            <th>Amount</th>
                            <th className="text-center">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {downloadData.length > 0 ? (
                            downloadData.map((element, index) => {
                              return (
                                <>
                                  <tr key={`payment_${element}`}>
                                    <td>{index + 1}</td>
                                    <td>
                                      <Moment
                                        format="DD MMMM YYYY, hh:mm A"
                                        date={element.createdAt}
                                      />
                                    </td>
                                    <td>{element.plantype}</td>
                                    <td>
                                      {element.noofsession === 1
                                        ? "Single session"
                                        : element.noofsession + " sessions"}
                                    </td>
                                    <td>$ {element.amount.toFixed(2)}</td>
                                    <td className="text-center">
                                      <span className="text-success paid-s p-status font-weight-bold">
                                        Paid
                                      </span>
                                    </td>
                                   
                                  </tr>
                                </>
                              );
                            })
                          ) : (
                            <>
                              <tr>
                                <td colSpan="10" className="-text-center">
                                  <div className="col-12 p-0 mb-4">
                                    <div className="trainer-main-box pb-0">
                                      <div className="trainer-main-box">
                                        <div className="no-record-box">
                                          <img
                                            src="/img/no-record.png"
                                            alt="no-img"
                                          />
                                          <h4 className="font-weight-bold">
                                            Payment Histroy Empty !
                                          </h4>
                                          <p>
                                            You have no Payment transection yet
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
      </div> */}

    </>
  );
}

export default ClientPaymentHistory;
