import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { apiUrl } from "../../environment/environment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import { Modal } from "react-bootstrap";
import { phoneNumberLength } from "../utility/Utility";

const intiVal = {
  platform: "",
  icon: "",
  url: "",
}

function EditProfile() {

  const naviagte = useNavigate();
  const ProfileImage_URL = "/img/Small-no-img.png";
  const userFirstNameRef = useRef()
  const userLastNameRef = useRef()
  const userPhonenoRef = useRef()
  const userAgeRef = useRef()
  const userHeightRef = useRef()
  const userWeightRef = useRef()
  const userInjuriesRef = useRef()

  const [user, setUser] = useState({
    // countryCode: null,
    firstname: "",
    lastname: "",
    email: "",
    oldpassword: "",
    password: "",
    confirmpassword: "",
    phoneno: "",
    countryDialCode: "",
    countryCode: "",
    age: "",
    gender: "Male",
    heightisfeet: true,
    height: "",
    weightiskg: true,
    weight: "",
    equipmentavailable: "",
    fitnessgoals: "",
    otherfitnessgoals: "",
    injuriesorhelthissues: "",
    emailnotifications: false,
    maillinglist: false,
    textnotifications: false,
    webnotifications: false,
    mobilenotifications: false,
  });
  const [fitnessgoal, setFitnessGoal] = useState([]);
  const [mobileNumber, setMobilenumber] = useState("");
  const [mobileNumberValidation, setMobileNumberValidation] = useState(true);
  useEffect(() => {
    // callToken();
    Getgoals()
    fetchProfile(0);
    // GetCountryList();
  }, []);


  const [errors, setErrors] = useState({});
  const [selectAllGoal, setSelectAllGoal] = useState([])

  const [profileimagepreview, setProfileImagePreview] = useState(ProfileImage_URL);
  const [profileimage, setProfileImage] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const [socialmedia, setSocialmedia] = useState(intiVal);
  const [socialmediaModal, setSocialmediaModal] = useState(false);
  const iconRef = useRef()
  const urlRef = useRef()

  async function Getgoals() {
    await axios.get(`${apiUrl}/client/getallgoals`).then((res) => {
      setSelectAllGoal(res.data.result)
    })
  }
  const heightRef = useRef(null);

  const weightRef = useRef(null);

  const handleHeightScroll = (itemTitle) => {
    const listContainer = heightRef.current;
    const clickedItem = listContainer.querySelector(`[title="${itemTitle}"]`);
    const rightScroll = clickedItem.offsetLeft + 250 - listContainer.clientWidth;
    listContainer.scrollTo({
      left:rightScroll,
      behavior: 'smooth'
    });
  }

  const handleWeightRefScroll = (itemTitle) => {
    const listContainer = weightRef.current;
    const clickedItem = listContainer.querySelector(`[title="${itemTitle}"]`);
    const rightScroll = clickedItem.offsetLeft + 250 - listContainer.clientWidth;
    listContainer.scrollTo({
      left:rightScroll,
      behavior: 'smooth'
    });
  }

  const fetchProfile = async (val) => {
    document.querySelector(".loading").classList.remove("d-none");
    await axios
      .get(`${apiUrl}/client/account/getprofile`, {}, {})
      .then(function (response) {
        if (response.data.status === 1) {
          setTimeout(() => {
            response.data.result.oldpassword = "";
            response.data.result.password = "";
            response.data.result.confirmpassword = "";
            setUser(response.data.result);
            let contectNUmber =
              response.data.result.country +
              response.data.result.countryDialCode +
              response.data.result.phoneno;

            setMobilenumber(contectNUmber);
            user.height = response.data.result.height;
            user.weight = response.data.result.weight;
            handleWeightRefScroll(response.data.result.weight)
            handleHeightScroll(response.data.result.height)
           


            setFitnessGoal(response.data.result.fitnessgoals);

            let profileImage =
              response.data?.result?.profile !== "null" &&
                response.data?.result?.profile !== "undefined" &&
                response.data?.result?.profile !== ""
                ? apiUrl + response.data?.result?.profile
                : ProfileImage_URL;

            setProfileImagePreview(profileImage);
            setProfileImage(profileImage);
            if (val === 1) {
              const loginuserdetail = sessionStorage.getItem("user");
              var loginUser = {};
              if (loginuserdetail) {
                loginUser = JSON.parse(loginuserdetail);

                const loginImg = response.data?.result?.profile !== "null" &&
                  response.data?.result?.profile !== "undefined" &&
                  response.data?.result?.profile !== ""
                  ? response.data?.result?.profile
                  : ProfileImage_URL;

                loginUser.profile = loginImg || ProfileImage_URL;
              }
            }
            document.querySelector(".loading").classList.add("d-none");
          }, 500);
        }

      })
      .catch(function (error) {
        window.alert(error)
        document.querySelector(".loading").classList.add("d-none");

      });
  };

  const SelectHeight = (e, number) => {
    if (document.querySelector("#heightul li.active") !== null)
      document.querySelector("#heightul li.active").classList.remove("active");

    e.currentTarget.classList.add("active");

    user.height = number;
  };

  const SelectWeight = (e, number) => {
    if (document.querySelector("#weightul li.active") !== null)
      document.querySelector("#weightul li.active").classList.remove("active");

    e.currentTarget.classList.add("active");

    user.weight = number;
  };

  const range = (start, end, step) => {
    return Array.from(
      Array.from(Array(Math.ceil((end - start) / step)).keys()),
      (x) => start + x * step
    );
  };

  const handleFitnessGoal = (e) => {
    if (e.currentTarget.checked) {
      fitnessgoal.push(e.target.value);
      setFitnessGoal(fitnessgoal.map((x) => x));
      user.fitnessgoals = fitnessgoal.map((x) => x).join(",");
    } else {
      setFitnessGoal(fitnessgoal.filter((x) => x !== e.target.value));
      user.fitnessgoals = fitnessgoal
        .filter((x) => x !== e.target.value)
        .join(",");
    }
  };

  const OnFileChange = (event) => {
    const file_size = event.target.files[0].size;
    if (file_size > 1048000) {
      setProfileImagePreview(ProfileImage_URL);
      //setProfileImage(null);
      alert("File size more than 1 MB. File size must under 1MB !");
      fetchProfile(1);
      event.preventDefault();
    } else {
      const fileReader = new window.FileReader();
      const file = event.target.files[0];
      setProfileImage(event.target.files[0]);
      fileReader.onload = (fileLoad) => {
        const { result } = fileLoad.target;
        //setProfileImage(result);
        setIsEdit(true);
        setProfileImagePreview(result);
      };

      fileReader.readAsDataURL(file);
    }
  };

  const OnFileDiacard = () => {
    setIsEdit(false);
    setProfileImagePreview(profileimagepreview && ProfileImage_URL);
    fetchProfile(1);
    naviagte("/editprofile");
  };

  // const validatePhoneNumber = (phoneNumber, countryCode) => {
  //   console.log("phoneNumber, countryCode", phoneNumber, countryCode);
  //   const formattedPhoneNumber = phoneNumber.replace(/[^0-9]/g, ""); // Remove non-numeric characters

  //   if (countryCode === "in") {
  //     return formattedPhoneNumber.length === 10;
  //   } else if (countryCode === "no" || countryCode === "am") {
  //     return formattedPhoneNumber.length === 8;
  //   } else if (countryCode === "al") {
  //     return formattedPhoneNumber.length === 12;
  //   } else if (countryCode === "hu" || countryCode === "id" || countryCode === "af") {
  //     return formattedPhoneNumber.length === 13;
  //   } else if (countryCode === "is") {
  //     return formattedPhoneNumber.length === 7;
  //   } else if (countryCode === "ie") {
  //     return formattedPhoneNumber.length === 9;
  //   } else {
  //     return formattedPhoneNumber.length === 10;
  //   }
  // };

  const isRequired = (fieldName) => {
    return ['firstname', 'lastname', 'phoneno', 'age', 'equipmentavailable', 'otherfitnessgoals', 'injuriesorhelthissues'].includes(fieldName)
  }

  const handleInputs = (e) => {
    const { name, value } = e.target

    if (value.trim() === "" && isRequired(name)) {
      let errorMessage = '';

      if (name === "firstname") {
        errorMessage = "Please enter first name";
      } else if (name === 'lastname') {
        errorMessage = "Please enter last name";
      } else if (name === "age") {
        errorMessage = "Please enter age";
      } else if (name === "gender") {
        errorMessage = "Please select gender";
      } else if (name === "injuriesorhelthissues") {
        errorMessage = "Please enter injuries Or helth issues";
      }

      setErrors((prevError) => ({ ...prevError, [name]: errorMessage }));
    } else if (((name === 'firstname') || (name === 'lastname')) && (value.length < 3 || value.length > 50)) {
      setErrors((prevError) => ({ ...prevError, [name]: "Please enter minimum 3 letters or maximum 50" }));
    } else if (name === 'age' && (value < 5 || value > 75)) {
      setErrors((prevError) => ({ ...prevError, age: "Age must be between 5 and 75 years" }));
    } else {
      setErrors((prevError) => ({ ...prevError, [name]: '' }));
    }

    if (name === "heightisfeet")
      setUser({ ...user, [name]: !!JSON.parse(String(value).toLowerCase()) });
    if (name === "weightiskg")
      setUser({ ...user, [name]: !!JSON.parse(String(value).toLowerCase()) });
    else if (name !== "heightisfeet")
      setUser({ ...user, [name]: value });

  };

  const PostEditProfile = async (e) => {
    e.preventDefault();
    setIsEdit(false);

    let isValid = true;
    var errormsg = {};

    let reg_numbers = /^[0-9]+$/;

    if (user.firstname === "") {
      errormsg.firstname = "Please enter first name.";
      isValid = false;
    } else if (user.firstname.length < 2 || user.firstname.length > 50) {
      errormsg.firstname = "First name should be between 3 and 50 characters.";
      isValid = false;
    }

    if (user.lastname === "") {
      errormsg.lastname = "Please enter last name.";
      isValid = false;
    } else if (user.lastname.length < 2 || user.lastname.length > 50) {
      errormsg.lastname = "Last name should be between 3 and 50 characters.";
      isValid = false;
    }

    if (user.phoneno === "") {
      errormsg.phoneno = "Please choose country and enter phone number.";
      isValid = false;
    } else if (!mobileNumberValidation) {
      errormsg.phoneno = "Please enter valid phone number.";
      isValid = false;
    }

    if (user.age === "") {
      errormsg.age = "Please enter Age.";
      isValid = false;
    } else if (user.age < 5 || user.age > 120 || !reg_numbers.test(user.age)) {
      errormsg.age = "Please enter valid Age.";
      isValid = false;
    }
    if (user.gender === "") {
      errormsg.gender = "Please select Gender.";
      isValid = false;
    }

    if (user.height === "") {
      errormsg.height = "Please select Height.";
      isValid = false;
    }
    if (user.weight === "") {
      errormsg.weight = "Please select Weight.";
      isValid = false;
    }
    if (fitnessgoal === "" && user.otherfitnessgoals === "") {
      errormsg.fitnessgoal = "Please select or enter Fitness Goal.";
      isValid = false;
    }
    if (user.injuriesorhelthissues === "") {
      errormsg.injuriesorhelthissues = "Please enter Injuries Or Helth Issues.";
      isValid = false;
    }

    if (user.country === "" || user.country === undefined) {
      errormsg.country = "Choose country.";
      isValid = false;
    }

    if (Object.keys(errormsg).length > 0) {
      if (errormsg.firstname) {
        userFirstNameRef.current.focus()
      } else if (errormsg.lastname) {
        userLastNameRef.current.focus()
      } else if (errormsg.phoneno) {
        userPhonenoRef.current.focus()
      } else if (errormsg.age) {
        userAgeRef.current.focus()
      } else if (errormsg.height) {
        userHeightRef.current.focus()
      } else if (errormsg.weight) {
        userWeightRef.current.focus()
      } else if (errormsg.injuriesorhelthissues) {
        userInjuriesRef.current.focus()
      }
    }


    setErrors(errormsg);
    if (isValid === true) {
      // user.phoneno = mobileNumber
      let arry = []
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(fitnessgoal)) {
        arry.push(value)
      }
      user.fitnessgoals = arry
      let obj = {
        //'profile': profileimage,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        phoneno: user.phoneno,
        countryDialCode: user.countryDialCode,
        countryCode: user.countryCode,
        age: user.age,
        gender: user.gender,
        heightisfeet: user.heightisfeet,
        height: user.height,
        weightiskg: user.weightiskg,
        weight: user.weight,
        equipmentavailable: user.equipmentavailable,
        fitnessgoals: JSON.stringify(user.fitnessgoals),
        otherfitnessgoals: user.otherfitnessgoals,
        injuriesorhelthissues: user.injuriesorhelthissues,
        emailnotifications: user.emailnotifications,
        maillinglist: user.maillinglist,
        textnotifications: user.textnotifications,
        webnotifications: user.webnotifications,
        mobilenotifications: user.mobilenotifications,

        oldpassword: user.oldpassword,
        password: user.password,
        confirmpassword: user.confirmpassword,
        country: user.country,
        // 'progressphotos': progressphotos,
      };
      var form_data = new FormData();
      for (var key in obj) {
        form_data.append(key, obj[key]);
      }

      let profile = profileimage;
      if (typeof profileimage == "string") {
        profile = profileimage.split(apiUrl);
        profile = profile[1] || "";
        setProfileImage(...profileimage, profile);
      }

      if (profileimage?.type === undefined) {
        form_data.append("edprofile", profile);
      } else {
        form_data.append("profile", profile);
      }

      document.querySelector(".loading").classList.remove("d-none");
      await axios
        .post(`${apiUrl}/client/account/updateprofile`, form_data, {})
        .then(function (response) {
          if (response.data.status === 1) {
            sessionStorage.setItem("progressphotos", []);
            sessionStorage.setItem("user", JSON.stringify(response.data.result));
            document.querySelector(".loading").classList.add("d-none");
            naviagte("/editprofile");
            fetchProfile(1);
            swal({
              title: "Success!",
              text: response.data.message,
              icon: "success",
              button: true,
            });
          } else {
            document.querySelector(".loading").classList.add("d-none");

            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
          }
        })
        .catch(function (error) {
          window.alert(error)
          document.querySelector(".loading").classList.add("d-none");
        });
    }
  };

  const pageNavigate = (e) => {
    e.preventDefault();
    naviagte("/viewphoto");
  };

  const openModal = () => {
    setSocialmedia(intiVal)
    setErrors({})
    document.querySelector(".loading").classList.add("d-none");
    setSocialmediaModal(true);
  };

  const mediaIsRequired = (fieldName) => {
    return ['platform', 'url'].includes(fieldName)
  }

  const socialmediaHandle = (e) => {
    const { name, value } = e.target

    if (value.trim() === "" && mediaIsRequired(name)) {
      let errorMessage = '';

      if (name === 'platform') {
        errorMessage = "Please select name."
      } else if (name === 'url') {
        errorMessage = "Please enter link."
      }
      setErrors((prevError) => ({ ...prevError, [name]: errorMessage }));
    } else {
      setErrors((prevError) => ({ ...prevError, [name]: '' }));
    }
    setSocialmedia({ ...socialmedia, [name]: value });
  };

  const [mediaLoader, setMediaLoader] = useState(false)

  const socialmediaAdd = async () => {
    let isValid = true;
    let errormsg = {};
    let url = socialmedia.url;

    if (!url.match(/^https?:\/\//i)) {
      url = `https://${url}`;
    }

    if (!url.match(/^www\./i)) {
      url = `www.${url}`;
    }

    url = url.replace(/^(https?:\/\/)?(www\.)?/i, '');

    if (!url.endsWith("/")) {
      url += "/";
    }

    const regexString = `${socialmedia.platform.toLowerCase()}\\.com`;
    const regex = new RegExp(regexString, 'gi');
    const match = regex.exec(url);

    if (socialmedia.platform === "") {
      errormsg.platform = "Please select name";
      isValid = false;
    }

    if (socialmedia.url === "") {
      errormsg.url = "Please enter link";
      isValid = false;
    } else if (!match) {
      errormsg.url = "Invalid Url";
      isValid = false;
    }

    if (Object.keys(errormsg).length > 0) {
      if (errormsg.platform) {
        iconRef.current.focus();
      } else if (errormsg.url) {
        urlRef.current.focus();
      }
    }

    setErrors(errormsg);
    if (isValid === true) {
      let icon
      if (socialmedia.platform === "Facebook") {
        icon = "fab fa-facebook-f"
      } else if (socialmedia.platform === "Instagram") {
        icon = "fab fa-instagram"
      } else if (socialmedia.platform === "Twitter") {
        icon = "fab fa-twitter"
      } else if (socialmedia.platform === "Linkedin") {
        icon = "fab fa-linkedin"
      } else if (socialmedia.platform === "Youtube") {
        icon = "fab fa-youtube"
      } else if (socialmedia.platform === "Tiktok") {
        icon = "fab fa-tiktok"
      }

      let obj = {
        platform: socialmedia.platform,
        icon: icon,
        url: url,
      };

      const userid = user._id;
      setMediaLoader(true)
      // document.querySelector(".loading").classList.remove("d-none");
      await axios
        .post(`${apiUrl}/client/account/socialmedia/${userid}`, obj)
        .then((response) => {
          if (response.data.status === 1) {
            setTimeout(() => {
              sessionStorage.setItem("user", JSON.stringify(response.data.result));
              setUser(response.data.result);
              swal({
                title: "success!",
                text: response.data.message,
                icon: "success",
                button: true,
              });
              setSocialmedia(intiVal)
              setSocialmediaModal(false);
              setMediaLoader(false)
            }, 500);
          } else {
            setMediaLoader(false)
            swal({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              button: true,
            });
          }
        }).catch((err) => {
          document.querySelector(".loading").classList.add("d-none");
          setMediaLoader(false)

        })
    }
  };

  const removeMedi = async (mid) => {
    const userid = user._id;
    Swal.fire({
      text: "Do you want to delete your social media account ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {

      if (result.isConfirmed) {
        document.querySelector(".loading").classList.remove("d-none");
        axios
          .post(`${apiUrl}/client/account/deleteSocialmedia/${userid}`, { mid })
          .then(function (response) {
            if (response.data.status === 1) {
              setTimeout(() => {
                setUser(response.data.result);
                sessionStorage.setItem("user", JSON.stringify(response.data.result));
                swal({
                  title: "Success!",
                  text: response.data.message,
                  icon: "success",
                  button: true,
                });
                document.querySelector(".loading").classList.add("d-none");
              }, 300);
            } else {
              document.querySelector(".loading").classList.add("d-none");
              swal({
                title: "Error!",
                text: response.data.message,
                icon: "error",
                button: true,
              });
            }
          })
      }
    });
  };

  return (
    <>
      <div className="sub-head-title">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-6">
              <h1 className="main_title">My Profile</h1>
            </div>
            <div className="col-md-6 col-6 text-right">
              <Link className="noti-btn" to="/notifications">
                <i className="fas fa-cog"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-md-12 col-12 p-0">
          {/* <form encType='multipart/form-data'> */}
          <div className="edit-profile">
            <div className="loading d-none">
              <div className="mainloader"></div>
            </div>
            <div className="row">
              <div className="col-md-6 col-12 mb-4">
                <div className="trainer-main-box">
                  <div className="row myprofile">
                    <div className="col-xl-4 col-12 mb-2">
                      <div className="edit_proimg avatar-upload">
                        <div className="avatar-edit">
                          <input
                            type="file"
                            onChange={OnFileChange}
                            id="imageUpload"
                            accept=".png, .jpg, .jpeg"
                          />
                          {isEdit ? (
                            <>
                              <button
                                className="img-cancel"
                                onClick={OnFileDiacard}
                              >
                                <i className="fas fa-times"></i>
                              </button>
                            </>
                          ) : (
                            <>
                              <label htmlFor="imageUpload"></label>
                            </>
                          )}
                        </div>
                        <div className="avatar-preview">

                          <div
                            id="imagePreview"
                            style={{ backgroundImage: `url(${profileimagepreview || ProfileImage_URL} )` }}
                          ></div>

                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-12 mb-2">
                      <div className="row">
                        <div className="col-12">
                          <label className=""> First Name </label> <span className="text-danger font-weight-bold">*</span>
                          <input
                            onChange={(e) => handleInputs(e)}
                            value={user.firstname}
                            name="firstname"
                            type="text"
                            ref={userFirstNameRef}
                            className="w-100  mb-4 input-box"
                            placeholder="First Name"
                          />
                          <span className="text-danger">{errors.firstname}</span>
                        </div>
                        <div className="col-12">
                          <label className=""> Last Name </label> <span className="text-danger font-weight-bold">*</span>
                          <input
                            onChange={(e) => handleInputs(e)}
                            value={user.lastname}
                            name="lastname"
                            type="text"
                            ref={userLastNameRef}
                            className="w-100  mb-4 input-box"
                            placeholder="Last Name"
                          />
                          <span className="text-danger">{errors.lastname}</span>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-12 col-12">
                      <label className=""> Email </label>
                      <input
                        onChange={(e) => handleInputs(e)}
                        value={user?.email}
                        name="email"
                        type="text"
                        className="w-100 mb-4 input-box"
                        placeholder="Email"
                      />
                      <div className="text-danger">{errors.email}</div>
                    </div> */}
                    <div className="col-md-12 col-12 mb-4">
                      <label className="">Phone Number </label> <span className="text-danger font-weight-bold">*</span>
                      <PhoneInput
                        country={"us"}
                        enableSearch={true}
                        countryCodeEditable={false}
                        inputclassName="w-100 mb-4"
                        name="phoneno"
                        inputProps={{ ref: userPhonenoRef }}
                        value={mobileNumber}
                        onChange={(value, country) => {
                          const phone = value.replace(country.dialCode, "");
                          const numberLength = phoneNumberLength(country)
                          if (phone === "") {
                            setErrors((prevError) => ({ ...prevError, phoneno: "Please enter phone number" }));
                          } else if (numberLength > phone.length) {
                            setMobileNumberValidation(false)
                            setErrors((prevError) => ({ ...prevError, phoneno: `Please enter valid phone number` }));
                          } else {
                            setMobileNumberValidation(true)
                            setErrors((prevError) => ({ ...prevError, phoneno: "" }));
                          }

                          setUser((prev) => ({ ...prev, phoneno: phone }));
                          setUser((prev) => ({ ...prev, country: country.name }));
                          setUser((prev) => ({ ...prev, countryCode: country.countryCode }));
                          setUser((prev) => ({ ...prev, countryDialCode: `+${country.dialCode}` }));
                        }}
                      />
                      <div className="text-danger">{errors.phoneno}</div>
                    </div>
                    <div className="col-md-12 col-12">
                      <label className=""> Age </label> <span className="text-danger font-weight-bold">*</span>
                      <input
                        onChange={(e) => handleInputs(e)}
                        value={user.age}
                        name="age"
                        ref={userAgeRef}
                        type="number"
                        className="w-100 mb-4 input-box"
                        placeholder="Age"
                      />
                      <div className="text-danger">{errors.age}</div>
                    </div>
                    <div className="col-md-12 col-12">
                      <label>Gender</label> <span className="text-danger font-weight-bold">*</span>
                      <div className="genderbox">
                        <div className="row">
                          <div className="col-4">
                            <div className="genderblock">
                              <div className="custom-control custom-checkbox mb-3">
                                <input
                                  onChange={(e) => handleInputs(e)}
                                  value="Male"
                                  type="radio"
                                  checked={user.gender === "Male"}
                                  className="custom-control-input"
                                  id="Male"
                                  name="gender"
                                />
                                <label
                                  className="custom-control-label w-100"
                                  htmlFor="Male"
                                ></label>
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="genderblock genderblock1">
                              <div className="custom-control custom-checkbox mb-3">
                                <input
                                  onChange={(e) => handleInputs(e)}
                                  value="Female"
                                  type="radio"
                                  checked={user.gender === "Female"}
                                  className="custom-control-input"
                                  id="Female"
                                  name="gender"
                                />
                                <label
                                  className="custom-control-label w-100"
                                  htmlFor="Female"
                                ></label>
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="genderblock genderblock2">
                              <div className="custom-control custom-checkbox mb-3">
                                <input
                                  onChange={(e) => handleInputs(e)}
                                  value="Non-Binary"
                                  type="radio"
                                  checked={user.gender === "Non-Binary"}
                                  className="custom-control-input"
                                  id="Non-Binary"
                                  name="gender"
                                />
                                <label
                                  className="custom-control-label w-100"
                                  htmlFor="Non-Binary"
                                ></label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-danger">{errors.gender}</div>
                    </div>
                    <div className="col-md-12 col-12 my-4">
                      <div className="d-flex justify-content-between mb-4">
                        <label className="pt-2 mb-0">Height<span className="text-danger font-weight-bold">*</span></label>
                        <div className="feet-metter d-flex">
                          <input
                            onChange={(e) => handleInputs(e)}
                            checked={user.heightisfeet === true}
                            value={true}
                            type="radio"
                            ref={userHeightRef}
                            name="heightisfeet"
                            id="radio1"
                          />
                          <label className="input_lbl mr-2" htmlFor="radio1">
                            Feet
                          </label>
                          <input
                            onChange={(e) => handleInputs(e)}
                            checked={user.heightisfeet === false}
                            value={false}
                            type="radio"
                            ref={userHeightRef}
                            name="heightisfeet"
                            id="radio2"
                          />
                          <label className="input_lbl" htmlFor="radio2">
                            Meters
                          </label>
                        </div>
                      </div>
                      <div className="scale-hw">
                        <ul key="heightul" id="heightul" className="scroll" ref={heightRef}>
                          {user.heightisfeet === true
                            ? range(4, 8, 1).map((number, index) => (
                              <span
                                key={Math.random().toString(36).substr(2, 9)}
                              >
                                <li
                                  onClick={(e) =>
                                    SelectHeight(e, number - 1 + 0.1)
                                  }
                                  title={number - 1 + 0.1}
                                  className={`list-item ${user.height === number - 1 + 0.1
                                    ? "active"
                                    : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) =>
                                    SelectHeight(e, number - 1 + 0.2)
                                  }
                                  title={number - 1 + 0.2}
                                  className={`list-item ${user.height === number - 1 + 0.2
                                    ? "active"
                                    : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) =>
                                    SelectHeight(e, number - 1 + 0.3)
                                  }
                                  title={number - 1 + 0.3}
                                  className={`list-item ${user.height === number - 1 + 0.3
                                    ? "active"
                                    : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) =>
                                    SelectHeight(e, number - 1 + 0.4)
                                  }
                                  title={number - 1 + 0.4}
                                  className={`list-item ${user.height === number - 1 + 0.4
                                    ? "active"
                                    : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) =>
                                    SelectHeight(e, number - 1 + 0.5)
                                  }
                                  title={number - 1 + 0.5}
                                  className={`list-item ${user.height === number - 1 + 0.5
                                    ? "active"
                                    : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) =>
                                    SelectHeight(e, number - 1 + 0.6)
                                  }
                                  title={number - 1 + 0.6}
                                  className={`list-item ${user.height === number - 1 + 0.6
                                    ? "active"
                                    : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) =>
                                    SelectHeight(e, number - 1 + 0.7)
                                  }
                                  title={number - 1 + 0.7}
                                  className={`list-item ${user.height === number - 1 + 0.7
                                    ? "active"
                                    : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) =>
                                    SelectHeight(e, number - 1 + 0.8)
                                  }
                                  title={number - 1 + 0.8}
                                  className={`list-item ${user.height === number - 1 + 0.8
                                    ? "active"
                                    : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) =>
                                    SelectHeight(e, number - 1 + 0.9)
                                  }
                                  title={number - 1 + 0.9}
                                  className={`list-item ${user.height === number - 1 + 0.9
                                    ? "active"
                                    : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectHeight(e, number)}
                                  title={number}
                                  className={`list-item ${user.height === number ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height"></span>
                                  <label>{number}</label>
                                </li>
                              </span>
                            ))
                            : range(100, 240, 10).map((number, index) => (
                              <span
                                key={Math.random().toString(36).substr(2, 9)}
                              >
                                <li
                                  onClick={(e) => SelectHeight(e, number - 9)}
                                  title={number - 9}
                                  className={`list-item ${user.height === number - 9 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectHeight(e, number - 8)}
                                  title={number - 8}
                                  className={`list-item ${user.height === number - 8 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectHeight(e, number - 7)}
                                  title={number - 7}
                                  className={`list-item ${user.height === number - 7 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectHeight(e, number - 6)}
                                  title={number - 6}
                                  className={`list-item ${user.height === number - 6 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectHeight(e, number - 5)}
                                  title={number - 5}
                                  className={`list-item ${user.height === number - 5 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectHeight(e, number - 4)}
                                  title={number - 4}
                                  className={`list-item ${user.height === number - 4 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectHeight(e, number - 3)}
                                  title={number - 3}
                                  className={`list-item ${user.height === number - 3 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectHeight(e, number - 2)}
                                  title={number - 2}
                                  className={`list-item ${user.height === number - 2 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectHeight(e, number - 1)}
                                  title={number - 1}
                                  className={`list-item ${user.height === number - 1 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectHeight(e, number)}
                                  title={number}
                                  className={`list-item ${user.height === number ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height"></span>
                                  <label>{number}</label>
                                </li>
                              </span>
                            ))}
                        </ul>
                      </div>
                      <div className="text-danger">{errors.height}</div>
                    </div>
                    <div className="col-md-12 col-12">
                      <div className="d-flex justify-content-between mb-4">
                        <label className="pt-2 mb-0">Weight<span className="text-danger font-weight-bold">*</span></label>
                        <div className="feet-metter d-flex">
                          <input
                            onChange={(e) => handleInputs(e)}
                            checked={user.weightiskg === true}
                            value={true}
                            ref={userWeightRef}
                            type="radio"
                            name="weightiskg"
                            id="radio3"
                          />
                          <label className="input_lbl mr-2" htmlFor="radio3">
                            Kg
                          </label>
                          <input
                            onChange={(e) => handleInputs(e)}
                            checked={user.weightiskg === false}
                            ref={userWeightRef}
                            value={false}
                            type="radio"
                            name="weightiskg"
                            id="radio4"
                          />
                          <label className="input_lbl" htmlFor="radio4">
                            Pound
                          </label>
                        </div>
                      </div>
                      <div className="scale-hw">
                        <ul id="weightul" ref={weightRef} className="scroll">
                          {user.weightiskg === true
                            ? range(30, 210, 10).map((number, index) => (
                              <span
                                key={Math.random().toString(36).substr(2, 9)}
                              >
                                <li
                                  onClick={(e) => SelectWeight(e, number - 9)}
                                  title={number - 9}
                                  className={`list-item ${user.weight === number - 9 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectWeight(e, number - 8)}
                                  title={number - 8}
                                  className={`list-item ${user.weight === number - 8 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectWeight(e, number - 7)}
                                  title={number - 7}
                                  className={`list-item ${user.weight === number - 7 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectWeight(e, number - 6)}
                                  title={number - 6}
                                  className={`list-item ${user.weight === number - 6 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectWeight(e, number - 5)}
                                  title={number - 5}
                                  className={`list-item ${user.weight === number - 5 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectWeight(e, number - 4)}
                                  title={number - 4}
                                  className={`list-item ${user.weight === number - 4 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectWeight(e, number - 3)}
                                  title={number - 3}
                                  className={`list-item ${user.weight === number - 3 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectWeight(e, number - 2)}
                                  title={number - 2}
                                  className={`list-item ${user.weight === number - 2 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectWeight(e, number - 1)}
                                  title={number - 1}
                                  className={`list-item ${user.weight === number - 1 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectWeight(e, number)}
                                  title={number}
                                  className={`list-item ${user.weight === number ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height"></span>
                                  <label>{number}</label>
                                </li>
                              </span>
                            ))
                            : range(30, 300, 5).map((number, index) => (
                              <span
                                key={Math.random().toString(36).substr(2, 9)}
                              >
                                <li
                                  onClick={(e) => SelectWeight(e, number - 4)}
                                  title={number - 4}
                                  className={`list-item ${user.weight === number - 4 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectWeight(e, number - 3)}
                                  title={number}
                                  className={`list-item ${user.weight === number - 3 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectWeight(e, number - 2)}
                                  title={number - 2}
                                  className={`list-item ${user.weight === number - 2 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectWeight(e, number - 1)}
                                  title={number - 1}
                                  className={`list-item ${user.weight === number - 1 ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height subline"></span>
                                </li>
                                <li
                                  onClick={(e) => SelectWeight(e, number)}
                                  title={number}
                                  className={`list-item ${user.weight === number ? "active" : ""
                                    }`}
                                >
                                  <span className="line-height"></span>
                                  <label>{number}</label>
                                </li>
                              </span>
                            ))}
                        </ul>
                      </div>
                      <div className="text-danger">{errors.weight}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12 mb-4">
                <div className="trainer-main-box">
                  <div className="row">
                    <div className="col-12">
                      <label>Equipment Available</label>
                      <input
                        onChange={(e) => handleInputs(e)}
                        value={user.equipmentavailable}
                        name="equipmentavailable"
                        type="text"
                        className="input-box w-100 mb-2"
                        placeholder="Equipment Available"
                      />
                      <div className="text-danger">
                        {errors.equipmentavailable}
                      </div>
                    </div>
                    <div className="col-md-12 mt-3 mb-4">
                      <label>Fitness Goals</label>
                      <div className="fitnessgoal">
                        <div className="cat action wrapper">
                          {selectAllGoal.map((ele, index) => {
                            const selected = fitnessgoal.filter((x) => (ele.name === x) ? true : false);
                            let checked = (selected.length !== 0) ? true : false;

                            return (<>
                              <label className="option option-1">
                                <input
                                  type="checkbox"
                                  value={ele?.name}
                                  name={`fitnessgoals${index + 1}`}
                                  onChange={(e) => handleFitnessGoal(e)}
                                  id={`fitnessgoals${index + 1}`}
                                  checked={checked}
                                />
                                <span>{ele?.name}</span>
                              </label>
                            </>)
                          })}

                        </div>
                      </div>
                      <div className="text-danger">{errors.fitnessgoal}</div>
                    </div>
                    <div className="col-12">
                      <label>Others</label>
                      <textarea
                        onChange={(e) => handleInputs(e)}
                        value={user.otherfitnessgoals}
                        name="otherfitnessgoals"
                        className="w-100 mb-3 other-txtarea"
                        placeholder="Please Write Any Other Fitness Goals."
                      ></textarea>
                    </div>

                    <div className="col-12">
                      <label>Injuries/Health Issues</label> <span className="text-danger font-weight-bold">*</span>
                      <textarea
                        onChange={(e) => handleInputs(e)}
                        value={user.injuriesorhelthissues}
                        name="injuriesorhelthissues"
                        className="w-100 mb-4 txt-area"
                        ref={userInjuriesRef}
                        placeholder="Let us know of any injuries or health issues you have you have or write 'none' if you have no concerns."
                      ></textarea>
                      <div className="text-danger">{errors.injuriesorhelthissues}</div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex justify-content-between align-items-centerd mb-3">
                        <label className="mb-0">Progress Photos</label>
                        <div
                          className="banner-btn mt-0 mb-0"
                          onClick={(e) => {
                            pageNavigate(e);
                          }}
                        >
                          Progress View
                        </div>
                      </div>
                      <Link className="upload_btn mb-4" to="/UploadProgressPhotos">
                        Upload
                      </Link>
                    </div>

                    <div className="col-md-12">
                      <div className="socialmedia-block">
                        <div className="d-flex justify-content-between align-items-center">
                          <h4 className="ticket-title">Social Media</h4>
                          <button className="media-btn" onClick={openModal}>
                            Add Social Media
                          </button>
                        </div>
                        {user?.socialmedia?.length > 0 ? (
                          <>
                            <ul className="list-inlne social-media-icon">
                              {user?.socialmedia?.map((media, i) => (
                                <li key={i} className="list-inline-item position-relative" >
                                  <Link
                                    className="text-decoration-none"
                                    to={media.url}
                                    target="_blank"
                                  >
                                    <span className="px-1">
                                      <i
                                        className={media.icon}
                                        aria-hidden="true"
                                        title={media.platform}
                                      ></i>
                                    </span>
                                  </Link>
                                  <div
                                    onClick={(e) => removeMedi(media._id)}
                                    className="c-mediaicon"
                                  >
                                    <i className="fa fa-times"></i>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <>
                            <div className="no-record-box">
                              <img src="/img/no-record.png" alt="no-img" />
                              <h4 className="font-weight-bold">
                                No Social Media Added Yet
                              </h4>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6 mx-auto">
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-12 mb-4">
                    <button
                      className="training_btn"
                      onClick={() => naviagte("/myprofile")}
                    >
                      Back
                    </button>
                  </div>
                  <div className="col-md-6 col-sm-6 col-12">
                    <Link
                      to="#"
                      onClick={(e) => {
                        PostEditProfile(e);
                      }}
                      className="training_btn edit_save"
                    >
                      Save
                    </Link>
                  </div>
                  <div className="col-md-12 text-center">
                    <Link
                      target="_blank"
                      // to="/termsncondition"
                      to="/public-view/termsandconditionapp"
                      className="text-center terms-condition"
                    >
                      Terms & Conditions
                    </Link>
                  </div>
                </div>
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <Modal
        show={socialmediaModal}
        onHide={(e) => {
          setSocialmediaModal(false);
        }}
        className="mbody socialmedia"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {mediaLoader &&
          <div className="loading">
            <div className="mainloader"></div>
          </div>
        }
        <Modal.Header className="session-m" closeButton>
          <Modal.Title className="book-title d-flex align-items-center ml-3 mb-0">
            <img src="/img/common-health.png" alt="" />
            <h4 className="mb-0 ml-3 font-weight-bold">
              Add Social Media
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="col-md-12 col-12 mb-3">
            <label className="lbl-name">Name </label><span className="text-danger font-weight-bold">*</span>

            <select
              className="input-box"
              name="platform"
              ref={iconRef}
              onChange={socialmediaHandle}
            >
              <option value="" disabled selected="selected">
                Select Name
              </option>
              <option value="Facebook">Facebook</option>
              <option value="Instagram">Instagram</option>
              <option value="Twitter">Twitter</option>
              <option value="Linkedin">Linkedin</option>
              <option value="Youtube">Youtube</option>
              <option value="Tiktok">Tiktok </option>
            </select>
            <div className="text-danger">{errors.platform}</div>
          </div>

          <div className="col-md-12 col-12 mb-3">
            <label className="lbl-name"> Social Media Link</label><span className="text-danger font-weight-bold">*</span>
            <input
              className="input-box"
              ref={urlRef}
              type="url"
              placeholder="Url"
              onChange={socialmediaHandle}
              name="url"

            />
            <div className="text-danger">{errors.url}</div>
          </div>
          <div className="col-12">
            <button
              className="training_btn mt-4"
              onClick={socialmediaAdd}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>


    </>
  );
}

export default EditProfile;
